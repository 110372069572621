<script>
  import { userStore, emptyUserStore } from "./../../stores/user.store";
  import { getCsrf, login } from "../../api/login";
  import { onMount } from "svelte";
  import { lastLoggedUser as lastLoggedUserStore } from "./../../stores/currentLoggedUser.store";

  let messages = [];
  let loginError = false;
  let loginInput;
  let submitText = "Prihlásiť sa";

  onMount(() => {
    setTimeout(() => {
      loginInput.focus();
    }, 0);
  });

  async function loginForm(e) {
    loginError = false;
    messages = [];
    let count = 0;
    submitText = "Prihlasovanie .";
    let interval = setInterval(() => {
      if (count % 3 === 0) {
        count = 1;
      } else {
        count++;
      }
      submitText = "Prihlasovanie " + ".".repeat(count);
    }, 300);
    const csrfResponse = await getCsrf();

    const formData = new FormData(e.target);

    const data = {};
    for (let field of formData) {
      const [key, value] = field;
      data[key] = value;
    }

    const loginResponse = await login(data.login_name, data.login_password);
    if (loginResponse.success === false) {
      loginError = true;
      messages = loginResponse.errors;
    } else {
      const storedUser = localStorage.getItem(
        `sm-user-${loginResponse.user.login}`
      );
      if (storedUser) {
        lastLoggedUserStore.updateLastLoggedUser(loginResponse.user.login);
      } else {
        const user = emptyUserStore;
        user.id = loginResponse.user.id;
        user.login = loginResponse.user.login;
        user.fullName = loginResponse.user.name;
        user.email = loginResponse.user.email;
        userStore.updateUser(user);
      }
    }

    clearInterval(interval);
    submitText = "Prihlásiť sa";
  }
</script>

<!-- #login-wrapper -->
<div id="login-wrapper">
  <!-- #login -->
  <div id="login">
    <!-- #login__box -->
    <div id="login__box" class="animate">
      <div class="login_header">
        <img src="/assets/img/smartmap.svg" alt="smartmap" />

        <hr />

        <h1>GEO Informačný portál <strong id="nazov_obce" /></h1>
        <div id="login_response">
          {#if loginError}
            {#each messages as message}
              <span class="error">{message}</span>
            {/each}
          {/if}
        </div>
      </div>
      <div class="body">
        <form
          class="validate"
          id="login_form"
          on:submit|preventDefault={loginForm}
        >
          <!-- .input-group -->
          <div class="input-group">
            <div class="input-label">
              <label for="meno">Meno</label>
              <input
                type="text"
                placeholder="Vaše meno"
                name="login_name"
                id="meno"
                required
                data-msg="Vložte meno"
                autocomplete="off"
                bind:this={loginInput}
              />
            </div>
            <div class="input-label">
              <label for="heslo">Heslo</label>
              <input
                type="password"
                placeholder="Vaše heslo"
                name="login_password"
                id="heslo"
                required
                data-msg="Vložte heslo"
                autocomplete="off"
              />
            </div>
          </div>
          <!-- /.input-group -->

          <input type="submit" id="loginSubmit" value={submitText} />
        </form>
      </div>
    </div>
    <!-- /#login__box -->
  </div>
  <!-- /#login -->

  <!-- #login-bg -->
  <div id="login-bg" class="animate">
    <img src="/assets/img/map.png" alt="" />
  </div>
  <!-- /#login-bg -->

  <!-- #copyright -->
  <div id="copyright" class="animate">
    <div class="inner">
      <a href="http://erasdatapro.sk" target="_blank"
        ><img src="/assets/img/erasdata.png" alt="erasdata" /></a
      >
    </div>
  </div>
  <!-- /#copyright -->
</div>

<!-- /#login-wrapper -->
<style>
  .error {
    color: red;
    display: block;
  }
  #login-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    padding: 40px;
    display: table;
  }
  #login-wrapper .input-group {
    width: 100%;
  }
  #login-wrapper #login {
    display: table-cell;
    vertical-align: middle;
    padding: 80px 0;
  }
  #login-wrapper #login #login__box {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    box-shadow: 0px 15px 3px 2px rgba(0, 0, 0, 0.5);
    max-width: 380px;
    width: 100%;
    z-index: 50;
    margin: 0 auto;
    position: relative;
    background: #084f7d;
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #084f7d 0%,
      #043c69 100%
    );
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #084f7d 0%,
      #043c69 100%
    );
    background: radial-gradient(ellipse at center, #084f7d 0%, #043c69 100%);
  }
  #login-wrapper #login #login__box .login_header {
    padding: 30px 50px 28px;
    text-align: center;
  }
  #login-wrapper #login #login__box .login_header h1 {
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    font-size: 19px;
    line-height: 28px;
  }
  #login-wrapper #login #login__box .login_header h1 strong {
    text-transform: none;
    display: block;
    font-weight: 400;
    font-size: 18px;
  }
  #login-wrapper #login #login__box .login_header img {
    width: 100%;
    max-width: 216px;
    height: 57px;
    margin: 0 auto;
  }
  #login-wrapper #login #login__box .login_header hr {
    margin: 22px 0 24px;
    border: none;
    border-top: 1px dotted rgba(255, 255, 255, 0.2);
    background: none;
  }
  #login-wrapper #login #login__box .login_header #login_response {
    margin-top: 20px;
    color: #c24141;
    font-size: 17px;
  }
  #login-wrapper #login #login__box .body {
    padding: 32px 60px 30px;
    background: url("/assets/img/smartmap-bg.png") repeat-y center top;
  }
  #login-wrapper #login #login__box .body form ::-webkit-input-placeholder {
    color: #6d6e71;
    opacity: 1;
  }
  #login-wrapper #login #login__box .body form :-moz-placeholder {
    /* Firefox 18- */
    color: #6d6e71;
    opacity: 1;
  }
  #login-wrapper #login #login__box .body form ::-moz-placeholder {
    /* Firefox 19+ */
    color: #6d6e71;
    opacity: 1;
  }
  #login-wrapper #login #login__box .body form :-ms-input-placeholder {
    color: #6d6e71;
    opacity: 1;
  }
  #login-wrapper #login #login__box .body form input[type="submit"] {
    width: 100%;
    height: 40px;
    line-height: 42px;
    text-align: center;
    background: #fff;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #14bcf2;
    border: none;
    margin: 2px 0 0 0;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-transition: 0.3s ease background, 0.3s ease color;
    transition: 0.3s ease background, 0.3s ease color;
    padding: 0;
    cursor: pointer;
  }
  #login-wrapper #login #login__box .body form input[type="submit"]:hover {
    color: #fff;
    background: #14bcf2;
  }
  #login-wrapper #login #login__box .body form .input-group {
    background: #fff;
    padding: 0 20px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }
  #login-wrapper #login #login__box .body form .input-group .input-label {
    position: relative;
  }
  #login-wrapper
    #login
    #login__box
    .body
    form
    .input-group
    .input-label:first-child {
    border-bottom: 1px solid #e6e7e8;
  }
  #login-wrapper #login #login__box .body form .input-group .input-label input {
    width: 100%;
    background: none;
    border: none;
    height: 40px;
    padding: 0 0 0 66px;
    font-size: 14px;
    color: #6d6e71;
    font-weight: 400;
    box-shadow: none;
    line-height: 42px;
    outline: none;
  }
  #login-wrapper #login #login__box .body form .input-group .input-label label {
    position: absolute;
    line-height: 40px;
    height: 40px;
    top: 0;
    left: 0;
    color: #14bcf2;
    font-weight: 400;
    cursor: pointer;
  }
  #login-wrapper #login-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  #login-wrapper #login-bg img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    height: auto;
  }
  #login-wrapper #copyright {
    position: absolute;
    bottom: 40px;
    right: 40px;
    display: table;
  }
  #login-wrapper #copyright .inner {
    display: table-cell;
    vertical-align: middle;
  }
  #login-wrapper #copyright .inner a {
    display: inline-block;
    vertical-align: middle;
  }
  #login-wrapper #copyright .inner a img {
    width: 140px;
    height: auto;
    margin: 0 30px 0 0;
  }
</style>
