export default [
  {
    time: "15:15:11",
    obec: "Pezinok",
    speed: "4 km/h",
    distance: "0,00",
    lat: 48.291999,
    lng: 17.27658,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:15:29",
    obec: "Pezinok",
    speed: "7 km/h",
    distance: "0,00",
    lat: 48.292128,
    lng: 17.276816,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:15:34",
    obec: "Pezinok",
    speed: "12 km/h",
    distance: "0,04",
    lat: 48.292128,
    lng: 17.277074,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:15:52",
    obec: "Pezinok",
    speed: "26 km/h",
    distance: "0,14",
    lat: 48.292192,
    lng: 17.278576,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:16:04",
    obec: "Pezinok",
    speed: "31 km/h",
    distance: "0,25",
    lat: 48.292353,
    lng: 17.279971,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:16:16",
    obec: "Pezinok",
    speed: "21 km/h",
    distance: "0,32",
    lat: 48.292525,
    lng: 17.281065,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:16:28",
    obec: "Pezinok",
    speed: "23 km/h",
    distance: "0,39",
    lat: 48.292761,
    lng: 17.282009,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:16:41",
    obec: "Pezinok",
    speed: "29 km/h",
    distance: "0,50",
    lat: 48.293061,
    lng: 17.283361,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:16:49",
    obec: "Pezinok",
    speed: "33 km/h",
    distance: "0,57",
    lat: 48.293147,
    lng: 17.284369,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:16:59",
    obec: "Pezinok",
    speed: "38 km/h",
    distance: "0,68",
    lat: 48.293179,
    lng: 17.285786,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:17:09",
    obec: "Pezinok",
    speed: "31 km/h",
    distance: "0,78",
    lat: 48.293211,
    lng: 17.287202,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:17:17",
    obec: "Pezinok",
    speed: "14 km/h",
    distance: "0,82",
    lat: 48.293233,
    lng: 17.287695,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:17:22",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "0,85",
    lat: 48.293211,
    lng: 17.287931,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:18:29",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "0,85",
    lat: 48.293211,
    lng: 17.287931,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:18:39",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "0,85",
    lat: 48.293211,
    lng: 17.288039,
    pluh: "hore",
    sypac: "nenesype",
  },
  {
    time: "15:20:19",
    obec: "Pezinok",
    speed: "13 km/h",
    distance: "0,85",
    lat: 48.293254,
    lng: 17.28821,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:20:35",
    obec: "Pezinok",
    speed: "27 km/h",
    distance: "0,96",
    lat: 48.293351,
    lng: 17.289541,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:20:47",
    obec: "Pezinok",
    speed: "33 km/h",
    distance: "1,07",
    lat: 48.293501,
    lng: 17.290871,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:20:54",
    obec: "Pezinok",
    speed: "37 km/h",
    distance: "1,14",
    lat: 48.293705,
    lng: 17.291815,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:21:03",
    obec: "Pezinok",
    speed: "36 km/h",
    distance: "1,24",
    lat: 48.29393,
    lng: 17.293017,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:21:14",
    obec: "Pezinok",
    speed: "35 km/h",
    distance: "1,35",
    lat: 48.294177,
    lng: 17.294412,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:21:25",
    obec: "Pezinok",
    speed: "31 km/h",
    distance: "1,46",
    lat: 48.294434,
    lng: 17.295742,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:21:37",
    obec: "Pezinok",
    speed: "33 km/h",
    distance: "1,56",
    lat: 48.294703,
    lng: 17.297137,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:21:48",
    obec: "Pezinok",
    speed: "33 km/h",
    distance: "1,67",
    lat: 48.294917,
    lng: 17.298446,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:21:58",
    obec: "Pezinok",
    speed: "20 km/h",
    distance: "1,74",
    lat: 48.295067,
    lng: 17.299497,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:22:13",
    obec: "Pezinok",
    speed: "30 km/h",
    distance: "1,81",
    lat: 48.295303,
    lng: 17.30057,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:22:22",
    obec: "Pezinok",
    speed: "40 km/h",
    distance: "1,92",
    lat: 48.29555,
    lng: 17.30175,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:22:30",
    obec: "Pezinok",
    speed: "41 km/h",
    distance: "1,99",
    lat: 48.295754,
    lng: 17.302909,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:22:38",
    obec: "Pezinok",
    speed: "39 km/h",
    distance: "2,10",
    lat: 48.295969,
    lng: 17.304111,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:22:47",
    obec: "Pezinok",
    speed: "40 km/h",
    distance: "2,20",
    lat: 48.296237,
    lng: 17.305419,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:22:56",
    obec: "Pezinok",
    speed: "39 km/h",
    distance: "2,31",
    lat: 48.296484,
    lng: 17.30675,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:23:05",
    obec: "Pezinok",
    speed: "41 km/h",
    distance: "2,42",
    lat: 48.296763,
    lng: 17.30808,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:23:14",
    obec: "Pezinok",
    speed: "42 km/h",
    distance: "2,52",
    lat: 48.296891,
    lng: 17.309453,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "15:23:23",
    obec: "Pezinok",
    speed: "39 km/h",
    distance: "2,63",
    lat: 48.29702,
    lng: 17.310891,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:23:32",
    obec: "Pezinok",
    speed: "41 km/h",
    distance: "2,70",
    lat: 48.297138,
    lng: 17.312243,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:23:41",
    obec: "Šenkvice",
    speed: "44 km/h",
    distance: "2,84",
    lat: 48.297245,
    lng: 17.313681,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:23:50",
    obec: "Šenkvice",
    speed: "44 km/h",
    distance: "2,95",
    lat: 48.297374,
    lng: 17.315161,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:23:58",
    obec: "Šenkvice",
    speed: "43 km/h",
    distance: "3,02",
    lat: 48.297492,
    lng: 17.316427,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:24:07",
    obec: "Šenkvice",
    speed: "44 km/h",
    distance: "3,13",
    lat: 48.297567,
    lng: 17.317886,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:24:15",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "3,24",
    lat: 48.297524,
    lng: 17.319238,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:24:23",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "3,34",
    lat: 48.297524,
    lng: 17.32059,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:24:31",
    obec: "Šenkvice",
    speed: "43 km/h",
    distance: "3,45",
    lat: 48.297503,
    lng: 17.321942,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:24:40",
    obec: "Šenkvice",
    speed: "43 km/h",
    distance: "3,56",
    lat: 48.297503,
    lng: 17.32338,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:24:49",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "3,66",
    lat: 48.297481,
    lng: 17.324882,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:24:57",
    obec: "Šenkvice",
    speed: "43 km/h",
    distance: "3,77",
    lat: 48.297396,
    lng: 17.326233,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:25:05",
    obec: "Šenkvice",
    speed: "44 km/h",
    distance: "3,88",
    lat: 48.297363,
    lng: 17.327542,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:25:14",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "3,98",
    lat: 48.297374,
    lng: 17.32898,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:25:20",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "4,02",
    lat: 48.297471,
    lng: 17.329774,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:25:29",
    obec: "Šenkvice",
    speed: "44 km/h",
    distance: "4,12",
    lat: 48.297846,
    lng: 17.331126,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:25:37",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "4,23",
    lat: 48.298189,
    lng: 17.332392,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:25:45",
    obec: "Šenkvice",
    speed: "46 km/h",
    distance: "4,34",
    lat: 48.298511,
    lng: 17.333679,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:25:53",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "4,44",
    lat: 48.298822,
    lng: 17.334967,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:25:59",
    obec: "Šenkvice",
    speed: "42 km/h",
    distance: "4,52",
    lat: 48.299058,
    lng: 17.335911,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:26:09",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "4,62",
    lat: 48.299413,
    lng: 17.337241,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:26:21",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "4,73",
    lat: 48.299734,
    lng: 17.33855,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:26:28",
    obec: "Šenkvice",
    speed: "21 km/h",
    distance: "4,80",
    lat: 48.29982,
    lng: 17.339237,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:26:33",
    obec: "Šenkvice",
    speed: "12 km/h",
    distance: "4,80",
    lat: 48.299831,
    lng: 17.339537,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:26:55",
    obec: "Šenkvice",
    speed: "22 km/h",
    distance: "4,87",
    lat: 48.299713,
    lng: 17.340589,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:27:08",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "4,98",
    lat: 48.299488,
    lng: 17.341897,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:27:16",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "5,05",
    lat: 48.299305,
    lng: 17.342863,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:27:26",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "5,16",
    lat: 48.299016,
    lng: 17.343893,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:27:36",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "5,23",
    lat: 48.298511,
    lng: 17.344987,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:27:47",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "5,33",
    lat: 48.297964,
    lng: 17.346168,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:27:58",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "5,44",
    lat: 48.297589,
    lng: 17.347391,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:28:10",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "5,55",
    lat: 48.297149,
    lng: 17.348549,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:28:22",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "5,65",
    lat: 48.296666,
    lng: 17.349687,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:28:30",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "5,72",
    lat: 48.29628,
    lng: 17.350459,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:28:37",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "5,80",
    lat: 48.295851,
    lng: 17.351038,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:28:44",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "5,87",
    lat: 48.295325,
    lng: 17.351489,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:28:55",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "5,94",
    lat: 48.294445,
    lng: 17.351854,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:29:04",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "6,04",
    lat: 48.293812,
    lng: 17.352197,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:29:09",
    obec: "Šenkvice",
    speed: "22 km/h",
    distance: "6,08",
    lat: 48.293565,
    lng: 17.352455,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:29:24",
    obec: "Šenkvice",
    speed: "24 km/h",
    distance: "6,15",
    lat: 48.293029,
    lng: 17.353528,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:29:32",
    obec: "Šenkvice",
    speed: "20 km/h",
    distance: "6,22",
    lat: 48.292707,
    lng: 17.353957,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:29:42",
    obec: "Šenkvice",
    speed: "19 km/h",
    distance: "6,26",
    lat: 48.292825,
    lng: 17.354558,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:29:54",
    obec: "Šenkvice",
    speed: "18 km/h",
    distance: "6,33",
    lat: 48.293394,
    lng: 17.354665,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:30:01",
    obec: "Šenkvice",
    speed: "14 km/h",
    distance: "6,36",
    lat: 48.293716,
    lng: 17.354665,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:30:11",
    obec: "Šenkvice",
    speed: "10 km/h",
    distance: "6,40",
    lat: 48.29393,
    lng: 17.354643,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:30:29",
    obec: "Šenkvice",
    speed: "20 km/h",
    distance: "6,47",
    lat: 48.294703,
    lng: 17.354622,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:30:39",
    obec: "Šenkvice",
    speed: "17 km/h",
    distance: "6,54",
    lat: 48.295024,
    lng: 17.355008,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:30:50",
    obec: "Šenkvice",
    speed: "23 km/h",
    distance: "6,58",
    lat: 48.29466,
    lng: 17.35548,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:31:11",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "6,72",
    lat: 48.293876,
    lng: 17.356939,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:31:21",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "6,83",
    lat: 48.293394,
    lng: 17.357948,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:31:30",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "6,90",
    lat: 48.293083,
    lng: 17.359085,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:31:37",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "6,97",
    lat: 48.292761,
    lng: 17.3599,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:31:48",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "7,11",
    lat: 48.292171,
    lng: 17.361145,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:31:57",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "7,18",
    lat: 48.29172,
    lng: 17.362132,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:32:05",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "7,25",
    lat: 48.291398,
    lng: 17.362947,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:32:19",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "7,40",
    lat: 48.291259,
    lng: 17.364578,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:32:28",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "7,47",
    lat: 48.291065,
    lng: 17.365844,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:32:37",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "7,57",
    lat: 48.290679,
    lng: 17.366831,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:32:47",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "7,68",
    lat: 48.290154,
    lng: 17.368033,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:32:56",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "7,75",
    lat: 48.289692,
    lng: 17.369192,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:33:05",
    obec: "Šenkvice",
    speed: "41 km/h",
    distance: "7,86",
    lat: 48.289209,
    lng: 17.37035,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:33:14",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "7,96",
    lat: 48.288759,
    lng: 17.371531,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:33:22",
    obec: "Šenkvice",
    speed: "23 km/h",
    distance: "8,04",
    lat: 48.288491,
    lng: 17.372496,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:33:27",
    obec: "Šenkvice",
    speed: "11 km/h",
    distance: "8,07",
    lat: 48.288383,
    lng: 17.372732,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:33:38",
    obec: "Šenkvice",
    speed: "6 km/h",
    distance: "8,07",
    lat: 48.28833,
    lng: 17.372947,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:33:43",
    obec: "Šenkvice",
    speed: "14 km/h",
    distance: "8,11",
    lat: 48.288297,
    lng: 17.373161,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:33:50",
    obec: "Šenkvice",
    speed: "24 km/h",
    distance: "8,14",
    lat: 48.288169,
    lng: 17.373655,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:34:02",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "8,25",
    lat: 48.287793,
    lng: 17.375007,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:34:12",
    obec: "Šenkvice",
    speed: "43 km/h",
    distance: "8,36",
    lat: 48.287428,
    lng: 17.376359,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:34:19",
    obec: "Šenkvice",
    speed: "43 km/h",
    distance: "8,43",
    lat: 48.287128,
    lng: 17.37741,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:34:28",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "8,57",
    lat: 48.286752,
    lng: 17.378805,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:34:36",
    obec: "Šenkvice",
    speed: "46 km/h",
    distance: "8,68",
    lat: 48.286388,
    lng: 17.380114,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:34:45",
    obec: "Šenkvice",
    speed: "43 km/h",
    distance: "8,78",
    lat: 48.286012,
    lng: 17.381465,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:34:54",
    obec: "Šenkvice",
    speed: "44 km/h",
    distance: "8,89",
    lat: 48.285647,
    lng: 17.382839,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "15:35:02",
    obec: "Šenkvice",
    speed: "47 km/h",
    distance: "9,00",
    lat: 48.285283,
    lng: 17.384126,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:35:08",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "9,07",
    lat: 48.284993,
    lng: 17.385092,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:35:14",
    obec: "Šenkvice",
    speed: "44 km/h",
    distance: "9,10",
    lat: 48.284746,
    lng: 17.386036,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:35:19",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "9,17",
    lat: 48.284521,
    lng: 17.386765,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:35:28",
    obec: "Šenkvice",
    speed: "43 km/h",
    distance: "9,28",
    lat: 48.283888,
    lng: 17.387795,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:35:34",
    obec: "Blatné",
    speed: "46 km/h",
    distance: "9,35",
    lat: 48.283405,
    lng: 17.388546,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:35:42",
    obec: "Blatné",
    speed: "46 km/h",
    distance: "9,46",
    lat: 48.282761,
    lng: 17.389576,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:35:48",
    obec: "Blatné",
    speed: "47 km/h",
    distance: "9,53",
    lat: 48.282279,
    lng: 17.390327,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:35:56",
    obec: "Blatné",
    speed: "47 km/h",
    distance: "9,64",
    lat: 48.281635,
    lng: 17.391336,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:36:04",
    obec: "Blatné",
    speed: "48 km/h",
    distance: "9,74",
    lat: 48.280991,
    lng: 17.392387,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:36:12",
    obec: "Blatné",
    speed: "49 km/h",
    distance: "9,85",
    lat: 48.280283,
    lng: 17.393439,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:36:20",
    obec: "Blatné",
    speed: "50 km/h",
    distance: "9,96",
    lat: 48.279607,
    lng: 17.394533,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:36:27",
    obec: "Blatné",
    speed: "50 km/h",
    distance: "10,06",
    lat: 48.278996,
    lng: 17.39552,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:36:34",
    obec: "Blatné",
    speed: "48 km/h",
    distance: "10,17",
    lat: 48.278395,
    lng: 17.396443,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:36:42",
    obec: "Blatné",
    speed: "47 km/h",
    distance: "10,28",
    lat: 48.277772,
    lng: 17.39758,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:36:50",
    obec: "Blatné",
    speed: "50 km/h",
    distance: "10,38",
    lat: 48.277225,
    lng: 17.398825,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:36:57",
    obec: "Blatné",
    speed: "52 km/h",
    distance: "10,49",
    lat: 48.27671,
    lng: 17.399962,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:37:04",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "10,60",
    lat: 48.276206,
    lng: 17.401078,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:37:12",
    obec: "Blatné",
    speed: "52 km/h",
    distance: "10,70",
    lat: 48.275637,
    lng: 17.402365,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:37:19",
    obec: "Blatné",
    speed: "52 km/h",
    distance: "10,81",
    lat: 48.275133,
    lng: 17.403502,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:37:26",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "10,88",
    lat: 48.274618,
    lng: 17.404618,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:37:34",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "11,02",
    lat: 48.274082,
    lng: 17.405927,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:37:40",
    obec: "Blatné",
    speed: "52 km/h",
    distance: "11,09",
    lat: 48.273674,
    lng: 17.406893,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:37:46",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "11,20",
    lat: 48.27317,
    lng: 17.407858,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:37:54",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "11,31",
    lat: 48.272612,
    lng: 17.409124,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:38:02",
    obec: "Blatné",
    speed: "47 km/h",
    distance: "11,41",
    lat: 48.272097,
    lng: 17.410326,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:38:09",
    obec: "Blatné",
    speed: "45 km/h",
    distance: "11,48",
    lat: 48.271614,
    lng: 17.411335,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:38:15",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "11,56",
    lat: 48.271249,
    lng: 17.412128,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:38:24",
    obec: "Blatné",
    speed: "41 km/h",
    distance: "11,66",
    lat: 48.270734,
    lng: 17.413352,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:38:33",
    obec: "Blatné",
    speed: "38 km/h",
    distance: "11,77",
    lat: 48.270198,
    lng: 17.414467,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:38:40",
    obec: "Blatné",
    speed: "38 km/h",
    distance: "11,84",
    lat: 48.269833,
    lng: 17.415347,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:38:47",
    obec: "Blatné",
    speed: "38 km/h",
    distance: "11,95",
    lat: 48.269404,
    lng: 17.416055,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:38:56",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "12,02",
    lat: 48.268674,
    lng: 17.416956,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:39:04",
    obec: "Blatné",
    speed: "39 km/h",
    distance: "12,12",
    lat: 48.268031,
    lng: 17.417707,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:39:11",
    obec: "Blatné",
    speed: "32 km/h",
    distance: "12,20",
    lat: 48.267484,
    lng: 17.418072,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:39:18",
    obec: "Blatné",
    speed: "36 km/h",
    distance: "12,27",
    lat: 48.267065,
    lng: 17.418673,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:39:25",
    obec: "Blatné",
    speed: "38 km/h",
    distance: "12,34",
    lat: 48.266614,
    lng: 17.419446,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:39:32",
    obec: "Blatné",
    speed: "39 km/h",
    distance: "12,41",
    lat: 48.266185,
    lng: 17.420282,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:39:38",
    obec: "Blatné",
    speed: "40 km/h",
    distance: "12,48",
    lat: 48.265831,
    lng: 17.42099,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:39:47",
    obec: "Blatné",
    speed: "40 km/h",
    distance: "12,59",
    lat: 48.265209,
    lng: 17.421999,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:39:55",
    obec: "Blatné",
    speed: "28 km/h",
    distance: "12,66",
    lat: 48.264683,
    lng: 17.422686,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:40:00",
    obec: "Blatné",
    speed: "22 km/h",
    distance: "12,69",
    lat: 48.264361,
    lng: 17.422965,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:40:06",
    obec: "Blatné",
    speed: "16 km/h",
    distance: "12,73",
    lat: 48.264082,
    lng: 17.423072,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:40:16",
    obec: "Blatné",
    speed: "3 km/h",
    distance: "12,76",
    lat: 48.263793,
    lng: 17.422943,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:40:40",
    obec: "Blatné",
    speed: "6 km/h",
    distance: "12,76",
    lat: 48.263696,
    lng: 17.422943,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:40:50",
    obec: "Blatné",
    speed: "12 km/h",
    distance: "12,80",
    lat: 48.263836,
    lng: 17.423222,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:41:02",
    obec: "Blatné",
    speed: "19 km/h",
    distance: "12,87",
    lat: 48.264361,
    lng: 17.423694,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:41:07",
    obec: "Blatné",
    speed: "11 km/h",
    distance: "12,91",
    lat: 48.264512,
    lng: 17.423801,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:41:17",
    obec: "Blatné",
    speed: "12 km/h",
    distance: "12,91",
    lat: 48.264565,
    lng: 17.423544,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:41:28",
    obec: "Blatné",
    speed: "15 km/h",
    distance: "12,98",
    lat: 48.264469,
    lng: 17.423072,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:41:41",
    obec: "Blatné",
    speed: "20 km/h",
    distance: "13,05",
    lat: 48.264898,
    lng: 17.422407,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:41:51",
    obec: "Blatné",
    speed: "25 km/h",
    distance: "13,12",
    lat: 48.265359,
    lng: 17.42172,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:41:59",
    obec: "Blatné",
    speed: "34 km/h",
    distance: "13,16",
    lat: 48.265799,
    lng: 17.421055,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:42:10",
    obec: "Blatné",
    speed: "35 km/h",
    distance: "13,26",
    lat: 48.266411,
    lng: 17.419939,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:42:21",
    obec: "Blatné",
    speed: "32 km/h",
    distance: "13,37",
    lat: 48.26699,
    lng: 17.418845,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:42:30",
    obec: "Blatné",
    speed: "31 km/h",
    distance: "13,48",
    lat: 48.267473,
    lng: 17.418072,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:42:39",
    obec: "Blatné",
    speed: "32 km/h",
    distance: "13,55",
    lat: 48.26817,
    lng: 17.4176,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:42:48",
    obec: "Blatné",
    speed: "34 km/h",
    distance: "13,62",
    lat: 48.268717,
    lng: 17.416914,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:42:59",
    obec: "Blatné",
    speed: "33 km/h",
    distance: "13,72",
    lat: 48.269458,
    lng: 17.415991,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:43:05",
    obec: "Blatné",
    speed: "38 km/h",
    distance: "13,80",
    lat: 48.269801,
    lng: 17.415433,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:43:16",
    obec: "Blatné",
    speed: "39 km/h",
    distance: "13,90",
    lat: 48.270402,
    lng: 17.414124,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:43:22",
    obec: "Blatné",
    speed: "36 km/h",
    distance: "13,97",
    lat: 48.270745,
    lng: 17.413394,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:43:32",
    obec: "Blatné",
    speed: "40 km/h",
    distance: "14,08",
    lat: 48.271271,
    lng: 17.412193,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:43:38",
    obec: "Blatné",
    speed: "40 km/h",
    distance: "14,15",
    lat: 48.271593,
    lng: 17.411442,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:43:46",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "14,26",
    lat: 48.272075,
    lng: 17.41039,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:43:55",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "14,36",
    lat: 48.272623,
    lng: 17.409146,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:44:04",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "14,47",
    lat: 48.273159,
    lng: 17.407966,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:44:12",
    obec: "Blatné",
    speed: "47 km/h",
    distance: "14,58",
    lat: 48.273674,
    lng: 17.406828,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:44:20",
    obec: "Blatné",
    speed: "49 km/h",
    distance: "14,68",
    lat: 48.2742,
    lng: 17.405648,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:44:28",
    obec: "Blatné",
    speed: "50 km/h",
    distance: "14,79",
    lat: 48.274747,
    lng: 17.404425,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:44:36",
    obec: "Blatné",
    speed: "50 km/h",
    distance: "14,90",
    lat: 48.275283,
    lng: 17.403181,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:44:43",
    obec: "Blatné",
    speed: "52 km/h",
    distance: "15,00",
    lat: 48.275809,
    lng: 17.402065,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:44:51",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "15,11",
    lat: 48.276378,
    lng: 17.400777,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:44:59",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "15,22",
    lat: 48.276946,
    lng: 17.399533,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:45:07",
    obec: "Blatné",
    speed: "50 km/h",
    distance: "15,32",
    lat: 48.277504,
    lng: 17.398288,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:45:13",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "15,43",
    lat: 48.277944,
    lng: 17.397323,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:45:21",
    obec: "Blatné",
    speed: "53 km/h",
    distance: "15,54",
    lat: 48.278609,
    lng: 17.396185,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:45:28",
    obec: "Blatné",
    speed: "53 km/h",
    distance: "15,64",
    lat: 48.279242,
    lng: 17.395155,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:45:35",
    obec: "Blatné",
    speed: "53 km/h",
    distance: "15,75",
    lat: 48.279908,
    lng: 17.394147,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:45:41",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "15,82",
    lat: 48.280422,
    lng: 17.393289,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:45:48",
    obec: "Blatné",
    speed: "53 km/h",
    distance: "15,93",
    lat: 48.281088,
    lng: 17.392344,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:45:55",
    obec: "Blatné",
    speed: "52 km/h",
    distance: "16,04",
    lat: 48.281699,
    lng: 17.391357,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:46:02",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "16,14",
    lat: 48.282311,
    lng: 17.390327,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:46:09",
    obec: "Blatné",
    speed: "52 km/h",
    distance: "16,25",
    lat: 48.282944,
    lng: 17.38934,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:46:15",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "16,32",
    lat: 48.283512,
    lng: 17.388482,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:46:22",
    obec: "Šenkvice",
    speed: "48 km/h",
    distance: "16,43",
    lat: 48.284092,
    lng: 17.387559,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:46:28",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "16,50",
    lat: 48.284532,
    lng: 17.386765,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:46:36",
    obec: "Šenkvice",
    speed: "48 km/h",
    distance: "16,60",
    lat: 48.284939,
    lng: 17.385499,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:46:44",
    obec: "Šenkvice",
    speed: "50 km/h",
    distance: "16,71",
    lat: 48.285315,
    lng: 17.384126,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:46:52",
    obec: "Šenkvice",
    speed: "50 km/h",
    distance: "16,82",
    lat: 48.285669,
    lng: 17.382817,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:47:00",
    obec: "Šenkvice",
    speed: "51 km/h",
    distance: "16,92",
    lat: 48.286066,
    lng: 17.381401,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:47:07",
    obec: "Šenkvice",
    speed: "52 km/h",
    distance: 17.03,
    lat: 48.28642,
    lng: 17.380135,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:47:14",
    obec: "Šenkvice",
    speed: "52 km/h",
    distance: 17.14,
    lat: 48.286763,
    lng: 17.378891,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:47:21",
    obec: "Šenkvice",
    speed: "52 km/h",
    distance: 17.24,
    lat: 48.287128,
    lng: 17.377625,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:47:29",
    obec: "Šenkvice",
    speed: "50 km/h",
    distance: 17.35,
    lat: 48.287482,
    lng: 17.37623,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:47:37",
    obec: "Šenkvice",
    speed: "46 km/h",
    distance: 17.46,
    lat: 48.287858,
    lng: 17.374856,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:47:45",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: 17.56,
    lat: 48.288212,
    lng: 17.37359,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:47:53",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: 17.64,
    lat: 48.288523,
    lng: 17.372496,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:48:01",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: 17.74,
    lat: 48.288812,
    lng: 17.371488,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:48:08",
    obec: "Šenkvice",
    speed: "16 km/h",
    distance: 17.78,
    lat: 48.289059,
    lng: 17.370951,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:48:13",
    obec: "Šenkvice",
    speed: "5 km/h",
    distance: 17.78,
    lat: 48.289134,
    lng: 17.37078,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:48:39",
    obec: "Šenkvice",
    speed: "0 km/h",
    distance: 17.81,
    lat: 48.289134,
    lng: 17.370737,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:52:54",
    obec: "Šenkvice",
    speed: "7 km/h",
    distance: 17.81,
    lat: 48.289166,
    lng: 17.370651,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:52:59",
    obec: "Šenkvice",
    speed: "13 km/h",
    distance: 17.81,
    lat: 48.289242,
    lng: 17.370436,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:53:17",
    obec: "Šenkvice",
    speed: "24 km/h",
    distance: 17.92,
    lat: 48.289735,
    lng: 17.369213,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:53:30",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "18,03",
    lat: 48.290229,
    lng: 17.368033,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:53:41",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "18,13",
    lat: 48.290701,
    lng: 17.366853,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:53:51",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "18,20",
    lat: 48.291141,
    lng: 17.365758,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:54:00",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "18,28",
    lat: 48.291312,
    lng: 17.364535,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:54:06",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "18,35",
    lat: 48.291377,
    lng: 17.363806,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:54:13",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "18,42",
    lat: 48.29143,
    lng: 17.362969,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:54:22",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "18,49",
    lat: 48.291817,
    lng: 17.362003,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:54:29",
    obec: "Šenkvice",
    speed: "13 km/h",
    distance: "18,52",
    lat: 48.292042,
    lng: 17.361488,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:54:47",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "18,63",
    lat: 48.292589,
    lng: 17.360351,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:54:56",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "18,74",
    lat: 48.293007,
    lng: 17.359428,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:55:06",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "18,81",
    lat: 48.293372,
    lng: 17.358162,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:55:12",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "18,88",
    lat: 48.293673,
    lng: 17.357433,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:55:20",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "18,95",
    lat: 48.294059,
    lng: 17.356639,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:55:27",
    obec: "Šenkvice",
    speed: "26 km/h",
    distance: "19,02",
    lat: 48.294402,
    lng: 17.355824,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:55:41",
    obec: "Šenkvice",
    speed: "19 km/h",
    distance: "19,13",
    lat: 48.295057,
    lng: 17.355244,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:55:51",
    obec: "Šenkvice",
    speed: "22 km/h",
    distance: "19,16",
    lat: 48.294874,
    lng: 17.354622,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:55:56",
    obec: "Šenkvice",
    speed: "26 km/h",
    distance: "19,20",
    lat: 48.294552,
    lng: 17.3546,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:56:08",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "19,31",
    lat: 48.29363,
    lng: 17.354622,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:56:15",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "19,38",
    lat: 48.293104,
    lng: 17.3546,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:56:20",
    obec: "Šenkvice",
    speed: "21 km/h",
    distance: "19,41",
    lat: 48.292814,
    lng: 17.354472,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:56:30",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "19,48",
    lat: 48.292911,
    lng: 17.353742,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:56:38",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "19,52",
    lat: 48.293319,
    lng: 17.35312,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:56:46",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "19,59",
    lat: 48.293673,
    lng: 17.35239,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:56:52",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "19,66",
    lat: 48.294102,
    lng: 17.35209,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:57:04",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "19,77",
    lat: 48.294982,
    lng: 17.351704,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:57:12",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "19,84",
    lat: 48.295668,
    lng: 17.351317,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:57:19",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "19,91",
    lat: 48.29614,
    lng: 17.350781,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:57:27",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "19,98",
    lat: 48.296537,
    lng: 17.350051,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:57:38",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "20,09",
    lat: 48.297106,
    lng: 17.348828,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:57:46",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "20,16",
    lat: 48.297438,
    lng: 17.347949,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:57:55",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "20,27",
    lat: 48.297771,
    lng: 17.346897,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:58:05",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "20,34",
    lat: 48.298168,
    lng: 17.345674,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:58:15",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "20,44",
    lat: 48.298747,
    lng: 17.34458,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:58:24",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "20,55",
    lat: 48.299134,
    lng: 17.343507,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:58:31",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "20,62",
    lat: 48.299391,
    lng: 17.342563,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:58:41",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "20,73",
    lat: 48.299659,
    lng: 17.341189,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:58:51",
    obec: "Šenkvice",
    speed: "24 km/h",
    distance: "20,80",
    lat: 48.299831,
    lng: 17.340117,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:59:01",
    obec: "Šenkvice",
    speed: "18 km/h",
    distance: "20,87",
    lat: 48.300196,
    lng: 17.339559,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:59:06",
    obec: "Šenkvice",
    speed: "22 km/h",
    distance: "20,91",
    lat: 48.30041,
    lng: 17.339559,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:59:18",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "21,01",
    lat: 48.301312,
    lng: 17.339816,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:59:24",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "21,05",
    lat: 48.301741,
    lng: 17.340095,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:59:29",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "21,08",
    lat: 48.301998,
    lng: 17.340524,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:59:41",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "21,19",
    lat: 48.302481,
    lng: 17.34179,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:59:49",
    obec: "Šenkvice",
    speed: "24 km/h",
    distance: "21,26",
    lat: 48.302846,
    lng: 17.342477,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "15:59:59",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "21,33",
    lat: 48.303339,
    lng: 17.342219,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:00:11",
    obec: "Šenkvice",
    speed: "26 km/h",
    distance: "21,44",
    lat: 48.303683,
    lng: 17.340932,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:00:16",
    obec: "Šenkvice",
    speed: "21 km/h",
    distance: "21,48",
    lat: 48.303844,
    lng: 17.340589,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:00:28",
    obec: "Šenkvice",
    speed: "14 km/h",
    distance: "21,55",
    lat: 48.304401,
    lng: 17.340717,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:00:37",
    obec: "Šenkvice",
    speed: "24 km/h",
    distance: "21,58",
    lat: 48.304659,
    lng: 17.340202,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:00:45",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "21,65",
    lat: 48.305002,
    lng: 17.339559,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:00:51",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "21,69",
    lat: 48.305453,
    lng: 17.339194,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:00:59",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "21,76",
    lat: 48.306011,
    lng: 17.338808,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:01:06",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "21,83",
    lat: 48.306429,
    lng: 17.338121,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:01:16",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "21,94",
    lat: 48.306944,
    lng: 17.336962,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:01:21",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "22,01",
    lat: 48.307255,
    lng: 17.336426,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:01:30",
    obec: "Modra",
    speed: "43 km/h",
    distance: "22,12",
    lat: 48.307996,
    lng: 17.335632,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:01:37",
    obec: "Modra",
    speed: "46 km/h",
    distance: "22,19",
    lat: 48.308672,
    lng: 17.334967,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:01:43",
    obec: "Modra",
    speed: "49 km/h",
    distance: "22,26",
    lat: 48.309294,
    lng: 17.334409,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:01:50",
    obec: "Modra",
    speed: "50 km/h",
    distance: "22,36",
    lat: 48.310002,
    lng: 17.333744,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:01:57",
    obec: "Modra",
    speed: "49 km/h",
    distance: "22,47",
    lat: 48.310764,
    lng: 17.333035,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:02:05",
    obec: "Modra",
    speed: "47 km/h",
    distance: "22,58",
    lat: 48.311622,
    lng: 17.332392,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:02:13",
    obec: "Modra",
    speed: "48 km/h",
    distance: "22,68",
    lat: 48.312523,
    lng: 17.331727,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:02:21",
    obec: "Modra",
    speed: "47 km/h",
    distance: "22,79",
    lat: 48.313339,
    lng: 17.331018,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:02:29",
    obec: "Modra",
    speed: "47 km/h",
    distance: "22,90",
    lat: 48.314165,
    lng: 17.330332,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:02:37",
    obec: "Modra",
    speed: "47 km/h",
    distance: "23,00",
    lat: 48.314991,
    lng: 17.329581,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:02:45",
    obec: "Modra",
    speed: "49 km/h",
    distance: "23,11",
    lat: 48.315881,
    lng: 17.329044,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:02:52",
    obec: "Modra",
    speed: "47 km/h",
    distance: "23,22",
    lat: 48.316675,
    lng: 17.328508,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:02:59",
    obec: "Modra",
    speed: "46 km/h",
    distance: "23,29",
    lat: 48.317297,
    lng: 17.327628,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:03:06",
    obec: "Modra",
    speed: "43 km/h",
    distance: "23,40",
    lat: 48.317963,
    lng: 17.327006,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:03:14",
    obec: "Modra",
    speed: "44 km/h",
    distance: "23,50",
    lat: 48.318746,
    lng: 17.326469,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:03:22",
    obec: "Modra",
    speed: "43 km/h",
    distance: "23,57",
    lat: 48.319454,
    lng: 17.325718,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:03:29",
    obec: "Modra",
    speed: "46 km/h",
    distance: "23,68",
    lat: 48.319969,
    lng: 17.324796,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:03:36",
    obec: "Modra",
    speed: "37 km/h",
    distance: "23,75",
    lat: 48.320387,
    lng: 17.323895,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:03:42",
    obec: "Modra",
    speed: "42 km/h",
    distance: "23,82",
    lat: 48.320806,
    lng: 17.323315,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:03:50",
    obec: "Modra",
    speed: "46 km/h",
    distance: "23,93",
    lat: 48.321557,
    lng: 17.322521,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:03:59",
    obec: "Modra",
    speed: "47 km/h",
    distance: "24,04",
    lat: 48.322383,
    lng: 17.32162,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:04:08",
    obec: "Modra",
    speed: "43 km/h",
    distance: "24,14",
    lat: 48.323177,
    lng: 17.320697,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:04:16",
    obec: "Modra",
    speed: "31 km/h",
    distance: "24,25",
    lat: 48.323842,
    lng: 17.320011,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:04:22",
    obec: "Modra",
    speed: "13 km/h",
    distance: "24,28",
    lat: 48.3241,
    lng: 17.319775,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:04:44",
    obec: "Modra",
    speed: "3 km/h",
    distance: "24,28",
    lat: 48.324218,
    lng: 17.319667,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:05:21",
    obec: "Modra",
    speed: "7 km/h",
    distance: "24,32",
    lat: 48.324175,
    lng: 17.319667,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:05:27",
    obec: "Modra",
    speed: "13 km/h",
    distance: "24,32",
    lat: 48.324024,
    lng: 17.319732,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:05:39",
    obec: "Modra",
    speed: "24 km/h",
    distance: "24,39",
    lat: 48.323552,
    lng: 17.320225,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:05:52",
    obec: "Modra",
    speed: "31 km/h",
    distance: "24,50",
    lat: 48.32278,
    lng: 17.321084,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:06:02",
    obec: "Modra",
    speed: "39 km/h",
    distance: "24,60",
    lat: 48.322061,
    lng: 17.32192,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:06:11",
    obec: "Modra",
    speed: "40 km/h",
    distance: "24,71",
    lat: 48.321299,
    lng: 17.322693,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:06:20",
    obec: "Modra",
    speed: "37 km/h",
    distance: "24,78",
    lat: 48.320559,
    lng: 17.323487,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:06:28",
    obec: "Modra",
    speed: "41 km/h",
    distance: "24,89",
    lat: 48.320087,
    lng: 17.324431,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:06:37",
    obec: "Modra",
    speed: "41 km/h",
    distance: "25,00",
    lat: 48.319486,
    lng: 17.32559,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:06:43",
    obec: "Modra",
    speed: "40 km/h",
    distance: "25,07",
    lat: 48.318982,
    lng: 17.326148,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:06:49",
    obec: "Modra",
    speed: "46 km/h",
    distance: "25,14",
    lat: 48.318403,
    lng: 17.326598,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:06:57",
    obec: "Modra",
    speed: "45 km/h",
    distance: "25,24",
    lat: 48.317587,
    lng: 17.32722,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:07:03",
    obec: "Modra",
    speed: "41 km/h",
    distance: "25,32",
    lat: 48.317115,
    lng: 17.327843,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:07:09",
    obec: "Modra",
    speed: "41 km/h",
    distance: "25,39",
    lat: 48.316664,
    lng: 17.328422,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:07:15",
    obec: "Modra",
    speed: "45 km/h",
    distance: "25,46",
    lat: 48.316074,
    lng: 17.328873,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:07:23",
    obec: "Modra",
    speed: "48 km/h",
    distance: "25,56",
    lat: 48.315184,
    lng: 17.329409,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:07:29",
    obec: "Modra",
    speed: "45 km/h",
    distance: "25,64",
    lat: 48.314519,
    lng: 17.329903,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:07:37",
    obec: "Modra",
    speed: "48 km/h",
    distance: "25,74",
    lat: 48.313736,
    lng: 17.330632,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:07:45",
    obec: "Modra",
    speed: "49 km/h",
    distance: "25,85",
    lat: 48.312877,
    lng: 17.331319,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:07:53",
    obec: "Modra",
    speed: "49 km/h",
    distance: "25,96",
    lat: 48.312008,
    lng: 17.332027,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:08:01",
    obec: "Modra",
    speed: "49 km/h",
    distance: "26,06",
    lat: 48.311139,
    lng: 17.332649,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:08:09",
    obec: "Modra",
    speed: "50 km/h",
    distance: "26,17",
    lat: 48.310259,
    lng: 17.333422,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:08:17",
    obec: "Modra",
    speed: "48 km/h",
    distance: "26,31",
    lat: 48.309401,
    lng: 17.334216,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:08:25",
    obec: "Modra",
    speed: "46 km/h",
    distance: "26,42",
    lat: 48.308586,
    lng: 17.334967,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:08:30",
    obec: "Modra",
    speed: "43 km/h",
    distance: "26,45",
    lat: 48.308124,
    lng: 17.335417,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:08:38",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "26,56",
    lat: 48.307427,
    lng: 17.336168,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:08:44",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "26,63",
    lat: 48.30703,
    lng: 17.336662,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:08:50",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "26,67",
    lat: 48.306708,
    lng: 17.337306,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:08:56",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "26,74",
    lat: 48.306397,
    lng: 17.338014,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:09:01",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "26,77",
    lat: 48.30614,
    lng: 17.338572,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:09:06",
    obec: "Šenkvice",
    speed: "28 km/h",
    distance: "26,84",
    lat: 48.305818,
    lng: 17.338979,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:09:16",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "26,92",
    lat: 48.305067,
    lng: 17.33943,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:09:21",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "26,99",
    lat: 48.304755,
    lng: 17.339838,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:09:36",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "27,09",
    lat: 48.304241,
    lng: 17.341061,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:09:48",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "27,16",
    lat: 48.303768,
    lng: 17.342198,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:10:00",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "27,24",
    lat: 48.303339,
    lng: 17.343421,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:10:13",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "27,34",
    lat: 48.302803,
    lng: 17.344623,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:10:23",
    obec: "Šenkvice",
    speed: "25 km/h",
    distance: "27,45",
    lat: 48.302395,
    lng: 17.345545,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:10:32",
    obec: "Šenkvice",
    speed: "14 km/h",
    distance: "27,48",
    lat: 48.302138,
    lng: 17.346125,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:10:37",
    obec: "Šenkvice",
    speed: "9 km/h",
    distance: "27,52",
    lat: 48.302073,
    lng: 17.346296,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:10:51",
    obec: "Šenkvice",
    speed: "7 km/h",
    distance: "27,52",
    lat: 48.302084,
    lng: 17.34664,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:11:00",
    obec: "Šenkvice",
    speed: "5 km/h",
    distance: "27,56",
    lat: 48.302138,
    lng: 17.346489,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:11:12",
    obec: "Šenkvice",
    speed: "9 km/h",
    distance: "27,56",
    lat: 48.302127,
    lng: 17.346361,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:11:17",
    obec: "Šenkvice",
    speed: "13 km/h",
    distance: "27,59",
    lat: 48.302202,
    lng: 17.346103,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:11:34",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "27,70",
    lat: 48.302685,
    lng: 17.344923,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:11:49",
    obec: "Šenkvice",
    speed: "20 km/h",
    distance: "27,77",
    lat: 48.303189,
    lng: 17.343764,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:11:54",
    obec: "Šenkvice",
    speed: "11 km/h",
    distance: "27,80",
    lat: 48.303286,
    lng: 17.343528,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:12:02",
    obec: "Šenkvice",
    speed: "0 km/h",
    distance: "27,80",
    lat: 48.303382,
    lng: 17.3434,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:12:11",
    obec: "Šenkvice",
    speed: "6 km/h",
    distance: "27,84",
    lat: 48.303479,
    lng: 17.343421,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:12:18",
    obec: "Šenkvice",
    speed: "13 km/h",
    distance: "27,84",
    lat: 48.303597,
    lng: 17.34355,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "16:12:38",
    obec: "Šenkvice",
    speed: "26 km/h",
    distance: "27,95",
    lat: 48.304273,
    lng: 17.344558,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:12:50",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "28,05",
    lat: 48.304874,
    lng: 17.345395,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:13:02",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "28,16",
    lat: 48.305442,
    lng: 17.346554,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:13:10",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "28,23",
    lat: 48.305764,
    lng: 17.347326,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:13:21",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "28,34",
    lat: 48.306161,
    lng: 17.348592,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:13:31",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "28,44",
    lat: 48.306579,
    lng: 17.34988,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:13:38",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "28,52",
    lat: 48.306891,
    lng: 17.350802,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:13:47",
    obec: "Šenkvice",
    speed: "46 km/h",
    distance: "28,62",
    lat: 48.307406,
    lng: 17.352026,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:13:55",
    obec: "Šenkvice",
    speed: "43 km/h",
    distance: "28,73",
    lat: 48.307867,
    lng: 17.353206,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:14:03",
    obec: "Šenkvice",
    speed: "44 km/h",
    distance: "28,80",
    lat: 48.308317,
    lng: 17.354321,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:14:12",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "28,91",
    lat: 48.308736,
    lng: 17.355609,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:14:18",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "28,98",
    lat: 48.308993,
    lng: 17.356317,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:14:30",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "29,08",
    lat: 48.309626,
    lng: 17.357411,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:14:40",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "29,19",
    lat: 48.310227,
    lng: 17.358613,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:14:46",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "29,26",
    lat: 48.310528,
    lng: 17.359428,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:14:55",
    obec: "Šenkvice",
    speed: "41 km/h",
    distance: "29,37",
    lat: 48.311107,
    lng: 17.36048,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:15:05",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "29,48",
    lat: 48.311686,
    lng: 17.361639,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:15:15",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "29,58",
    lat: 48.312319,
    lng: 17.362647,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:15:21",
    obec: "Šenkvice",
    speed: "42 km/h",
    distance: "29,65",
    lat: 48.312824,
    lng: 17.363183,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:15:27",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "29,72",
    lat: 48.313253,
    lng: 17.36372,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:15:37",
    obec: "Šenkvice",
    speed: "41 km/h",
    distance: "29,83",
    lat: 48.313854,
    lng: 17.364857,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:15:46",
    obec: "Šenkvice",
    speed: "26 km/h",
    distance: "29,90",
    lat: 48.314336,
    lng: 17.365737,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:15:58",
    obec: "Šenkvice",
    speed: "22 km/h",
    distance: "29,97",
    lat: 48.314787,
    lng: 17.366488,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:16:03",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "30,01",
    lat: 48.315012,
    lng: 17.366788,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:16:12",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "30,08",
    lat: 48.315592,
    lng: 17.367411,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:16:21",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "30,19",
    lat: 48.316257,
    lng: 17.368183,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:16:26",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "30,26",
    lat: 48.316514,
    lng: 17.368762,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:16:31",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "30,29",
    lat: 48.316664,
    lng: 17.369406,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:16:39",
    obec: "Šenkvice",
    speed: "42 km/h",
    distance: "30,36",
    lat: 48.316729,
    lng: 17.370565,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:16:48",
    obec: "Šenkvice",
    speed: "41 km/h",
    distance: "30,47",
    lat: 48.31674,
    lng: 17.37196,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:16:55",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "30,58",
    lat: 48.316933,
    lng: 17.373033,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:17:01",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "30,65",
    lat: 48.317319,
    lng: 17.373762,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:17:10",
    obec: "Vištuk",
    speed: "44 km/h",
    distance: "30,76",
    lat: 48.318134,
    lng: 17.374642,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:17:19",
    obec: "Vištuk",
    speed: "43 km/h",
    distance: "30,86",
    lat: 48.318907,
    lng: 17.3755,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:17:28",
    obec: "Vištuk",
    speed: "44 km/h",
    distance: "30,97",
    lat: 48.319679,
    lng: 17.376337,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:17:36",
    obec: "Vištuk",
    speed: "43 km/h",
    distance: "31,08",
    lat: 48.32042,
    lng: 17.377152,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:17:45",
    obec: "Vištuk",
    speed: "47 km/h",
    distance: "31,18",
    lat: 48.321289,
    lng: 17.377903,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:17:53",
    obec: "Vištuk",
    speed: "47 km/h",
    distance: "31,29",
    lat: 48.322168,
    lng: 17.378461,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:18:00",
    obec: "Vištuk",
    speed: "50 km/h",
    distance: "31,36",
    lat: 48.322952,
    lng: 17.378976,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:18:08",
    obec: "Vištuk",
    speed: "49 km/h",
    distance: "31,50",
    lat: 48.323864,
    lng: 17.379577,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:18:16",
    obec: "Vištuk",
    speed: "48 km/h",
    distance: "31,61",
    lat: 48.324754,
    lng: 17.380157,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:18:22",
    obec: "Vištuk",
    speed: "51 km/h",
    distance: "31,68",
    lat: 48.325344,
    lng: 17.380908,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:18:30",
    obec: "Vištuk",
    speed: "50 km/h",
    distance: "31,79",
    lat: 48.326063,
    lng: 17.381916,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:18:39",
    obec: "Vištuk",
    speed: "37 km/h",
    distance: "31,89",
    lat: 48.326814,
    lng: 17.382903,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:18:45",
    obec: "Vištuk",
    speed: "41 km/h",
    distance: "31,96",
    lat: 48.32734,
    lng: 17.383268,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:18:54",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "32,07",
    lat: 48.328337,
    lng: 17.383354,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:19:03",
    obec: "Vištuk",
    speed: "44 km/h",
    distance: "32,18",
    lat: 48.329292,
    lng: 17.383525,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:19:11",
    obec: "Vištuk",
    speed: "46 km/h",
    distance: "32,28",
    lat: 48.330215,
    lng: 17.383697,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:19:19",
    obec: "Vištuk",
    speed: "37 km/h",
    distance: "32,39",
    lat: 48.330987,
    lng: 17.383912,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:19:25",
    obec: "Vištuk",
    speed: "38 km/h",
    distance: "32,43",
    lat: 48.331438,
    lng: 17.38447,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:19:31",
    obec: "Vištuk",
    speed: "41 km/h",
    distance: "32,50",
    lat: 48.331739,
    lng: 17.385221,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:19:40",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "32,60",
    lat: 48.332157,
    lng: 17.386487,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:19:47",
    obec: "Vištuk",
    speed: "44 km/h",
    distance: "32,71",
    lat: 48.332468,
    lng: 17.387474,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:19:54",
    obec: "Vištuk",
    speed: "30 km/h",
    distance: "32,78",
    lat: 48.332758,
    lng: 17.388353,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:20:05",
    obec: "Vištuk",
    speed: "30 km/h",
    distance: "32,85",
    lat: 48.333165,
    lng: 17.389448,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:20:13",
    obec: "Vištuk",
    speed: "29 km/h",
    distance: "32,92",
    lat: 48.333638,
    lng: 17.390006,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:20:21",
    obec: "Vištuk",
    speed: "20 km/h",
    distance: "33,00",
    lat: 48.333916,
    lng: 17.390628,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:20:31",
    obec: "Vištuk",
    speed: "13 km/h",
    distance: "33,03",
    lat: 48.334346,
    lng: 17.390842,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:20:36",
    obec: "Vištuk",
    speed: "6 km/h",
    distance: "33,07",
    lat: 48.334474,
    lng: 17.390864,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:20:47",
    obec: "Vištuk",
    speed: "8 km/h",
    distance: "33,07",
    lat: 48.33456,
    lng: 17.390907,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:21:04",
    obec: "Vištuk",
    speed: "31 km/h",
    distance: "33,17",
    lat: 48.334539,
    lng: 17.392302,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:21:14",
    obec: "Vištuk",
    speed: "37 km/h",
    distance: "33,28",
    lat: 48.334571,
    lng: 17.393675,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:21:24",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: "33,35",
    lat: 48.334603,
    lng: 17.395027,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:21:31",
    obec: "Vištuk",
    speed: "31 km/h",
    distance: "33,42",
    lat: 48.334549,
    lng: 17.395842,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:21:40",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "33,53",
    lat: 48.334238,
    lng: 17.396915,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:21:47",
    obec: "Vištuk",
    speed: "34 km/h",
    distance: "33,60",
    lat: 48.334024,
    lng: 17.397795,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:21:55",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "33,67",
    lat: 48.333713,
    lng: 17.398739,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:22:06",
    obec: "Vištuk",
    speed: "37 km/h",
    distance: "33,78",
    lat: 48.333133,
    lng: 17.39994,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:22:13",
    obec: "Vištuk",
    speed: "40 km/h",
    distance: "33,85",
    lat: 48.332844,
    lng: 17.400906,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:22:23",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: "33,96",
    lat: 48.3325,
    lng: 17.402236,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:22:29",
    obec: "Vištuk",
    speed: "15 km/h",
    distance: "33,99",
    lat: 48.332296,
    lng: 17.402709,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:22:34",
    obec: "Vištuk",
    speed: "8 km/h",
    distance: "34,03",
    lat: 48.332221,
    lng: 17.402902,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:22:43",
    obec: "Vištuk",
    speed: "5 km/h",
    distance: "34,03",
    lat: 48.332146,
    lng: 17.403116,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:23:53",
    obec: "Vištuk",
    speed: "10 km/h",
    distance: "34,06",
    lat: 48.33206,
    lng: 17.403266,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:23:58",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "34,06",
    lat: 48.331975,
    lng: 17.403481,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:24:11",
    obec: "Vištuk",
    speed: "26 km/h",
    distance: "34,13",
    lat: 48.331663,
    lng: 17.404447,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:24:21",
    obec: "Vištuk",
    speed: "13 km/h",
    distance: "34,20",
    lat: 48.331492,
    lng: 17.405219,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:24:26",
    obec: "Vištuk",
    speed: "7 km/h",
    distance: "34,24",
    lat: 48.33146,
    lng: 17.405391,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:24:32",
    obec: "Vištuk",
    speed: "13 km/h",
    distance: "34,24",
    lat: 48.331384,
    lng: 17.405605,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:24:43",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: "34,31",
    lat: 48.330987,
    lng: 17.406464,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:24:51",
    obec: "Vištuk",
    speed: "38 km/h",
    distance: "34,42",
    lat: 48.330569,
    lng: 17.407365,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:25:01",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "34,52",
    lat: 48.330129,
    lng: 17.408674,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:25:10",
    obec: "Vištuk",
    speed: "37 km/h",
    distance: "34,63",
    lat: 48.329743,
    lng: 17.409961,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:25:18",
    obec: "Vištuk",
    speed: "41 km/h",
    distance: "34,70",
    lat: 48.329528,
    lng: 17.411141,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:25:26",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "34,81",
    lat: 48.329668,
    lng: 17.412322,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:25:35",
    obec: "Vištuk",
    speed: "41 km/h",
    distance: "34,92",
    lat: 48.329807,
    lng: 17.413781,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:25:42",
    obec: "Vištuk",
    speed: "34 km/h",
    distance: "34,99",
    lat: 48.32984,
    lng: 17.414746,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:25:50",
    obec: "Vištuk",
    speed: "41 km/h",
    distance: "35,06",
    lat: 48.329389,
    lng: 17.415669,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:25:57",
    obec: "Vištuk",
    speed: "43 km/h",
    distance: "35,16",
    lat: 48.328906,
    lng: 17.41657,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:26:06",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "35,27",
    lat: 48.328337,
    lng: 17.41775,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:26:12",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "35,34",
    lat: 48.327973,
    lng: 17.418523,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:26:19",
    obec: "Vištuk",
    speed: "44 km/h",
    distance: "35,41",
    lat: 48.32749,
    lng: 17.419424,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:26:28",
    obec: "Vištuk",
    speed: "44 km/h",
    distance: "35,52",
    lat: 48.326889,
    lng: 17.420626,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:26:35",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "35,59",
    lat: 48.326449,
    lng: 17.421505,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:26:41",
    obec: "Vištuk",
    speed: "41 km/h",
    distance: "35,66",
    lat: 48.326052,
    lng: 17.422256,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:26:50",
    obec: "Vištuk",
    speed: "28 km/h",
    distance: "35,77",
    lat: 48.325537,
    lng: 17.423222,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:26:55",
    obec: "Vištuk",
    speed: "18 km/h",
    distance: "35,80",
    lat: 48.325376,
    lng: 17.423544,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:27:09",
    obec: "Vištuk",
    speed: "30 km/h",
    distance: "35,88",
    lat: 48.324904,
    lng: 17.424531,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:27:20",
    obec: "Vištuk",
    speed: "34 km/h",
    distance: "35,98",
    lat: 48.324346,
    lng: 17.425625,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:27:30",
    obec: "Vištuk",
    speed: "40 km/h",
    distance: "36,09",
    lat: 48.323767,
    lng: 17.426784,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:27:39",
    obec: "Vištuk",
    speed: "44 km/h",
    distance: "36,20",
    lat: 48.323177,
    lng: 17.4279,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:27:47",
    obec: "Báhoò",
    speed: "40 km/h",
    distance: "36,27",
    lat: 48.322619,
    lng: 17.428951,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:27:57",
    obec: "Báhoò",
    speed: "25 km/h",
    distance: "36,37",
    lat: 48.322125,
    lng: 17.430003,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:28:02",
    obec: "Báhoò",
    speed: "21 km/h",
    distance: "36,41",
    lat: 48.322093,
    lng: 17.43041,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:28:19",
    obec: "Báhoò",
    speed: "38 km/h",
    distance: "36,55",
    lat: 48.322995,
    lng: 17.431676,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:28:27",
    obec: "Báhoò",
    speed: "36 km/h",
    distance: "36,62",
    lat: 48.323563,
    lng: 17.432363,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:28:38",
    obec: "Báhoò",
    speed: "27 km/h",
    distance: "36,73",
    lat: 48.324314,
    lng: 17.433264,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:28:43",
    obec: "Báhoò",
    speed: "14 km/h",
    distance: "36,76",
    lat: 48.324486,
    lng: 17.433522,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:28:50",
    obec: "Báhoò",
    speed: "10 km/h",
    distance: "36,76",
    lat: 48.324443,
    lng: 17.433715,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:28:55",
    obec: "Báhoò",
    speed: "17 km/h",
    distance: "36,80",
    lat: 48.324303,
    lng: 17.433844,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:29:09",
    obec: "Báhoò",
    speed: "35 km/h",
    distance: "36,91",
    lat: 48.323456,
    lng: 17.43438,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:29:16",
    obec: "Báhoò",
    speed: "39 km/h",
    distance: "36,98",
    lat: 48.322844,
    lng: 17.434766,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:29:25",
    obec: "Báhoò",
    speed: "45 km/h",
    distance: "37,08",
    lat: 48.321932,
    lng: 17.435346,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:29:33",
    obec: "Báhoò",
    speed: "45 km/h",
    distance: "37,19",
    lat: 48.321074,
    lng: 17.435904,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:29:41",
    obec: "Báhoò",
    speed: "46 km/h",
    distance: "37,30",
    lat: 48.320216,
    lng: 17.436461,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:29:48",
    obec: "Báhoò",
    speed: "42 km/h",
    distance: "37,37",
    lat: 48.319518,
    lng: 17.436891,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:29:57",
    obec: "Báhoò",
    speed: "44 km/h",
    distance: "37,48",
    lat: 48.318639,
    lng: 17.43747,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:30:06",
    obec: "Báhoò",
    speed: "39 km/h",
    distance: "37,58",
    lat: 48.31777,
    lng: 17.438049,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:30:11",
    obec: "Báhoò",
    speed: "36 km/h",
    distance: "37,62",
    lat: 48.31734,
    lng: 17.438307,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:30:21",
    obec: "Báhoò",
    speed: "34 km/h",
    distance: "37,72",
    lat: 48.316536,
    lng: 17.438843,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:30:31",
    obec: "Báhoò",
    speed: "40 km/h",
    distance: "37,83",
    lat: 48.315688,
    lng: 17.439401,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:30:40",
    obec: "Báhoò",
    speed: "39 km/h",
    distance: "37,94",
    lat: 48.314862,
    lng: 17.439916,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:30:49",
    obec: "Báhoò",
    speed: "39 km/h",
    distance: "38,04",
    lat: 48.314025,
    lng: 17.440431,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:30:59",
    obec: "Báhoò",
    speed: "38 km/h",
    distance: "38,15",
    lat: 48.313135,
    lng: 17.441032,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:31:09",
    obec: "Báhoò",
    speed: "39 km/h",
    distance: "38,26",
    lat: 48.312255,
    lng: 17.441611,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:31:19",
    obec: "Báhoò",
    speed: "32 km/h",
    distance: "38,36",
    lat: 48.311375,
    lng: 17.442126,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:31:26",
    obec: "Báhoò",
    speed: "32 km/h",
    distance: "38,40",
    lat: 48.310839,
    lng: 17.442362,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:31:32",
    obec: "Báhoò",
    speed: "34 km/h",
    distance: "38,47",
    lat: 48.310367,
    lng: 17.442555,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:31:42",
    obec: "Báhoò",
    speed: "34 km/h",
    distance: "38,58",
    lat: 48.309669,
    lng: 17.443349,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:31:51",
    obec: "Báhoò",
    speed: "38 km/h",
    distance: "38,65",
    lat: 48.308972,
    lng: 17.444057,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:32:00",
    obec: "Báhoò",
    speed: "36 km/h",
    distance: "38,76",
    lat: 48.308285,
    lng: 17.444787,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:32:10",
    obec: "Báhoò",
    speed: "38 km/h",
    distance: "38,86",
    lat: 48.307577,
    lng: 17.445667,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:32:16",
    obec: "Báhoò",
    speed: "34 km/h",
    distance: "38,93",
    lat: 48.307169,
    lng: 17.446203,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:32:23",
    obec: "Báhoò",
    speed: "37 km/h",
    distance: "38,97",
    lat: 48.306687,
    lng: 17.446783,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:32:30",
    obec: "Báhoò",
    speed: "36 km/h",
    distance: "39,04",
    lat: 48.306182,
    lng: 17.447383,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:32:39",
    obec: "Báhoò",
    speed: "34 km/h",
    distance: "39,15",
    lat: 48.305496,
    lng: 17.447984,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:32:48",
    obec: "Báhoò",
    speed: "34 km/h",
    distance: "39,22",
    lat: 48.304777,
    lng: 17.448413,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:32:56",
    obec: "Báhoò",
    speed: "31 km/h",
    distance: "39,29",
    lat: 48.304165,
    lng: 17.448714,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:33:07",
    obec: "Báhoò",
    speed: "39 km/h",
    distance: "39,40",
    lat: 48.303275,
    lng: 17.449014,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:33:17",
    obec: "Báhoò",
    speed: "36 km/h",
    distance: "39,50",
    lat: 48.302352,
    lng: 17.44925,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:33:27",
    obec: "Báhoò",
    speed: "30 km/h",
    distance: "39,61",
    lat: 48.301569,
    lng: 17.449551,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:33:34",
    obec: "Kaplna",
    speed: "35 km/h",
    distance: "39,68",
    lat: 48.301054,
    lng: 17.450001,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:33:40",
    obec: "Kaplna",
    speed: "38 km/h",
    distance: "39,72",
    lat: 48.300561,
    lng: 17.45043,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:33:50",
    obec: "Kaplna",
    speed: "35 km/h",
    distance: "39,82",
    lat: 48.299767,
    lng: 17.451181,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:34:00",
    obec: "Kaplna",
    speed: "38 km/h",
    distance: "39,93",
    lat: 48.298962,
    lng: 17.451868,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:34:07",
    obec: "Kaplna",
    speed: "39 km/h",
    distance: "40,00",
    lat: 48.298372,
    lng: 17.452383,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:34:13",
    obec: "Kaplna",
    speed: "41 km/h",
    distance: "40,07",
    lat: 48.297825,
    lng: 17.452812,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:34:20",
    obec: "Kaplna",
    speed: "45 km/h",
    distance: "40,18",
    lat: 48.297117,
    lng: 17.453327,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:34:27",
    obec: "Kaplna",
    speed: "24 km/h",
    distance: "40,25",
    lat: 48.296569,
    lng: 17.453649,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:34:36",
    obec: "Kaplna",
    speed: "23 km/h",
    distance: "40,28",
    lat: 48.296151,
    lng: 17.453671,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:34:42",
    obec: "Kaplna",
    speed: "31 km/h",
    distance: "40,32",
    lat: 48.295722,
    lng: 17.453542,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:34:49",
    obec: "Kaplna",
    speed: "38 km/h",
    distance: "40,39",
    lat: 48.295153,
    lng: 17.453842,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:34:59",
    obec: "Kaplna",
    speed: "38 km/h",
    distance: "40,50",
    lat: 48.294327,
    lng: 17.45455,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:35:04",
    obec: "Kaplna",
    speed: "35 km/h",
    distance: "40,57",
    lat: 48.293952,
    lng: 17.454958,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:35:13",
    obec: "Kaplna",
    speed: "30 km/h",
    distance: "40,64",
    lat: 48.293415,
    lng: 17.455688,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:35:19",
    obec: "Kaplna",
    speed: "22 km/h",
    distance: "40,68",
    lat: 48.293147,
    lng: 17.45616,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:35:24",
    obec: "Kaplna",
    speed: "10 km/h",
    distance: "40,71",
    lat: 48.293007,
    lng: 17.456331,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:35:45",
    obec: "Kaplna",
    speed: "6 km/h",
    distance: "40,71",
    lat: 48.292889,
    lng: 17.456374,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:35:50",
    obec: "Kaplna",
    speed: "10 km/h",
    distance: "40,71",
    lat: 48.292846,
    lng: 17.456481,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:35:55",
    obec: "Kaplna",
    speed: "4 km/h",
    distance: "40,75",
    lat: 48.292889,
    lng: 17.456632,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:36:16",
    obec: "Kaplna",
    speed: "14 km/h",
    distance: "40,75",
    lat: 48.293018,
    lng: 17.456396,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:36:34",
    obec: "Kaplna",
    speed: "29 km/h",
    distance: "40,85",
    lat: 48.293662,
    lng: 17.455344,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:36:42",
    obec: "Kaplna",
    speed: "29 km/h",
    distance: "40,92",
    lat: 48.294123,
    lng: 17.454786,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:36:53",
    obec: "Kaplna",
    speed: "34 km/h",
    distance: "41,03",
    lat: 48.294917,
    lng: 17.454057,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:37:01",
    obec: "Kaplna",
    speed: "27 km/h",
    distance: "41,10",
    lat: 48.295454,
    lng: 17.453649,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:37:07",
    obec: "Kaplna",
    speed: "29 km/h",
    distance: "41,14",
    lat: 48.295893,
    lng: 17.453649,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:37:18",
    obec: "Kaplna",
    speed: "28 km/h",
    distance: "41,21",
    lat: 48.296537,
    lng: 17.453735,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:37:26",
    obec: "Kaplna",
    speed: "26 km/h",
    distance: "41,28",
    lat: 48.297063,
    lng: 17.453413,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:37:39",
    obec: "Kaplna",
    speed: "33 km/h",
    distance: "41,39",
    lat: 48.297921,
    lng: 17.452726,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:37:50",
    obec: "Kaplna",
    speed: "36 km/h",
    distance: "41,49",
    lat: 48.298801,
    lng: 17.45204,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:38:00",
    obec: "Kaplna",
    speed: "37 km/h",
    distance: "41,60",
    lat: 48.299638,
    lng: 17.45131,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:38:11",
    obec: "Kaplna",
    speed: "39 km/h",
    distance: "41,71",
    lat: 48.30041,
    lng: 17.450624,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:38:18",
    obec: "Kaplna",
    speed: "35 km/h",
    distance: "41,78",
    lat: 48.300904,
    lng: 17.450151,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:38:28",
    obec: "Báhoò",
    speed: "36 km/h",
    distance: "41,88",
    lat: 48.301708,
    lng: 17.449508,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:38:38",
    obec: "Báhoò",
    speed: "42 km/h",
    distance: "41,99",
    lat: 48.302696,
    lng: 17.449186,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:38:46",
    obec: "Báhoò",
    speed: "35 km/h",
    distance: "42,06",
    lat: 48.303457,
    lng: 17.44895,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:38:57",
    obec: "Báhoò",
    speed: "33 km/h",
    distance: "42,20",
    lat: 48.304401,
    lng: 17.448692,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:39:06",
    obec: "Báhoò",
    speed: "35 km/h",
    distance: "42,28",
    lat: 48.305088,
    lng: 17.448349,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:39:15",
    obec: "Báhoò",
    speed: "33 km/h",
    distance: "42,35",
    lat: 48.305743,
    lng: 17.447855,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:39:26",
    obec: "Báhoò",
    speed: "30 km/h",
    distance: "42,45",
    lat: 48.306526,
    lng: 17.447062,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:39:37",
    obec: "Báhoò",
    speed: "37 km/h",
    distance: "42,56",
    lat: 48.307287,
    lng: 17.446096,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:39:44",
    obec: "Báhoò",
    speed: "36 km/h",
    distance: "42,63",
    lat: 48.307802,
    lng: 17.445452,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:39:55",
    obec: "Báhoò",
    speed: "35 km/h",
    distance: "42,74",
    lat: 48.308468,
    lng: 17.444658,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:40:04",
    obec: "Báhoò",
    speed: "35 km/h",
    distance: "42,84",
    lat: 48.309219,
    lng: 17.443886,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:40:14",
    obec: "Báhoò",
    speed: "35 km/h",
    distance: "42,95",
    lat: 48.309959,
    lng: 17.44307,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:40:23",
    obec: "Báhoò",
    speed: "34 km/h",
    distance: "43,02",
    lat: 48.310603,
    lng: 17.442491,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:40:31",
    obec: "Báhoò",
    speed: "33 km/h",
    distance: "43,09",
    lat: 48.311289,
    lng: 17.442276,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:40:42",
    obec: "Báhoò",
    speed: "34 km/h",
    distance: "43,20",
    lat: 48.31218,
    lng: 17.441697,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:40:53",
    obec: "Báhoò",
    speed: "36 km/h",
    distance: "43,31",
    lat: 48.31307,
    lng: 17.441118,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:41:03",
    obec: "Báhoò",
    speed: "38 km/h",
    distance: "43,41",
    lat: 48.313907,
    lng: 17.440581,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:41:13",
    obec: "Báhoò",
    speed: "38 km/h",
    distance: "43,52",
    lat: 48.314808,
    lng: 17.440023,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:41:23",
    obec: "Báhoò",
    speed: "40 km/h",
    distance: "43,63",
    lat: 48.315688,
    lng: 17.439444,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:41:32",
    obec: "Báhoò",
    speed: "46 km/h",
    distance: "43,73",
    lat: 48.3166,
    lng: 17.438843,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:41:40",
    obec: "Báhoò",
    speed: "46 km/h",
    distance: "43,84",
    lat: 48.317426,
    lng: 17.438307,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:41:48",
    obec: "Báhoò",
    speed: "51 km/h",
    distance: "43,95",
    lat: 48.318306,
    lng: 17.437727,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:41:55",
    obec: "Báhoò",
    speed: "51 km/h",
    distance: "44,05",
    lat: 48.319143,
    lng: 17.43717,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:42:02",
    obec: "Báhoò",
    speed: "53 km/h",
    distance: "44,16",
    lat: 48.31998,
    lng: 17.436655,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:42:09",
    obec: "Báhoò",
    speed: "54 km/h",
    distance: "44,27",
    lat: 48.320849,
    lng: 17.436118,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:42:16",
    obec: "Báhoò",
    speed: "54 km/h",
    distance: "44,37",
    lat: 48.321729,
    lng: 17.435539,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:42:23",
    obec: "Báhoò",
    speed: "54 km/h",
    distance: "44,48",
    lat: 48.322608,
    lng: 17.434959,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:42:31",
    obec: "Báhoò",
    speed: "48 km/h",
    distance: "44,59",
    lat: 48.323563,
    lng: 17.43438,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:42:39",
    obec: "Báhoò",
    speed: "26 km/h",
    distance: "44,66",
    lat: 48.324271,
    lng: 17.433929,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:42:44",
    obec: "Báhoò",
    speed: "15 km/h",
    distance: "44,69",
    lat: 48.324507,
    lng: 17.433736,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:42:54",
    obec: "Báhoò",
    speed: "15 km/h",
    distance: "44,73",
    lat: 48.324421,
    lng: 17.43335,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:43:03",
    obec: "Báhoò",
    speed: "36 km/h",
    distance: "44,80",
    lat: 48.323939,
    lng: 17.432749,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:43:12",
    obec: "Báhoò",
    speed: "46 km/h",
    distance: "44,91",
    lat: 48.323231,
    lng: 17.43187,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:43:20",
    obec: "Báhoò",
    speed: "40 km/h",
    distance: "45,01",
    lat: 48.322522,
    lng: 17.431011,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:43:26",
    obec: "Báhoò",
    speed: "26 km/h",
    distance: "45,05",
    lat: 48.322158,
    lng: 17.430518,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:43:31",
    obec: "Báhoò",
    speed: "33 km/h",
    distance: "45,12",
    lat: 48.322168,
    lng: 17.430003,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:43:37",
    obec: "Báhoò",
    speed: "42 km/h",
    distance: "45,16",
    lat: 48.322533,
    lng: 17.429295,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:43:45",
    obec: "Vištuk",
    speed: "50 km/h",
    distance: "45,26",
    lat: 48.323102,
    lng: 17.428157,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:43:51",
    obec: "Vištuk",
    speed: "48 km/h",
    distance: "45,33",
    lat: 48.32352,
    lng: 17.427278,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:43:59",
    obec: "Vištuk",
    speed: "50 km/h",
    distance: "45,44",
    lat: 48.324153,
    lng: 17.426076,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:44:06",
    obec: "Vištuk",
    speed: "46 km/h",
    distance: "45,55",
    lat: 48.324657,
    lng: 17.425067,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:44:14",
    obec: "Vištuk",
    speed: "46 km/h",
    distance: "45,62",
    lat: 48.325237,
    lng: 17.423952,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:44:22",
    obec: "Vištuk",
    speed: "48 km/h",
    distance: "45,72",
    lat: 48.325805,
    lng: 17.422836,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:44:27",
    obec: "Vištuk",
    speed: "48 km/h",
    distance: "45,80",
    lat: 48.326181,
    lng: 17.422106,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:44:35",
    obec: "Vištuk",
    speed: "44 km/h",
    distance: "45,90",
    lat: 48.326728,
    lng: 17.421012,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:44:43",
    obec: "Vištuk",
    speed: "47 km/h",
    distance: "46,01",
    lat: 48.327275,
    lng: 17.419875,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:44:50",
    obec: "Vištuk",
    speed: "48 km/h",
    distance: "46,12",
    lat: 48.32779,
    lng: 17.418888,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:44:58",
    obec: "Vištuk",
    speed: "48 km/h",
    distance: "46,22",
    lat: 48.328391,
    lng: 17.417729,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:45:06",
    obec: "Vištuk",
    speed: "48 km/h",
    distance: "46,33",
    lat: 48.32897,
    lng: 17.416592,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:45:14",
    obec: "Vištuk",
    speed: "44 km/h",
    distance: "46,44",
    lat: 48.329539,
    lng: 17.415433,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:45:19",
    obec: "Vištuk",
    speed: "34 km/h",
    distance: "46,47",
    lat: 48.329807,
    lng: 17.414832,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:45:26",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "46,54",
    lat: 48.329829,
    lng: 17.413824,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:45:36",
    obec: "Vištuk",
    speed: "34 km/h",
    distance: "46,68",
    lat: 48.329657,
    lng: 17.412386,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:45:46",
    obec: "Vištuk",
    speed: "18 km/h",
    distance: "46,76",
    lat: 48.32955,
    lng: 17.411377,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:45:53",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "46,79",
    lat: 48.329561,
    lng: 17.410927,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:45:59",
    obec: "Vištuk",
    speed: "22 km/h",
    distance: "46,79",
    lat: 48.329593,
    lng: 17.410562,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:46:09",
    obec: "Vištuk",
    speed: "28 km/h",
    distance: "46,90",
    lat: 48.329829,
    lng: 17.409618,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:46:21",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "47,00",
    lat: 48.330247,
    lng: 17.40833,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:46:31",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "47,08",
    lat: 48.330698,
    lng: 17.407086,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:46:41",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "47,18",
    lat: 48.331234,
    lng: 17.405992,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:46:48",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: "47,25",
    lat: 48.331513,
    lng: 17.405176,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:46:59",
    obec: "Vištuk",
    speed: "34 km/h",
    distance: "47,36",
    lat: 48.331824,
    lng: 17.403889,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:47:07",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: "47,43",
    lat: 48.332157,
    lng: 17.403009,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:47:17",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "47,54",
    lat: 48.332565,
    lng: 17.401872,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:47:26",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "47,61",
    lat: 48.332886,
    lng: 17.400756,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:47:34",
    obec: "Vištuk",
    speed: "31 km/h",
    distance: "47,72",
    lat: 48.333198,
    lng: 17.399855,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:47:45",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: "47,79",
    lat: 48.333702,
    lng: 17.398739,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:47:53",
    obec: "Vištuk",
    speed: "34 km/h",
    distance: "47,89",
    lat: 48.334013,
    lng: 17.397816,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:48:03",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "47,96",
    lat: 48.334367,
    lng: 17.396572,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:48:10",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: 48.04,
    lat: 48.334592,
    lng: 17.395778,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:48:20",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: 48.14,
    lat: 48.334614,
    lng: 17.394469,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:48:30",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: 48.25,
    lat: 48.334614,
    lng: 17.393095,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:48:40",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: 48.36,
    lat: 48.334571,
    lng: 17.391787,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:48:52",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: 48.46,
    lat: 48.334582,
    lng: 17.390306,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:49:00",
    obec: "Vištuk",
    speed: "26 km/h",
    distance: 48.5,
    lat: 48.334603,
    lng: 17.389448,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:49:17",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: 48.64,
    lat: 48.335182,
    lng: 17.387817,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:49:25",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: 48.71,
    lat: 48.335504,
    lng: 17.386959,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:49:35",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: 48.82,
    lat: 48.335976,
    lng: 17.3861,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:49:41",
    obec: "Vištuk",
    speed: "34 km/h",
    distance: 48.89,
    lat: 48.336352,
    lng: 17.385435,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:49:52",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "49,00",
    lat: 48.336974,
    lng: 17.384362,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:50:03",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "49,10",
    lat: 48.337596,
    lng: 17.383225,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:50:14",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "49,21",
    lat: 48.338219,
    lng: 17.382088,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:50:24",
    obec: "Vištuk",
    speed: "38 km/h",
    distance: "49,32",
    lat: 48.338798,
    lng: 17.380993,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:50:33",
    obec: "Vištuk",
    speed: "43 km/h",
    distance: "49,42",
    lat: 48.33942,
    lng: 17.37992,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:50:41",
    obec: "Vištuk",
    speed: "45 km/h",
    distance: "49,49",
    lat: 48.34001,
    lng: 17.378869,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:50:49",
    obec: "Vištuk",
    speed: "46 km/h",
    distance: "49,60",
    lat: 48.340622,
    lng: 17.377818,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:50:57",
    obec: "Vištuk",
    speed: "46 km/h",
    distance: "49,71",
    lat: 48.341212,
    lng: 17.376745,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:51:05",
    obec: "Vištuk",
    speed: "45 km/h",
    distance: "49,81",
    lat: 48.34177,
    lng: 17.375672,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:51:13",
    obec: "Vištuk",
    speed: "47 km/h",
    distance: "49,92",
    lat: 48.342382,
    lng: 17.37462,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:51:21",
    obec: "Vištuk",
    speed: "43 km/h",
    distance: "50,03",
    lat: 48.342972,
    lng: 17.37359,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:51:30",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "50,13",
    lat: 48.34354,
    lng: 17.372539,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:51:38",
    obec: "Vištuk",
    speed: "39 km/h",
    distance: "50,20",
    lat: 48.344044,
    lng: 17.371659,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:51:48",
    obec: "Vištuk",
    speed: "23 km/h",
    distance: "50,31",
    lat: 48.344613,
    lng: 17.370672,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:51:53",
    obec: "Vištuk",
    speed: "10 km/h",
    distance: "50,31",
    lat: 48.344731,
    lng: 17.370479,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:52:26",
    obec: "Vištuk",
    speed: "5 km/h",
    distance: "50,35",
    lat: 48.344838,
    lng: 17.370436,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:52:31",
    obec: "Vištuk",
    speed: "12 km/h",
    distance: "50,35",
    lat: 48.344892,
    lng: 17.370522,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:52:38",
    obec: "Vištuk",
    speed: "21 km/h",
    distance: "50,38",
    lat: 48.344892,
    lng: 17.371016,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:52:49",
    obec: "Vištuk",
    speed: "39 km/h",
    distance: "50,49",
    lat: 48.344967,
    lng: 17.372367,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:52:57",
    obec: "Vištuk",
    speed: "50 km/h",
    distance: "50,60",
    lat: 48.345064,
    lng: 17.373719,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:53:04",
    obec: "Vištuk",
    speed: "51 km/h",
    distance: "50,70",
    lat: 48.345128,
    lng: 17.375093,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:53:11",
    obec: "Vištuk",
    speed: "50 km/h",
    distance: "50,77",
    lat: 48.345182,
    lng: 17.376401,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:53:18",
    obec: "Vištuk",
    speed: "46 km/h",
    distance: "50,88",
    lat: 48.345321,
    lng: 17.377667,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:53:24",
    obec: "Vištuk",
    speed: "46 km/h",
    distance: "50,95",
    lat: 48.345643,
    lng: 17.378612,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:53:31",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "51,06",
    lat: 48.346094,
    lng: 17.379599,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:53:37",
    obec: "Vištuk",
    speed: "44 km/h",
    distance: "51,13",
    lat: 48.346426,
    lng: 17.380393,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:53:43",
    obec: "Vištuk",
    speed: "50 km/h",
    distance: "51,20",
    lat: 48.346759,
    lng: 17.381337,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:53:51",
    obec: "Vištuk",
    speed: "51 km/h",
    distance: "51,31",
    lat: 48.347199,
    lng: 17.38271,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:53:58",
    obec: "Vištuk",
    speed: "51 km/h",
    distance: "51,41",
    lat: 48.347596,
    lng: 17.383933,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:54:05",
    obec: "Vištuk",
    speed: "53 km/h",
    distance: "51,52",
    lat: 48.348014,
    lng: 17.385221,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:54:12",
    obec: "Vištuk",
    speed: "54 km/h",
    distance: "51,63",
    lat: 48.3484,
    lng: 17.386508,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:54:19",
    obec: "Vištuk",
    speed: "54 km/h",
    distance: "51,73",
    lat: 48.34883,
    lng: 17.387817,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:54:26",
    obec: "Vištuk",
    speed: "47 km/h",
    distance: "51,84",
    lat: 48.349216,
    lng: 17.388954,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:54:34",
    obec: "Vištuk",
    speed: "50 km/h",
    distance: "51,95",
    lat: 48.349838,
    lng: 17.390049,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:54:40",
    obec: "Vištuk",
    speed: "54 km/h",
    distance: "52,02",
    lat: 48.350407,
    lng: 17.390907,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:54:47",
    obec: "Budmerice",
    speed: "54 km/h",
    distance: "52,12",
    lat: 48.35105,
    lng: 17.391958,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:54:54",
    obec: "Budmerice",
    speed: "53 km/h",
    distance: "52,23",
    lat: 48.351641,
    lng: 17.393095,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:55:00",
    obec: "Budmerice",
    speed: "53 km/h",
    distance: "52,34",
    lat: 48.35208,
    lng: 17.394083,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:55:07",
    obec: "Budmerice",
    speed: "51 km/h",
    distance: "52,44",
    lat: 48.352552,
    lng: 17.395241,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:55:14",
    obec: "Budmerice",
    speed: "51 km/h",
    distance: "52,52",
    lat: 48.353057,
    lng: 17.396379,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:55:21",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "52,62",
    lat: 48.35354,
    lng: 17.397494,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:55:29",
    obec: "Budmerice",
    speed: "54 km/h",
    distance: "52,73",
    lat: 48.354108,
    lng: 17.398739,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:55:36",
    obec: "Budmerice",
    speed: "51 km/h",
    distance: "52,84",
    lat: 48.354698,
    lng: 17.399833,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:55:41",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "52,91",
    lat: 48.355031,
    lng: 17.400606,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:55:49",
    obec: "Budmerice",
    speed: "46 km/h",
    distance: "53,01",
    lat: 48.35546,
    lng: 17.40185,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:55:57",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "53,12",
    lat: 48.355964,
    lng: 17.402987,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:56:04",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "53,19",
    lat: 48.356308,
    lng: 17.40376,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:56:11",
    obec: "Budmerice",
    speed: "32 km/h",
    distance: "53,26",
    lat: 48.35634,
    lng: 17.404575,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:56:18",
    obec: "Budmerice",
    speed: "34 km/h",
    distance: "53,30",
    lat: 48.356383,
    lng: 17.405434,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:56:26",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "53,40",
    lat: 48.356608,
    lng: 17.406378,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:56:35",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "53,48",
    lat: 48.356758,
    lng: 17.407408,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:56:43",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "53,55",
    lat: 48.356726,
    lng: 17.408395,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:56:52",
    obec: "Budmerice",
    speed: "27 km/h",
    distance: "53,62",
    lat: 48.356501,
    lng: 17.409296,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:56:58",
    obec: "Budmerice",
    speed: "29 km/h",
    distance: "53,65",
    lat: 48.356211,
    lng: 17.409725,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:57:06",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "53,72",
    lat: 48.355696,
    lng: 17.410026,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:57:15",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "53,80",
    lat: 48.355031,
    lng: 17.410347,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:57:20",
    obec: "Budmerice",
    speed: "16 km/h",
    distance: "53,83",
    lat: 48.354763,
    lng: 17.410541,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:57:31",
    obec: "Budmerice",
    speed: "19 km/h",
    distance: "53,87",
    lat: 48.354366,
    lng: 17.410498,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:57:41",
    obec: "Budmerice",
    speed: "28 km/h",
    distance: "53,94",
    lat: 48.353776,
    lng: 17.410734,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:57:53",
    obec: "Budmerice",
    speed: "35 km/h",
    distance: "54,04",
    lat: 48.352853,
    lng: 17.41127,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:58:03",
    obec: "Budmerice",
    speed: "37 km/h",
    distance: "54,15",
    lat: 48.351995,
    lng: 17.411807,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:58:12",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "54,26",
    lat: 48.351297,
    lng: 17.412558,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:58:22",
    obec: "Budmerice",
    speed: "36 km/h",
    distance: "54,36",
    lat: 48.350514,
    lng: 17.413416,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:58:30",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "54,40",
    lat: 48.349935,
    lng: 17.413931,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:58:43",
    obec: "Budmerice",
    speed: "34 km/h",
    distance: "54,51",
    lat: 48.349023,
    lng: 17.414424,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:58:53",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "54,61",
    lat: 48.348239,
    lng: 17.415218,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:59:02",
    obec: "Budmerice",
    speed: "42 km/h",
    distance: "54,72",
    lat: 48.347521,
    lng: 17.416162,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:59:08",
    obec: "Budmerice",
    speed: "50 km/h",
    distance: "54,79",
    lat: 48.347016,
    lng: 17.416914,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:59:15",
    obec: "Budmerice",
    speed: "51 km/h",
    distance: "54,90",
    lat: 48.346351,
    lng: 17.41775,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:59:22",
    obec: "Budmerice",
    speed: "51 km/h",
    distance: "55,00",
    lat: 48.345632,
    lng: 17.418566,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:59:28",
    obec: "Budmerice",
    speed: "51 km/h",
    distance: "55,08",
    lat: 48.344892,
    lng: 17.418845,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:59:35",
    obec: "Budmerice",
    speed: "50 km/h",
    distance: "55,18",
    lat: 48.34398,
    lng: 17.418973,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:59:43",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "55,29",
    lat: 48.343057,
    lng: 17.419167,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "16:59:53",
    obec: "Budmerice",
    speed: "43 km/h",
    distance: "55,40",
    lat: 48.342049,
    lng: 17.41936,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:00:00",
    obec: "Budmerice",
    speed: "40 km/h",
    distance: "55,47",
    lat: 48.341416,
    lng: 17.419596,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:00:06",
    obec: "Budmerice",
    speed: "43 km/h",
    distance: "55,54",
    lat: 48.340879,
    lng: 17.420089,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:00:14",
    obec: "Budmerice",
    speed: "46 km/h",
    distance: "55,64",
    lat: 48.340118,
    lng: 17.420797,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:00:22",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "55,75",
    lat: 48.339292,
    lng: 17.42157,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:00:30",
    obec: "Budmerice",
    speed: "51 km/h",
    distance: "55,86",
    lat: 48.338444,
    lng: 17.422407,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:00:35",
    obec: "Budmerice",
    speed: "53 km/h",
    distance: "55,93",
    lat: 48.337897,
    lng: 17.422922,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:00:42",
    obec: "Budmerice",
    speed: "55 km/h",
    distance: "56,04",
    lat: 48.337071,
    lng: 17.423673,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:00:49",
    obec: "Budmerice",
    speed: "59 km/h",
    distance: "56,18",
    lat: 48.336223,
    lng: 17.42451,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:00:55",
    obec: "Budmerice",
    speed: "59 km/h",
    distance: "56,25",
    lat: 48.335461,
    lng: 17.425218,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:01:02",
    obec: "Budmerice",
    speed: "57 km/h",
    distance: "56,39",
    lat: 48.334603,
    lng: 17.426033,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:01:09",
    obec: "Vištuk",
    speed: "57 km/h",
    distance: "56,50",
    lat: 48.333766,
    lng: 17.426848,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:01:16",
    obec: "Jablonec",
    speed: "58 km/h",
    distance: "56,60",
    lat: 48.332919,
    lng: 17.427642,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:01:23",
    obec: "Vištuk",
    speed: "59 km/h",
    distance: "56,71",
    lat: 48.332007,
    lng: 17.428372,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:01:29",
    obec: "Vištuk",
    speed: "58 km/h",
    distance: "56,82",
    lat: 48.331191,
    lng: 17.428951,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:01:35",
    obec: "Vištuk",
    speed: "60 km/h",
    distance: "56,92",
    lat: 48.330387,
    lng: 17.429552,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:01:42",
    obec: "Vištuk",
    speed: "59 km/h",
    distance: "57,03",
    lat: 48.329464,
    lng: 17.43026,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:01:48",
    obec: "Jablonec",
    speed: "60 km/h",
    distance: "57,14",
    lat: 48.328638,
    lng: 17.430818,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:01:55",
    obec: "Jablonec",
    speed: "58 km/h",
    distance: "57,24",
    lat: 48.327704,
    lng: 17.431483,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:02:02",
    obec: "Jablonec",
    speed: "55 km/h",
    distance: "57,35",
    lat: 48.326814,
    lng: 17.432106,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:02:09",
    obec: "Jablonec",
    speed: "53 km/h",
    distance: "57,46",
    lat: 48.325923,
    lng: 17.432706,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:02:14",
    obec: "Jablonec",
    speed: "47 km/h",
    distance: "57,53",
    lat: 48.325333,
    lng: 17.433114,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:02:22",
    obec: "Jablonec",
    speed: "17 km/h",
    distance: "57,60",
    lat: 48.324775,
    lng: 17.433479,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:02:27",
    obec: "Báhoò",
    speed: "4 km/h",
    distance: "57,60",
    lat: 48.3247,
    lng: 17.433543,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:02:39",
    obec: "Báhoò",
    speed: "6 km/h",
    distance: "57,64",
    lat: 48.32455,
    lng: 17.433565,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:03:40",
    obec: "Báhoò",
    speed: "0 km/h",
    distance: "57,64",
    lat: 48.324454,
    lng: 17.433608,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:05:07",
    obec: "Báhoò",
    speed: "10 km/h",
    distance: "57,67",
    lat: 48.324529,
    lng: 17.433672,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:05:12",
    obec: "Báhoò",
    speed: "14 km/h",
    distance: "57,67",
    lat: 48.32469,
    lng: 17.433608,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:05:27",
    obec: "Jablonec",
    speed: "37 km/h",
    distance: "57,78",
    lat: 48.325559,
    lng: 17.433028,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:05:36",
    obec: "Jablonec",
    speed: "45 km/h",
    distance: "57,88",
    lat: 48.326428,
    lng: 17.432449,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:05:43",
    obec: "Jablonec",
    speed: "49 km/h",
    distance: "57,99",
    lat: 48.327179,
    lng: 17.431955,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:05:51",
    obec: "Jablonec",
    speed: "49 km/h",
    distance: "58,10",
    lat: 48.328069,
    lng: 17.431312,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:05:59",
    obec: "Vištuk",
    speed: "46 km/h",
    distance: "58,20",
    lat: 48.328949,
    lng: 17.430732,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:06:08",
    obec: "Vištuk",
    speed: "39 km/h",
    distance: "58,31",
    lat: 48.329818,
    lng: 17.430089,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:06:18",
    obec: "Vištuk",
    speed: "43 km/h",
    distance: "58,42",
    lat: 48.330655,
    lng: 17.429402,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:06:26",
    obec: "Vištuk",
    speed: "46 km/h",
    distance: "58,52",
    lat: 48.33147,
    lng: 17.428823,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:06:34",
    obec: "Jablonec",
    speed: "48 km/h",
    distance: "58,63",
    lat: 48.332339,
    lng: 17.428179,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:06:40",
    obec: "Jablonec",
    speed: "45 km/h",
    distance: "58,70",
    lat: 48.332972,
    lng: 17.427664,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:06:49",
    obec: "Vištuk",
    speed: "41 km/h",
    distance: "58,81",
    lat: 48.333734,
    lng: 17.426934,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:06:58",
    obec: "Budmerice",
    speed: "40 km/h",
    distance: "58,92",
    lat: 48.334549,
    lng: 17.426162,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:07:07",
    obec: "Budmerice",
    speed: "37 km/h",
    distance: "58,99",
    lat: 48.335258,
    lng: 17.425497,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:07:13",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "59,06",
    lat: 48.335794,
    lng: 17.424982,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:07:21",
    obec: "Budmerice",
    speed: "44 km/h",
    distance: "59,16",
    lat: 48.336577,
    lng: 17.424231,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:07:30",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "59,27",
    lat: 48.337371,
    lng: 17.42348,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:07:39",
    obec: "Budmerice",
    speed: "42 km/h",
    distance: "59,38",
    lat: 48.338144,
    lng: 17.422729,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:07:48",
    obec: "Budmerice",
    speed: "44 km/h",
    distance: "59,48",
    lat: 48.33897,
    lng: 17.421935,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:07:56",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "59,59",
    lat: 48.339753,
    lng: 17.421205,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:08:05",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "59,70",
    lat: 48.340558,
    lng: 17.420411,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:08:15",
    obec: "Budmerice",
    speed: "35 km/h",
    distance: "59,80",
    lat: 48.341384,
    lng: 17.419682,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:08:20",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "59,88",
    lat: 48.341834,
    lng: 17.419467,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:08:29",
    obec: "Budmerice",
    speed: "43 km/h",
    distance: "59,98",
    lat: 48.342768,
    lng: 17.419295,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:08:35",
    obec: "Budmerice",
    speed: "44 km/h",
    distance: "60,05",
    lat: 48.343433,
    lng: 17.419145,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:08:44",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "60,16",
    lat: 48.344388,
    lng: 17.418973,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:08:55",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "60,27",
    lat: 48.345353,
    lng: 17.418802,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:09:02",
    obec: "Budmerice",
    speed: "37 km/h",
    distance: "60,30",
    lat: 48.345847,
    lng: 17.418394,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:09:11",
    obec: "Budmerice",
    speed: "41 km/h",
    distance: "60,41",
    lat: 48.346576,
    lng: 17.417536,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:09:18",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "60,48",
    lat: 48.347102,
    lng: 17.416892,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:09:24",
    obec: "Budmerice",
    speed: "36 km/h",
    distance: "60,55",
    lat: 48.347521,
    lng: 17.416248,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:09:35",
    obec: "Budmerice",
    speed: "34 km/h",
    distance: "60,66",
    lat: 48.348239,
    lng: 17.415283,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:09:44",
    obec: "Budmerice",
    speed: "34 km/h",
    distance: "60,76",
    lat: 48.348872,
    lng: 17.414596,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:09:53",
    obec: "Budmerice",
    speed: "34 km/h",
    distance: "60,84",
    lat: 48.349581,
    lng: 17.414167,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:10:01",
    obec: "Budmerice",
    speed: "21 km/h",
    distance: "60,91",
    lat: 48.350138,
    lng: 17.413824,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:10:16",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "61,01",
    lat: 48.350943,
    lng: 17.41303,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:10:27",
    obec: "Budmerice",
    speed: "34 km/h",
    distance: "61,12",
    lat: 48.351662,
    lng: 17.412214,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:10:34",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "61,19",
    lat: 48.352145,
    lng: 17.411764,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:10:41",
    obec: "Budmerice",
    speed: "36 km/h",
    distance: "61,26",
    lat: 48.352713,
    lng: 17.411399,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:10:52",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "61,37",
    lat: 48.353604,
    lng: 17.410905,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:11:01",
    obec: "Budmerice",
    speed: "20 km/h",
    distance: "61,44",
    lat: 48.354194,
    lng: 17.410605,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:11:06",
    obec: "Budmerice",
    speed: "13 km/h",
    distance: "61,44",
    lat: 48.354409,
    lng: 17.410583,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:11:11",
    obec: "Budmerice",
    speed: "6 km/h",
    distance: "61,48",
    lat: 48.354494,
    lng: 17.410669,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:11:21",
    obec: "Budmerice",
    speed: "8 km/h",
    distance: "61,48",
    lat: 48.354527,
    lng: 17.410798,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:11:35",
    obec: "Budmerice",
    speed: "28 km/h",
    distance: "61,55",
    lat: 48.35428,
    lng: 17.411721,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:11:47",
    obec: "Budmerice",
    speed: "32 km/h",
    distance: "61,65",
    lat: 48.354044,
    lng: 17.413137,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:11:56",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "61,72",
    lat: 48.353947,
    lng: 17.414188,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:12:04",
    obec: "Budmerice",
    speed: "20 km/h",
    distance: "61,80",
    lat: 48.353979,
    lng: 17.415025,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:12:09",
    obec: "Budmerice",
    speed: "15 km/h",
    distance: "61,83",
    lat: 48.353958,
    lng: 17.415304,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:12:23",
    obec: "Budmerice",
    speed: "28 km/h",
    distance: "61,90",
    lat: 48.353346,
    lng: 17.416334,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:12:32",
    obec: "Budmerice",
    speed: "28 km/h",
    distance: "61,97",
    lat: 48.352917,
    lng: 17.417042,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:12:39",
    obec: "Budmerice",
    speed: "26 km/h",
    distance: "62,04",
    lat: 48.35266,
    lng: 17.417643,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:12:53",
    obec: "Budmerice",
    speed: "32 km/h",
    distance: "62,15",
    lat: 48.352177,
    lng: 17.418888,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:13:05",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "62,26",
    lat: 48.351662,
    lng: 17.420132,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:13:15",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "62,36",
    lat: 48.35104,
    lng: 17.421162,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:13:25",
    obec: "Budmerice",
    speed: "36 km/h",
    distance: "62,47",
    lat: 48.350364,
    lng: 17.422192,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:13:32",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "62,54",
    lat: 48.349945,
    lng: 17.4229,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:13:39",
    obec: "Budmerice",
    speed: "40 km/h",
    distance: "62,61",
    lat: 48.349441,
    lng: 17.423651,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:13:49",
    obec: "Budmerice",
    speed: "35 km/h",
    distance: "62,72",
    lat: 48.348797,
    lng: 17.424681,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:14:00",
    obec: "Budmerice",
    speed: "35 km/h",
    distance: "62,83",
    lat: 48.348132,
    lng: 17.425733,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:14:11",
    obec: "Budmerice",
    speed: "37 km/h",
    distance: "62,93",
    lat: 48.347478,
    lng: 17.426741,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:14:17",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "63,00",
    lat: 48.346984,
    lng: 17.427278,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:14:23",
    obec: "Budmerice",
    speed: "34 km/h",
    distance: "63,08",
    lat: 48.346523,
    lng: 17.427707,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:14:29",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "63,11",
    lat: 48.346169,
    lng: 17.428179,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:14:38",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "63,18",
    lat: 48.345879,
    lng: 17.429144,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:14:44",
    obec: "Budmerice",
    speed: "35 km/h",
    distance: "63,25",
    lat: 48.345632,
    lng: 17.42981,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:14:52",
    obec: "Budmerice",
    speed: "37 km/h",
    distance: "63,32",
    lat: 48.34515,
    lng: 17.430604,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:15:02",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "63,43",
    lat: 48.344474,
    lng: 17.431633,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:15:12",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "63,50",
    lat: 48.34383,
    lng: 17.432706,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:15:22",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "63,61",
    lat: 48.343208,
    lng: 17.433736,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:15:34",
    obec: "Budmerice",
    speed: "26 km/h",
    distance: "63,72",
    lat: 48.342575,
    lng: 17.434788,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:15:42",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "63,79",
    lat: 48.342167,
    lng: 17.435389,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:15:51",
    obec: "Jablonec",
    speed: "28 km/h",
    distance: "63,86",
    lat: 48.341673,
    lng: 17.436054,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:16:03",
    obec: "Jablonec",
    speed: "32 km/h",
    distance: "63,96",
    lat: 48.340987,
    lng: 17.436976,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:16:11",
    obec: "Jablonec",
    speed: "34 km/h",
    distance: "64,04",
    lat: 48.340504,
    lng: 17.437663,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:16:22",
    obec: "Jablonec",
    speed: "32 km/h",
    distance: "64,14",
    lat: 48.339828,
    lng: 17.438564,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:16:33",
    obec: "Jablonec",
    speed: "34 km/h",
    distance: "64,25",
    lat: 48.339141,
    lng: 17.439551,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:16:40",
    obec: "Jablonec",
    speed: "31 km/h",
    distance: "64,32",
    lat: 48.338712,
    lng: 17.440131,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:16:52",
    obec: "Jablonec",
    speed: "33 km/h",
    distance: "64,43",
    lat: 48.338004,
    lng: 17.441118,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:16:59",
    obec: "Jablonec",
    speed: "17 km/h",
    distance: "64,46",
    lat: 48.337661,
    lng: 17.44159,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:17:09",
    obec: "Jablonec",
    speed: "16 km/h",
    distance: "64,50",
    lat: 48.337382,
    lng: 17.441976,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:17:23",
    obec: "Jablonec",
    speed: "30 km/h",
    distance: "64,60",
    lat: 48.336674,
    lng: 17.44292,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:17:34",
    obec: "Jablonec",
    speed: "33 km/h",
    distance: "64,71",
    lat: 48.335998,
    lng: 17.443864,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:17:46",
    obec: "Jablonec",
    speed: "37 km/h",
    distance: "64,82",
    lat: 48.3353,
    lng: 17.444851,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:17:53",
    obec: "Jablonec",
    speed: "38 km/h",
    distance: "64,89",
    lat: 48.334807,
    lng: 17.445538,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:18:00",
    obec: "Jablonec",
    speed: "34 km/h",
    distance: "64,96",
    lat: 48.334389,
    lng: 17.446225,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:18:08",
    obec: "Jablonec",
    speed: "32 km/h",
    distance: "65,03",
    lat: 48.333938,
    lng: 17.446976,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:18:20",
    obec: "Jablonec",
    speed: "29 km/h",
    distance: "65,14",
    lat: 48.333434,
    lng: 17.448113,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:18:28",
    obec: "Jablonec",
    speed: "19 km/h",
    distance: "65,21",
    lat: 48.333123,
    lng: 17.448735,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:18:33",
    obec: "Jablonec",
    speed: "6 km/h",
    distance: "65,21",
    lat: 48.333037,
    lng: 17.448928,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:21:05",
    obec: "Jablonec",
    speed: "0 km/h",
    distance: "65,21",
    lat: 48.333015,
    lng: 17.44895,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:22:09",
    obec: "Jablonec",
    speed: "12 km/h",
    distance: "65,24",
    lat: 48.332962,
    lng: 17.449121,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:22:26",
    obec: "Jablonec",
    speed: "27 km/h",
    distance: "65,35",
    lat: 48.332447,
    lng: 17.450345,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:22:38",
    obec: "Jablonec",
    speed: "22 km/h",
    distance: "65,42",
    lat: 48.332028,
    lng: 17.451439,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:22:50",
    obec: "Jablonec",
    speed: "27 km/h",
    distance: "65,49",
    lat: 48.331696,
    lng: 17.452276,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:23:02",
    obec: "Jablonec",
    speed: "34 km/h",
    distance: "65,60",
    lat: 48.331116,
    lng: 17.453392,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:23:13",
    obec: "Jablonec",
    speed: "34 km/h",
    distance: "65,71",
    lat: 48.330548,
    lng: 17.454529,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:23:24",
    obec: "Jablonec",
    speed: "34 km/h",
    distance: "65,81",
    lat: 48.32999,
    lng: 17.455645,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:23:34",
    obec: "Jablonec",
    speed: "36 km/h",
    distance: "65,92",
    lat: 48.329485,
    lng: 17.456782,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:23:44",
    obec: "Jablonec",
    speed: "35 km/h",
    distance: "66,03",
    lat: 48.329046,
    lng: 17.457983,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:23:51",
    obec: "Jablonec",
    speed: "35 km/h",
    distance: "66,10",
    lat: 48.328756,
    lng: 17.45882,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:24:02",
    obec: "Jablonec",
    speed: "32 km/h",
    distance: "66,20",
    lat: 48.328337,
    lng: 17.460022,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:24:11",
    obec: "Jablonec",
    speed: "24 km/h",
    distance: "66,28",
    lat: 48.328005,
    lng: 17.460923,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:24:16",
    obec: "Jablonec",
    speed: "13 km/h",
    distance: "66,31",
    lat: 48.327898,
    lng: 17.461181,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:24:26",
    obec: "Jablonec",
    speed: "4 km/h",
    distance: "66,31",
    lat: 48.327833,
    lng: 17.461374,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:24:32",
    obec: "Jablonec",
    speed: "9 km/h",
    distance: "66,31",
    lat: 48.32778,
    lng: 17.461503,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:24:43",
    obec: "Cífer",
    speed: "22 km/h",
    distance: "66,38",
    lat: 48.327533,
    lng: 17.462318,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:24:48",
    obec: "Cífer",
    speed: "12 km/h",
    distance: "66,42",
    lat: 48.32749,
    lng: 17.462575,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:24:53",
    obec: "Cífer",
    speed: "7 km/h",
    distance: "66,42",
    lat: 48.327436,
    lng: 17.462769,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:25:09",
    obec: "Cífer",
    speed: "0 km/h",
    distance: "66,45",
    lat: 48.327404,
    lng: 17.462919,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:25:35",
    obec: "Cífer",
    speed: "0 km/h",
    distance: "66,45",
    lat: 48.327393,
    lng: 17.462854,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:25:49",
    obec: "Cífer",
    speed: "10 km/h",
    distance: "66,45",
    lat: 48.327458,
    lng: 17.462811,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:26:03",
    obec: "Jablonec",
    speed: "15 km/h",
    distance: "66,52",
    lat: 48.327619,
    lng: 17.462125,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:26:11",
    obec: "Jablonec",
    speed: "21 km/h",
    distance: "66,56",
    lat: 48.327769,
    lng: 17.461674,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:26:22",
    obec: "Jablonec",
    speed: "14 km/h",
    distance: "66,60",
    lat: 48.328048,
    lng: 17.460988,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:26:44",
    obec: "Jablonec",
    speed: "9 km/h",
    distance: "66,63",
    lat: 48.328101,
    lng: 17.460816,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:26:58",
    obec: "Jablonec",
    speed: "23 km/h",
    distance: "66,70",
    lat: 48.328413,
    lng: 17.459936,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:27:11",
    obec: "Jablonec",
    speed: "30 km/h",
    distance: "66,81",
    lat: 48.328831,
    lng: 17.458756,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:27:22",
    obec: "Jablonec",
    speed: "34 km/h",
    distance: "66,92",
    lat: 48.32926,
    lng: 17.45749,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:27:33",
    obec: "Jablonec",
    speed: "33 km/h",
    distance: "67,02",
    lat: 48.329732,
    lng: 17.456267,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:27:43",
    obec: "Jablonec",
    speed: "33 km/h",
    distance: "67,09",
    lat: 48.330215,
    lng: 17.455258,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:27:54",
    obec: "Jablonec",
    speed: "33 km/h",
    distance: "67,20",
    lat: 48.330784,
    lng: 17.4541,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:28:06",
    obec: "Jablonec",
    speed: "30 km/h",
    distance: "67,31",
    lat: 48.331363,
    lng: 17.452984,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:28:18",
    obec: "Jablonec",
    speed: "30 km/h",
    distance: "67,41",
    lat: 48.331899,
    lng: 17.451847,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:28:30",
    obec: "Jablonec",
    speed: "31 km/h",
    distance: "67,52",
    lat: 48.332382,
    lng: 17.450624,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:28:43",
    obec: "Jablonec",
    speed: "25 km/h",
    distance: "67,63",
    lat: 48.332876,
    lng: 17.449422,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:28:53",
    obec: "Jablonec",
    speed: "27 km/h",
    distance: "67,70",
    lat: 48.333251,
    lng: 17.448585,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:29:02",
    obec: "Jablonec",
    speed: "30 km/h",
    distance: "67,77",
    lat: 48.333595,
    lng: 17.447791,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:29:14",
    obec: "Jablonec",
    speed: "28 km/h",
    distance: "67,88",
    lat: 48.33411,
    lng: 17.446675,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:29:22",
    obec: "Jablonec",
    speed: "28 km/h",
    distance: "67,95",
    lat: 48.334528,
    lng: 17.446053,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:29:31",
    obec: "Jablonec",
    speed: "22 km/h",
    distance: "68,02",
    lat: 48.334968,
    lng: 17.445366,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:29:36",
    obec: "Jablonec",
    speed: "11 km/h",
    distance: "68,02",
    lat: 48.335097,
    lng: 17.445173,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:29:41",
    obec: "Jablonec",
    speed: "6 km/h",
    distance: "68,05",
    lat: 48.335172,
    lng: 17.445045,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:29:46",
    obec: "Jablonec",
    speed: "10 km/h",
    distance: "68,05",
    lat: 48.335236,
    lng: 17.444937,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:29:51",
    obec: "Jablonec",
    speed: "16 km/h",
    distance: "68,09",
    lat: 48.335354,
    lng: 17.444766,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:29:59",
    obec: "Jablonec",
    speed: "21 km/h",
    distance: "68,12",
    lat: 48.335644,
    lng: 17.444379,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:30:14",
    obec: "Jablonec",
    speed: "26 km/h",
    distance: "68,23",
    lat: 48.336352,
    lng: 17.443457,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:30:19",
    obec: "Jablonec",
    speed: "21 km/h",
    distance: "68,27",
    lat: 48.336556,
    lng: 17.443156,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:30:24",
    obec: "Jablonec",
    speed: "14 km/h",
    distance: "68,27",
    lat: 48.336738,
    lng: 17.442899,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:30:29",
    obec: "Jablonec",
    speed: "7 km/h",
    distance: "68,30",
    lat: 48.336813,
    lng: 17.442791,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:30:49",
    obec: "Jablonec",
    speed: "28 km/h",
    distance: "68,41",
    lat: 48.337532,
    lng: 17.441804,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:30:54",
    obec: "Jablonec",
    speed: "24 km/h",
    distance: "68,44",
    lat: 48.33779,
    lng: 17.441461,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:31:07",
    obec: "Jablonec",
    speed: "29 km/h",
    distance: "68,55",
    lat: 48.338455,
    lng: 17.440517,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:31:16",
    obec: "Jablonec",
    speed: "31 km/h",
    distance: "68,62",
    lat: 48.338959,
    lng: 17.439852,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:31:27",
    obec: "Jablonec",
    speed: "35 km/h",
    distance: "68,73",
    lat: 48.339624,
    lng: 17.438908,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:31:37",
    obec: "Jablonec",
    speed: "37 km/h",
    distance: "68,80",
    lat: 48.340289,
    lng: 17.438006,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:31:48",
    obec: "Jablonec",
    speed: "34 km/h",
    distance: "68,91",
    lat: 48.341008,
    lng: 17.437041,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:32:01",
    obec: "Jablonec",
    speed: "29 km/h",
    distance: "69,01",
    lat: 48.341716,
    lng: 17.436054,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:32:11",
    obec: "Budmerice",
    speed: "27 km/h",
    distance: "69,12",
    lat: 48.342285,
    lng: 17.435281,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:32:18",
    obec: "Budmerice",
    speed: "16 km/h",
    distance: "69,16",
    lat: 48.342575,
    lng: 17.434874,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:32:23",
    obec: "Budmerice",
    speed: "6 km/h",
    distance: "69,19",
    lat: 48.342671,
    lng: 17.434723,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "17:34:22",
    obec: "Budmerice",
    speed: "0 km/h",
    distance: "69,19",
    lat: 48.342693,
    lng: 17.434702,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:38:07",
    obec: "Budmerice",
    speed: "12 km/h",
    distance: "69,19",
    lat: 48.342768,
    lng: 17.434509,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:38:15",
    obec: "Budmerice",
    speed: "7 km/h",
    distance: "69,23",
    lat: 48.342961,
    lng: 17.434165,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:38:35",
    obec: "Budmerice",
    speed: "27 km/h",
    distance: "69,30",
    lat: 48.343454,
    lng: 17.433329,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:38:45",
    obec: "Budmerice",
    speed: "36 km/h",
    distance: "69,40",
    lat: 48.344034,
    lng: 17.432384,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:38:52",
    obec: "Budmerice",
    speed: "41 km/h",
    distance: "69,48",
    lat: 48.344484,
    lng: 17.431569,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:39:01",
    obec: "Budmerice",
    speed: "41 km/h",
    distance: "69,58",
    lat: 48.345128,
    lng: 17.430604,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:39:08",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "69,65",
    lat: 48.345654,
    lng: 17.429831,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:39:15",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "69,72",
    lat: 48.345976,
    lng: 17.428994,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:39:20",
    obec: "Budmerice",
    speed: "35 km/h",
    distance: "69,80",
    lat: 48.346158,
    lng: 17.428308,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:39:25",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "69,83",
    lat: 48.346416,
    lng: 17.427857,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:39:36",
    obec: "Budmerice",
    speed: "35 km/h",
    distance: "69,94",
    lat: 48.347231,
    lng: 17.427106,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "17:39:44",
    obec: "Budmerice",
    speed: "36 km/h",
    distance: "70,01",
    lat: 48.347714,
    lng: 17.426333,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:39:50",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "70,08",
    lat: 48.348121,
    lng: 17.42569,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:39:55",
    obec: "Budmerice",
    speed: "41 km/h",
    distance: "70,15",
    lat: 48.348454,
    lng: 17.425175,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:40:03",
    obec: "Budmerice",
    speed: "44 km/h",
    distance: "70,26",
    lat: 48.349087,
    lng: 17.424209,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:40:11",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "70,36",
    lat: 48.349742,
    lng: 17.423158,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:40:19",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "70,47",
    lat: 48.350407,
    lng: 17.422128,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:40:27",
    obec: "Budmerice",
    speed: "48 km/h",
    distance: "70,58",
    lat: 48.35104,
    lng: 17.421098,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:40:33",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "70,65",
    lat: 48.351565,
    lng: 17.420368,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:40:39",
    obec: "Budmerice",
    speed: "43 km/h",
    distance: "70,72",
    lat: 48.351962,
    lng: 17.419596,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:40:47",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "70,83",
    lat: 48.352434,
    lng: 17.418394,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:40:56",
    obec: "Budmerice",
    speed: "40 km/h",
    distance: "70,93",
    lat: 48.352917,
    lng: 17.417128,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:41:02",
    obec: "Budmerice",
    speed: "40 km/h",
    distance: "71,00",
    lat: 48.353314,
    lng: 17.416463,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:41:08",
    obec: "Budmerice",
    speed: "27 km/h",
    distance: "71,04",
    lat: 48.35369,
    lng: 17.415905,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:41:15",
    obec: "Budmerice",
    speed: "16 km/h",
    distance: "71,11",
    lat: 48.353947,
    lng: 17.415454,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:41:20",
    obec: "Budmerice",
    speed: "0 km/h",
    distance: "71,11",
    lat: 48.35399,
    lng: 17.415433,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:41:25",
    obec: "Budmerice",
    speed: "12 km/h",
    distance: "71,11",
    lat: 48.354087,
    lng: 17.415497,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:41:31",
    obec: "Budmerice",
    speed: "20 km/h",
    distance: "71,15",
    lat: 48.35414,
    lng: 17.415819,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:41:46",
    obec: "Budmerice",
    speed: "32 km/h",
    distance: "71,25",
    lat: 48.354419,
    lng: 17.41715,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:41:55",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "71,32",
    lat: 48.354559,
    lng: 17.418244,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:42:03",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "71,43",
    lat: 48.354634,
    lng: 17.419488,
    pluh: "dole",
    sypac: "nesype",
  },
  {
    time: "17:42:11",
    obec: "Budmerice",
    speed: "41 km/h",
    distance: "71,50",
    lat: 48.354623,
    lng: 17.420776,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:42:18",
    obec: "Budmerice",
    speed: "42 km/h",
    distance: "71,61",
    lat: 48.354816,
    lng: 17.421784,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:42:24",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "71,68",
    lat: 48.355202,
    lng: 17.422664,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:42:32",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "71,79",
    lat: 48.35575,
    lng: 17.423866,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:42:37",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "71,86",
    lat: 48.356039,
    lng: 17.424595,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:42:45",
    obec: "Budmerice",
    speed: "47 km/h",
    distance: "71,96",
    lat: 48.356318,
    lng: 17.425969,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:42:52",
    obec: "Budmerice",
    speed: "48 km/h",
    distance: "72,04",
    lat: 48.356576,
    lng: 17.427213,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:43:00",
    obec: "Budmerice",
    speed: "50 km/h",
    distance: "72,14",
    lat: 48.356865,
    lng: 17.428651,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:43:07",
    obec: "Budmerice",
    speed: "46 km/h",
    distance: "72,25",
    lat: 48.357144,
    lng: 17.429917,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:43:15",
    obec: "Budmerice",
    speed: "51 km/h",
    distance: "72,36",
    lat: 48.357456,
    lng: 17.431269,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:43:22",
    obec: "Budmerice",
    speed: "52 km/h",
    distance: "72,46",
    lat: 48.357734,
    lng: 17.432599,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:43:30",
    obec: "Budmerice",
    speed: "50 km/h",
    distance: "72,53",
    lat: 48.358035,
    lng: 17.434015,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:43:37",
    obec: "Budmerice",
    speed: "53 km/h",
    distance: "72,64",
    lat: 48.358303,
    lng: 17.435346,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:43:44",
    obec: "Budmerice",
    speed: "55 km/h",
    distance: "72,75",
    lat: 48.358593,
    lng: 17.436697,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:43:51",
    obec: "Budmerice",
    speed: "52 km/h",
    distance: "72,85",
    lat: 48.358893,
    lng: 17.438071,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:43:59",
    obec: "Budmerice",
    speed: "50 km/h",
    distance: "72,96",
    lat: 48.359,
    lng: 17.439551,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:44:07",
    obec: "Budmerice",
    speed: "47 km/h",
    distance: "73,07",
    lat: 48.359097,
    lng: 17.44101,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:44:15",
    obec: "Budmerice",
    speed: "47 km/h",
    distance: "73,17",
    lat: 48.359215,
    lng: 17.442405,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:44:23",
    obec: "Budmerice",
    speed: "50 km/h",
    distance: "73,28",
    lat: 48.359312,
    lng: 17.443886,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:44:31",
    obec: "Budmerice",
    speed: "50 km/h",
    distance: "73,39",
    lat: 48.359419,
    lng: 17.445345,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:44:39",
    obec: "Budmerice",
    speed: "48 km/h",
    distance: "73,49",
    lat: 48.359505,
    lng: 17.446783,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:44:47",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "73,60",
    lat: 48.359601,
    lng: 17.448177,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:44:55",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "73,71",
    lat: 48.359709,
    lng: 17.449594,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:45:03",
    obec: "Budmerice",
    speed: "48 km/h",
    distance: "73,81",
    lat: 48.359827,
    lng: 17.450945,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:45:11",
    obec: "Budmerice",
    speed: "47 km/h",
    distance: "73,92",
    lat: 48.359912,
    lng: 17.452383,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:45:19",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "74,03",
    lat: 48.360009,
    lng: 17.453842,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:45:27",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "74,13",
    lat: 48.360106,
    lng: 17.455323,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:45:35",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "74,24",
    lat: 48.360191,
    lng: 17.45676,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:45:42",
    obec: "Budmerice",
    speed: "52 km/h",
    distance: "74,35",
    lat: 48.360288,
    lng: 17.458091,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:45:49",
    obec: "Budmerice",
    speed: "53 km/h",
    distance: "74,45",
    lat: 48.360449,
    lng: 17.459486,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:45:56",
    obec: "Budmerice",
    speed: "53 km/h",
    distance: "74,56",
    lat: 48.360717,
    lng: 17.460794,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:46:02",
    obec: "Budmerice",
    speed: "55 km/h",
    distance: "74,67",
    lat: 48.36105,
    lng: 17.461953,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:46:09",
    obec: "Budmerice",
    speed: "53 km/h",
    distance: "74,77",
    lat: 48.361575,
    lng: 17.463155,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:46:15",
    obec: "Budmerice",
    speed: "53 km/h",
    distance: "74,84",
    lat: 48.362047,
    lng: 17.464142,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:46:20",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "74,92",
    lat: 48.362541,
    lng: 17.464807,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:46:26",
    obec: "Ružindol",
    speed: "33 km/h",
    distance: "74,99",
    lat: 48.36297,
    lng: 17.465386,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:46:32",
    obec: "Ružindol",
    speed: "13 km/h",
    distance: "75,02",
    lat: 48.363195,
    lng: 17.465687,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:46:38",
    obec: "Ružindol",
    speed: "0 km/h",
    distance: "75,02",
    lat: 48.363281,
    lng: 17.465858,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:47:00",
    obec: "Ružindol",
    speed: "4 km/h",
    distance: "75,06",
    lat: 48.36326,
    lng: 17.465837,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:47:14",
    obec: "Ružindol",
    speed: "10 km/h",
    distance: "75,06",
    lat: 48.363228,
    lng: 17.465708,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:47:19",
    obec: "Ružindol",
    speed: "20 km/h",
    distance: "75,09",
    lat: 48.363099,
    lng: 17.465494,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:47:28",
    obec: "Budmerice",
    speed: "13 km/h",
    distance: "75,13",
    lat: 48.362734,
    lng: 17.465,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:47:37",
    obec: "Budmerice",
    speed: "14 km/h",
    distance: "75,16",
    lat: 48.362627,
    lng: 17.46485,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:47:47",
    obec: "Budmerice",
    speed: "34 km/h",
    distance: "75,24",
    lat: 48.362165,
    lng: 17.464163,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:47:54",
    obec: "Budmerice",
    speed: "42 km/h",
    distance: "75,31",
    lat: 48.361704,
    lng: 17.463348,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:48:03",
    obec: "Budmerice",
    speed: "44 km/h",
    distance: "75,41",
    lat: 48.361178,
    lng: 17.462125,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:48:09",
    obec: "Budmerice",
    speed: "48 km/h",
    distance: "75,48",
    lat: 48.360899,
    lng: 17.461181,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:48:16",
    obec: "Budmerice",
    speed: "54 km/h",
    distance: "75,59",
    lat: 48.360545,
    lng: 17.459872,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:48:22",
    obec: "Budmerice",
    speed: "52 km/h",
    distance: "75,70",
    lat: 48.360406,
    lng: 17.458692,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:48:30",
    obec: "Budmerice",
    speed: "51 km/h",
    distance: "75,80",
    lat: 48.360266,
    lng: 17.457168,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:48:38",
    obec: "Budmerice",
    speed: "47 km/h",
    distance: "75,91",
    lat: 48.360159,
    lng: 17.455688,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:48:46",
    obec: "Budmerice",
    speed: "48 km/h",
    distance: "76,02",
    lat: 48.360063,
    lng: 17.454207,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:48:54",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "76,12",
    lat: 48.359955,
    lng: 17.452705,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:49:02",
    obec: "Budmerice",
    speed: "50 km/h",
    distance: "76,23",
    lat: 48.359837,
    lng: 17.451224,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:49:10",
    obec: "Budmerice",
    speed: "47 km/h",
    distance: "76,34",
    lat: 48.359741,
    lng: 17.449787,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:49:18",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "76,44",
    lat: 48.359655,
    lng: 17.448328,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:49:26",
    obec: "Budmerice",
    speed: "47 km/h",
    distance: "76,55",
    lat: 48.359548,
    lng: 17.446847,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:49:34",
    obec: "Budmerice",
    speed: "47 km/h",
    distance: "76,69",
    lat: 48.35944,
    lng: 17.445366,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:49:42",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "76,80",
    lat: 48.359344,
    lng: 17.44395,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:49:50",
    obec: "Budmerice",
    speed: "50 km/h",
    distance: "76,91",
    lat: 48.359237,
    lng: 17.442513,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:49:57",
    obec: "Budmerice",
    speed: "51 km/h",
    distance: "76,98",
    lat: 48.35914,
    lng: 17.441161,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:50:05",
    obec: "Budmerice",
    speed: "51 km/h",
    distance: "77,08",
    lat: 48.359054,
    lng: 17.43968,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:50:13",
    obec: "Budmerice",
    speed: "47 km/h",
    distance: "77,23",
    lat: 48.358958,
    lng: 17.438178,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:50:19",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "77,30",
    lat: 48.358722,
    lng: 17.437191,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:50:27",
    obec: "Budmerice",
    speed: "44 km/h",
    distance: "77,40",
    lat: 48.358443,
    lng: 17.435882,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:50:35",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "77,51",
    lat: 48.358174,
    lng: 17.434552,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:50:44",
    obec: "Budmerice",
    speed: "44 km/h",
    distance: "77,62",
    lat: 48.357853,
    lng: 17.433114,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:50:52",
    obec: "Budmerice",
    speed: "47 km/h",
    distance: "77,72",
    lat: 48.357584,
    lng: 17.431762,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:51:00",
    obec: "Budmerice",
    speed: "49 km/h",
    distance: "77,83",
    lat: 48.357284,
    lng: 17.43041,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:51:07",
    obec: "Budmerice",
    speed: "52 km/h",
    distance: "77,94",
    lat: 48.357016,
    lng: 17.429123,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:51:14",
    obec: "Budmerice",
    speed: "54 km/h",
    distance: "78,01",
    lat: 48.356737,
    lng: 17.427814,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:51:21",
    obec: "Budmerice",
    speed: "52 km/h",
    distance: "78,12",
    lat: 48.356447,
    lng: 17.426484,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:51:28",
    obec: "Budmerice",
    speed: "53 km/h",
    distance: "78,22",
    lat: 48.356179,
    lng: 17.425153,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:51:35",
    obec: "Budmerice",
    speed: "52 km/h",
    distance: "78,33",
    lat: 48.355835,
    lng: 17.423887,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:51:42",
    obec: "Budmerice",
    speed: "53 km/h",
    distance: "78,44",
    lat: 48.355278,
    lng: 17.422707,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:51:49",
    obec: "Budmerice",
    speed: "43 km/h",
    distance: "78,54",
    lat: 48.354763,
    lng: 17.421591,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:51:54",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "78,58",
    lat: 48.354634,
    lng: 17.420948,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:52:03",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "78,72",
    lat: 48.354655,
    lng: 17.419446,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:52:11",
    obec: "Budmerice",
    speed: "41 km/h",
    distance: "78,79",
    lat: 48.354591,
    lng: 17.418094,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:52:20",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "78,90",
    lat: 48.354376,
    lng: 17.416785,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:52:28",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "78,97",
    lat: 48.354183,
    lng: 17.415755,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:52:37",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "79,08",
    lat: 48.354001,
    lng: 17.41451,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:52:46",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "79,18",
    lat: 48.354097,
    lng: 17.413244,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:52:56",
    obec: "Budmerice",
    speed: "37 km/h",
    distance: "79,29",
    lat: 48.354333,
    lng: 17.411849,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:53:01",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "79,32",
    lat: 48.354451,
    lng: 17.411206,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:53:07",
    obec: "Budmerice",
    speed: "34 km/h",
    distance: "79,40",
    lat: 48.354773,
    lng: 17.410605,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:53:13",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "79,47",
    lat: 48.355288,
    lng: 17.410326,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:53:22",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "79,54",
    lat: 48.356082,
    lng: 17.409961,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:53:27",
    obec: "Budmerice",
    speed: "25 km/h",
    distance: "79,57",
    lat: 48.356393,
    lng: 17.409682,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:53:32",
    obec: "Budmerice",
    speed: "18 km/h",
    distance: "79,61",
    lat: 48.356619,
    lng: 17.409489,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:53:47",
    obec: "Budmerice",
    speed: "32 km/h",
    distance: "79,72",
    lat: 48.35752,
    lng: 17.40906,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:53:58",
    obec: "Budmerice",
    speed: "32 km/h",
    distance: "79,82",
    lat: 48.358389,
    lng: 17.408609,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:54:09",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "79,93",
    lat: 48.35914,
    lng: 17.407837,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:54:16",
    obec: "Budmerice",
    speed: "36 km/h",
    distance: "80,00",
    lat: 48.359655,
    lng: 17.407258,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:54:25",
    obec: "Budmerice",
    speed: "37 km/h",
    distance: "80,07",
    lat: 48.360309,
    lng: 17.406485,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:54:35",
    obec: "Budmerice",
    speed: "37 km/h",
    distance: "80,18",
    lat: 48.360996,
    lng: 17.405498,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:54:45",
    obec: "Budmerice",
    speed: "36 km/h",
    distance: "80,28",
    lat: 48.361683,
    lng: 17.404597,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:54:51",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "80,36",
    lat: 48.362101,
    lng: 17.404039,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:55:00",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "80,46",
    lat: 48.362756,
    lng: 17.403138,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:55:06",
    obec: "Budmerice",
    speed: "39 km/h",
    distance: "80,53",
    lat: 48.363206,
    lng: 17.402558,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:55:15",
    obec: "Budmerice",
    speed: "37 km/h",
    distance: "80,60",
    lat: 48.363828,
    lng: 17.401679,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:55:25",
    obec: "Budmerice",
    speed: "35 km/h",
    distance: "80,71",
    lat: 48.364504,
    lng: 17.400756,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:55:32",
    obec: "Budmerice",
    speed: "36 km/h",
    distance: "80,78",
    lat: 48.365052,
    lng: 17.400219,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:55:39",
    obec: "Budmerice",
    speed: "38 km/h",
    distance: "80,85",
    lat: 48.365652,
    lng: 17.399876,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:55:46",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "80,92",
    lat: 48.366232,
    lng: 17.399511,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:55:52",
    obec: "Budmerice",
    speed: "35 km/h",
    distance: "81,00",
    lat: 48.366725,
    lng: 17.399619,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:55:59",
    obec: "Budmerice",
    speed: "42 km/h",
    distance: "81,07",
    lat: 48.367305,
    lng: 17.400198,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:56:06",
    obec: "Budmerice",
    speed: "35 km/h",
    distance: "81,14",
    lat: 48.367873,
    lng: 17.400756,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:56:14",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "81,21",
    lat: 48.36841,
    lng: 17.401185,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:56:23",
    obec: "Budmerice",
    speed: "18 km/h",
    distance: "81,28",
    lat: 48.369064,
    lng: 17.401314,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:56:28",
    obec: "Budmerice",
    speed: "9 km/h",
    distance: "81,32",
    lat: 48.369214,
    lng: 17.401314,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:56:51",
    obec: "Budmerice",
    speed: "9 km/h",
    distance: "81,32",
    lat: 48.369343,
    lng: 17.401335,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:57:03",
    obec: "Budmerice",
    speed: "18 km/h",
    distance: "81,39",
    lat: 48.369869,
    lng: 17.401335,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:57:17",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "81,49",
    lat: 48.370791,
    lng: 17.4014,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:57:28",
    obec: "Budmerice",
    speed: "34 km/h",
    distance: "81,56",
    lat: 48.371725,
    lng: 17.401464,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:57:40",
    obec: "Budmerice",
    speed: "25 km/h",
    distance: "81,64",
    lat: 48.372637,
    lng: 17.401507,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:57:51",
    obec: "Budmerice",
    speed: "46 km/h",
    distance: "81,74",
    lat: 48.373635,
    lng: 17.401593,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:58:00",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "81,88",
    lat: 48.374643,
    lng: 17.401636,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:58:08",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "81,99",
    lat: 48.375587,
    lng: 17.401636,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:58:14",
    obec: "Budmerice",
    speed: "41 km/h",
    distance: "82,06",
    lat: 48.376231,
    lng: 17.401593,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:58:25",
    obec: "Budmerice",
    speed: "27 km/h",
    distance: "82,17",
    lat: 48.377164,
    lng: 17.40155,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:58:30",
    obec: "Budmerice",
    speed: "23 km/h",
    distance: "82,17",
    lat: 48.377486,
    lng: 17.401528,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:58:44",
    obec: "Budmerice",
    speed: "17 km/h",
    distance: "82,28",
    lat: 48.378205,
    lng: 17.401507,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:58:50",
    obec: "Budmerice",
    speed: "22 km/h",
    distance: "82,31",
    lat: 48.378506,
    lng: 17.401507,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:58:55",
    obec: "Budmerice",
    speed: "26 km/h",
    distance: "82,35",
    lat: 48.378806,
    lng: 17.40155,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:59:03",
    obec: "Budmerice",
    speed: "28 km/h",
    distance: "82,38",
    lat: 48.379364,
    lng: 17.401872,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:59:15",
    obec: "Štefanová",
    speed: "27 km/h",
    distance: "82,49",
    lat: 48.380126,
    lng: 17.402365,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:59:23",
    obec: "Štefanová",
    speed: "26 km/h",
    distance: "82,56",
    lat: 48.380662,
    lng: 17.402279,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:59:31",
    obec: "Štefanová",
    speed: "29 km/h",
    distance: "82,63",
    lat: 48.381123,
    lng: 17.401786,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:59:43",
    obec: "Štefanová",
    speed: "30 km/h",
    distance: "82,70",
    lat: 48.381831,
    lng: 17.400906,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "17:59:55",
    obec: "Štefanová",
    speed: "32 km/h",
    distance: "82,81",
    lat: 48.382529,
    lng: 17.400005,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:00:07",
    obec: "Štefanová",
    speed: "31 km/h",
    distance: "82,92",
    lat: 48.383248,
    lng: 17.399082,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:00:19",
    obec: "Štefanová",
    speed: "28 km/h",
    distance: "83,02",
    lat: 48.383999,
    lng: 17.398331,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:00:31",
    obec: "Štefanová",
    speed: "28 km/h",
    distance: "83,13",
    lat: 48.384653,
    lng: 17.39758,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:00:44",
    obec: "Štefanová",
    speed: "28 km/h",
    distance: "83,24",
    lat: 48.385275,
    lng: 17.396593,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:00:57",
    obec: "Štefanová",
    speed: "28 km/h",
    distance: "83,31",
    lat: 48.38593,
    lng: 17.395585,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:01:03",
    obec: "Štefanová",
    speed: "28 km/h",
    distance: "83,38",
    lat: 48.386252,
    lng: 17.395155,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:01:08",
    obec: "Štefanová",
    speed: "27 km/h",
    distance: "83,41",
    lat: 48.386552,
    lng: 17.394834,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:01:16",
    obec: "Štefanová",
    speed: "28 km/h",
    distance: "83,48",
    lat: 48.387046,
    lng: 17.39449,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:01:29",
    obec: "Štefanová",
    speed: "29 km/h",
    distance: "83,59",
    lat: 48.387925,
    lng: 17.393997,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:01:38",
    obec: "Štefanová",
    speed: "28 km/h",
    distance: "83,66",
    lat: 48.388548,
    lng: 17.393696,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:01:48",
    obec: "Štefanová",
    speed: "30 km/h",
    distance: "83,73",
    lat: 48.389277,
    lng: 17.393525,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:01:57",
    obec: "Štefanová",
    speed: "30 km/h",
    distance: "83,80",
    lat: 48.389921,
    lng: 17.393289,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:02:09",
    obec: "Štefanová",
    speed: "29 km/h",
    distance: "83,91",
    lat: 48.390715,
    lng: 17.392752,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:02:21",
    obec: "Štefanová",
    speed: "30 km/h",
    distance: "84,02",
    lat: 48.391541,
    lng: 17.392173,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:02:29",
    obec: "Štefanová",
    speed: "28 km/h",
    distance: "84,05",
    lat: 48.392077,
    lng: 17.391808,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:02:43",
    obec: "Štefanová",
    speed: "27 km/h",
    distance: "84,16",
    lat: 48.392957,
    lng: 17.391229,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:02:55",
    obec: "Štefanová",
    speed: "30 km/h",
    distance: "84,27",
    lat: 48.39373,
    lng: 17.390585,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:03:09",
    obec: "Štefanová",
    speed: "26 km/h",
    distance: "84,37",
    lat: 48.394545,
    lng: 17.389834,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:03:23",
    obec: "Štefanová",
    speed: "27 km/h",
    distance: "84,48",
    lat: 48.39535,
    lng: 17.389061,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:03:38",
    obec: "Štefanová",
    speed: "27 km/h",
    distance: "84,59",
    lat: 48.396165,
    lng: 17.38831,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:03:48",
    obec: "Štefanová",
    speed: "18 km/h",
    distance: "84,66",
    lat: 48.396648,
    lng: 17.38786,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:03:53",
    obec: "Štefanová",
    speed: "13 km/h",
    distance: "84,69",
    lat: 48.396809,
    lng: 17.387731,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:03:59",
    obec: "Štefanová",
    speed: "6 km/h",
    distance: "84,69",
    lat: 48.396927,
    lng: 17.387624,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:04:43",
    obec: "Štefanová",
    speed: "8 km/h",
    distance: "84,69",
    lat: 48.396981,
    lng: 17.387538,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:04:57",
    obec: "Štefanová",
    speed: "5 km/h",
    distance: "84,73",
    lat: 48.397045,
    lng: 17.387495,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:05:06",
    obec: "Štefanová",
    speed: "2 km/h",
    distance: "84,73",
    lat: 48.396938,
    lng: 17.387409,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:05:17",
    obec: "Štefanová",
    speed: "7 km/h",
    distance: "84,73",
    lat: 48.396905,
    lng: 17.387431,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:05:22",
    obec: "Štefanová",
    speed: "13 km/h",
    distance: "84,76",
    lat: 48.396841,
    lng: 17.387559,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:05:31",
    obec: "Štefanová",
    speed: "24 km/h",
    distance: "84,80",
    lat: 48.396466,
    lng: 17.387967,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:05:43",
    obec: "Štefanová",
    speed: "33 km/h",
    distance: "84,91",
    lat: 48.395672,
    lng: 17.388697,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:05:54",
    obec: "Štefanová",
    speed: "33 km/h",
    distance: "85,01",
    lat: 48.394888,
    lng: 17.389426,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:06:05",
    obec: "Štefanová",
    speed: "33 km/h",
    distance: "85,12",
    lat: 48.394105,
    lng: 17.390177,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:06:16",
    obec: "Štefanová",
    speed: "33 km/h",
    distance: "85,23",
    lat: 48.393311,
    lng: 17.390885,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:06:23",
    obec: "Štefanová",
    speed: "31 km/h",
    distance: "85,30",
    lat: 48.392786,
    lng: 17.391272,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:06:35",
    obec: "Štefanová",
    speed: "30 km/h",
    distance: "85,37",
    lat: 48.391949,
    lng: 17.391872,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:06:46",
    obec: "Štefanová",
    speed: "32 km/h",
    distance: "85,48",
    lat: 48.391112,
    lng: 17.39243,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:06:57",
    obec: "Štefanová",
    speed: "33 km/h",
    distance: "85,58",
    lat: 48.390275,
    lng: 17.39301,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:07:05",
    obec: "Štefanová",
    speed: "32 km/h",
    distance: "85,65",
    lat: 48.389685,
    lng: 17.393374,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:07:13",
    obec: "Štefanová",
    speed: "32 km/h",
    distance: "85,72",
    lat: 48.389031,
    lng: 17.393525,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:07:23",
    obec: "Štefanová",
    speed: "32 km/h",
    distance: "85,83",
    lat: 48.388258,
    lng: 17.393739,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:07:31",
    obec: "Štefanová",
    speed: "28 km/h",
    distance: "85,90",
    lat: 48.387668,
    lng: 17.394061,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:07:43",
    obec: "Štefanová",
    speed: "32 km/h",
    distance: "86,01",
    lat: 48.386788,
    lng: 17.394598,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:07:51",
    obec: "Štefanová",
    speed: "31 km/h",
    distance: "86,08",
    lat: 48.38623,
    lng: 17.395048,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:08:00",
    obec: "Štefanová",
    speed: "30 km/h",
    distance: "86,15",
    lat: 48.385747,
    lng: 17.395799,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:08:12",
    obec: "Štefanová",
    speed: "29 km/h",
    distance: "86,26",
    lat: 48.385136,
    lng: 17.396829,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:08:22",
    obec: "Štefanová",
    speed: "29 km/h",
    distance: "86,33",
    lat: 48.38461,
    lng: 17.397645,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:08:34",
    obec: "Štefanová",
    speed: "31 km/h",
    distance: "86,44",
    lat: 48.383827,
    lng: 17.39846,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:08:46",
    obec: "Štefanová",
    speed: "33 km/h",
    distance: "86,54",
    lat: 48.383033,
    lng: 17.399318,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:08:58",
    obec: "Štefanová",
    speed: "32 km/h",
    distance: "86,65",
    lat: 48.382293,
    lng: 17.400241,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:09:10",
    obec: "Štefanová",
    speed: "31 km/h",
    distance: "86,76",
    lat: 48.381585,
    lng: 17.401164,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:09:18",
    obec: "Štefanová",
    speed: "29 km/h",
    distance: "86,83",
    lat: 48.381113,
    lng: 17.401743,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:09:27",
    obec: "Štefanová",
    speed: "26 km/h",
    distance: "86,90",
    lat: 48.380619,
    lng: 17.402258,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:09:33",
    obec: "Štefanová",
    speed: "31 km/h",
    distance: "86,93",
    lat: 48.38019,
    lng: 17.402301,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:09:40",
    obec: "Štefanová",
    speed: "32 km/h",
    distance: "87,00",
    lat: 48.379643,
    lng: 17.402022,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:09:48",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "87,08",
    lat: 48.379063,
    lng: 17.401614,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:09:55",
    obec: "Budmerice",
    speed: "29 km/h",
    distance: "87,15",
    lat: 48.378559,
    lng: 17.401464,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:10:07",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "87,22",
    lat: 48.377658,
    lng: 17.401507,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:10:19",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "87,32",
    lat: 48.376735,
    lng: 17.401507,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:10:31",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "87,43",
    lat: 48.375813,
    lng: 17.401528,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:10:42",
    obec: "Budmerice",
    speed: "35 km/h",
    distance: "87,54",
    lat: 48.374901,
    lng: 17.401593,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:10:51",
    obec: "Budmerice",
    speed: "34 km/h",
    distance: "87,64",
    lat: 48.374107,
    lng: 17.401571,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:11:02",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "87,72",
    lat: 48.373184,
    lng: 17.401507,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:11:13",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "87,82",
    lat: 48.372261,
    lng: 17.401464,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:11:25",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "87,93",
    lat: 48.371317,
    lng: 17.4014,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:11:38",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "88,04",
    lat: 48.370352,
    lng: 17.401335,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:11:49",
    obec: "Budmerice",
    speed: "32 km/h",
    distance: "88,14",
    lat: 48.36944,
    lng: 17.401271,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:12:01",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "88,25",
    lat: 48.368506,
    lng: 17.401164,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:12:07",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "88,32",
    lat: 48.368088,
    lng: 17.400863,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:12:19",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "88,43",
    lat: 48.367272,
    lng: 17.400091,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:12:27",
    obec: "Budmerice",
    speed: "26 km/h",
    distance: "88,46",
    lat: 48.366811,
    lng: 17.399597,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:12:33",
    obec: "Budmerice",
    speed: "22 km/h",
    distance: "88,50",
    lat: 48.366478,
    lng: 17.399426,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:12:38",
    obec: "Budmerice",
    speed: "26 km/h",
    distance: "88,53",
    lat: 48.366167,
    lng: 17.399468,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:12:52",
    obec: "Budmerice",
    speed: "26 km/h",
    distance: "88,64",
    lat: 48.365288,
    lng: 17.400026,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:13:01",
    obec: "Budmerice",
    speed: "24 km/h",
    distance: "88,71",
    lat: 48.364794,
    lng: 17.400413,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:13:09",
    obec: "Budmerice",
    speed: "28 km/h",
    distance: "88,78",
    lat: 48.364365,
    lng: 17.400928,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:13:21",
    obec: "Budmerice",
    speed: "29 km/h",
    distance: "88,89",
    lat: 48.363689,
    lng: 17.40185,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:13:33",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "89,00",
    lat: 48.363013,
    lng: 17.402773,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:13:42",
    obec: "Budmerice",
    speed: "29 km/h",
    distance: "89,07",
    lat: 48.36252,
    lng: 17.403438,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "18:13:51",
    obec: "Budmerice",
    speed: "28 km/h",
    distance: "89,14",
    lat: 48.362037,
    lng: 17.404103,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:14:00",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "89,21",
    lat: 48.361532,
    lng: 17.404768,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:14:05",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "89,24",
    lat: 48.361232,
    lng: 17.405155,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:14:17",
    obec: "Budmerice",
    speed: "32 km/h",
    distance: "89,35",
    lat: 48.360556,
    lng: 17.406099,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:14:29",
    obec: "Budmerice",
    speed: "32 km/h",
    distance: "89,46",
    lat: 48.359816,
    lng: 17.407043,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:14:38",
    obec: "Budmerice",
    speed: "32 km/h",
    distance: "89,53",
    lat: 48.359237,
    lng: 17.407708,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:14:49",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "89,64",
    lat: 48.358475,
    lng: 17.408481,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:14:56",
    obec: "Budmerice",
    speed: "33 km/h",
    distance: "89,71",
    lat: 48.357928,
    lng: 17.408824,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:15:05",
    obec: "Budmerice",
    speed: "28 km/h",
    distance: "89,78",
    lat: 48.357241,
    lng: 17.409124,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:15:13",
    obec: "Budmerice",
    speed: "14 km/h",
    distance: "89,85",
    lat: 48.356833,
    lng: 17.409317,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:15:18",
    obec: "Budmerice",
    speed: "7 km/h",
    distance: "89,85",
    lat: 48.356694,
    lng: 17.409317,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:15:31",
    obec: "Budmerice",
    speed: "11 km/h",
    distance: "89,88",
    lat: 48.356629,
    lng: 17.409081,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:15:43",
    obec: "Budmerice",
    speed: "19 km/h",
    distance: "89,92",
    lat: 48.356801,
    lng: 17.40833,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:15:53",
    obec: "Budmerice",
    speed: "26 km/h",
    distance: "89,99",
    lat: 48.356855,
    lng: 17.407451,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:16:03",
    obec: "Budmerice",
    speed: "27 km/h",
    distance: "90,06",
    lat: 48.356683,
    lng: 17.406464,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:16:14",
    obec: "Budmerice",
    speed: "29 km/h",
    distance: "90,17",
    lat: 48.356436,
    lng: 17.405369,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:16:21",
    obec: "Budmerice",
    speed: "29 km/h",
    distance: "90,20",
    lat: 48.356393,
    lng: 17.404597,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:16:29",
    obec: "Budmerice",
    speed: "30 km/h",
    distance: "90,28",
    lat: 48.35634,
    lng: 17.403738,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:16:41",
    obec: "Budmerice",
    speed: "31 km/h",
    distance: "90,38",
    lat: 48.355771,
    lng: 17.40258,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:16:49",
    obec: "Budmerice",
    speed: "37 km/h",
    distance: "90,45",
    lat: 48.355406,
    lng: 17.401636,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:16:59",
    obec: "Budmerice",
    speed: "40 km/h",
    distance: "90,56",
    lat: 48.354956,
    lng: 17.40037,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:17:08",
    obec: "Budmerice",
    speed: "41 km/h",
    distance: "90,67",
    lat: 48.354462,
    lng: 17.399232,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:17:17",
    obec: "Budmerice",
    speed: "41 km/h",
    distance: "90,74",
    lat: 48.353872,
    lng: 17.398138,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:17:26",
    obec: "Budmerice",
    speed: "42 km/h",
    distance: "90,84",
    lat: 48.353325,
    lng: 17.396979,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:17:35",
    obec: "Budmerice",
    speed: "46 km/h",
    distance: "90,95",
    lat: 48.35281,
    lng: 17.395692,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:17:43",
    obec: "Budmerice",
    speed: "45 km/h",
    distance: "91,06",
    lat: 48.352295,
    lng: 17.394533,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:17:51",
    obec: "Budmerice",
    speed: "43 km/h",
    distance: "91,16",
    lat: 48.351823,
    lng: 17.393374,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:18:00",
    obec: "Budmerice",
    speed: "44 km/h",
    distance: "91,27",
    lat: 48.351254,
    lng: 17.392237,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:18:09",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "91,38",
    lat: 48.350578,
    lng: 17.391121,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:18:15",
    obec: "Vištuk",
    speed: "43 km/h",
    distance: "91,45",
    lat: 48.350138,
    lng: 17.390435,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:18:24",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "91,56",
    lat: 48.349495,
    lng: 17.389297,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:18:31",
    obec: "Vištuk",
    speed: "41 km/h",
    distance: "91,66",
    lat: 48.349076,
    lng: 17.388461,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:18:40",
    obec: "Vištuk",
    speed: "44 km/h",
    distance: "91,77",
    lat: 48.348701,
    lng: 17.387152,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:18:49",
    obec: "Vištuk",
    speed: "46 km/h",
    distance: "91,88",
    lat: 48.348272,
    lng: 17.385778,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:18:57",
    obec: "Vištuk",
    speed: "47 km/h",
    distance: "91,98",
    lat: 48.347864,
    lng: 17.384512,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:19:03",
    obec: "Vištuk",
    speed: "42 km/h",
    distance: "92,05",
    lat: 48.347574,
    lng: 17.38359,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:19:10",
    obec: "Vištuk",
    speed: "31 km/h",
    distance: "92,12",
    lat: 48.347285,
    lng: 17.382667,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:19:18",
    obec: "Vištuk",
    speed: "13 km/h",
    distance: "92,16",
    lat: 48.347102,
    lng: 17.382131,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:19:23",
    obec: "Vištuk",
    speed: "8 km/h",
    distance: "92,20",
    lat: 48.347038,
    lng: 17.381895,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:19:28",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "92,20",
    lat: 48.346995,
    lng: 17.381766,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:19:45",
    obec: "Vištuk",
    speed: "26 km/h",
    distance: "92,30",
    lat: 48.346587,
    lng: 17.380543,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:19:55",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "92,37",
    lat: 48.346137,
    lng: 17.379491,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:20:01",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "92,44",
    lat: 48.345783,
    lng: 17.378869,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:20:07",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "92,52",
    lat: 48.345504,
    lng: 17.378139,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:20:15",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "92,59",
    lat: 48.345321,
    lng: 17.377131,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:20:24",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: "92,69",
    lat: 48.345203,
    lng: 17.375994,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:20:35",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: "92,76",
    lat: 48.34515,
    lng: 17.374642,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:20:46",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: "92,87",
    lat: 48.345085,
    lng: 17.37329,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:20:58",
    obec: "Vištuk",
    speed: "31 km/h",
    distance: "92,98",
    lat: 48.345021,
    lng: 17.371874,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:21:11",
    obec: "Vištuk",
    speed: "26 km/h",
    distance: "93,08",
    lat: 48.344946,
    lng: 17.370458,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:21:17",
    obec: "Vištuk",
    speed: "19 km/h",
    distance: "93,12",
    lat: 48.344935,
    lng: 17.369943,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:21:22",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "93,16",
    lat: 48.344935,
    lng: 17.369664,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:21:37",
    obec: "Vištuk",
    speed: "0 km/h",
    distance: "93,16",
    lat: 48.344924,
    lng: 17.369449,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:21:42",
    obec: "Vištuk",
    speed: "10 km/h",
    distance: "93,16",
    lat: 48.344914,
    lng: 17.369342,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:21:52",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "93,19",
    lat: 48.344871,
    lng: 17.368913,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:22:16",
    obec: "Vištuk",
    speed: "24 km/h",
    distance: "93,33",
    lat: 48.344774,
    lng: 17.367153,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:22:30",
    obec: "Vištuk",
    speed: "28 km/h",
    distance: "93,44",
    lat: 48.34471,
    lng: 17.365758,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:22:48",
    obec: "Vištuk",
    speed: "31 km/h",
    distance: "93,58",
    lat: 48.344602,
    lng: 17.363827,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:23:00",
    obec: "Dubová",
    speed: "31 km/h",
    distance: "93,69",
    lat: 48.344559,
    lng: 17.362411,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:23:12",
    obec: "Dubová",
    speed: "30 km/h",
    distance: "93,80",
    lat: 48.344474,
    lng: 17.360973,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:23:23",
    obec: "Dubová",
    speed: "36 km/h",
    distance: "93,90",
    lat: 48.344399,
    lng: 17.359557,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:23:33",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "94,01",
    lat: 48.344323,
    lng: 17.358184,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:23:43",
    obec: "Vištuk",
    speed: "37 km/h",
    distance: "94,12",
    lat: 48.344313,
    lng: 17.356789,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:23:53",
    obec: "Vištuk",
    speed: "34 km/h",
    distance: "94,22",
    lat: 48.344205,
    lng: 17.355437,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:24:04",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "94,33",
    lat: 48.344109,
    lng: 17.354,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:24:14",
    obec: "Vištuk",
    speed: "37 km/h",
    distance: "94,40",
    lat: 48.344034,
    lng: 17.352626,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:24:23",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "94,51",
    lat: 48.344002,
    lng: 17.351403,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:24:33",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "94,61",
    lat: 48.343916,
    lng: 17.350008,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:24:44",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "94,72",
    lat: 48.343862,
    lng: 17.348549,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:24:55",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "94,83",
    lat: 48.343776,
    lng: 17.346983,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:25:05",
    obec: "Vištuk",
    speed: "37 km/h",
    distance: "94,93",
    lat: 48.34368,
    lng: 17.34561,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:25:16",
    obec: "Vištuk",
    speed: "28 km/h",
    distance: "95,04",
    lat: 48.34368,
    lng: 17.344322,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:25:24",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "95,11",
    lat: 48.343937,
    lng: 17.343485,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:25:32",
    obec: "Modra",
    speed: "38 km/h",
    distance: "95,18",
    lat: 48.344409,
    lng: 17.34267,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:25:38",
    obec: "Modra",
    speed: "43 km/h",
    distance: "95,25",
    lat: 48.344828,
    lng: 17.34194,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:25:45",
    obec: "Modra",
    speed: "41 km/h",
    distance: "95,36",
    lat: 48.345117,
    lng: 17.34091,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:25:53",
    obec: "Modra",
    speed: "41 km/h",
    distance: "95,43",
    lat: 48.3453,
    lng: 17.339666,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:25:59",
    obec: "Modra",
    speed: "39 km/h",
    distance: "95,50",
    lat: 48.345386,
    lng: 17.338722,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:26:08",
    obec: "Modra",
    speed: "27 km/h",
    distance: "95,57",
    lat: 48.345493,
    lng: 17.337627,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:26:13",
    obec: "Modra",
    speed: "19 km/h",
    distance: "95,61",
    lat: 48.345536,
    lng: 17.337198,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:26:18",
    obec: "Modra",
    speed: "11 km/h",
    distance: "95,64",
    lat: 48.345547,
    lng: 17.336984,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:26:23",
    obec: "Modra",
    speed: "7 km/h",
    distance: "95,64",
    lat: 48.345557,
    lng: 17.336833,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:26:28",
    obec: "Modra",
    speed: "3 km/h",
    distance: "95,68",
    lat: 48.345568,
    lng: 17.336662,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:27:39",
    obec: "Modra",
    speed: "10 km/h",
    distance: "95,68",
    lat: 48.345579,
    lng: 17.336361,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:27:53",
    obec: "Modra",
    speed: "14 km/h",
    distance: "95,75",
    lat: 48.345643,
    lng: 17.33546,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:28:08",
    obec: "Modra",
    speed: "22 km/h",
    distance: "95,82",
    lat: 48.345707,
    lng: 17.334538,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:28:21",
    obec: "Modra",
    speed: "27 km/h",
    distance: "95,89",
    lat: 48.345783,
    lng: 17.333357,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:28:34",
    obec: "Modra",
    speed: "30 km/h",
    distance: "96,00",
    lat: 48.345901,
    lng: 17.33192,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:28:46",
    obec: "Modra",
    speed: "29 km/h",
    distance: "96,11",
    lat: 48.345997,
    lng: 17.330546,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:28:56",
    obec: "Modra",
    speed: "31 km/h",
    distance: "96,21",
    lat: 48.346126,
    lng: 17.329473,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:29:07",
    obec: "Modra",
    speed: "33 km/h",
    distance: "96,28",
    lat: 48.34633,
    lng: 17.328165,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:29:18",
    obec: "Modra",
    speed: "36 km/h",
    distance: "96,39",
    lat: 48.346555,
    lng: 17.32677,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:29:29",
    obec: "Modra",
    speed: "34 km/h",
    distance: "96,50",
    lat: 48.34678,
    lng: 17.325375,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:29:40",
    obec: "Modra",
    speed: "34 km/h",
    distance: "96,60",
    lat: 48.346995,
    lng: 17.324002,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:29:51",
    obec: "Modra",
    speed: "31 km/h",
    distance: "96,71",
    lat: 48.34722,
    lng: 17.322693,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:30:03",
    obec: "Modra",
    speed: "28 km/h",
    distance: "96,82",
    lat: 48.347424,
    lng: 17.321341,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:30:16",
    obec: "Modra",
    speed: "27 km/h",
    distance: "96,92",
    lat: 48.347703,
    lng: 17.320011,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:30:27",
    obec: "Modra",
    speed: "14 km/h",
    distance: "97,00",
    lat: 48.347875,
    lng: 17.319152,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:31:09",
    obec: "Modra",
    speed: "6 km/h",
    distance: "97,03",
    lat: 48.348014,
    lng: 17.31868,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:31:14",
    obec: "Modra",
    speed: "13 km/h",
    distance: "97,03",
    lat: 48.347939,
    lng: 17.318509,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:31:23",
    obec: "Modra",
    speed: "0 km/h",
    distance: "97,07",
    lat: 48.347842,
    lng: 17.318401,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:31:35",
    obec: "Modra",
    speed: "9 km/h",
    distance: "97,07",
    lat: 48.347778,
    lng: 17.318552,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:31:48",
    obec: "Modra",
    speed: "18 km/h",
    distance: "97,10",
    lat: 48.347832,
    lng: 17.319131,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:32:02",
    obec: "Modra",
    speed: "30 km/h",
    distance: "97,21",
    lat: 48.347574,
    lng: 17.320461,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:32:14",
    obec: "Modra",
    speed: "34 km/h",
    distance: "97,32",
    lat: 48.347317,
    lng: 17.321813,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:32:25",
    obec: "Modra",
    speed: "34 km/h",
    distance: "97,42",
    lat: 48.34707,
    lng: 17.323251,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:32:35",
    obec: "Modra",
    speed: "36 km/h",
    distance: "97,53",
    lat: 48.346834,
    lng: 17.324495,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:32:43",
    obec: "Modra",
    speed: "40 km/h",
    distance: "97,64",
    lat: 48.346662,
    lng: 17.325633,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:32:53",
    obec: "Modra",
    speed: "39 km/h",
    distance: "97,74",
    lat: 48.346426,
    lng: 17.32707,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:33:01",
    obec: "Modra",
    speed: "39 km/h",
    distance: "97,81",
    lat: 48.346244,
    lng: 17.328229,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:33:11",
    obec: "Modra",
    speed: "38 km/h",
    distance: "97,92",
    lat: 48.346008,
    lng: 17.329645,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:33:17",
    obec: "Modra",
    speed: "35 km/h",
    distance: "97,99",
    lat: 48.345933,
    lng: 17.330461,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:33:28",
    obec: "Modra",
    speed: "32 km/h",
    distance: "98,10",
    lat: 48.345815,
    lng: 17.331834,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:33:39",
    obec: "Modra",
    speed: "34 km/h",
    distance: "98,20",
    lat: 48.34574,
    lng: 17.333229,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:33:49",
    obec: "Modra",
    speed: "33 km/h",
    distance: "98,28",
    lat: 48.345643,
    lng: 17.334473,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:34:00",
    obec: "Modra",
    speed: "35 km/h",
    distance: "98,38",
    lat: 48.345547,
    lng: 17.335889,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:34:10",
    obec: "Modra",
    speed: "40 km/h",
    distance: "98,49",
    lat: 48.34545,
    lng: 17.337263,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:34:15",
    obec: "Modra",
    speed: "39 km/h",
    distance: "98,56",
    lat: 48.345386,
    lng: 17.338014,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:34:24",
    obec: "Modra",
    speed: "42 km/h",
    distance: "98,67",
    lat: 48.345257,
    lng: 17.339344,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:34:33",
    obec: "Modra",
    speed: "42 km/h",
    distance: "98,77",
    lat: 48.345096,
    lng: 17.340782,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:34:40",
    obec: "Modra",
    speed: "40 km/h",
    distance: "98,84",
    lat: 48.344849,
    lng: 17.341769,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:34:46",
    obec: "Modra",
    speed: "38 km/h",
    distance: "98,92",
    lat: 48.344484,
    lng: 17.342477,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:34:52",
    obec: "Modra",
    speed: "37 km/h",
    distance: "98,99",
    lat: 48.34412,
    lng: 17.343099,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:34:59",
    obec: "Vištuk",
    speed: "31 km/h",
    distance: "99,02",
    lat: 48.343744,
    lng: 17.34385,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:35:04",
    obec: "Vištuk",
    speed: "18 km/h",
    distance: "99,06",
    lat: 48.343626,
    lng: 17.344215,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:35:09",
    obec: "Vištuk",
    speed: "8 km/h",
    distance: "99,09",
    lat: 48.343583,
    lng: 17.344472,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:35:21",
    obec: "Vištuk",
    speed: "10 km/h",
    distance: "99,13",
    lat: 48.343615,
    lng: 17.34488,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:35:27",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "99,13",
    lat: 48.343626,
    lng: 17.345159,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:35:45",
    obec: "Vištuk",
    speed: "28 km/h",
    distance: "99,24",
    lat: 48.343712,
    lng: 17.346532,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:35:57",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: "99,34",
    lat: 48.343776,
    lng: 17.347991,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:36:08",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "99,45",
    lat: 48.343851,
    lng: 17.349365,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:36:18",
    obec: "Vištuk",
    speed: "37 km/h",
    distance: "99,56",
    lat: 48.343905,
    lng: 17.350738,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:36:29",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: "99,66",
    lat: 48.343991,
    lng: 17.352197,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:36:40",
    obec: "Vištuk",
    speed: "34 km/h",
    distance: "99,73",
    lat: 48.344055,
    lng: 17.353613,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:36:52",
    obec: "Vištuk",
    speed: "30 km/h",
    distance: "99,84",
    lat: 48.344141,
    lng: 17.355008,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:37:04",
    obec: "Vištuk",
    speed: "31 km/h",
    distance: "99,95",
    lat: 48.344205,
    lng: 17.356403,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:37:16",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: "100,05",
    lat: 48.344281,
    lng: 17.357862,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:37:26",
    obec: "Dubová",
    speed: "38 km/h",
    distance: "100,16",
    lat: 48.344356,
    lng: 17.359235,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:37:36",
    obec: "Dubová",
    speed: "43 km/h",
    distance: "100,27",
    lat: 48.344377,
    lng: 17.360716,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:37:45",
    obec: "Dubová",
    speed: "44 km/h",
    distance: "100,37",
    lat: 48.344495,
    lng: 17.362282,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:37:50",
    obec: "Dubová",
    speed: "41 km/h",
    distance: "100,44",
    lat: 48.344527,
    lng: 17.363076,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:37:59",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "100,55",
    lat: 48.344592,
    lng: 17.364342,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:38:09",
    obec: "Vištuk",
    speed: "37 km/h",
    distance: "100,66",
    lat: 48.344667,
    lng: 17.36578,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:38:19",
    obec: "Vištuk",
    speed: "36 km/h",
    distance: "100,76",
    lat: 48.34472,
    lng: 17.367196,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:38:30",
    obec: "Vištuk",
    speed: "31 km/h",
    distance: "100,84",
    lat: 48.344806,
    lng: 17.368569,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:38:42",
    obec: "Vištuk",
    speed: "23 km/h",
    distance: "100,94",
    lat: 48.344892,
    lng: 17.369943,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:38:47",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "100,98",
    lat: 48.344892,
    lng: 17.370243,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:38:52",
    obec: "Vištuk",
    speed: "0 km/h",
    distance: "100,98",
    lat: 48.344796,
    lng: 17.37035,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:39:04",
    obec: "Vištuk",
    speed: "7 km/h",
    distance: "101,01",
    lat: 48.344731,
    lng: 17.370436,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:39:21",
    obec: "Vištuk",
    speed: "27 km/h",
    distance: "101,08",
    lat: 48.344152,
    lng: 17.371488,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:39:33",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: "101,19",
    lat: 48.343562,
    lng: 17.372518,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:39:44",
    obec: "Vištuk",
    speed: "32 km/h",
    distance: "101,30",
    lat: 48.342982,
    lng: 17.373569,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:39:55",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "101,40",
    lat: 48.342382,
    lng: 17.37462,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:40:06",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: "101,51",
    lat: 48.34177,
    lng: 17.375715,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:40:11",
    obec: "Vištuk",
    speed: "35 km/h",
    distance: "101,55",
    lat: 48.34148,
    lng: 17.376208,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:40:22",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: "101,65",
    lat: 48.340858,
    lng: 17.377303,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:40:33",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: "101,76",
    lat: 48.340279,
    lng: 17.378333,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:40:44",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: "101,87",
    lat: 48.339699,
    lng: 17.379405,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:40:56",
    obec: "Vištuk",
    speed: "31 km/h",
    distance: "101,97",
    lat: 48.339077,
    lng: 17.380521,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:41:08",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: "102,08",
    lat: 48.338487,
    lng: 17.381594,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:41:21",
    obec: "Vištuk",
    speed: "29 km/h",
    distance: "102,19",
    lat: 48.337875,
    lng: 17.382689,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:41:34",
    obec: "Vištuk",
    speed: "30 km/h",
    distance: "102,29",
    lat: 48.337253,
    lng: 17.383761,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:41:45",
    obec: "Vištuk",
    speed: "30 km/h",
    distance: "102,40",
    lat: 48.336685,
    lng: 17.384706,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:41:54",
    obec: "Vištuk",
    speed: "30 km/h",
    distance: "102,47",
    lat: 48.336245,
    lng: 17.385521,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:42:06",
    obec: "Vištuk",
    speed: "29 km/h",
    distance: "102,58",
    lat: 48.335655,
    lng: 17.386594,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:42:14",
    obec: "Vištuk",
    speed: "31 km/h",
    distance: "102,65",
    lat: 48.33529,
    lng: 17.387323,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:42:24",
    obec: "Vištuk",
    speed: "30 km/h",
    distance: "102,72",
    lat: 48.335,
    lng: 17.388396,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:42:32",
    obec: "Vištuk",
    speed: "26 km/h",
    distance: "102,79",
    lat: 48.334667,
    lng: 17.389104,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:42:42",
    obec: "Vištuk",
    speed: "25 km/h",
    distance: "102,86",
    lat: 48.334528,
    lng: 17.390134,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:42:47",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "102,90",
    lat: 48.334549,
    lng: 17.390521,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:43:01",
    obec: "Vištuk",
    speed: "6 km/h",
    distance: "102,90",
    lat: 48.334517,
    lng: 17.3908,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:43:13",
    obec: "Vištuk",
    speed: "9 km/h",
    distance: "102,93",
    lat: 48.334249,
    lng: 17.390735,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:43:25",
    obec: "Vištuk",
    speed: "10 km/h",
    distance: "102,97",
    lat: 48.333927,
    lng: 17.390606,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:43:35",
    obec: "Vištuk",
    speed: "12 km/h",
    distance: "103,00",
    lat: 48.333734,
    lng: 17.390199,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:43:46",
    obec: "Vištuk",
    speed: "16 km/h",
    distance: "103,08",
    lat: 48.33352,
    lng: 17.389791,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:43:57",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "103,11",
    lat: 48.333144,
    lng: 17.389405,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:44:08",
    obec: "Vištuk",
    speed: "12 km/h",
    distance: "103,15",
    lat: 48.33294,
    lng: 17.388933,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:44:18",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "103,18",
    lat: 48.33279,
    lng: 17.388461,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:44:38",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "103,29",
    lat: 48.332436,
    lng: 17.387388,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:44:50",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "103,32",
    lat: 48.332253,
    lng: 17.386787,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:45:04",
    obec: "Vištuk",
    speed: "15 km/h",
    distance: "103,40",
    lat: 48.331996,
    lng: 17.386079,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:45:15",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "103,43",
    lat: 48.331803,
    lng: 17.385607,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:45:26",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "103,47",
    lat: 48.33161,
    lng: 17.385027,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:45:35",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "103,50",
    lat: 48.331545,
    lng: 17.384748,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:45:44",
    obec: "Vištuk",
    speed: "18 km/h",
    distance: "103,54",
    lat: 48.331363,
    lng: 17.384341,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:45:55",
    obec: "Vištuk",
    speed: "19 km/h",
    distance: "103,61",
    lat: 48.330891,
    lng: 17.383826,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:46:10",
    obec: "Vištuk",
    speed: "14 km/h",
    distance: "103,68",
    lat: 48.330204,
    lng: 17.383611,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:46:25",
    obec: "Vištuk",
    speed: "16 km/h",
    distance: "103,75",
    lat: 48.32955,
    lng: 17.383482,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:46:30",
    obec: "Vištuk",
    speed: "12 km/h",
    distance: "103,79",
    lat: 48.329389,
    lng: 17.38344,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:46:40",
    obec: "Vištuk",
    speed: "6 km/h",
    distance: "103,82",
    lat: 48.329067,
    lng: 17.383375,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:46:56",
    obec: "Vištuk",
    speed: "5 km/h",
    distance: "103,82",
    lat: 48.329013,
    lng: 17.383354,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:47:01",
    obec: "Vištuk",
    speed: "12 km/h",
    distance: "103,82",
    lat: 48.328885,
    lng: 17.383332,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:47:15",
    obec: "Vištuk",
    speed: "22 km/h",
    distance: "103,89",
    lat: 48.328187,
    lng: 17.383246,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:47:36",
    obec: "Vištuk",
    speed: "21 km/h",
    distance: "104,04",
    lat: 48.32705,
    lng: 17.383075,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:47:46",
    obec: "Vištuk",
    speed: "23 km/h",
    distance: "104,11",
    lat: 48.32661,
    lng: 17.382603,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:48:02",
    obec: "Vištuk",
    speed: "24 km/h",
    distance: "104,21",
    lat: 48.325902,
    lng: 17.381659,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:48:18",
    obec: "Vištuk",
    speed: "22 km/h",
    distance: "104,28",
    lat: 48.325237,
    lng: 17.380736,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:48:32",
    obec: "Vištuk",
    speed: "21 km/h",
    distance: "104,39",
    lat: 48.324636,
    lng: 17.379985,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:48:47",
    obec: "Vištuk",
    speed: "23 km/h",
    distance: "104,50",
    lat: 48.323853,
    lng: 17.37947,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:49:03",
    obec: "Vištuk",
    speed: "24 km/h",
    distance: "104,57",
    lat: 48.323005,
    lng: 17.378912,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:49:18",
    obec: "Vištuk",
    speed: "24 km/h",
    distance: "104,68",
    lat: 48.322147,
    lng: 17.378333,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:49:30",
    obec: "Vištuk",
    speed: "19 km/h",
    distance: "104,75",
    lat: 48.321525,
    lng: 17.377968,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:49:41",
    obec: "Vištuk",
    speed: "21 km/h",
    distance: "104,82",
    lat: 48.320988,
    lng: 17.377603,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:49:51",
    obec: "Vištuk",
    speed: "20 km/h",
    distance: "104,89",
    lat: 48.320516,
    lng: 17.377195,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:50:03",
    obec: "Vištuk",
    speed: "23 km/h",
    distance: "104,96",
    lat: 48.31999,
    lng: 17.376702,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:50:19",
    obec: "Vištuk",
    speed: "23 km/h",
    distance: "105,07",
    lat: 48.319229,
    lng: 17.375865,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:50:33",
    obec: "Vištuk",
    speed: "28 km/h",
    distance: "105,17",
    lat: 48.31851,
    lng: 17.375071,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:50:45",
    obec: "Vištuk",
    speed: "33 km/h",
    distance: "105,28",
    lat: 48.317759,
    lng: 17.374234,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:50:54",
    obec: "Vištuk",
    speed: "28 km/h",
    distance: "105,35",
    lat: 48.31719,
    lng: 17.37359,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:51:02",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "105,42",
    lat: 48.31689,
    lng: 17.372861,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:51:08",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "105,46",
    lat: 48.316783,
    lng: 17.372217,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:51:20",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "105,56",
    lat: 48.31674,
    lng: 17.37078,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:51:31",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "105,67",
    lat: 48.316675,
    lng: 17.369342,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:51:38",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "105,74",
    lat: 48.316471,
    lng: 17.368505,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:51:45",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "105,81",
    lat: 48.316021,
    lng: 17.367775,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:51:55",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "105,92",
    lat: 48.31528,
    lng: 17.36711,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:52:05",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "106,03",
    lat: 48.314594,
    lng: 17.366166,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:52:15",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "106,13",
    lat: 48.313982,
    lng: 17.365029,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:52:25",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "106,24",
    lat: 48.313349,
    lng: 17.363892,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:52:31",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "106,31",
    lat: 48.31292,
    lng: 17.363269,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:52:38",
    obec: "Šenkvice",
    speed: "42 km/h",
    distance: "106,38",
    lat: 48.312341,
    lng: 17.362626,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:52:44",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "106,45",
    lat: 48.311912,
    lng: 17.36196,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:52:53",
    obec: "Šenkvice",
    speed: "43 km/h",
    distance: "106,56",
    lat: 48.311311,
    lng: 17.360823,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:52:59",
    obec: "Šenkvice",
    speed: "42 km/h",
    distance: "106,63",
    lat: 48.310914,
    lng: 17.360051,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:53:08",
    obec: "Šenkvice",
    speed: "44 km/h",
    distance: "106,74",
    lat: 48.310367,
    lng: 17.358892,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:53:15",
    obec: "Šenkvice",
    speed: "50 km/h",
    distance: "106,84",
    lat: 48.309895,
    lng: 17.357841,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:53:23",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "106,95",
    lat: 48.309262,
    lng: 17.356746,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:53:31",
    obec: "Šenkvice",
    speed: "41 km/h",
    distance: "107,02",
    lat: 48.308757,
    lng: 17.355781,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:53:40",
    obec: "Šenkvice",
    speed: "46 km/h",
    distance: "107,13",
    lat: 48.308328,
    lng: 17.354429,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:53:48",
    obec: "Šenkvice",
    speed: "48 km/h",
    distance: "107,24",
    lat: 48.307867,
    lng: 17.353184,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:53:56",
    obec: "Šenkvice",
    speed: "48 km/h",
    distance: "107,34",
    lat: 48.307373,
    lng: 17.351918,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:54:04",
    obec: "Šenkvice",
    speed: "46 km/h",
    distance: "107,45",
    lat: 48.306901,
    lng: 17.350674,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:54:12",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "107,56",
    lat: 48.306418,
    lng: 17.349451,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:54:19",
    obec: "Šenkvice",
    speed: "42 km/h",
    distance: "107,66",
    lat: 48.306097,
    lng: 17.348378,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:54:26",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "107,73",
    lat: 48.305818,
    lng: 17.347476,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:54:35",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "107,80",
    lat: 48.305399,
    lng: 17.346447,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:54:46",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "107,91",
    lat: 48.30482,
    lng: 17.345266,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:54:54",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "108,02",
    lat: 48.304294,
    lng: 17.344537,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:55:05",
    obec: "Šenkvice",
    speed: "23 km/h",
    distance: "108,12",
    lat: 48.30365,
    lng: 17.34355,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:55:10",
    obec: "Šenkvice",
    speed: "15 km/h",
    distance: "108,12",
    lat: 48.303511,
    lng: 17.343335,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:55:15",
    obec: "Šenkvice",
    speed: "5 km/h",
    distance: "108,16",
    lat: 48.303457,
    lng: 17.343163,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:55:35",
    obec: "Šenkvice",
    speed: "28 km/h",
    distance: "108,27",
    lat: 48.303897,
    lng: 17.341919,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:55:47",
    obec: "Šenkvice",
    speed: "13 km/h",
    distance: "108,34",
    lat: 48.304262,
    lng: 17.340975,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:55:56",
    obec: "Šenkvice",
    speed: "11 km/h",
    distance: "108,34",
    lat: 48.304316,
    lng: 17.340803,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:56:01",
    obec: "Šenkvice",
    speed: "16 km/h",
    distance: "108,37",
    lat: 48.304241,
    lng: 17.340631,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:56:11",
    obec: "Šenkvice",
    speed: "16 km/h",
    distance: "108,41",
    lat: 48.303844,
    lng: 17.340438,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:56:16",
    obec: "Šenkvice",
    speed: "23 km/h",
    distance: "108,44",
    lat: 48.30364,
    lng: 17.340846,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:56:24",
    obec: "Šenkvice",
    speed: "14 km/h",
    distance: "108,48",
    lat: 48.303489,
    lng: 17.341425,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:56:37",
    obec: "Šenkvice",
    speed: "20 km/h",
    distance: "108,59",
    lat: 48.303189,
    lng: 17.34237,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:56:47",
    obec: "Šenkvice",
    speed: "25 km/h",
    distance: "108,62",
    lat: 48.302738,
    lng: 17.342262,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:56:55",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "108,69",
    lat: 48.302341,
    lng: 17.341533,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:57:07",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "108,80",
    lat: 48.301891,
    lng: 17.340288,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:57:13",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "108,84",
    lat: 48.301526,
    lng: 17.339838,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:57:22",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "108,91",
    lat: 48.300839,
    lng: 17.339623,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:57:32",
    obec: "Šenkvice",
    speed: "19 km/h",
    distance: "108,98",
    lat: 48.300196,
    lng: 17.339473,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:57:37",
    obec: "Šenkvice",
    speed: "9 km/h",
    distance: "108,98",
    lat: 48.300056,
    lng: 17.339559,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:57:49",
    obec: "Šenkvice",
    speed: "6 km/h",
    distance: "109,01",
    lat: 48.299874,
    lng: 17.339666,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:57:55",
    obec: "Šenkvice",
    speed: "10 km/h",
    distance: "109,01",
    lat: 48.299852,
    lng: 17.339516,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:58:04",
    obec: "Šenkvice",
    speed: "14 km/h",
    distance: "109,05",
    lat: 48.299799,
    lng: 17.339022,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "18:58:14",
    obec: "Šenkvice",
    speed: "14 km/h",
    distance: "109,12",
    lat: 48.299713,
    lng: 17.338529,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:58:28",
    obec: "Šenkvice",
    speed: "22 km/h",
    distance: "109,19",
    lat: 48.299466,
    lng: 17.337606,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:58:39",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "109,26",
    lat: 48.299187,
    lng: 17.336555,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:58:50",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "109,37",
    lat: 48.298876,
    lng: 17.335203,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:58:59",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "109,48",
    lat: 48.298576,
    lng: 17.334044,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:59:07",
    obec: "Šenkvice",
    speed: "13 km/h",
    distance: "109,51",
    lat: 48.298436,
    lng: 17.333465,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:59:16",
    obec: "Šenkvice",
    speed: "6 km/h",
    distance: "109,51",
    lat: 48.298393,
    lng: 17.333293,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:59:30",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "109,58",
    lat: 48.298147,
    lng: 17.33237,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:59:35",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "109,62",
    lat: 48.298018,
    lng: 17.331855,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:59:44",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "109,72",
    lat: 48.297771,
    lng: 17.33089,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "18:59:54",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "109,80",
    lat: 48.297492,
    lng: 17.329774,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:00:03",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "109,87",
    lat: 48.297353,
    lng: 17.328744,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:00:14",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "109,97",
    lat: 48.297342,
    lng: 17.327328,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:00:24",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "110,08",
    lat: 48.297438,
    lng: 17.325997,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:00:35",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "110,19",
    lat: 48.297471,
    lng: 17.324495,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:00:45",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "110,29",
    lat: 48.297481,
    lng: 17.323143,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:00:56",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "110,40",
    lat: 48.297514,
    lng: 17.321706,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:01:06",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "110,51",
    lat: 48.297524,
    lng: 17.320225,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:01:16",
    obec: "Šenkvice",
    speed: "42 km/h",
    distance: "110,61",
    lat: 48.297535,
    lng: 17.318766,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:01:25",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "110,72",
    lat: 48.297556,
    lng: 17.317393,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:01:34",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "110,83",
    lat: 48.297492,
    lng: 17.316041,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:01:43",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "110,93",
    lat: 48.297374,
    lng: 17.314711,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:01:53",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "111,04",
    lat: 48.297256,
    lng: 17.313251,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:02:03",
    obec: "Pezinok",
    speed: "39 km/h",
    distance: "111,15",
    lat: 48.297138,
    lng: 17.311814,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:02:12",
    obec: "Pezinok",
    speed: "39 km/h",
    distance: "111,25",
    lat: 48.297009,
    lng: 17.310462,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:02:21",
    obec: "Pezinok",
    speed: "39 km/h",
    distance: "111,36",
    lat: 48.296902,
    lng: 17.309132,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:02:30",
    obec: "Pezinok",
    speed: "42 km/h",
    distance: "111,47",
    lat: 48.296666,
    lng: 17.30778,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:02:38",
    obec: "Pezinok",
    speed: "42 km/h",
    distance: "111,54",
    lat: 48.296451,
    lng: 17.306557,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:02:47",
    obec: "Pezinok",
    speed: "44 km/h",
    distance: "111,64",
    lat: 48.296205,
    lng: 17.305162,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:02:56",
    obec: "Pezinok",
    speed: "43 km/h",
    distance: "111,75",
    lat: 48.295936,
    lng: 17.303703,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:03:05",
    obec: "Pezinok",
    speed: "43 km/h",
    distance: "111,86",
    lat: 48.295668,
    lng: 17.302287,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:03:14",
    obec: "Pezinok",
    speed: "34 km/h",
    distance: "111,96",
    lat: 48.295421,
    lng: 17.300999,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:03:22",
    obec: "Pezinok",
    speed: "19 km/h",
    distance: "112,04",
    lat: 48.295293,
    lng: 17.300227,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:03:27",
    obec: "Pezinok",
    speed: "15 km/h",
    distance: "112,07",
    lat: 48.295314,
    lng: 17.299926,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:03:37",
    obec: "Pezinok",
    speed: "15 km/h",
    distance: "112,11",
    lat: 48.295121,
    lng: 17.299562,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:03:47",
    obec: "Pezinok",
    speed: "14 km/h",
    distance: "112,14",
    lat: 48.295067,
    lng: 17.299991,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:03:57",
    obec: "Pezinok",
    speed: "14 km/h",
    distance: "112,18",
    lat: 48.295325,
    lng: 17.299819,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:04:07",
    obec: "Pezinok",
    speed: "15 km/h",
    distance: "112,21",
    lat: 48.295078,
    lng: 17.299583,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:04:17",
    obec: "Pezinok",
    speed: "16 km/h",
    distance: "112,25",
    lat: 48.29511,
    lng: 17.300034,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:04:27",
    obec: "Pezinok",
    speed: "18 km/h",
    distance: "112,32",
    lat: 48.295336,
    lng: 17.299712,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:04:37",
    obec: "Pezinok",
    speed: "16 km/h",
    distance: "112,36",
    lat: 48.295003,
    lng: 17.29969,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:04:47",
    obec: "Pezinok",
    speed: "15 km/h",
    distance: "112,39",
    lat: 48.295207,
    lng: 17.300055,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:04:57",
    obec: "Pezinok",
    speed: "15 km/h",
    distance: "112,43",
    lat: 48.295314,
    lng: 17.299647,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:05:07",
    obec: "Pezinok",
    speed: "17 km/h",
    distance: "112,46",
    lat: 48.295003,
    lng: 17.299733,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:05:17",
    obec: "Pezinok",
    speed: "14 km/h",
    distance: "112,53",
    lat: 48.295196,
    lng: 17.300076,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:05:27",
    obec: "Pezinok",
    speed: "16 km/h",
    distance: "112,57",
    lat: 48.295293,
    lng: 17.299626,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:05:47",
    obec: "Pezinok",
    speed: "21 km/h",
    distance: "112,68",
    lat: 48.294906,
    lng: 17.298338,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:05:55",
    obec: "Pezinok",
    speed: "21 km/h",
    distance: "112,71",
    lat: 48.294799,
    lng: 17.297738,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:06:07",
    obec: "Pezinok",
    speed: "16 km/h",
    distance: "112,78",
    lat: 48.294638,
    lng: 17.296858,
    pluh: "dole",
    sypac: "sype",
  },
  {
    time: "19:06:12",
    obec: "Pezinok",
    speed: "12 km/h",
    distance: "112,82",
    lat: 48.294595,
    lng: 17.2966,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:06:17",
    obec: "Pezinok",
    speed: "8 km/h",
    distance: "112,82",
    lat: 48.294563,
    lng: 17.29645,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:06:26",
    obec: "Pezinok",
    speed: "7 km/h",
    distance: "112,82",
    lat: 48.294509,
    lng: 17.296214,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:06:45",
    obec: "Pezinok",
    speed: "23 km/h",
    distance: "112,92",
    lat: 48.294263,
    lng: 17.294862,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:07:01",
    obec: "Pezinok",
    speed: "22 km/h",
    distance: "113,03",
    lat: 48.293994,
    lng: 17.293532,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:07:19",
    obec: "Pezinok",
    speed: "18 km/h",
    distance: "113,14",
    lat: 48.293769,
    lng: 17.292202,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:07:24",
    obec: "Pezinok",
    speed: "10 km/h",
    distance: "113,17",
    lat: 48.293737,
    lng: 17.292008,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:07:41",
    obec: "Pezinok",
    speed: "14 km/h",
    distance: "113,24",
    lat: 48.293576,
    lng: 17.291129,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:07:57",
    obec: "Pezinok",
    speed: "27 km/h",
    distance: "113,32",
    lat: 48.293415,
    lng: 17.28997,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:08:10",
    obec: "Pezinok",
    speed: "33 km/h",
    distance: "113,42",
    lat: 48.293297,
    lng: 17.288511,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:08:22",
    obec: "Pezinok",
    speed: "33 km/h",
    distance: "113,53",
    lat: 48.293222,
    lng: 17.287052,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:08:33",
    obec: "Pezinok",
    speed: "34 km/h",
    distance: "113,64",
    lat: 48.293168,
    lng: 17.285635,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:08:43",
    obec: "Pezinok",
    speed: "31 km/h",
    distance: "113,74",
    lat: 48.293147,
    lng: 17.284348,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:08:53",
    obec: "Pezinok",
    speed: "32 km/h",
    distance: "113,81",
    lat: 48.293029,
    lng: 17.283189,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:09:05",
    obec: "Pezinok",
    speed: "27 km/h",
    distance: "113,92",
    lat: 48.292771,
    lng: 17.28188,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:09:13",
    obec: "Pezinok",
    speed: "13 km/h",
    distance: "113,96",
    lat: 48.292664,
    lng: 17.281301,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:09:18",
    obec: "Pezinok",
    speed: "8 km/h",
    distance: "113,99",
    lat: 48.292696,
    lng: 17.281108,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:09:23",
    obec: "Pezinok",
    speed: "19 km/h",
    distance: "113,99",
    lat: 48.292846,
    lng: 17.281022,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:09:37",
    obec: "Pezinok",
    speed: "41 km/h",
    distance: "114,10",
    lat: 48.293834,
    lng: 17.281001,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:09:46",
    obec: "Pezinok",
    speed: "44 km/h",
    distance: "114,20",
    lat: 48.294745,
    lng: 17.281086,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:09:54",
    obec: "Pezinok",
    speed: "48 km/h",
    distance: "114,31",
    lat: 48.295711,
    lng: 17.281151,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:10:02",
    obec: "Pezinok",
    speed: "50 km/h",
    distance: "114,42",
    lat: 48.296709,
    lng: 17.281237,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:10:10",
    obec: "Pezinok",
    speed: "49 km/h",
    distance: "114,52",
    lat: 48.297696,
    lng: 17.281301,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:10:16",
    obec: "Pezinok",
    speed: "45 km/h",
    distance: "114,63",
    lat: 48.298404,
    lng: 17.281301,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:10:22",
    obec: "Pezinok",
    speed: "48 km/h",
    distance: "114,70",
    lat: 48.299037,
    lng: 17.281666,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:10:27",
    obec: "Pezinok",
    speed: "46 km/h",
    distance: "114,77",
    lat: 48.299509,
    lng: 17.282267,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:10:33",
    obec: "Pezinok",
    speed: "44 km/h",
    distance: "114,84",
    lat: 48.300121,
    lng: 17.282696,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:10:38",
    obec: "Pezinok",
    speed: "43 km/h",
    distance: "114,92",
    lat: 48.300636,
    lng: 17.282503,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:10:44",
    obec: "Pezinok",
    speed: "43 km/h",
    distance: "114,99",
    lat: 48.301075,
    lng: 17.281837,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:10:52",
    obec: "Pezinok",
    speed: "48 km/h",
    distance: "115,06",
    lat: 48.301687,
    lng: 17.28085,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:10:58",
    obec: "Pezinok",
    speed: "51 km/h",
    distance: "115,16",
    lat: 48.302213,
    lng: 17.280035,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:11:03",
    obec: "Pezinok",
    speed: "53 km/h",
    distance: "115,24",
    lat: 48.302717,
    lng: 17.279434,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:11:10",
    obec: "Pezinok",
    speed: "54 km/h",
    distance: "115,34",
    lat: 48.303597,
    lng: 17.278876,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:11:15",
    obec: "Pezinok",
    speed: "56 km/h",
    distance: "115,41",
    lat: 48.304208,
    lng: 17.278404,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:11:22",
    obec: "Pezinok",
    speed: "58 km/h",
    distance: "115,52",
    lat: 48.305077,
    lng: 17.27761,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:11:29",
    obec: "Pezinok",
    speed: "56 km/h",
    distance: "115,63",
    lat: 48.305893,
    lng: 17.276773,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:11:35",
    obec: "Pezinok",
    speed: "57 km/h",
    distance: "115,73",
    lat: 48.306569,
    lng: 17.276001,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:11:42",
    obec: "Pezinok",
    speed: "56 km/h",
    distance: "115,84",
    lat: 48.307309,
    lng: 17.274992,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:11:47",
    obec: "Pezinok",
    speed: "58 km/h",
    distance: "115,91",
    lat: 48.307813,
    lng: 17.274241,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:11:52",
    obec: "Pezinok",
    speed: "51 km/h",
    distance: "115,98",
    lat: 48.308264,
    lng: 17.27349,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:12:00",
    obec: "Pezinok",
    speed: "50 km/h",
    distance: "116,12",
    lat: 48.308897,
    lng: 17.27231,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:12:08",
    obec: "Pezinok",
    speed: "52 km/h",
    distance: "116,23",
    lat: 48.30953,
    lng: 17.27113,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:12:15",
    obec: "Pezinok",
    speed: "51 km/h",
    distance: "116,34",
    lat: 48.310098,
    lng: 17.270036,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:12:22",
    obec: "Pezinok",
    speed: "53 km/h",
    distance: "116,41",
    lat: 48.310667,
    lng: 17.268963,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:12:29",
    obec: "Pezinok",
    speed: "52 km/h",
    distance: "116,52",
    lat: 48.311279,
    lng: 17.26789,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:12:34",
    obec: "Pezinok",
    speed: "53 km/h",
    distance: "116,59",
    lat: 48.311665,
    lng: 17.267139,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:12:41",
    obec: "Pezinok",
    speed: "48 km/h",
    distance: "116,69",
    lat: 48.312223,
    lng: 17.266066,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:12:49",
    obec: "Pezinok",
    speed: "47 km/h",
    distance: "116,80",
    lat: 48.312824,
    lng: 17.264972,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:12:57",
    obec: "Pezinok",
    speed: "44 km/h",
    distance: "116,91",
    lat: 48.313403,
    lng: 17.263877,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:13:05",
    obec: "Pezinok",
    speed: "45 km/h",
    distance: 117.01,
    lat: 48.313972,
    lng: 17.262826,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:13:14",
    obec: "Pezinok",
    speed: "43 km/h",
    distance: 117.12,
    lat: 48.314572,
    lng: 17.261667,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:13:22",
    obec: "Pezinok",
    speed: "45 km/h",
    distance: 117.23,
    lat: 48.31512,
    lng: 17.260594,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:13:30",
    obec: "Pezinok",
    speed: "46 km/h",
    distance: 117.33,
    lat: 48.315699,
    lng: 17.259543,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:13:38",
    obec: "Pezinok",
    speed: "46 km/h",
    distance: 117.44,
    lat: 48.316268,
    lng: 17.258363,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:13:46",
    obec: "Pezinok",
    speed: "44 km/h",
    distance: 117.55,
    lat: 48.316783,
    lng: 17.257183,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:13:52",
    obec: "Pezinok",
    speed: "42 km/h",
    distance: 117.62,
    lat: 48.317061,
    lng: 17.256346,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:14:01",
    obec: "Pezinok",
    speed: "46 km/h",
    distance: 117.72,
    lat: 48.317469,
    lng: 17.254951,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:14:08",
    obec: "Pezinok",
    speed: "45 km/h",
    distance: 117.8,
    lat: 48.317748,
    lng: 17.253771,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:14:16",
    obec: "Pezinok",
    speed: "42 km/h",
    distance: 117.87,
    lat: 48.317973,
    lng: 17.252419,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:14:24",
    obec: "Pezinok",
    speed: "36 km/h",
    distance: 117.97,
    lat: 48.318091,
    lng: 17.251239,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:14:32",
    obec: "Pezinok",
    speed: "25 km/h",
    distance: "118,04",
    lat: 48.318124,
    lng: 17.250273,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:14:37",
    obec: "Pezinok",
    speed: "19 km/h",
    distance: "118,08",
    lat: 48.318081,
    lng: 17.249866,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:14:42",
    obec: "Pezinok",
    speed: "15 km/h",
    distance: "118,08",
    lat: 48.317995,
    lng: 17.249565,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:14:47",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,12",
    lat: 48.317984,
    lng: 17.249458,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:15:01",
    obec: "Pezinok",
    speed: "8 km/h",
    distance: "118,12",
    lat: 48.317963,
    lng: 17.249222,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:15:12",
    obec: "Pezinok",
    speed: "7 km/h",
    distance: "118,15",
    lat: 48.31792,
    lng: 17.248836,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:15:23",
    obec: "Pezinok",
    speed: "5 km/h",
    distance: "118,19",
    lat: 48.317898,
    lng: 17.248578,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:15:28",
    obec: "Pezinok",
    speed: "9 km/h",
    distance: "118,19",
    lat: 48.317888,
    lng: 17.248471,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:15:33",
    obec: "Pezinok",
    speed: "13 km/h",
    distance: "118,19",
    lat: 48.317888,
    lng: 17.24817,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:15:38",
    obec: "Pezinok",
    speed: "9 km/h",
    distance: "118,22",
    lat: 48.317898,
    lng: 17.247999,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:15:48",
    obec: "Pezinok",
    speed: "10 km/h",
    distance: "118,26",
    lat: 48.31778,
    lng: 17.248063,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:19:22",
    obec: "Pezinok",
    speed: "1 km/h",
    distance: "118,26",
    lat: 48.317791,
    lng: 17.248342,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:21:07",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,26",
    lat: 48.31778,
    lng: 17.248299,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:22:06",
    obec: "Pezinok",
    speed: "1 km/h",
    distance: "118,26",
    lat: 48.31777,
    lng: 17.248235,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:30:16",
    obec: "Pezinok",
    speed: "4 km/h",
    distance: "118,29",
    lat: 48.317727,
    lng: 17.248127,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "19:30:35",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,29",
    lat: 48.317705,
    lng: 17.248342,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "19:30:54",
    obec: "Pezinok",
    speed: "1 km/h",
    distance: "118,33",
    lat: 48.317737,
    lng: 17.248514,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "19:34:35",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,33",
    lat: 48.317737,
    lng: 17.248514,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "19:44:35",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,33",
    lat: 48.317759,
    lng: 17.248535,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "19:49:35",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,33",
    lat: 48.317759,
    lng: 17.248557,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "19:50:52",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,33",
    lat: 48.317759,
    lng: 17.248557,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "19:51:32",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,33",
    lat: 48.317759,
    lng: 17.248557,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:51:42",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,33",
    lat: 48.317759,
    lng: 17.248557,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "19:51:58",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,33",
    lat: 48.317759,
    lng: 17.248557,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:52:08",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,33",
    lat: 48.317759,
    lng: 17.248557,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "19:53:29",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "118,33",
    lat: 48.317748,
    lng: 17.24875,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:55:19",
    obec: "Pezinok",
    speed: "5 km/h",
    distance: "118,36",
    lat: 48.317834,
    lng: 17.248857,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:55:26",
    obec: "Pezinok",
    speed: "9 km/h",
    distance: "118,36",
    lat: 48.317898,
    lng: 17.249029,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:55:34",
    obec: "Pezinok",
    speed: "3 km/h",
    distance: "118,36",
    lat: 48.317941,
    lng: 17.249222,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:55:50",
    obec: "Pezinok",
    speed: "4 km/h",
    distance: "118,40",
    lat: 48.317984,
    lng: 17.249436,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:56:55",
    obec: "Pezinok",
    speed: "11 km/h",
    distance: "118,40",
    lat: 48.318027,
    lng: 17.249608,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:57:07",
    obec: "Pezinok",
    speed: "24 km/h",
    distance: "118,47",
    lat: 48.31807,
    lng: 17.250509,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:57:16",
    obec: "Pezinok",
    speed: "38 km/h",
    distance: "118,58",
    lat: 48.317963,
    lng: 17.251647,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:57:25",
    obec: "Pezinok",
    speed: "42 km/h",
    distance: "118,68",
    lat: 48.317834,
    lng: 17.25302,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:57:33",
    obec: "Pezinok",
    speed: "50 km/h",
    distance: "118,79",
    lat: 48.317576,
    lng: 17.254393,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:57:39",
    obec: "Pezinok",
    speed: "52 km/h",
    distance: "118,86",
    lat: 48.317319,
    lng: 17.255466,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:57:46",
    obec: "Pezinok",
    speed: "53 km/h",
    distance: "118,97",
    lat: 48.316879,
    lng: 17.256732,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:57:53",
    obec: "Pezinok",
    speed: "54 km/h",
    distance: "119,08",
    lat: 48.316364,
    lng: 17.257955,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:58:00",
    obec: "Pezinok",
    speed: "53 km/h",
    distance: "119,18",
    lat: 48.315871,
    lng: 17.259157,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:58:07",
    obec: "Pezinok",
    speed: "51 km/h",
    distance: "119,29",
    lat: 48.315323,
    lng: 17.260251,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:58:15",
    obec: "Pezinok",
    speed: "49 km/h",
    distance: "119,40",
    lat: 48.31468,
    lng: 17.26141,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:58:23",
    obec: "Pezinok",
    speed: "52 km/h",
    distance: "119,50",
    lat: 48.314047,
    lng: 17.262633,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:58:30",
    obec: "Pezinok",
    speed: "52 km/h",
    distance: "119,61",
    lat: 48.313478,
    lng: 17.263684,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:58:36",
    obec: "Pezinok",
    speed: "52 km/h",
    distance: "119,68",
    lat: 48.312985,
    lng: 17.264585,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:58:43",
    obec: "Pezinok",
    speed: "51 km/h",
    distance: "119,79",
    lat: 48.312427,
    lng: 17.265637,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:58:50",
    obec: "Pezinok",
    speed: "55 km/h",
    distance: "119,89",
    lat: 48.311826,
    lng: 17.266731,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:58:57",
    obec: "Pezinok",
    speed: "57 km/h",
    distance: "120,00",
    lat: 48.311214,
    lng: 17.26789,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:59:04",
    obec: "Pezinok",
    speed: "59 km/h",
    distance: "120,11",
    lat: 48.310592,
    lng: 17.269092,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:59:11",
    obec: "Pezinok",
    speed: "59 km/h",
    distance: "120,25",
    lat: 48.309938,
    lng: 17.270315,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:59:18",
    obec: "Pezinok",
    speed: "58 km/h",
    distance: "120,36",
    lat: 48.309294,
    lng: 17.271516,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:59:24",
    obec: "Pezinok",
    speed: "60 km/h",
    distance: "120,46",
    lat: 48.308725,
    lng: 17.272568,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:59:30",
    obec: "Pezinok",
    speed: "61 km/h",
    distance: "120,57",
    lat: 48.308146,
    lng: 17.273641,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:59:42",
    obec: "Pezinok",
    speed: "63 km/h",
    distance: "120,75",
    lat: 48.306815,
    lng: 17.275572,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "19:59:54",
    obec: "Pezinok",
    speed: "62 km/h",
    distance: "120,96",
    lat: 48.305324,
    lng: 17.277267,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:00:06",
    obec: "Pezinok",
    speed: "62 km/h",
    distance: "121,17",
    lat: 48.303726,
    lng: 17.278662,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:00:11",
    obec: "Pezinok",
    speed: "56 km/h",
    distance: "121,24",
    lat: 48.30306,
    lng: 17.279134,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:00:17",
    obec: "Pezinok",
    speed: "49 km/h",
    distance: "121,35",
    lat: 48.302395,
    lng: 17.279713,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:00:22",
    obec: "Pezinok",
    speed: "46 km/h",
    distance: "121,42",
    lat: 48.301934,
    lng: 17.280314,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:00:29",
    obec: "Pezinok",
    speed: "38 km/h",
    distance: "121,49",
    lat: 48.301376,
    lng: 17.281001,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:00:35",
    obec: "Pezinok",
    speed: "46 km/h",
    distance: "121,56",
    lat: 48.300797,
    lng: 17.281129,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:00:42",
    obec: "Pezinok",
    speed: "55 km/h",
    distance: "121,67",
    lat: 48.299885,
    lng: 17.281129,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:00:49",
    obec: "Pezinok",
    speed: "58 km/h",
    distance: "121,78",
    lat: 48.298908,
    lng: 17.281129,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:00:56",
    obec: "Pezinok",
    speed: "58 km/h",
    distance: "121,88",
    lat: 48.297889,
    lng: 17.281151,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:01:03",
    obec: "Pezinok",
    speed: "56 km/h",
    distance: "121,99",
    lat: 48.296881,
    lng: 17.281108,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:01:10",
    obec: "Pezinok",
    speed: "58 km/h",
    distance: "122,10",
    lat: 48.295851,
    lng: 17.281086,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:01:17",
    obec: "Pezinok",
    speed: "56 km/h",
    distance: "122,20",
    lat: 48.294863,
    lng: 17.281001,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:01:24",
    obec: "Pezinok",
    speed: "53 km/h",
    distance: "122,31",
    lat: 48.293909,
    lng: 17.280893,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:01:32",
    obec: "Pezinok",
    speed: "28 km/h",
    distance: "122,42",
    lat: 48.293061,
    lng: 17.280915,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:01:40",
    obec: "Pezinok",
    speed: "15 km/h",
    distance: "122,45",
    lat: 48.292686,
    lng: 17.280893,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:01:50",
    obec: "Pezinok",
    speed: "14 km/h",
    distance: "122,49",
    lat: 48.292492,
    lng: 17.281086,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:01:58",
    obec: "Pezinok",
    speed: "18 km/h",
    distance: "122,52",
    lat: 48.292653,
    lng: 17.28158,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:02:03",
    obec: "Pezinok",
    speed: "12 km/h",
    distance: "122,56",
    lat: 48.292664,
    lng: 17.281816,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:02:22",
    obec: "Pezinok",
    speed: "11 km/h",
    distance: "122,56",
    lat: 48.292696,
    lng: 17.281988,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:02:39",
    obec: "Pezinok",
    speed: "31 km/h",
    distance: "122,67",
    lat: 48.293007,
    lng: 17.283361,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:02:49",
    obec: "Pezinok",
    speed: "36 km/h",
    distance: "122,77",
    lat: 48.293104,
    lng: 17.284648,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:02:59",
    obec: "Pezinok",
    speed: "36 km/h",
    distance: "122,88",
    lat: 48.293147,
    lng: 17.286043,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:03:07",
    obec: "Pezinok",
    speed: "36 km/h",
    distance: "122,95",
    lat: 48.293201,
    lng: 17.287138,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:03:17",
    obec: "Pezinok",
    speed: "39 km/h",
    distance: "123,06",
    lat: 48.293265,
    lng: 17.288554,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:03:25",
    obec: "Pezinok",
    speed: "40 km/h",
    distance: "123,16",
    lat: 48.293372,
    lng: 17.289734,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:03:35",
    obec: "Pezinok",
    speed: "39 km/h",
    distance: "123,27",
    lat: 48.293544,
    lng: 17.291193,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:03:45",
    obec: "Pezinok",
    speed: "35 km/h",
    distance: "123,38",
    lat: 48.293737,
    lng: 17.292523,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:03:56",
    obec: "Pezinok",
    speed: "35 km/h",
    distance: "123,48",
    lat: 48.294016,
    lng: 17.293918,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:04:07",
    obec: "Pezinok",
    speed: "33 km/h",
    distance: "123,59",
    lat: 48.294295,
    lng: 17.295313,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:04:20",
    obec: "Pezinok",
    speed: "27 km/h",
    distance: "123,66",
    lat: 48.294531,
    lng: 17.296622,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:04:31",
    obec: "Pezinok",
    speed: "37 km/h",
    distance: "123,80",
    lat: 48.294821,
    lng: 17.298038,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:04:40",
    obec: "Pezinok",
    speed: "27 km/h",
    distance: "123,88",
    lat: 48.294992,
    lng: 17.299025,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:04:45",
    obec: "Pezinok",
    speed: "18 km/h",
    distance: "123,91",
    lat: 48.295046,
    lng: 17.299433,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:04:59",
    obec: "Pezinok",
    speed: "26 km/h",
    distance: "123,98",
    lat: 48.29526,
    lng: 17.300398,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:05:11",
    obec: "Pezinok",
    speed: "36 km/h",
    distance: "124,09",
    lat: 48.295529,
    lng: 17.301793,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:05:21",
    obec: "Pezinok",
    speed: "39 km/h",
    distance: "124,20",
    lat: 48.295786,
    lng: 17.303188,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:05:30",
    obec: "Pezinok",
    speed: "42 km/h",
    distance: "124,30",
    lat: 48.296044,
    lng: 17.304497,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:05:39",
    obec: "Pezinok",
    speed: "44 km/h",
    distance: "124,41",
    lat: 48.296301,
    lng: 17.305934,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:05:48",
    obec: "Pezinok",
    speed: "45 km/h",
    distance: "124,52",
    lat: 48.29658,
    lng: 17.307372,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:05:54",
    obec: "Pezinok",
    speed: "44 km/h",
    distance: "124,59",
    lat: 48.29673,
    lng: 17.308402,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:06:02",
    obec: "Pezinok",
    speed: "45 km/h",
    distance: "124,69",
    lat: 48.296902,
    lng: 17.309711,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:06:11",
    obec: "Pezinok",
    speed: "42 km/h",
    distance: "124,80",
    lat: 48.297063,
    lng: 17.31117,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:06:19",
    obec: "Pezinok",
    speed: "46 km/h",
    distance: "124,91",
    lat: 48.297159,
    lng: 17.312522,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:06:27",
    obec: "Šenkvice",
    speed: "47 km/h",
    distance: "125,01",
    lat: 48.297267,
    lng: 17.313917,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:06:35",
    obec: "Šenkvice",
    speed: "47 km/h",
    distance: "125,12",
    lat: 48.297396,
    lng: 17.315354,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:06:43",
    obec: "Šenkvice",
    speed: "49 km/h",
    distance: "125,23",
    lat: 48.297514,
    lng: 17.316749,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:06:51",
    obec: "Šenkvice",
    speed: "49 km/h",
    distance: "125,33",
    lat: 48.297503,
    lng: 17.318273,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:06:59",
    obec: "Šenkvice",
    speed: "49 km/h",
    distance: "125,44",
    lat: 48.297481,
    lng: 17.319753,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:07:07",
    obec: "Šenkvice",
    speed: "49 km/h",
    distance: "125,55",
    lat: 48.297471,
    lng: 17.321255,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:07:15",
    obec: "Šenkvice",
    speed: "50 km/h",
    distance: "125,65",
    lat: 48.29746,
    lng: 17.322714,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:07:23",
    obec: "Šenkvice",
    speed: "49 km/h",
    distance: "125,80",
    lat: 48.297417,
    lng: 17.324238,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:07:31",
    obec: "Šenkvice",
    speed: "43 km/h",
    distance: "125,87",
    lat: 48.297396,
    lng: 17.325611,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:07:38",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "125,97",
    lat: 48.297299,
    lng: 17.326791,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:07:46",
    obec: "Šenkvice",
    speed: "46 km/h",
    distance: "126,08",
    lat: 48.29732,
    lng: 17.328186,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:07:54",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "126,15",
    lat: 48.297374,
    lng: 17.329388,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:08:01",
    obec: "Šenkvice",
    speed: "41 km/h",
    distance: "126,22",
    lat: 48.297621,
    lng: 17.330332,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:08:10",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "126,36",
    lat: 48.297964,
    lng: 17.331705,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:08:18",
    obec: "Šenkvice",
    speed: "44 km/h",
    distance: "126,44",
    lat: 48.298286,
    lng: 17.332971,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:08:25",
    obec: "Šenkvice",
    speed: "45 km/h",
    distance: "126,54",
    lat: 48.298576,
    lng: 17.334044,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:08:34",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "126,65",
    lat: 48.298898,
    lng: 17.335396,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:08:44",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "126,76",
    lat: 48.299219,
    lng: 17.336705,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:08:54",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "126,83",
    lat: 48.29952,
    lng: 17.337842,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:09:04",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "126,93",
    lat: 48.299745,
    lng: 17.338979,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:09:09",
    obec: "Šenkvice",
    speed: "19 km/h",
    distance: "126,93",
    lat: 48.299788,
    lng: 17.33943,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:09:14",
    obec: "Šenkvice",
    speed: "0 km/h",
    distance: "126,93",
    lat: 48.299788,
    lng: 17.339623,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:09:19",
    obec: "Šenkvice",
    speed: "10 km/h",
    distance: "126,93",
    lat: 48.299788,
    lng: 17.339666,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:09:34",
    obec: "Šenkvice",
    speed: "23 km/h",
    distance: "127,00",
    lat: 48.299638,
    lng: 17.340696,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:09:47",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "127,11",
    lat: 48.299391,
    lng: 17.342048,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:09:57",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "127,22",
    lat: 48.299144,
    lng: 17.343121,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:10:06",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "127,29",
    lat: 48.298855,
    lng: 17.344086,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:10:15",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "127,36",
    lat: 48.298458,
    lng: 17.344902,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:10:26",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "127,47",
    lat: 48.297986,
    lng: 17.345889,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:10:36",
    obec: "Šenkvice",
    speed: "19 km/h",
    distance: "127,54",
    lat: 48.297707,
    lng: 17.346768,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:10:54",
    obec: "Šenkvice",
    speed: "24 km/h",
    distance: "127,64",
    lat: 48.29732,
    lng: 17.348034,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:11:09",
    obec: "Šenkvice",
    speed: "24 km/h",
    distance: "127,72",
    lat: 48.296795,
    lng: 17.349215,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:11:22",
    obec: "Šenkvice",
    speed: "18 km/h",
    distance: "127,82",
    lat: 48.296366,
    lng: 17.350202,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:11:32",
    obec: "Šenkvice",
    speed: "26 km/h",
    distance: "127,89",
    lat: 48.296011,
    lng: 17.350781,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:11:41",
    obec: "Šenkvice",
    speed: "21 km/h",
    distance: "127,93",
    lat: 48.29555,
    lng: 17.351274,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:11:51",
    obec: "Šenkvice",
    speed: "24 km/h",
    distance: "128,00",
    lat: 48.295046,
    lng: 17.351575,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:12:09",
    obec: "Šenkvice",
    speed: "20 km/h",
    distance: "128,11",
    lat: 48.294145,
    lng: 17.352004,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:12:20",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "128,18",
    lat: 48.293555,
    lng: 17.352347,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:12:25",
    obec: "Šenkvice",
    speed: "16 km/h",
    distance: "128,21",
    lat: 48.293447,
    lng: 17.352583,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:12:36",
    obec: "Šenkvice",
    speed: "22 km/h",
    distance: "128,28",
    lat: 48.293115,
    lng: 17.353399,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:12:49",
    obec: "Šenkvice",
    speed: "18 km/h",
    distance: "128,36",
    lat: 48.292664,
    lng: 17.353978,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:12:59",
    obec: "Šenkvice",
    speed: "16 km/h",
    distance: "128,39",
    lat: 48.292739,
    lng: 17.354472,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:13:04",
    obec: "Šenkvice",
    speed: "20 km/h",
    distance: "128,43",
    lat: 48.292997,
    lng: 17.354622,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:13:20",
    obec: "Šenkvice",
    speed: "22 km/h",
    distance: "128,50",
    lat: 48.293941,
    lng: 17.354622,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:13:36",
    obec: "Šenkvice",
    speed: "15 km/h",
    distance: "128,60",
    lat: 48.294788,
    lng: 17.354643,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:13:46",
    obec: "Šenkvice",
    speed: "22 km/h",
    distance: "128,68",
    lat: 48.294949,
    lng: 17.355223,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:13:53",
    obec: "Šenkvice",
    speed: "21 km/h",
    distance: "128,71",
    lat: 48.294606,
    lng: 17.355502,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:14:06",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "128,82",
    lat: 48.294048,
    lng: 17.356424,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:14:13",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "128,85",
    lat: 48.29378,
    lng: 17.357068,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:14:22",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "128,92",
    lat: 48.293361,
    lng: 17.357883,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:14:27",
    obec: "Šenkvice",
    speed: "28 km/h",
    distance: "128,96",
    lat: 48.293211,
    lng: 17.358398,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:14:40",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "129,07",
    lat: 48.292804,
    lng: 17.359686,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:14:48",
    obec: "Šenkvice",
    speed: "30 km/h",
    distance: "129,14",
    lat: 48.292417,
    lng: 17.360437,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:14:59",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "129,24",
    lat: 48.291924,
    lng: 17.361596,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:15:08",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "129,35",
    lat: 48.291495,
    lng: 17.362583,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:15:13",
    obec: "Šenkvice",
    speed: "23 km/h",
    distance: "129,39",
    lat: 48.291312,
    lng: 17.363055,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:15:25",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "129,49",
    lat: 48.291194,
    lng: 17.364428,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:15:36",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "129,56",
    lat: 48.291055,
    lng: 17.36563,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:15:44",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "129,64",
    lat: 48.290765,
    lng: 17.366467,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:15:55",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "129,74",
    lat: 48.290261,
    lng: 17.367733,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:16:00",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "129,81",
    lat: 48.290014,
    lng: 17.368376,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:16:10",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "129,92",
    lat: 48.28951,
    lng: 17.369599,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:16:17",
    obec: "Šenkvice",
    speed: "24 km/h",
    distance: "129,99",
    lat: 48.28922,
    lng: 17.37035,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:16:22",
    obec: "Šenkvice",
    speed: "11 km/h",
    distance: "129,99",
    lat: 48.289156,
    lng: 17.370586,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:19:12",
    obec: "Šenkvice",
    speed: "0 km/h",
    distance: "130,03",
    lat: 48.289091,
    lng: 17.370737,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:19:45",
    obec: "Šenkvice",
    speed: "0 km/h",
    distance: "130,03",
    lat: 48.289091,
    lng: 17.370737,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:19:55",
    obec: "Šenkvice",
    speed: "0 km/h",
    distance: "130,03",
    lat: 48.289091,
    lng: 17.370737,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "20:21:58",
    obec: "Šenkvice",
    speed: "0 km/h",
    distance: "130,03",
    lat: 48.289091,
    lng: 17.370737,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:22:08",
    obec: "Šenkvice",
    speed: "0 km/h",
    distance: "130,03",
    lat: 48.289091,
    lng: 17.370737,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "20:22:25",
    obec: "Šenkvice",
    speed: "13 km/h",
    distance: "130,03",
    lat: 48.288984,
    lng: 17.370908,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:22:37",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "130,10",
    lat: 48.288694,
    lng: 17.371638,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:22:48",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "130,20",
    lat: 48.28833,
    lng: 17.372882,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:22:57",
    obec: "Šenkvice",
    speed: "41 km/h",
    distance: "130,31",
    lat: 48.287997,
    lng: 17.374127,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:23:06",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "130,42",
    lat: 48.287632,
    lng: 17.375414,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:23:15",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "130,52",
    lat: 48.2873,
    lng: 17.37668,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:23:24",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "130,60",
    lat: 48.286946,
    lng: 17.377925,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:23:34",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "130,70",
    lat: 48.286559,
    lng: 17.379255,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:23:44",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "130,81",
    lat: 48.286205,
    lng: 17.380607,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:23:54",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "130,92",
    lat: 48.285851,
    lng: 17.381873,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:24:03",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "131,02",
    lat: 48.285519,
    lng: 17.383096,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:24:13",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "131,13",
    lat: 48.285143,
    lng: 17.38447,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:24:23",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "131,24",
    lat: 48.284789,
    lng: 17.385736,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:24:32",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "131,34",
    lat: 48.284446,
    lng: 17.386808,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:24:39",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "131,41",
    lat: 48.284027,
    lng: 17.387474,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:24:49",
    obec: "Blatné",
    speed: "38 km/h",
    distance: "131,48",
    lat: 48.283384,
    lng: 17.388504,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:24:55",
    obec: "Blatné",
    speed: "39 km/h",
    distance: "131,56",
    lat: 48.282987,
    lng: 17.389147,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:25:02",
    obec: "Blatné",
    speed: "39 km/h",
    distance: "131,66",
    lat: 48.282493,
    lng: 17.38992,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:25:09",
    obec: "Blatné",
    speed: "41 km/h",
    distance: "131,73",
    lat: 48.281978,
    lng: 17.390692,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:25:18",
    obec: "Blatné",
    speed: "42 km/h",
    distance: "131,84",
    lat: 48.281334,
    lng: 17.391722,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:25:27",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "131,95",
    lat: 48.280648,
    lng: 17.392795,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:25:35",
    obec: "Blatné",
    speed: "46 km/h",
    distance: "132,05",
    lat: 48.280047,
    lng: 17.393761,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:25:43",
    obec: "Blatné",
    speed: "46 km/h",
    distance: "132,16",
    lat: 48.279393,
    lng: 17.394791,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:25:50",
    obec: "Blatné",
    speed: "47 km/h",
    distance: "132,23",
    lat: 48.278824,
    lng: 17.395692,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:25:58",
    obec: "Blatné",
    speed: "47 km/h",
    distance: "132,34",
    lat: 48.278159,
    lng: 17.396722,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:26:06",
    obec: "Blatné",
    speed: "49 km/h",
    distance: "132,44",
    lat: 48.277601,
    lng: 17.397881,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:26:14",
    obec: "Blatné",
    speed: "48 km/h",
    distance: "132,55",
    lat: 48.277043,
    lng: 17.399082,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:26:22",
    obec: "Blatné",
    speed: "49 km/h",
    distance: "132,66",
    lat: 48.276496,
    lng: 17.400305,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:26:30",
    obec: "Blatné",
    speed: "49 km/h",
    distance: "132,76",
    lat: 48.27597,
    lng: 17.401528,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:26:38",
    obec: "Blatné",
    speed: "49 km/h",
    distance: "132,91",
    lat: 48.275401,
    lng: 17.402794,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:26:46",
    obec: "Blatné",
    speed: "49 km/h",
    distance: "133,01",
    lat: 48.274854,
    lng: 17.403996,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:26:51",
    obec: "Blatné",
    speed: "48 km/h",
    distance: "133,08",
    lat: 48.274511,
    lng: 17.40479,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:26:59",
    obec: "Blatné",
    speed: "47 km/h",
    distance: "133,19",
    lat: 48.273974,
    lng: 17.40597,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:27:07",
    obec: "Blatné",
    speed: "50 km/h",
    distance: "133,30",
    lat: 48.273427,
    lng: 17.40715,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:27:15",
    obec: "Blatné",
    speed: "51 km/h",
    distance: "133,40",
    lat: 48.272869,
    lng: 17.408438,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:27:23",
    obec: "Blatné",
    speed: "49 km/h",
    distance: "133,51",
    lat: 48.272301,
    lng: 17.409682,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:27:30",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "133,62",
    lat: 48.271839,
    lng: 17.410712,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:27:39",
    obec: "Blatné",
    speed: "40 km/h",
    distance: "133,69",
    lat: 48.271324,
    lng: 17.411871,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:27:48",
    obec: "Blatné",
    speed: "42 km/h",
    distance: "133,80",
    lat: 48.270788,
    lng: 17.41303,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:27:57",
    obec: "Blatné",
    speed: "40 km/h",
    distance: "133,90",
    lat: 48.270262,
    lng: 17.414188,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:28:07",
    obec: "Blatné",
    speed: "34 km/h",
    distance: "134,01",
    lat: 48.269737,
    lng: 17.415433,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:28:12",
    obec: "Blatné",
    speed: "23 km/h",
    distance: "134,04",
    lat: 48.269511,
    lng: 17.415841,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:28:23",
    obec: "Blatné",
    speed: "38 km/h",
    distance: "134,15",
    lat: 48.268835,
    lng: 17.416656,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:28:33",
    obec: "Blatné",
    speed: "35 km/h",
    distance: "134,26",
    lat: 48.268127,
    lng: 17.417557,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:28:38",
    obec: "Blatné",
    speed: "29 km/h",
    distance: "134,29",
    lat: 48.267773,
    lng: 17.417836,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:28:44",
    obec: "Blatné",
    speed: "18 km/h",
    distance: "134,33",
    lat: 48.267462,
    lng: 17.418029,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:28:49",
    obec: "Blatné",
    speed: "8 km/h",
    distance: "134,36",
    lat: 48.267323,
    lng: 17.418072,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:29:01",
    obec: "Blatné",
    speed: "6 km/h",
    distance: "134,36",
    lat: 48.267269,
    lng: 17.418137,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:29:13",
    obec: "Blatné",
    speed: "3 km/h",
    distance: "134,40",
    lat: 48.267258,
    lng: 17.418158,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:29:35",
    obec: "Blatné",
    speed: "7 km/h",
    distance: "134,40",
    lat: 48.267301,
    lng: 17.418115,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:29:40",
    obec: "Blatné",
    speed: "13 km/h",
    distance: "134,40",
    lat: 48.267441,
    lng: 17.418094,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:29:52",
    obec: "Blatné",
    speed: "22 km/h",
    distance: "134,47",
    lat: 48.268009,
    lng: 17.417729,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:30:02",
    obec: "Blatné",
    speed: "24 km/h",
    distance: "134,54",
    lat: 48.268481,
    lng: 17.417192,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:30:14",
    obec: "Blatné",
    speed: "32 km/h",
    distance: "134,65",
    lat: 48.269168,
    lng: 17.416313,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:30:22",
    obec: "Blatné",
    speed: "31 km/h",
    distance: "134,72",
    lat: 48.26964,
    lng: 17.415712,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:30:33",
    obec: "Blatné",
    speed: "34 km/h",
    distance: "134,83",
    lat: 48.270166,
    lng: 17.414575,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:30:44",
    obec: "Blatné",
    speed: "35 km/h",
    distance: "134,93",
    lat: 48.270702,
    lng: 17.413373,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:30:51",
    obec: "Blatné",
    speed: "35 km/h",
    distance: "135,00",
    lat: 48.271067,
    lng: 17.412579,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:31:02",
    obec: "Blatné",
    speed: "37 km/h",
    distance: "135,11",
    lat: 48.271614,
    lng: 17.411335,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:31:12",
    obec: "Blatné",
    speed: "38 km/h",
    distance: "135,22",
    lat: 48.272151,
    lng: 17.410154,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:31:21",
    obec: "Blatné",
    speed: "40 km/h",
    distance: "135,32",
    lat: 48.272666,
    lng: 17.409039,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:31:29",
    obec: "Blatné",
    speed: "35 km/h",
    distance: "135,40",
    lat: 48.273084,
    lng: 17.408073,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:31:40",
    obec: "Blatné",
    speed: "36 km/h",
    distance: "135,50",
    lat: 48.27361,
    lng: 17.406914,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:31:50",
    obec: "Blatné",
    speed: "38 km/h",
    distance: "135,61",
    lat: 48.274135,
    lng: 17.405756,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:31:59",
    obec: "Blatné",
    speed: "39 km/h",
    distance: "135,72",
    lat: 48.274629,
    lng: 17.40464,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:32:09",
    obec: "Blatné",
    speed: "41 km/h",
    distance: "135,82",
    lat: 48.275165,
    lng: 17.403417,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:32:18",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "135,93",
    lat: 48.275723,
    lng: 17.402194,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:32:27",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "136,00",
    lat: 48.27626,
    lng: 17.400992,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:32:35",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "136,11",
    lat: 48.276753,
    lng: 17.399876,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:32:43",
    obec: "Blatné",
    speed: "44 km/h",
    distance: "136,18",
    lat: 48.277257,
    lng: 17.398782,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:32:51",
    obec: "Blatné",
    speed: "45 km/h",
    distance: "136,28",
    lat: 48.277762,
    lng: 17.397666,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:32:57",
    obec: "Blatné",
    speed: "45 km/h",
    distance: "136,36",
    lat: 48.278169,
    lng: 17.396829,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:33:03",
    obec: "Blatné",
    speed: "45 km/h",
    distance: "136,46",
    lat: 48.278609,
    lng: 17.3961,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:33:10",
    obec: "Blatné",
    speed: "45 km/h",
    distance: "136,53",
    lat: 48.279156,
    lng: 17.395241,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:33:18",
    obec: "Blatné",
    speed: "45 km/h",
    distance: "136,64",
    lat: 48.279789,
    lng: 17.394276,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:33:27",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "136,75",
    lat: 48.280465,
    lng: 17.393181,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:33:34",
    obec: "Blatné",
    speed: "44 km/h",
    distance: "136,82",
    lat: 48.281002,
    lng: 17.392323,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:33:43",
    obec: "Blatné",
    speed: "44 km/h",
    distance: "136,96",
    lat: 48.281688,
    lng: 17.391229,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:33:51",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "137,03",
    lat: 48.282332,
    lng: 17.390285,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:33:58",
    obec: "Blatné",
    speed: "43 km/h",
    distance: "137,14",
    lat: 48.282847,
    lng: 17.389448,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:34:06",
    obec: "Blatné",
    speed: "45 km/h",
    distance: "137,24",
    lat: 48.283469,
    lng: 17.388461,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:34:15",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "137,35",
    lat: 48.284145,
    lng: 17.387409,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:34:22",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "137,42",
    lat: 48.284564,
    lng: 17.386658,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:34:30",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "137,49",
    lat: 48.284864,
    lng: 17.385628,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:34:39",
    obec: "Šenkvice",
    speed: "41 km/h",
    distance: "137,60",
    lat: 48.285229,
    lng: 17.384362,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:34:48",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "137,71",
    lat: 48.285562,
    lng: 17.383096,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:34:55",
    obec: "Šenkvice",
    speed: "41 km/h",
    distance: "137,78",
    lat: 48.285851,
    lng: 17.382131,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:35:04",
    obec: "Šenkvice",
    speed: "42 km/h",
    distance: "137,88",
    lat: 48.286195,
    lng: 17.380822,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:35:13",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "137,99",
    lat: 48.286559,
    lng: 17.379534,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:35:23",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "138,10",
    lat: 48.286924,
    lng: 17.378204,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:35:32",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "138,20",
    lat: 48.287267,
    lng: 17.376938,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:35:42",
    obec: "Šenkvice",
    speed: "38 km/h",
    distance: "138,31",
    lat: 48.287632,
    lng: 17.375607,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:35:52",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "138,42",
    lat: 48.288008,
    lng: 17.374299,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:36:03",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "138,52",
    lat: 48.288383,
    lng: 17.372947,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:36:14",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "138,63",
    lat: 48.288759,
    lng: 17.371616,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:36:26",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "138,74",
    lat: 48.289295,
    lng: 17.370243,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:36:37",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "138,84",
    lat: 48.289789,
    lng: 17.368977,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:36:48",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "138,95",
    lat: 48.290261,
    lng: 17.367797,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:36:58",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "139,06",
    lat: 48.290754,
    lng: 17.366681,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:37:03",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "139,09",
    lat: 48.290969,
    lng: 17.366123,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:37:08",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "139,16",
    lat: 48.291141,
    lng: 17.365458,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:37:19",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "139,27",
    lat: 48.291323,
    lng: 17.364063,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:37:29",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "139,34",
    lat: 48.291409,
    lng: 17.362862,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:37:40",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "139,45",
    lat: 48.291892,
    lng: 17.36166,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:37:54",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "139,56",
    lat: 48.292417,
    lng: 17.360501,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:38:02",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "139,63",
    lat: 48.292836,
    lng: 17.359686,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:38:12",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "139,73",
    lat: 48.293243,
    lng: 17.358441,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:38:20",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "139,80",
    lat: 48.293555,
    lng: 17.35754,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:38:28",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "139,88",
    lat: 48.293952,
    lng: 17.356746,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:38:41",
    obec: "Šenkvice",
    speed: "25 km/h",
    distance: "139,98",
    lat: 48.294488,
    lng: 17.35563,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:38:50",
    obec: "Šenkvice",
    speed: "19 km/h",
    distance: "140,05",
    lat: 48.294949,
    lng: 17.35533,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:39:00",
    obec: "Šenkvice",
    speed: "21 km/h",
    distance: "140,09",
    lat: 48.294917,
    lng: 17.354686,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:39:05",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "140,12",
    lat: 48.294617,
    lng: 17.354579,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:39:17",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "140,23",
    lat: 48.293705,
    lng: 17.3546,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:39:27",
    obec: "Šenkvice",
    speed: "21 km/h",
    distance: "140,30",
    lat: 48.292997,
    lng: 17.354579,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:39:38",
    obec: "Šenkvice",
    speed: "22 km/h",
    distance: "140,37",
    lat: 48.292739,
    lng: 17.354,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:39:44",
    obec: "Šenkvice",
    speed: "26 km/h",
    distance: "140,41",
    lat: 48.292997,
    lng: 17.353635,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:39:53",
    obec: "Šenkvice",
    speed: "25 km/h",
    distance: "140,48",
    lat: 48.293351,
    lng: 17.352927,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:39:59",
    obec: "Šenkvice",
    speed: "27 km/h",
    distance: "140,52",
    lat: 48.293587,
    lng: 17.352476,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:40:06",
    obec: "Šenkvice",
    speed: "28 km/h",
    distance: "140,59",
    lat: 48.294005,
    lng: 17.352111,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:40:14",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "140,66",
    lat: 48.294585,
    lng: 17.351811,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:40:23",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "140,73",
    lat: 48.295293,
    lng: 17.351489,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:40:31",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "140,80",
    lat: 48.295872,
    lng: 17.350996,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:40:39",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "140,87",
    lat: 48.296323,
    lng: 17.350352,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:40:48",
    obec: "Šenkvice",
    speed: "31 km/h",
    distance: "140,94",
    lat: 48.29673,
    lng: 17.349494,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:40:58",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "141,05",
    lat: 48.297138,
    lng: 17.348549,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:41:09",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "141,16",
    lat: 48.297578,
    lng: 17.347326,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:41:20",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "141,26",
    lat: 48.298007,
    lng: 17.346017,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:41:28",
    obec: "Šenkvice",
    speed: "34 km/h",
    distance: "141,33",
    lat: 48.298372,
    lng: 17.345202,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:41:39",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "141,44",
    lat: 48.298919,
    lng: 17.344043,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:41:47",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "141,51",
    lat: 48.299198,
    lng: 17.343078,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:41:57",
    obec: "Šenkvice",
    speed: "36 km/h",
    distance: "141,62",
    lat: 48.299498,
    lng: 17.34179,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:42:05",
    obec: "Šenkvice",
    speed: "29 km/h",
    distance: "141,69",
    lat: 48.299681,
    lng: 17.340846,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:42:16",
    obec: "Šenkvice",
    speed: "28 km/h",
    distance: "141,76",
    lat: 48.299863,
    lng: 17.33973,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:42:24",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "141,83",
    lat: 48.299777,
    lng: 17.338786,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:42:32",
    obec: "Šenkvice",
    speed: "33 km/h",
    distance: "141,90",
    lat: 48.299563,
    lng: 17.337842,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:42:43",
    obec: "Šenkvice",
    speed: "32 km/h",
    distance: "142,01",
    lat: 48.299209,
    lng: 17.336555,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:42:52",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "142,12",
    lat: 48.298962,
    lng: 17.335396,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:42:59",
    obec: "Šenkvice",
    speed: "37 km/h",
    distance: "142,19",
    lat: 48.298715,
    lng: 17.334452,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:43:09",
    obec: "Šenkvice",
    speed: "35 km/h",
    distance: "142,29",
    lat: 48.298404,
    lng: 17.333186,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:43:19",
    obec: "Šenkvice",
    speed: "39 km/h",
    distance: "142,40",
    lat: 48.298061,
    lng: 17.331855,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:43:28",
    obec: "Šenkvice",
    speed: "42 km/h",
    distance: "142,51",
    lat: 48.297728,
    lng: 17.330568,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:43:37",
    obec: "Šenkvice",
    speed: "40 km/h",
    distance: "142,58",
    lat: 48.297406,
    lng: 17.329259,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:43:45",
    obec: "Šenkvice",
    speed: "44 km/h",
    distance: "142,68",
    lat: 48.297385,
    lng: 17.328014,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:43:53",
    obec: "Šenkvice",
    speed: "47 km/h",
    distance: "142,79",
    lat: 48.297396,
    lng: 17.32662,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:44:01",
    obec: "Šenkvice",
    speed: "46 km/h",
    distance: "142,90",
    lat: 48.297471,
    lng: 17.325203,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:44:09",
    obec: "Šenkvice",
    speed: "47 km/h",
    distance: "143,00",
    lat: 48.297492,
    lng: 17.323787,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:44:17",
    obec: "Šenkvice",
    speed: "50 km/h",
    distance: "143,11",
    lat: 48.297503,
    lng: 17.322285,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:44:23",
    obec: "Šenkvice",
    speed: "54 km/h",
    distance: "143,18",
    lat: 48.297524,
    lng: 17.321148,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:44:30",
    obec: "Šenkvice",
    speed: "54 km/h",
    distance: "143,29",
    lat: 48.297535,
    lng: 17.31971,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:44:37",
    obec: "Šenkvice",
    speed: "53 km/h",
    distance: "143,40",
    lat: 48.297567,
    lng: 17.318273,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:44:44",
    obec: "Šenkvice",
    speed: "53 km/h",
    distance: "143,50",
    lat: 48.297567,
    lng: 17.316878,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:44:51",
    obec: "Šenkvice",
    speed: "53 km/h",
    distance: "143,61",
    lat: 48.297471,
    lng: 17.315505,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:44:58",
    obec: "Šenkvice",
    speed: "52 km/h",
    distance: "143,72",
    lat: 48.297353,
    lng: 17.314131,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:45:04",
    obec: "Šenkvice",
    speed: "52 km/h",
    distance: "143,82",
    lat: 48.297256,
    lng: 17.312951,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:45:11",
    obec: "Pezinok",
    speed: "51 km/h",
    distance: "143,93",
    lat: 48.297127,
    lng: 17.311599,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:45:18",
    obec: "Pezinok",
    speed: "52 km/h",
    distance: "144,00",
    lat: 48.297009,
    lng: 17.310226,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:45:25",
    obec: "Pezinok",
    speed: "51 km/h",
    distance: "144,11",
    lat: 48.296881,
    lng: 17.308853,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:45:31",
    obec: "Pezinok",
    speed: "50 km/h",
    distance: "144,21",
    lat: 48.29672,
    lng: 17.307737,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:45:39",
    obec: "Pezinok",
    speed: "51 km/h",
    distance: "144,32",
    lat: 48.296441,
    lng: 17.306278,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:45:46",
    obec: "Pezinok",
    speed: "51 km/h",
    distance: "144,43",
    lat: 48.296194,
    lng: 17.304947,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:45:53",
    obec: "Pezinok",
    speed: "52 km/h",
    distance: "144,53",
    lat: 48.295969,
    lng: 17.303617,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:45:59",
    obec: "Pezinok",
    speed: "48 km/h",
    distance: "144,60",
    lat: 48.295743,
    lng: 17.302544,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:46:07",
    obec: "Pezinok",
    speed: "37 km/h",
    distance: "144,71",
    lat: 48.295507,
    lng: 17.3013,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:46:17",
    obec: "Pezinok",
    speed: "21 km/h",
    distance: "144,78",
    lat: 48.295336,
    lng: 17.300205,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:46:27",
    obec: "Pezinok",
    speed: "23 km/h",
    distance: "144,85",
    lat: 48.295239,
    lng: 17.299519,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:46:35",
    obec: "Pezinok",
    speed: "33 km/h",
    distance: "144,92",
    lat: 48.295003,
    lng: 17.298703,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:46:46",
    obec: "Pezinok",
    speed: "39 km/h",
    distance: "145,03",
    lat: 48.294756,
    lng: 17.297244,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:46:55",
    obec: "Pezinok",
    speed: "42 km/h",
    distance: "145,10",
    lat: 48.294499,
    lng: 17.295935,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:47:03",
    obec: "Pezinok",
    speed: "46 km/h",
    distance: "145,17",
    lat: 48.294241,
    lng: 17.294648,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:47:11",
    obec: "Pezinok",
    speed: "41 km/h",
    distance: "145,28",
    lat: 48.293994,
    lng: 17.293339,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:47:20",
    obec: "Pezinok",
    speed: "45 km/h",
    distance: "145,39",
    lat: 48.293791,
    lng: 17.291987,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:47:29",
    obec: "Pezinok",
    speed: "45 km/h",
    distance: "145,49",
    lat: 48.293522,
    lng: 17.290506,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:47:37",
    obec: "Pezinok",
    speed: "46 km/h",
    distance: "145,60",
    lat: 48.293372,
    lng: 17.289133,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:47:45",
    obec: "Pezinok",
    speed: "47 km/h",
    distance: "145,71",
    lat: 48.293276,
    lng: 17.287717,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:47:53",
    obec: "Pezinok",
    speed: "46 km/h",
    distance: "145,81",
    lat: 48.293211,
    lng: 17.286344,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:48:01",
    obec: "Pezinok",
    speed: "47 km/h",
    distance: "145,92",
    lat: 48.293168,
    lng: 17.284949,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:48:09",
    obec: "Pezinok",
    speed: "40 km/h",
    distance: "146,03",
    lat: 48.293104,
    lng: 17.28364,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:48:19",
    obec: "Pezinok",
    speed: "34 km/h",
    distance: "146,13",
    lat: 48.292889,
    lng: 17.282224,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:48:27",
    obec: "Pezinok",
    speed: "19 km/h",
    distance: "146,20",
    lat: 48.292718,
    lng: 17.281387,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:48:32",
    obec: "Pezinok",
    speed: "15 km/h",
    distance: "146,20",
    lat: 48.292696,
    lng: 17.281086,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:48:37",
    obec: "Pezinok",
    speed: "22 km/h",
    distance: "146,24",
    lat: 48.292632,
    lng: 17.280786,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:48:47",
    obec: "Pezinok",
    speed: "30 km/h",
    distance: "146,31",
    lat: 48.292417,
    lng: 17.27982,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:48:58",
    obec: "Pezinok",
    speed: "27 km/h",
    distance: "146,42",
    lat: 48.292289,
    lng: 17.278619,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:49:11",
    obec: "Pezinok",
    speed: "27 km/h",
    distance: "146,49",
    lat: 48.292171,
    lng: 17.277246,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:49:17",
    obec: "Pezinok",
    speed: "14 km/h",
    distance: "146,52",
    lat: 48.292192,
    lng: 17.276816,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:49:23",
    obec: "Pezinok",
    speed: "6 km/h",
    distance: "146,56",
    lat: 48.292171,
    lng: 17.276559,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:49:33",
    obec: "Pezinok",
    speed: "5 km/h",
    distance: "146,56",
    lat: 48.292053,
    lng: 17.276516,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:49:48",
    obec: "Pezinok",
    speed: "7 km/h",
    distance: "146,60",
    lat: 48.291838,
    lng: 17.276773,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:50:05",
    obec: "Pezinok",
    speed: "3 km/h",
    distance: "146,63",
    lat: 48.291709,
    lng: 17.276773,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:50:29",
    obec: "Pezinok",
    speed: "2 km/h",
    distance: "146,63",
    lat: 48.291731,
    lng: 17.276752,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:51:34",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "146,63",
    lat: 48.291688,
    lng: 17.276838,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:52:09",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "146,63",
    lat: 48.291688,
    lng: 17.276838,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:52:32",
    obec: "Pezinok",
    speed: "0 km/h",
    distance: "146,63",
    lat: 48.291688,
    lng: 17.276838,
    pluh: "hore",
    sypac: "sype",
  },
  {
    time: "20:53:31",
    obec: "Pezinok",
    speed: "1 km/h",
    distance: "146,67",
    lat: 48.291709,
    lng: 17.276881,
    pluh: "hore",
    sypac: "nesype",
  },
  {
    time: "20:56:32",
    obec: "Pezinok",
    speed: "3 km/h",
    distance: "146,67",
    lat: 48.291709,
    lng: 17.276881,
    pluh: "hore",
    sypac: "nesype",
  },
];
