<script>
  import obecConfig from "../../../configs/obec.config";
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();
  import Ikonka from "../../UI/Icon.svelte";
  export let open;

  import {
    changeMapTilesSource,
    ziskajVrstvy,
    najdiVrstvuPodlaKodu,
    upravZoznamVrstiev,
  } from "../../map/mapbox";

  import { mapStore } from "../../../stores/map.store";
  const map = $mapStore.mapbox.getMap();

  // vytvorim si defaultny objekt vrstiev
  let vrstvy = {
    interne: { podkladove: [], priehladne: [] },
    externe: { podkladove: [], priehladne: [] },
  };

  // vytvorim si defaultny objekt zvolenych vrstiev
  let zvoleneVrstvy = {
    interne: { podkladove: [], priehladne: [] },
    externe: { podkladove: [], priehladne: [] },
  };

  // ked sa nacita mapbox, tak navolim zvolene vrstvy
  map.on("load", () => {
    // podkladove a priehladne vrstvy chcem mat hned viditelne
    document.querySelectorAll("#cb1, #cb2").forEach((input) => {
      input.checked = true;
    });

    ziskajZvoleneVrstvy();
  });

  function ziskajZvoleneVrstvy() {
    // ziskam zvolene vrstvy (z localstorage alebo z konfigu)
    vrstvy = ziskajVrstvy();

    // vytvorim si pole zvolenych vrstiev, ktore obsahuje iba kod vrstvy
    // aby som mohol nastavit "checked"
    zvoleneVrstvy = {
      interne: {
        podkladove: vrstvy.interne.podkladove.map((vrstva) => vrstva.kod),
        priehladne: vrstvy.interne.priehladne.map((vrstva) => vrstva.kod),
      },
      externe: {
        podkladove: vrstvy.externe.podkladove.map((vrstva) => vrstva.kod),
        priehladne: vrstvy.externe.priehladne.map((vrstva) => vrstva.kod),
      },
    };
  }

  // prechadzam DOM a ziskavam zakliknute vrstvy
  function ziskajVrstvyZMenu() {
    const types = [
      "podkladove_interne",
      "priehladne_interne",
      "podkladove_externe",
      "priehladne_externe",
    ];

    types.map((type) => {
      const [typ, zdroj] = type.split("_");

      vrstvy[zdroj][typ] = Array.from(
        document.querySelectorAll(
          `.sidebar__tab--map input[data-type="${type}"]`
        )
      )
        .filter((input) => input.checked)
        .map((input) => input.value);
    });
    vrstvy["interne"]["priehladne"] = vrstvy["interne"]["priehladne"].reverse();
  }

  // po kliknuti na vrstvu najprv ziskam vsetky zakliknute vrstvy
  // a potom menim mapbox
  function zmenVrstvy() {
    ziskajVrstvyZMenu();
    vrstvy = upravZoznamVrstiev(vrstvy);
    changeMapTilesSource(map, vrstvy);
    ziskajZvoleneVrstvy();
  }
</script>

<!-- Tab change map start -->
<div
  class="sidebar__tab sidebar__tab--map"
  class:js-tab--open={open}
  data-tab="2"
>
  <div
    class="sidebar__close"
    on:click|preventDefault={() => dispatch("toggleSidebarTabVrstvy")}
  >
    <Ikonka kod="012" />
  </div>
  <div class="sidebar__wrapper">
    <nav class="accordion arrows">
      <header class="box-header">
        <label for="acc-close" class="box-title">Vrstvy</label>
      </header>
      <input type="checkbox" id="cb0" />
      <section class="box">
        <label class="box-title" for="cb0">Aktívne vrstvy</label>
        <label class="box-close" for="cb0" />
        <div class="box-content">
          {#each zvoleneVrstvy.interne.podkladove as vrstva}
            <div class="input-group">
              <!-- Toggle start -->
              <div class="toggle">
                <label
                  for="interna_podkladova-{najdiVrstvuPodlaKodu(vrstva).vrstva
                    .id}"
                >
                  <!-- JSON icon Close start -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    viewBox="0 0 329.27 329.263"
                  >
                    <g transform="translate(0 -0.135)">
                      <path
                        d="M21.34,329.4A21.332,21.332,0,0,1,6.258,292.984L292.848,6.39a21.329,21.329,0,0,1,30.164,30.164L36.422,323.148A21.231,21.231,0,0,1,21.34,329.4Zm0,0"
                        fill="#ff8d8d"
                      />
                      <path
                        d="M307.93,329.4a21.273,21.273,0,0,1-15.082-6.25L6.258,36.554A21.329,21.329,0,0,1,36.422,6.39l286.59,286.594A21.332,21.332,0,0,1,307.93,329.4Zm0,0"
                        fill="#ff8d8d"
                      />
                    </g>
                  </svg>
                  <!-- JSON icon Close end -->
                </label>
                <label
                  class="toggle__text"
                  for="interna_podkladova-{najdiVrstvuPodlaKodu(vrstva).vrstva
                    .id}">{najdiVrstvuPodlaKodu(vrstva).vrstva.nazov}</label
                >
              </div>
              <!-- Toggle end -->
            </div>
          {/each}
          {#each zvoleneVrstvy.externe.podkladove as vrstva}
            <div class="input-group">
              <!-- Toggle start -->
              <div class="toggle">
                <span
                  class="toggle__text"
                  for="externa_priehladna-{najdiVrstvuPodlaKodu(vrstva, false)
                    .vrstva.id}"
                  >{najdiVrstvuPodlaKodu(vrstva, false).vrstva.nazov}</span
                >
              </div>
              <!-- Toggle end -->
            </div>
          {/each}
          {#each zvoleneVrstvy.interne.priehladne as vrstva}
            <div class="input-group">
              <!-- Toggle start -->
              <div class="toggle">
                <label
                  for="interna_priehladna-{najdiVrstvuPodlaKodu(vrstva).vrstva
                    .id}"
                >
                  <!-- JSON icon Close start -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    viewBox="0 0 329.27 329.263"
                  >
                    <g transform="translate(0 -0.135)">
                      <path
                        d="M21.34,329.4A21.332,21.332,0,0,1,6.258,292.984L292.848,6.39a21.329,21.329,0,0,1,30.164,30.164L36.422,323.148A21.231,21.231,0,0,1,21.34,329.4Zm0,0"
                        fill="#ff8d8d"
                      />
                      <path
                        d="M307.93,329.4a21.273,21.273,0,0,1-15.082-6.25L6.258,36.554A21.329,21.329,0,0,1,36.422,6.39l286.59,286.594A21.332,21.332,0,0,1,307.93,329.4Zm0,0"
                        fill="#ff8d8d"
                      />
                    </g>
                  </svg>
                  <!-- JSON icon Close end -->
                </label>
                <label
                  class="toggle__text"
                  for="interna_priehladna-{najdiVrstvuPodlaKodu(vrstva).vrstva
                    .id}">{najdiVrstvuPodlaKodu(vrstva).vrstva.nazov}</label
                >
              </div>
              <!-- Toggle end -->
            </div>
          {/each}
          {#each zvoleneVrstvy.externe.priehladne as vrstva}
            <div class="input-group">
              <!-- Toggle start -->
              <div class="toggle">
                <label
                  for="externa_priehladna-{najdiVrstvuPodlaKodu(vrstva, false)
                    .vrstva.id}"
                >
                  <!-- JSON icon Close start -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    viewBox="0 0 329.27 329.263"
                  >
                    <g transform="translate(0 -0.135)">
                      <path
                        d="M21.34,329.4A21.332,21.332,0,0,1,6.258,292.984L292.848,6.39a21.329,21.329,0,0,1,30.164,30.164L36.422,323.148A21.231,21.231,0,0,1,21.34,329.4Zm0,0"
                        fill="#ff8d8d"
                      />
                      <path
                        d="M307.93,329.4a21.273,21.273,0,0,1-15.082-6.25L6.258,36.554A21.329,21.329,0,0,1,36.422,6.39l286.59,286.594A21.332,21.332,0,0,1,307.93,329.4Zm0,0"
                        fill="#ff8d8d"
                      />
                    </g>
                  </svg>
                  <!-- JSON icon Close end -->
                </label>
                <label
                  class="toggle__text"
                  for="externa_priehladna-{najdiVrstvuPodlaKodu(vrstva, false)
                    .vrstva.id}"
                  >{najdiVrstvuPodlaKodu(vrstva, false).vrstva.nazov}</label
                >
              </div>
              <!-- Toggle end -->
            </div>
          {/each}
        </div>
      </section>
      <input type="checkbox" id="cb1" checked />
      <section class="box">
        <label class="box-title" for="cb1"
          >{obecConfig.vrstvy.interne.podkladove.nazov}</label
        >
        <label class="box-close" for="cb1" />
        <div class="box-content">
          {#each obecConfig.vrstvy.interne.podkladove.vrstvy as vrstva}
            <div class="input-group">
              <label class="checkbox">
                <input
                  type="radio"
                  data-type="podkladove_interne"
                  name="podkladove"
                  value={vrstva.kod}
                  on:change={() => zmenVrstvy()}
                  checked={zvoleneVrstvy.interne.podkladove.includes(
                    vrstva.kod
                  )}
                  id="interna_podkladova-{vrstva.id}"
                />
                <label for="interna_podkladova-{vrstva.id}"
                  >{vrstva.nazov}</label
                >
              </label>
            </div>
          {/each}
        </div>
      </section>

      <input type="checkbox" id="cb2" checked />
      <section class="box">
        <label class="box-title" for="cb2"
          >{obecConfig.vrstvy.interne.priehladne.nazov}</label
        >
        <label class="box-close" for="cb2" />
        <div class="box-content">
          {#each obecConfig.vrstvy.interne.priehladne.vrstvy as vrstva}
            <div class="input-group">
              <!-- Toggle start -->
              <div class="toggle">
                <input
                  type="checkbox"
                  data-type="priehladne_interne"
                  checked={zvoleneVrstvy.interne.priehladne.includes(
                    vrstva.kod
                  )}
                  on:change={() => zmenVrstvy()}
                  value={vrstva.kod}
                  id="interna_priehladna-{vrstva.id}"
                />
                <label
                  class="toggle__icon"
                  for="interna_priehladna-{vrstva.id}"
                />
                <label class="toggle__text" for="interna_priehladna-{vrstva.id}"
                  >{vrstva.nazov}</label
                >
              </div>
              <!-- Toggle end -->
            </div>
          {/each}
        </div>
      </section>
      <!-- <input type="radio" name="accordion" id="acc-close" /> -->
      {#if obecConfig.vrstvy.externe}
        {#if obecConfig.vrstvy.externe.podkladove.vrstvy.length}
          <input type="checkbox" id="cb3" />
          <section class="box">
            <label class="box-title" for="cb3"
              >{obecConfig.vrstvy.externe.podkladove.nazov}</label
            >
            <label class="box-close" for="cb3" />
            <div class="box-content">
              {#each obecConfig.vrstvy.externe.podkladove.vrstvy as vrstva}
                <div class="input-group">
                  <label class="checkbox">
                    <input
                      type="radio"
                      data-type="podkladove_externe"
                      name="podkladove"
                      value={vrstva.kod}
                      on:change={() => zmenVrstvy()}
                      checked={zvoleneVrstvy.externe.podkladove.includes(
                        vrstva.kod
                      )}
                      id="externa_podkladova-{vrstva.id}"
                    />
                    <label for="externa_podkladova-{vrstva.id}"
                      >{vrstva.nazov}</label
                    >
                  </label>
                </div>
              {/each}
            </div>
          </section>
        {/if}
        {#if obecConfig.vrstvy.externe.priehladne.vrstvy.length}
          <input type="checkbox" id="cb4" />
          <section class="box">
            <label class="box-title" for="cb4"
              >{obecConfig.vrstvy.externe.priehladne.nazov}</label
            >
            <label class="box-close" for="cb4" />
            <div class="box-content">
              {#each obecConfig.vrstvy.externe.priehladne.vrstvy as vrstva}
                <div class="input-group">
                  <!-- Toggle start -->
                  <div class="toggle">
                    <input
                      type="checkbox"
                      data-type="priehladne_externe"
                      checked={zvoleneVrstvy.externe.priehladne.includes(
                        vrstva.kod
                      )}
                      on:change={() => zmenVrstvy()}
                      value={vrstva.kod}
                      id="externa_priehladna-{vrstva.id}"
                    />
                    <label
                      class="toggle__icon"
                      for="externa_priehladna-{vrstva.id}"
                    />
                    <label
                      class="toggle__text"
                      for="externa_priehladna-{vrstva.id}">{vrstva.nazov}</label
                    >
                  </div>
                  <!-- Toggle end -->
                </div>
              {/each}
            </div>
          </section>
        {/if}
      {/if}
    </nav>
  </div>
</div>
<!-- Tab change map end -->
