<script>
  // import { zobrazDvojklikOkno } from "../map/mapbox";

  import { mapStore } from "../../stores/map.store";
  import { errorLog } from "../../helpers/error";
  import {
    sourceExists,
    vygenerujIdZdroja,
    pridajVrstvuNaMapu,
    zobrazPopUpOkno,
  } from "../../helpers/map";
  import { zobrazDataNaMape, nastavViditelnostVrstvy } from "../map/mapbox";
  import { ziskajDataIkonky } from "../../helpers/ikonka";
  import get from "get-value";
  import {
    vratHtmlKodFarbyPodlaTypu,
    vratVrstvyMapyPodlaTypu,
    vratKodIkonkyPodlaTypu,
    vratKodFarbyPodlaTypu,
  } from "../../helpers/config";
  import { vratDataZDvojkliku } from "../../api/popups";
  import { time_ranges_to_array } from "svelte/internal";

  const map = $mapStore.mapbox.getMap();
  let open = false;
  let x = 0;
  let y = 0;

  map.on("dblclick", (sourceData) => {
    const { lat, lng } = sourceData.lngLat;
    if (lat && lng) {
      pridajBodNaMapu(map, lat, lng);
    }
  });

  async function ziskajData(lat, lng) {
    if (lat === undefined || lng === undefined) {
      errorLog(`Nie su zadane suradnice!`);
      return false;
    }
    const response = await vratDataZDvojkliku(lng, lat);

    if (response.data && response.data.length) {
      return response.data;
    }

    return false;
  }

  export async function pridajBodNaMapu(map, lat, lng) {
    const typ = "point";
    const source_id = vygenerujIdZdroja(typ, `${lat}_${lng}`);
    if (!source_id) {
      return false;
    }

    // z konfigu si vytiahnem data o vrstve daneho typu
    // tych vrstiev moze byt viac. Napr lokalita ma oramovanie a vyfarbenie
    const vrstvy = vratVrstvyMapyPodlaTypu("point");

    // ak uz taky zdroj mam, tak ho nemozem znova pridava
    // ale mozem ho znova nacentrovat
    if (sourceExists(map, source_id) !== false) {
      return false;
    }

    const data = await ziskajData(lng, lat);
    let obsah = "";
    if (data) {
      obsah = `<div class="tab-data active"><ul>`;
      for (const item of data) {
        obsah += `<li data-typ="${item.typ}" data-id="${item.id}">`;
        const kod_ikonky = vratKodIkonkyPodlaTypu(item.typ);
        if (kod_ikonky) {
          let ikonkaData = ziskajDataIkonky(kod_ikonky);
          if (typeof ikonkaData === "object" && ikonkaData !== null) {
            const attrs = get(ikonkaData.attrs.svg, { default: false });
            const ikonka = get(ikonkaData.ikonka.body, { default: false });
            let html_attrs = "";

            if (attrs !== undefined) {
              for (const attr in attrs) {
                if (attr === "class") {
                  attrs[attr] += ` color__text--${vratKodFarbyPodlaTypu(
                    item.typ
                  )}`;
                }
                html_attrs += `${attr}="${attrs[attr]}"`;
              }
            }
            if (ikonka !== undefined) {
              obsah += `
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" ${html_attrs}>
                    ${ikonka}
                  </svg>
                </span>
              `;
            }
          }
        }
        obsah += `
            <span class="color__text--${vratKodFarbyPodlaTypu(
              item.typ
            )}">${pripravNazov(item)}`;

        if (item.info !== undefined) {
          obsah += ` <small>${item.info}</small>`;
        }

        obsah += `</span>`;

        obsah += `
          </li>
        `;
      }
      obsah += `</ul></div><div class="tab-poznamka"></div>`;
      obsah += `<div class="footer-buttons">
                  <span class="pridat-poznamku active" data-source-id="${source_id}">Pridať poznámku</span>
                  <span class="zobrazit-poznamku">Zobraziť poznámku</span>
                  <span class="zobrazit-data">Zobraziť dáta</span>
                  <span class="upravit-poznamku" data-source-id="${source_id}" data-poznamka-id="">Upraviť</span>
                  <span class="zmazat-poznamku" data-source-id="${source_id}" data-poznamka-id="">Zmazať</span>
                  <span data-source-id="${source_id}" class="zavriet-okno active">Zavrieť</span>
                </div>`;
    }

    const sourceData = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            description: obsah,
          },
          geometry: {
            type: "Point",
            coordinates: [lng, lat],
          },
        },
      ],
    };

    if (vrstvy) {
      // vytvorim si zdroj dat
      map.addSource(source_id, {
        type: "geojson",
        data: sourceData,
        generateId: true,
      });

      pridajVrstvuNaMapu(map, typ, `${lat}_${lng}`, true);

      zobrazPopUpOkno(map, [lng, lat], obsah, sourceData, source_id);
    }
  }

  function pripravNazov(item) {
    let nazov = item.nazov;
    switch (item.typ) {
      case "obec":
        return `Obec ${nazov}`;
      case "okres":
        return `Okres ${nazov}`;
      case "ku":
        return `K. ú. ${nazov}`;
      case "cesty":
        return `Cesta č. ${nazov}`;
      case "okruhy":
        return `${nazov}`;
    }

    return nazov;
  }
</script>
