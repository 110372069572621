import Api from "../helpers/api";
import obecConfig from "../configs/obec.config";

export const vratZoznamSelectu = async (typ) => {
  try {
    const response = await Api.get(`/api/v1/smartmap/select/${typ}`);
    if (typ === "okruhy") {
      response.data.map((okruh) => {
        okruh.nazov = obecConfig.okruhy.find((o) => o.id === okruh.id).nazov;
      });
      response.data.sort((a, b) => {
        if (a.nazov < b.nazov) {
          return -1;
        }
        if (a.nazov > b.nazov) {
          return 1;
        }
        return 0;
      });
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
