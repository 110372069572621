import { tooltipData, tooltipHelp } from "../createTooltip.js";

const DrawRectangleMode = DrawRectangle.default;

const _onMouseMove = DrawRectangleMode.onMouseMove;

DrawRectangleMode.onMouseMove = function(state, e) {
  const result = _onMouseMove.apply(this, [state, e]);

  const feature = state.rectangle.toGeoJSON();

  tooltipData([feature], "rectangle");
  tooltipHelp([feature], "rectangle");

  return result;
};

export default DrawRectangleMode;
