<script>
  import { tempStore } from "../../stores/temp.store";
  import { onDestroy } from "svelte";
  import SidebarNav from "./Sidebar/SidebarNav.svelte";
  import SidebarTabVrstvy from "./Sidebar/SidebarTabVrstvy.svelte";
  import SidebarTabPopisZnaciek from "./Sidebar/SidebarTabPopisZnaciek.svelte";
  import SidebarTabChangelog from "./Sidebar/SidebarTabChangelog.svelte";
  import SidebarTabPoznamky from "./Sidebar/SidebarTabPoznamky.svelte";
  import SidebarTabMeranie from "./Sidebar/SidebarTabMeranie.svelte";
  import SidebarTabNastavenieUctu from "./Sidebar/SidebarTabNastavenieUctu.svelte";
  import SidebarTabAuta from "./Sidebar/SidebarTabAuta.svelte";
  import SidebarTabPocasie from "./Sidebar/SidebarTabPocasie.svelte";
  import SidebarTabSystemovdInformacie from "./Sidebar/SidebarTabSystemovdInformacie.svelte";

  let sidebarTabs = {
    vrstvy: false,
    popisZnaciek: false,
    changelog: false,
    poznamky: false,
    nastavenieUctu: false,
    meranie: false,
    auta: false,
    pocasie: false,
    systemInfo: false,
  };

  const unsubscribe = tempStore.subscribe((value) => {
    const poznamkaFormular = value.poznamkaFormular || false;
    if (poznamkaFormular) {
      sidebarTabs.poznamky = true;
    }
  });

  onDestroy(unsubscribe);

  function viditelnostTabu(tab) {
    for (const property in sidebarTabs) {
      if (property !== tab) {
        sidebarTabs[property] = false;
      }
    }
    setTimeout(() => {
      sidebarTabs[tab] = !sidebarTabs[tab];
    }, 100);
  }
</script>

<!-- ///////////////////////////-->
<!-- Sidebar start -->
<!-- //////////////////////////-->
<aside class="sidebar">
  <div class="sidebar__logo">
    <img src="assets/img/logo/logo.svg" alt="SmartApp" title="SmartApp" />
  </div>
  <SidebarNav
    on:toggleSidebarTabVrstvy={() => viditelnostTabu("vrstvy")}
    on:toggleSidebarTabPopisZnaciek={() => viditelnostTabu("popisZnaciek")}
    on:toggleSidebarTabPoznamky={() => viditelnostTabu("poznamky")}
    on:toggleSidebarTabMeranie={() => viditelnostTabu("meranie")}
    on:toggleSidebarTabNastavenieUctu={() => viditelnostTabu("nastavenieUctu")}
    on:toggleSidebarTabChangelog={() => viditelnostTabu("changelog")}
    on:toggleSidebarTabAuta={() => viditelnostTabu("auta")}
    on:toggleSidebarTabPocasie={() => viditelnostTabu("pocasie")}
    on:toggleSidebarTabSystemovdInformacie={() => viditelnostTabu("systemInfo")}
  />
  <SidebarTabVrstvy
    on:toggleSidebarTabVrstvy={() => viditelnostTabu("vrstvy")}
    open={sidebarTabs.vrstvy}
  />
  <SidebarTabPopisZnaciek
    on:toggleSidebarTabPopisZnaciek={() => viditelnostTabu("popisZnaciek")}
    open={sidebarTabs.popisZnaciek}
  />
  <SidebarTabPoznamky
    on:toggleSidebarTabPoznamky={() => viditelnostTabu("poznamky")}
    open={sidebarTabs.poznamky}
  />
  <SidebarTabMeranie
    on:toggleSidebarTabMeranie={() => viditelnostTabu("meranie")}
    open={sidebarTabs.meranie}
  />
  <SidebarTabAuta
    on:toggleSidebarTabAuta={() => viditelnostTabu("auta")}
    open={sidebarTabs.auta}
  />
  <SidebarTabPocasie
    on:toggleSidebarTabPocasie={() => viditelnostTabu("pocasie")}
    open={sidebarTabs.pocasie}
  />
  <SidebarTabChangelog
    on:toggleSidebarTabChangelog={() => viditelnostTabu("changelog")}
    open={sidebarTabs.changelog}
  />
  <SidebarTabNastavenieUctu
    on:toggleSidebarTabNastavenieUctu={() => viditelnostTabu("nastavenieUctu")}
    open={sidebarTabs.nastavenieUctu}
  />
  <SidebarTabSystemovdInformacie
    on:toggleSidebarTabSystemovdInformacie={() => viditelnostTabu("systemInfo")}
    open={sidebarTabs.systemInfo}
  />
</aside>
