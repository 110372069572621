<script>
  import obecConfig from "./configs/obec.config";
  import { userStore } from "./stores/user.store.js";
  import { lastLoggedUser } from "./stores/currentLoggedUser.store";
  import { onlineStatus } from "./stores/onlineStatus.store.js";
  import Login from "./components/Layout/Login.svelte";
  import Dashboard from "./components/Layout/Dashboard.svelte";

  // uzivatelske data z local storage vlozim do svelte store
  export let storedUser;
  userStore.initStoredUser(storedUser);

  export let storedLastLoggedUser;
  lastLoggedUser.initStoredLastLoggedUser(storedLastLoggedUser);

  // z konfigu vytvaram styly farieb
  let stylyFarieb = "";
  if (obecConfig.farby) {
    for (const farba of obecConfig.farby) {
      stylyFarieb += `
        .color__text--${farba.kod} {
          color: #${farba.html_kod};
        }

        .color__bg--${farba.kod} {
          background-color: #${farba.html_kod};
        }
      `;
    }
  }
  // svelte to nevie vlozit, tak to vkladam cez klasicke JS
  document.head.insertAdjacentHTML(
    "beforeend",
    `<style>${stylyFarieb}</style>`
  );
</script>

<svelte:head>
  <title>{obecConfig.titulka}</title>
</svelte:head>
{#if !$onlineStatus}
  <div id="online-status">offline</div>
{/if}
{#if $lastLoggedUser}
  <Dashboard />
{:else}
  <Login />
{/if}

<style>
  #online-status {
    position: absolute;
    top: 42px;
    left: 50%;
    background: red;
    z-index: 9;
    padding: 5px 10px;
    color: #fff;
  }
</style>
