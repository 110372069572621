import {
  vratHtmlKodFarbyPodlaTypu,
  vratVrstvyMapyPodlaTypu,
} from "../helpers/config";
import {
  zobrazDataNaMape,
  nastavViditelnostVrstvy,
  ulozPridanuVrstvu,
} from "../components/map/mapbox";

import { vratGeojsonData } from "../api/geojson";
import { userStore } from "../stores/user.store.js";
import { tempStore } from "../stores/temp.store.js";
import { pointTo } from "../components/map/mapbox";

import { zmazatPoznamku } from "../helpers/poznamky";

export function sourceExists(map, id) {
  if (typeof map === "undefined" || typeof id === "undefined" || id === "") {
    return false;
  }

  const source = map.getSource(id);

  return source ? true : false;
}

export function layerExists(map, id) {
  if (typeof map === "undefined" || typeof id === "undefined" || id === "") {
    return false;
  }

  const source = map.getLayer(id);

  return source ? true : false;
}

export function vygenerujIdZdroja(typ, id) {
  if (!typ || !id) {
    return false;
  }
  return `sm_${typ}_${id}`;
}

export function nastavPremenneZDatZdroja(data) {
  if (data === null || data !== Object(data)) {
    return false;
  }

  // zistim si typ dat ktore potrebujem zonbrazit
  const typ = data.gistyp || data.typ;
  // potrebujem ID dat, aby som podla toho zavolal api
  // FIX: parcely mozu mat ID s pomlckou!
  const id = parseInt(data.id);
  // ak som spravil dvojklik a v zobrazenom popupe
  // prechadzam jednotlivymi vrstvami
  const hover = data.hover === true || false;

  if (!typ || !id) {
    return false;
  }

  return { typ, id, hover };
}

export function pridajVrstvuNaMapu(map, typ, id, isPopup = false) {
  const source_id = vygenerujIdZdroja(typ, id);
  if (!source_id) {
    return false;
  }

  // z konfigu si vytiahnem data o vrstve daneho typu
  // tych vrstiev moze byt viac. Napr lokalita ma oramovanie a vyfarbenie
  const vrstvy = vratVrstvyMapyPodlaTypu(typ);

  // idem po jednej vrstve a samostatne jej nastavim layer
  vrstvy.forEach((vrstva, i) => {
    // vrstva moze mat nastavenia pre paint aj pre layout. Je to podla rozneho typu vrstvy
    for (const v in vrstva.paint) {
      if (typeof vrstva.paint[v] === "string") {
        vrstvy[i].paint[v] = vrstvy[i].paint[v].replace(
          /\%kod_farby\%/,
          `#${vratHtmlKodFarbyPodlaTypu(typ)}`
        );
      }
    }

    for (const v in vrstva.layout) {
      if (typeof vrstva.layout[v] === "string") {
        vrstvy[i].layout[v] = vrstvy[i].layout[v].replace(
          /\%kod_farby\%/,
          `#${vratHtmlKodFarbyPodlaTypu(typ)}`
        );
      }
    }

    // ak nemam v konfigu vetvu pre layout, tak ju vytvorim
    if (vrstva.layout === undefined) {
      vrstva.layout = {};
    }

    // defualtne nastavim visibility pre vsetky vrstvy
    // toto sa pouziva pri hoveri na dvojkliku
    vrstva.layout.visibility = "visible";

    const typ_vrstvy = vrstva.typ_vrstvy;
    // vytvorim layer pre danu vrstvu
    const l = map.addLayer({
      id: `${source_id}_${i}`,
      type: typ_vrstvy,
      source: source_id,
      paint: vrstva.paint || {},
      layout: vrstva.layout || {},
    });

    if (isPopup) {
      popupEvents(map, source_id, i);
    }
  });
}

export function popupEvents(map, source_id, i) {
  // Change the cursor to a pointer when the mouse is over the places layer.
  map.on("mouseenter", `${source_id}_${i}`, function () {
    map.getCanvas().style.cursor = "pointer";
  });

  // Change it back to a pointer when it leaves.
  map.on("mouseleave", `${source_id}_${i}`, function () {
    map.getCanvas().style.cursor = "";
  });

  map.on("click", `${source_id}_${i}`, function (e) {
    var coordinates = e.features[0].geometry.coordinates.slice();
    var description = e.features[0].properties.description;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    zobrazPopUpOkno(map, [e.lngLat.lng, e.lngLat.lat], description);
  });
}

export async function zobrazPopUpOkno(
  map,
  coordinates,
  description,
  sourceData,
  source_id_parent
) {
  const popup = new mapboxgl.Popup({
    maxWidth: "none",
    offset: {
      top: [0, 0],
      bottom: [0, -45],
      right: [-10, 0],
      left: [10, 0],
      "top-left": [0, 0],
      "top-right": [0, 0],
      "bottom-left": [0, -45],
      "bottom-right": [0, -45],
    },
  })
    .setLngLat(coordinates)
    .setHTML(description)
    .addTo(map);

  const popupElem = popup.getElement();
  const listItems = popupElem.querySelectorAll(".mapboxgl-popup-content ul li");
  const user = userStore.getUser();
  for (const item of listItems) {
    const id = item.getAttribute("data-id");
    const typ = item.getAttribute("data-typ");
    const nazov = item
      .querySelector("span:last-child")
      .innerHTML.replace(/ *\<small>[^)]*<\/small>/g, "");

    if (!typ || !id) {
      return false;
    }

    // vytvorim si ID pre zdroj mapboxu. Musi to byt jedinecne
    const source_id = vygenerujIdZdroja(typ, id);
    if (!source_id) {
      return false;
    }

    let timeout = null;

    const mouseover_f = function (event) {
      if (timeout !== null) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (user.mapa.stav[source_id] !== undefined) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      timeout = setTimeout(() => {
        if (sourceExists(map, source_id) !== false) {
          nastavViditelnostVrstvy(map, typ, source_id, "visible");
        } else {
          // ak taky zdroj nemam, musim ho najprv vytvoritt
          zobrazDataNaMape(map, { id: id, typ: typ, hover: true }, false);
        }
      }, 300);
    };
    const mouseout_f = function (event) {
      if (timeout !== null) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (user.mapa.stav[source_id] !== undefined) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      // timeout = setTimeout(() => {
      if (sourceExists(map, source_id) !== false) {
        nastavViditelnostVrstvy(map, typ, source_id, "none");
      }
      // }, 300);
    };

    item.addEventListener("mouseover", mouseover_f);
    item.addEventListener("mouseout", mouseout_f);

    item.addEventListener("click", async (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (timeout !== null) {
        clearTimeout(timeout);
        timeout = null;
      }

      item.removeEventListener("mouseover", mouseover_f);
      item.removeEventListener("mouseout", mouseout_f);

      if (sourceExists(map, source_id) !== false) {
        const vrstvy = vratVrstvyMapyPodlaTypu(typ);
        // z api vratim geojson data
        const sourceData = await vratGeojsonData(typ, id);
        if (!sourceData) {
          return false;
        }

        nastavViditelnostVrstvy(map, typ, source_id, "visible");
        ulozPridanuVrstvu(
          { id: id, gistyp: typ, nazov: nazov },
          source_id,
          sourceData,
          vrstvy[0].typ_vrstvy
        );
        pointTo(map, sourceData, vrstvy[0].typ_vrstvy);
      } else {
        // ak taky zdroj nemam, musim ho najprv vytvoritt
        zobrazDataNaMape(map, { id: id, typ: typ, nazov: nazov });
      }
    });
  }

  const pridatPoznamku = popupElem.querySelector(
    ".footer-buttons .pridat-poznamku"
  );

  pridatPoznamku.addEventListener("click", (e) => {
    e.preventDefault();
    const geojson = JSON.stringify(sourceData);
    tempStore.update((t) => {
      t.poznamkaFormular = { coordinates, e, geojson, source_id_parent, popup };
      return t;
    });
  });

  const zavrietOkno = popupElem.querySelector(".footer-buttons .zavriet-okno");
  const sourceId = zavrietOkno.getAttribute("data-source-id");
  zavrietOkno.addEventListener("click", (e) => {
    e.preventDefault();
    if (map.getLayer(`${sourceId}_0`)) {
      map.removeLayer(`${sourceId}_0`);
    }

    if (map.getSource(sourceId)) {
      map.removeSource(sourceId);
    }
    popup.remove();
    tempStore.update((t) => {
      delete t.poznamkaFormular;
      return t;
    });
  });

  const zobrazitPoznamku = popupElem.querySelector(
    ".footer-buttons .zobrazit-poznamku"
  );
  zobrazitPoznamku.addEventListener("click", (e) => {
    e.preventDefault();
    const content = zobrazitPoznamku.closest(".mapboxgl-popup-content");
    content.querySelector(".tab-data").classList.remove("active");
    content.querySelector(".tab-poznamka").classList.add("active");

    const footer = zobrazitPoznamku.closest(".footer-buttons");
    footer.querySelector(".zobrazit-data").classList.add("active");
    footer.querySelector(".upravit-poznamku").classList.add("active");
    footer.querySelector(".zmazat-poznamku").classList.add("active");

    zobrazitPoznamku.classList.remove("active");
  });

  const zobrazitData = popupElem.querySelector(
    ".footer-buttons .zobrazit-data"
  );
  zobrazitData.addEventListener("click", (e) => {
    e.preventDefault();
    const content = zobrazitData.closest(".mapboxgl-popup-content");
    content.querySelector(".tab-data").classList.add("active");
    content.querySelector(".tab-poznamka").classList.remove("active");

    const footer = zobrazitData.closest(".footer-buttons");
    footer.querySelector(".zobrazit-data").classList.remove("active");
    footer.querySelector(".upravit-poznamku").classList.remove("active");
    footer.querySelector(".zmazat-poznamku").classList.remove("active");
    footer.querySelector(".zobrazit-poznamku").classList.add("active");

    zobrazitData.classList.remove("active");
  });

  const zmazat = popupElem.querySelector(".footer-buttons .zmazat-poznamku");
  zmazat.addEventListener("click", (e) => {
    e.preventDefault();

    const source_id_zmazat = zmazat.getAttribute("data-source-id");
    const poznamka_id = zmazat.getAttribute("data-poznamka-id");

    if (window.confirm("Urcčite chcete zmazať túto poznámku?")) {
      if (zmazatPoznamku(map, source_id_zmazat, poznamka_id)) {
        popup.remove();
      }
    }
  });

  const upravit = popupElem.querySelector(".footer-buttons .upravit-poznamku");
  upravit.addEventListener("click", (e) => {
    e.preventDefault();

    const source_id_edit = zmazat.getAttribute("data-source-id");
    const poznamka_id = zmazat.getAttribute("data-poznamka-id");

    const stores = map.querySourceFeatures(source_id_edit);
    const geojson = JSON.stringify(map.getStyle().sources[source_id_edit].data);

    const props =
      map.getStyle().sources[source_id_edit].data.features[0].properties;

    tempStore.update((t) => {
      t.poznamkaFormular = {
        coordinates,
        e,
        geojson,
        poznamka_id,
        title: props.title,
        public: props.public,
        note_desc: props.note_desc,
        color: props.color,
        popup,
      };
      return t;
    });
  });
}
