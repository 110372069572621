import systemConfig from "./system.config";
const prefix = window.location.hostname.startsWith("demo") ? "demo" : "app";
export default {
  titulka: "Správa ciest BSK | Smartmap v4", // zobrazi sa v nazve tabu - <title>
  pociatocne_pozicie: {
    lat: 48.3182,
    lon: 17.1436,
    zoom: 9.7,
  },
  map: {
    maxZoom: 16,
    minZoom: 9,
    maxBounds: [
      [16.5713, 47.8943], // Southwest coordinates
      [17.7447, 48.8095], // Northeast coordinates
    ],
  },
  sources: {
    maxzoom: 17,
    minzoom: 10,
  },
  layers: {
    maxzoom: 20,
    minzoom: 8,
  },
  /**
   * VRSTVY
   */
  vrstvy: {
    url: systemConfig.apiUrl + "/api/v1/smartmap/tilemap/{x}.{y}.{z}.${vrstvy}",
    interne: {
      /**
       * PODKLADOVE VRSTVY
       * vrstvy, ktore mozu byt navolene iba pojednom, cize nie viac naraz
       * nacitavaju sa vzdy ako prve a nie su transparentne
       */
      podkladove: {
        nazov: "Podkladové vrstvy",
        vrstvy: [
          {
            id: 1,
            kod_ikonky: "",
            nazov: "Ortofoto 2020 GKU",
            kod: "ortoBAkraj",
            vrstvy: ["ortoBAkraj"],
            default: true,
          },
          {
            id: 2,
            kod_ikonky: "",
            nazov: "Administratívne hranice",
            kod: "hranice_podkladove",
            vrstvy: ["hranice_podkladove"],
            default: false,
          },
          {
            id: 3,
            kod_ikonky: "",
            nazov: "ZBGIS 2021 / DMR",
            kod: "zbgis",
            vrstvy: ["zbgis", "dmr"],
            default: false,
          },
        ],
      },
      /**
       * PRIEHLADNE VRSTVY
       * vrstvy, ktorych moze byt navolenych aj viac a pridavaju sa nad podkladove
       * tieto vrstvy su transparentne aby sa mohli prekryvat
       * POZOR: dolezite je poradie vrstiev v konfigu, podla tohto poradia
       * sa potom vrstvy skladaju
       */
      priehladne: {
        nazov: "Priehľadné vrstvy",
        vrstvy: [
          {
            id: 12,
            kod_ikonky: "",
            nazov: "Názvy ciest",
            kod: "cesty-nazvy",
            vrstvy: ["cesty-nazvy"],
            default: false,
          },
          {
            id: 13,
            kod_ikonky: "",
            nazov: "Adresy",
            kod: "adresy",
            vrstvy: ["adresy"],
            default: false,
          },
          {
            id: 22,
            kod_ikonky: "",
            nazov: "Prechody - približné",
            kod: "prechody-modre",
            vrstvy: ["prechody-modre-ram"],
            default: false,
          },
          {
            id: 23,
            kod_ikonky: "",
            nazov: "Prechody - presné",
            kod: "prechody-oranzove",
            vrstvy: ["prechody-oranzove-ram"],
            default: false,
          },
          {
            id: 31,
            kod_ikonky: "",
            nazov: "Okruh MA1",
            kod: "okruhMA1",
            vrstvy: ["okruhMA1", "okruhMA1_popis"],
            default: true,
          },
          {
            id: 32,
            kod_ikonky: "",
            nazov: "Okruh MA2",
            kod: "okruhMA2",
            vrstvy: ["okruhMA2", "okruhMA2_popis"],
            default: true,
          },
          {
            id: 33,
            kod_ikonky: "",
            nazov: "Okruh MA3",
            kod: "okruhMA3",
            vrstvy: ["okruhMA3", "okruhMA3_popis"],
            default: true,
          },
          {
            id: 34,
            kod_ikonky: "",
            nazov: "Okruh PK1",
            kod: "okruhPK1",
            vrstvy: ["okruhPK1", "okruhPK1_popis"],
            default: true,
          },
          {
            id: 35,
            kod_ikonky: "",
            nazov: "Okruh PK2",
            kod: "okruhPK2",
            vrstvy: ["okruhPK2", "okruhPK2_popis"],
            default: true,
          },
          {
            id: 36,
            kod_ikonky: "",
            nazov: "Okruh PK3",
            kod: "okruhPK3",
            vrstvy: ["okruhPK3", "okruhPK3_popis"],
            default: true,
          },
          {
            id: 37,
            kod_ikonky: "",
            nazov: "Okruh PK4",
            kod: "okruhPK4",
            vrstvy: ["okruhPK4", "okruhPK4_popis"],
            default: true,
          },
          {
            id: 38,
            kod_ikonky: "",
            nazov: "Okruh PK5",
            kod: "okruhPK5",
            vrstvy: ["okruhPK5", "okruhPK5_popis"],
            default: true,
          },
          {
            id: 39,
            kod_ikonky: "",
            nazov: "Okruh SC1",
            kod: "okruhSC1",
            vrstvy: ["okruhSC1", "okruhSC1_popis"],
            default: true,
          },
          {
            id: 40,
            kod_ikonky: "",
            nazov: "Okruh SC2",
            kod: "okruhSC2",
            vrstvy: ["okruhSC2", "okruhSC2_popis"],
            default: true,
          },
          {
            id: 41,
            kod_ikonky: "",
            nazov: "Okruh SC3",
            kod: "okruhSC3",
            vrstvy: ["okruhSC3", "okruhSC3_popis"],
            default: true,
          },
          {
            id: 42,
            kod_ikonky: "",
            nazov: "Okruh SC4",
            kod: "okruhSC4",
            vrstvy: ["okruhSC4", "okruhSC4_popis"],
            default: true,
          },
          {
            id: 11,
            kod_ikonky: "",
            nazov: "Cesty",
            kod: "cesty",
            vrstvy: ["cesty"],
            default: false,
          },
          {
            id: 10,
            kod_ikonky: "",
            nazov: "Administratívne hranice",
            kod: "hranice_priehladne",
            vrstvy: ["hranice_priehladne"],
            default: true,
          },
        ],
      },
    },
    externe: {
      // ak nechceme externe vrstvy vobec povolit, tak nastavime false
      // povolitPouzitie: true,
      podkladove: {
        nazov: "Externé podkladové vrstvy",
        vrstvy: [
          {
            id: 332,
            kod_ikonky: "",
            nazov: "ZBGIS",
            kod: "zbgis",
            url: "https://zbgisws.skgeodesy.sk/zbgis_wms_featureinfo/service.svc/get?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&crs=EPSG:3857&transparent=true&width=256&height=256&styles=&layers=21,139,2,3,4,5,6,7,8,9,10,11,12,14,15,16,17,18,19,20,23,24,25,26,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,46,47,48,49,52,53,58,137,140,141,142,143,144,13,22,28,29,45,51,54,55,56,57,59,60,61,62,63,64,65,66,67,68,69,145,146,147,148,149,150,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91",
            //139,21,137,140,141,142,143,2,3,4,5,6,7,8,9,10,11,12,14,15,16,17,18,19,20,23,24,25,26,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,46,47,48,49,52,53,58,145,146,147,148,149,150,13,22,28,29,45,51,54,55,56,57,59,60,61,62,63,64,65,66,67,68,69,144,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91
            //72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,144,13,22,28,29,45,51,54,55,56,57,59,60,61,62,63,64,65,66,67,68,69,145,146,147,148,149,150,2,3,4,5,6,7,8,9,10,11,12,14,15,16,17,18,19,20,23,24,25,26,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,46,47,48,49,52,53,58,137,140,141,142,143,21,139
          },
        ],
      },
      priehladne: {
        nazov: "Externé priehľadné vrstvy",
        vrstvy: [],
      },
    },
  },
  /**
   * FARBY
   */
  farby: [
    { kod: "001", html_kod: "424242" }, // parcely
    { kod: "002", html_kod: "256326" },
    { kod: "003", html_kod: "6755cc" }, // clv
    { kod: "004", html_kod: "5a6c4f" }, // lokality
    { kod: "005", html_kod: "000088" }, // ulice
    { kod: "006", html_kod: "82acd1" }, // adresy
    { kod: "007", html_kod: "89d4d4" }, // casti
    { kod: "008", html_kod: "27a934" }, // psy
    { kod: "009", html_kod: "f4a658" }, // obyvatelia
    { kod: "010", html_kod: "e9b6da" }, // smeti
    { kod: "011", html_kod: "a69ae7" }, // majitelia
    { kod: "012", html_kod: "a55063" }, // dane
    { kod: "013", html_kod: "25bafc" }, // rozhodnutia
    { kod: "014", html_kod: "769909" }, //
    { kod: "015", html_kod: "00e1e1" }, // cesty
  ],
  /**
   * VYHLADAVANIE
   */
  typy_vyhladavani: [
    {
      id: 1,
      nazov: "Okres, obec, ulica, adresa",
      placeholder: "Zadajte názov obce, okresu, ulice alebo adresy",
      typy: ["okres", "obec", "ulice", "adresy"], // TODO: #47 pridat ulice ked pribudnu v db
      kod_ikonky: "001",
      kod_farby: "006",
    },
    {
      id: 2,
      nazov: "Cesty",
      placeholder: "Zadajte označenie cesty",
      typy: "cestytr",
      kod_ikonky: "008",
      kod_farby: "005",
    },
    {
      id: 3,
      nazov: "Geografické názvy",
      placeholder: "Zadajte geografický názov",
      typy: "gn",
      kod_ikonky: "007",
      kod_farby: "001",
    },
  ],
  /**
   * SELECTY
   */
  selecty: [
    {
      nazov: "Okruhy",
      typ: "okruhy",
      kod_ikonky: "008",
      kod_farby: "005",
    },
    {
      nazov: "Cesty",
      typ: "cesty",
      kod_ikonky: "008",
      kod_farby: "015",
    },
    {
      nazov: "Obce",
      typ: "obec",
      kod_ikonky: "009",
      kod_farby: "002",
    },
    /* {
      nazov: "Autá",
      typ: "auta",
      kod_ikonky: "006",
      kod_farby: "003",
    }, */
  ],
  /**
   * TYPY
   */
  typy: [
    {
      id: 999,
      nazov: "Point",
      kod: "point",
      kod_farby: "006",
      kod_ikonky: "",
      hrubka_ciary: 1,
      nastavenie_mapy: [
        {
          typ_vrstvy: "symbol",
          layout: {
            "icon-image": "marker",
            "icon-anchor": "bottom",
            "icon-offset": [0, 5],
            "icon-allow-overlap": true,
            "icon-size": 0.4,
            "text-field": ["get", "title"],
            "text-variable-anchor": ["top", "bottom", "left", "right"],
            "text-radial-offset": 0.5,
            "text-justify": "auto",
            "text-font": ["Open Sans Bold"],
          },
          paint: {
            "text-color": "#000000",
            "text-halo-color": "#ffffff",
            "text-halo-width": 3,
            "icon-color": "#2981ca",
            "icon-halo-color": "#ffffff",
            "icon-halo-width": 1,
          },
        },
      ],
    },
    {
      id: 1,
      nazov: "Geografické názvy",
      kod: ["gn"],
      kod_farby: "001",
      kod_ikonky: "007",
      nastavenie_mapy: [
        {
          typ_vrstvy: "symbol",
          layout: {
            "icon-image": "marker",
            "icon-anchor": "bottom",
            "icon-offset": [0, 5],
            "icon-allow-overlap": true,
            "icon-size": 0.4,
            "text-field": ["get", "title"],
            "text-variable-anchor": ["top", "bottom", "left", "right"],
            "text-radial-offset": 0.5,
            "text-justify": "auto",
            "text-font": ["Open Sans Bold"],
          },
          paint: {
            "text-color": "#000000",
            "text-halo-color": "#ffffff",
            "text-halo-width": 3,
            "icon-color": "#2981ca",
            "icon-halo-color": "#ffffff",
            "icon-halo-width": 1,
          },
        },
      ],
    },
    {
      id: 2,
      nazov: "Obec",
      kod: "obec",
      kod_farby: "002",
      kod_ikonky: "009",
      nastavenie_mapy: [
        {
          typ_vrstvy: "fill",
          paint: {
            "fill-color": "%kod_farby%",
            "fill-opacity": 0.5,
          },
        },
        {
          typ_vrstvy: "line",
          paint: {
            "line-color": `%kod_farby%`,
            "line-width": 4,
          },
        },
      ],
    },
    {
      id: 3,
      nazov: "Okres",
      kod: ["okres"],
      kod_farby: "003",
      kod_ikonky: "006",
      nastavenie_mapy: [
        {
          typ_vrstvy: "fill",
          paint: {
            "fill-color": "%kod_farby%",
            "fill-opacity": 0.5,
          },
        },
        {
          typ_vrstvy: "line",
          paint: {
            "line-color": `%kod_farby%`,
            "line-width": 4,
          },
        },
      ],
    },
    {
      id: 4,
      nazov: "Kraj",
      kod: "kraj",
      kod_farby: "004",
      kod_ikonky: "006",
      nastavenie_mapy: [
        {
          typ_vrstvy: "fill",
          paint: {
            "fill-color": "%kod_farby%",
            "fill-opacity": 0.5,
          },
        },
        {
          typ_vrstvy: "line",
          paint: {
            "line-color": `%kod_farby%`,
            "line-width": 4,
          },
        },
      ],
    },
    {
      id: 5,
      nazov: "Katastrálne územia",
      kod: "ku",
      kod_farby: "007",
      kod_ikonky: "006",
      hrubka_ciary: 1,
      nastavenie_mapy: [
        {
          typ_vrstvy: "fill",
          paint: {
            "fill-color": "%kod_farby%",
            "fill-opacity": 0.5,
          },
        },
        {
          typ_vrstvy: "line",
          paint: {
            "line-color": `%kod_farby%`,
            "line-width": 4,
          },
        },
      ],
    },
    {
      id: 6,
      nazov: "Adresy",
      kod: "adresy",
      kod_farby: "006",
      kod_ikonky: "001",
      hrubka_ciary: 1,
      nastavenie_mapy: [
        {
          typ_vrstvy: "symbol",
          layout: {
            "icon-image": "marker",
            "icon-anchor": "bottom",
            "icon-offset": [0, 5],
            "icon-allow-overlap": true,
            "icon-size": 0.4,
            "text-field": ["get", "title"],
            "text-variable-anchor": ["top", "bottom", "left", "right"],
            "text-radial-offset": 0.5,
            "text-justify": "auto",
            "text-font": ["Open Sans Bold"],
          },
          paint: {
            "text-color": "#000000",
            "text-halo-color": "#ffffff",
            "text-halo-width": 3,
            "icon-color": "#2981ca",
            "icon-halo-color": "#ffffff",
            "icon-halo-width": 1,
          },
        },
      ],
    },
    {
      id: 7,
      nazov: "Cesty",
      kod: "cesty",
      kod_farby: "015",
      kod_ikonky: "008",
      nastavenie_mapy: [
        {
          typ_vrstvy: "line",
          paint: {
            "line-color": `%kod_farby%`,
            "line-width": 4,
          },
        },
      ],
    },
    {
      id: 8,
      nazov: "SR",
      kod: "sr",
      kod_farby: "013",
      kod_ikonky: "006",
      nastavenie_mapy: [
        {
          typ_vrstvy: "fill",
          paint: {
            "fill-color": "%kod_farby%",
            "fill-opacity": 0.5,
          },
        },
        {
          typ_vrstvy: "line",
          paint: {
            "line-color": `%kod_farby%`,
            "line-width": 4,
          },
        },
      ],
    },
    {
      id: 9,
      nazov: "Okruhy",
      kod: "okruhy",
      kod_farby: "005",
      kod_ikonky: "008",
      nastavenie_mapy: [
        {
          typ_vrstvy: "line",
          layout: {
            visibility: "visible",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "yellow",
            "line-opacity": 0.7,
            "line-width": 6,
            "line-offset": 5,
          },
        },
      ],
    },
    {
      id: 10,
      nazov: "Prejazdy",
      kod: "prejazdy",
      kod_farby: "005",
      kod_ikonky: "008",
      nastavenie_mapy: [
        {
          typ_vrstvy: "symbol",
          layout: {
            "icon-image": "marker",
            "icon-anchor": "bottom",
            "icon-offset": [0, 5],
            "icon-allow-overlap": true,
            "icon-size": 0.4,
            "text-field": ["get", "title"],
            "text-variable-anchor": ["top", "bottom", "left", "right"],
            "text-radial-offset": 0.5,
            "text-justify": "auto",
            "text-font": ["Open Sans Bold"],
          },
          paint: {
            "text-color": "#000000",
            "text-halo-color": "#ffffff",
            "text-halo-width": 3,
            "icon-color": "#2981ca",
            "icon-halo-color": "#ffffff",
            "icon-halo-width": 1,
          },
        },
      ],
    },
    {
      id: 11,
      nazov: "Ulice",
      kod: "ulice",
      kod_farby: "015",
      kod_ikonky: "008",
      nastavenie_mapy: [
        {
          typ_vrstvy: "line",
          paint: {
            "line-color": `%kod_farby%`,
            "line-width": 4,
          },
        },
      ],
    },
    {
      id: 12,
      nazov: "Auto",
      kod: "auta",
      kod_farby: "006",
      kod_ikonky: "",
      hrubka_ciary: 1,
      nastavenie_mapy: [
        {
          typ_vrstvy: "symbol",
          filter: ["all", ["!has", "point_count"]],
          layout: {
            "icon-image": "car",
            "icon-anchor": "bottom",
            // "icon-offset": [10, 40],
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "icon-size": {
              stops: [
                [11, 1],
                [12, 1.5],
              ],
            },
            // "icon-size": 1.5,
            // "text-field": ["get", "identifikator"],
            "text-optional": true,
            "text-field": [
              "format",
              ["get", "identifikator"],
              {
                "font-scale": 1,
                "text-color": "#000000",
              },
              "\n",
              {},
              "Pluh: ",
              { "font-scale": 0.6 },
              ["case", ["get", "radlicaText"], "dole", "hore"],
              {
                "font-scale": 0.6,
                "text-color": [
                  "case",
                  ["get", "radlicaText"],
                  "#ff0000",
                  "#007000",
                ],
              },
              "\n",
              {},
              "Sypač: ",
              { "font-scale": 0.6 },
              ["case", ["get", "posypText"], "sype", "nesype"],
              {
                "font-scale": 0.6,
                "text-color": [
                  "case",
                  ["get", "posypText"],
                  "#ff0000",
                  "#007000",
                ],
              },
            ],
            // "text-variable-anchor": ["left"],
            "text-offset": [3.5, -0.6],
            "text-justify": "left",
            "text-font": ["Open Sans Bold"],
            "text-size": {
              stops: [
                [11, 13],
                [12, 20],
              ],
            },
            "text-allow-overlap": true,
            "text-ignore-placement": true,
          },
          paint: {
            "text-color": "#000000",
            "text-halo-color": "#ffffff",
            "text-halo-width": 1,
            "icon-color": [
              "case",
              ["all", ["get", "radlicaText"], ["get", "posypText"]],
              "#910000",
              ["get", "radlicaText"],
              "#ff0000",
              ["get", "posypText"],
              "#ffaa00",
              [">", ["get", "rychlost"], 0],
              "#e6e600",
              "#cccccc",
            ],
            "icon-halo-color": "#000000",
            "icon-halo-width": 5,
          },
        },
        {
          typ_vrstvy: "symbol",
          filter: ["all", ["has", "point_count"]],
          layout: {
            "icon-image": "car",
            "icon-anchor": "bottom",
            // "icon-offset": [10, 40],
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "icon-size": {
              stops: [
                [11, 1],
                [12, 1.5],
              ],
            },
          },
          paint: {
            "icon-color": "#cccccc",
            "icon-halo-color": "#000000",
            "icon-halo-width": 5,
          },
        },
        {
          typ_vrstvy: "symbol",
          filter: ["all", ["has", "point_count"]],
          layout: {
            // "text-field": "{point_count_abbreviated}",
            "text-field": [
              "format",
              ["get", "popis"],
              {
                "font-scale": 1,
                "text-color": "#000000",
              },
            ],
            "text-font": ["Open Sans Bold"],
            "text-size": 12,
            // "text-variable-anchor": ["left"],
            "text-justify": "left",
            // "text-radial-offset": -1,
            "text-offset": [
              "interpolate",
              ["linear"],
              ["zoom"],
              11,
              ["literal", [3.7, -1]],
              12,
              ["literal", [4, -2]],
            ],
          },
          paint: {
            "text-color": "#000000",
            "text-halo-color": "#ffffff",
            "text-halo-width": 1,
          },
        },
      ],
    },
    {
      id: 12,
      nazov: "Auto",
      kod: "autaHistoria",
      kod_farby: "006",
      kod_ikonky: "",
      hrubka_ciary: 1,
      nastavenie_mapy: [
        {
          typ_vrstvy: "line",
          layout: {
            visibility: "visible",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": [
              "case",
              ["all", ["get", "radlicaText"], ["get", "posypText"]],
              "#910000",
              ["get", "radlicaText"],
              "#ff0000",
              ["get", "posypText"],
              "#ffaa00",
              [">", ["get", "rychlost"], 0],
              "#e6e600",
              "#cccccc",
            ],
            "line-opacity": 0.7,
            "line-width": 6,
            "line-offset": {
              stops: [
                [11, 1],
                [12, 5],
              ],
            },
          },
        },
      ],
    },
    {
      id: 12,
      nazov: "Meteo",
      kod: "meteo",
      kod_farby: "006",
      kod_ikonky: "",
      hrubka_ciary: 1,
      nastavenie_mapy: [
        {
          typ_vrstvy: "symbol",
          layout: {
            "icon-offset": [-60, 45],
            "icon-allow-overlap": true,
            "text-field": [
              "format",
              ["upcase", ["get", "teplotaVzduchu"]],
              { "font-scale": 1 },
              "\n",
              {},
              ["downcase", ["get", "teplotaVozovky"]],
              { "font-scale": 1 },
            ],
            "text-variable-anchor": ["top", "bottom", "left", "right"],
            "text-radial-offset": 0.5,
            "text-justify": "auto",
            "icon-image": ["get", "icon"],
            "icon-size": {
              stops: [
                [12, 0.6],
                [15, 1],
              ],
            },
            "text-font": ["Open Sans Semibold"],
            "text-size": {
              stops: [
                [10, 15],
                [12, 20],
                [15, 30],
              ],
            },
          },
          paint: {
            "text-color": "#ff0000",
            "text-halo-color": "#ffffff",
            "text-halo-width": 2,
            "icon-color": "#2981ca",
            "icon-halo-color": "#ffffff",
            "icon-halo-width": 1,
          },
        },
      ],
    },
  ],
  /**
   * OKRUHY ciselnik
   */
  okruhy: [
    { id: 1, nazov: "Okruh MA1" },
    { id: 2, nazov: "Okruh MA2" },
    { id: 3, nazov: "Okruh MA3" },
    { id: 4, nazov: "Okruh PK1" },
    { id: 5, nazov: "Okruh PK2" },
    { id: 6, nazov: "Okruh PK3" },
    { id: 7, nazov: "Okruh PK4" },
    { id: 8, nazov: "Okruh PK5" },
    { id: 9, nazov: "Okruh SC1" },
    { id: 10, nazov: "Okruh SC2" },
    { id: 11, nazov: "Okruh SC3" },
    { id: 12, nazov: "Okruh SC4" },
  ],
};
