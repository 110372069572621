import Api from "../helpers/api";
import { errorLog } from "../helpers/error";
import obecConfig from "../configs/obec.config";

export const vratDataZDvojkliku = async (lng, lat) => {
  try {
    const response = await Api.get(`/api/v1/smartmap/click/${lat}/${lng}`);
    response.data.map((obj) => {
      if (obj.typ === "okruhy") {
        obj.nazov = obecConfig.okruhy.find((o) => o.id === obj.id).nazov;
      }
    });
    return response;
  } catch (error) {
    errorLog(error);
  }
};
