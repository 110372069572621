import Api from "../helpers/api";

export const getCsrf = async () => {
  try {
    const response = await Api.get(`/sanctum/csrf-cookie`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const login = async (login, password) => {
  let response = {
    success: false,
    message: "",
    errors: [],
  };
  await Api.post(`/api/v1/auth/login`, {
    login: login,
    password: password,
  })
    .then((res) => {
      response.success = true;
      response.message = "Úspešne prihlásený.";
      response.user = res.user;
    })
    .catch((error) => {
      if (error.response.data) {
        for (const key in error.response.data.errors) {
          error.response.data.errors[key].forEach((err) =>
            response.errors.push(err)
          );
        }
      }
    });
  return response;
};

export const logout = async () => {
  let response = {
    success: false,
    message: "",
    errors: [],
  };
  await Api.post(`/api/v1/auth/logout`)
    .then((res) => {
      response.success = true;
      response.message = "Úspešne odhlásený.";
    })
    .catch((error) => {
      if (error.response.data) {
        for (const key in error.response.data.errors) {
          error.response.data.errors[key].forEach((err) =>
            response.errors.push(err)
          );
        }
      }
    });
  return response;
};
