<script>
  import Split from "split.js";
  import Header from "./Header.svelte";
  import SidebarLeft from "./SidebarLeft.svelte";
  import Footer from "./Footer.svelte";
  import Controls from "../map/Controls.svelte";
  import Popups from "../map/Popups.svelte";
  import SideManager from "../map/SideManager.svelte";

  import { onMount } from "svelte";
  import {
    mapboxInit,
    ulozAktualneZobrazenieMapy,
    changeMapTilesSource,
    ziskajVrstvy,
    nacitajUlozenyStav,
    upravZoznamVrstiev,
  } from "../map/mapbox";
  import { mapStore } from "../../stores/map.store";

  /**
   * kedze nacitanie mapboxu chvilku trva a nacitava sa az po mountnuti tohto komponentu
   * tak si nacitanu mapu ukladam do store aby som ju mohol pouzit aj neskor (ked sa nacita mapbox)
   */
  mapStore.initMap({
    getMap: () => map,
  });

  let container;
  let map;
  let loader;

  onMount(() => {
    // treba nastavit spliter, ktory rozdeluje obrazokvku na mapu a tabulku udajov
    Split([".panel1", ".panel2"], {
      sizes: [100, 0],
      minSize: [300, 210],
      direction: "vertical",
      cursor: "row-resize",
      onDrag: function (sizes) {
        // pri upravovani velkosti treba menit aj velkost canvasu kde je mapa
        map.resize();
      },
    });

    // vytvorim mapbox
    map = mapboxInit(container);

    map.on("load", () => {
      // zavriem preloader
      loader.classList.add("loading--close");

      map.loadImage(
        `/assets/img/marker-small-sdf.png`,
        function (error, image) {
          if (error) throw error;
          map.addImage("marker", image, { pixelRatio: 1, sdf: true }); // 38x55px, shadow adds 5px (for scale eq 1)
        }
      );

      map.loadImage(
        `/assets/img/Method Draw Image (4).png`,
        function (error, image) {
          if (error) throw error;
          map.addImage("car", image, { pixelRatio: 1, sdf: true }); // 38x55px, shadow adds 5px (for scale eq 1)
        }
      );

      map.loadImage(
        `/assets/img/wind-road-icon(1).png`,
        function (error, image) {
          if (error) throw error;
          map.addImage("wind-road-icon", image, { pixelRatio: 1 }); // 38x55px, shadow adds 5px (for scale eq 1)
        }
      );
      // musim nacitat vrstvy, kedze pri inicializacii sa vrstvy nenacitaju
      // robim to kvoli tomu, ze ak mam podkladovu externu vrstvu, musim ju
      // nacitat pred internymi. A to sa da nastavit iba pri funkcii addLayer
      // a nie pri inicializacii (neda sa to v options objekte) a musi sa to nastavit
      // az po tom, ako sa nacita mapbox (prazdny)
      const { interne, externe } = ziskajVrstvy(true);
      const vrstvy = upravZoznamVrstiev({ interne, externe });
      changeMapTilesSource(map, vrstvy);

      // zobrazim ulozeny stav
      nacitajUlozenyStav(map);
    });

    map.on("moveend", (x) => {
      ulozAktualneZobrazenieMapy(map);
    });

    map.on("error", (error) => {
      console.log(error);
    });

    // document.head.appendChild(link);

    /* return () => {
      map.remove();
      // link.parentNode.removeChild(link);
    }; */
  });
</script>

{#if map}
  <Header />
{/if}
{#if map}
  <SidebarLeft />
{/if}

<!-- Content start -->
<div class="content--layout">
  <div class="splitter split">
    <div class="panel1">
      <!-- Map start  -->

      <!-- Loading start -->
      <div class="loading js-loading" bind:this={loader}>
        <span class="loading__loader">
          <span class="loading__loader-inner" />
        </span>
        <span class="loading__text">Mapa sa načítava</span>
      </div>
      <!-- Loading end  -->

      <!-- Map start  -->
      <div class="content__map" bind:this={container}>
        {#if map}
          <slot />
          <Controls />
          <Popups />
          <SideManager />
        {/if}
      </div>
      <!-- Map end  -->
    </div>
    <div class="panel2">
      {#if map}
        <Footer />
      {/if}
    </div>
  </div>
</div>

<!-- Content end -->
<style lang="scss">
  .content__map {
    width: 100%;
    height: 100%;
  }
</style>
