import { writable } from "svelte/store";
import { errorLog } from "../helpers/error";
import { lastLoggedUser as lastLoggedUserStore } from "./currentLoggedUser.store";

export const emptyUserStore = {
  id: "",
  login: "",
  fullName: "",
  email: "",
  mapa: {
    lat: "",
    lng: "",
    zoom: "",
    vrstvy: {
      interne: { podkladove: {}, priehladne: {} },
      externe: { podkladove: {}, priehladne: {} },
    },
    stav: {},
  },
};

// ziskam data z local storage
// ak ziadne nemam, ulozim tam defaultne objekt
export const getLocalStorageData = () => {
  const lastLoggedUser = lastLoggedUserStore.getLastLoggedUser();

  if (lastLoggedUser === false) {
    return false;
  }

  const storedUser = localStorage.getItem(`sm-user-${lastLoggedUser}`);

  if (storedUser) {
    return JSON.parse(storedUser);
  }

  return false;
};

// ukladam data do local storage
const updateLocalStorageData = (data) => {
  if (data) {
    try {
      localStorage.setItem(`sm-user-${data.login}`, JSON.stringify(data));
      lastLoggedUserStore.updateLastLoggedUser(data.login);
      /* localStorage.setItem(
        "sm-lastLoggedUser",
        data.login
      ); */
      return true;
    } catch (error) {
      errorLog(error);
    }
  }

  return false;
};

// svelte custom store pre uzivatelske data
function createUser() {
  const { subscribe, set, update } = writable(0);

  return {
    subscribe,
    initStoredUser: (storedUser) => {
      set(storedUser ? storedUser : emptyUserStore);
    },
    updateUser: (userData) => {
      if (updateLocalStorageData(userData)) {
        update((u) => (u = userData));
      }
    },
    getUser: () => getLocalStorageData(),
  };
}

export const userStore = createUser();
