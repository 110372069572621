import iconPrint from "./printer.svg";

/**
 * Simple zoom control
 */
export default class PrintControl {
  insertControls() {
    this.container = document.createElement("div");
    this.container.classList.add("mapboxgl-ctrl");
    this.container.classList.add("mapboxgl-ctrl-group");
    this.container.classList.add("mapboxgl-ctrl-print");
    this.zoomIn = document.createElement("button");
    this.zoomIn.setAttribute("type", "button");
    this.zoomIn.appendChild(iconPrint());
    this.container.appendChild(this.zoomIn);
  }

  onAdd(map) {
    this.map = map;
    this.insertControls();
    this.zoomIn.addEventListener("click", () => {
      map.getCanvas().toBlob(function(blob) {
        // saveAs(blob, "map.png");
        // console.log(blob);
        var a = document.createElement("a");
        document.body.append(a);
        a.download = "map.png";
        a.href = URL.createObjectURL(blob);
        a.click();
        a.remove();
      });
    });
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}
