import { tooltipData, tooltipHelp } from "../createTooltip.js";

const DirectSelectMode = MapboxDraw.modes.direct_select;

const _dragFeature = DirectSelectMode.dragFeature;
const _dragVertex = DirectSelectMode.dragVertex;

DirectSelectMode.dragFeature = function(state, e, delta) {
  const result = _dragFeature.apply(this, [state, e, delta]);

  const feature = state.feature.toGeoJSON();

  let type = "";
  switch (feature.geometry.type) {
    case "Polygon":
      type = "polygon";
      break;
    case "LineString":
      type = "line_string";
      break;
  }

  tooltipData([feature], type, true);
  tooltipHelp([feature], type);

  return result;
};

DirectSelectMode.dragVertex = function(state, e, delta) {
  const result = _dragVertex.apply(this, [state, e, delta]);

  const feature = state.feature.toGeoJSON();

  let type = "";
  switch (feature.geometry.type) {
    case "Polygon":
      type = "polygon";
      break;
    case "LineString":
      type = "line_string";
      break;
  }

  tooltipData([feature], type, true);
  tooltipHelp([feature], type);

  return result;
};

export default DirectSelectMode;
