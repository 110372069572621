<script>
  import obecConfig from "../../../configs/obec.config";
  import Ikonka from "../../UI/Icon.svelte";
  import { showHideSelect } from "../../../helpers/header";
  import SearchSelect from "../../UI/SearchSelect.svelte";
  import Diacritics from "diacritic";
  import { vratHladanyVyraz } from "../../../api/search";
  import { vratKodFarbyPodlaTypu } from "../../../helpers/config";
  import { zobrazDataNaMape } from "../../map/mapbox";
  import { mapStore } from "../../../stores/map.store";

  const map = $mapStore.mapbox.getMap();

  let vyberVyhladavaniaKod = obecConfig.typy_vyhladavani[0].kod_ikonky;

  function volbaTypuVyhladavania(event) {
    let vyhladavanieId;
    // ziskam ID typu vyhladavanie
    if (event.target.hasAttribute("vyhladavanie-id")) {
      vyhladavanieId = event.target.getAttribute("vyhladavanie-id");
    } else {
      vyhladavanieId = event.target
        .closest("li")
        .getAttribute("vyhladavanie-id");
    }
    // z konfigu si vytiahnem dane vyhladavanie
    const vyhladavanie = obecConfig.typy_vyhladavani.find(
      (v) => v.id === parseInt(vyhladavanieId)
    );

    // nahradim placeholder v inpute vyhladavanie
    document
      .getElementById("search")
      .setAttribute("placeholder", vyhladavanie.placeholder);
    document
      .getElementById("search")
      .setAttribute("vyhladavanie_id", vyhladavanie.id);
    document.getElementById("search").focus();

    // zmenim hlavicku vyberu typu vyhladavania
    const vyberVyhladavania = document.getElementById("vyberVyhladavania");
    vyberVyhladavania.className = vyberVyhladavania.className.replace(
      /color__bg--[0-9]{3}/,
      `color__bg--${vyhladavanie.kod_farby}`
    );
    vyberVyhladavaniaKod = vyhladavanie.kod_ikonky;
    vyberVyhladavania.querySelector(".select__text").innerHTML =
      vyhladavanie.nazov;
  }

  async function vyhladajVyraz(keyword) {
    const idVyhladavania = document
      .getElementById("search")
      .getAttribute("vyhladavanie_id");
    const vyhladavanie = obecConfig.typy_vyhladavani.find(
      (v) => v.id === parseInt(idVyhladavania)
    );

    const response = await vratHladanyVyraz(vyhladavanie.id, keyword);

    return response.data;
  }

  function createLabel(item) {
    /* if ( undefined === item ) {
      return false;
    } */
    return `<span class="color__text--${vratKodFarbyPodlaTypu(item.typ)}">${
      item.value
    }</span><span class="popis">${item.label}</span>`;
  }
</script>

<div class="header__header">
  <!-- Input search start -->

  <SearchSelect
    inputId="search"
    searchFunction={vyhladajVyraz}
    labelFunction={createLabel}
    inputClassName=""
    dropdownClassName="select__list autocomplete-search"
    className="input__group input__group--search"
    placeholder={obecConfig.typy_vyhladavani[0].placeholder}
    vyhladavanie_id={obecConfig.typy_vyhladavani[0].id}
    keywordsCleanFunction={(keyword) => Diacritics.clean(keyword)}
    textCleanFunction={(keyword) => Diacritics.clean(keyword)}
    beforeChange={(o, n) => zobrazDataNaMape(map, n)}
    showSearchIcon="true"
    minCharactersToSearch="2"
    autoHide="true"
  />

  <!-- Input search end -->

  <!-- Input select start -->
  <div class="select input__group vyber-vyhladavania" on:click={showHideSelect}>
    <div
      class="select__header color__bg--{obecConfig.typy_vyhladavani[0]
        .kod_farby}"
      id="vyberVyhladavania"
    >
      <div class="select__label">
        <div class="select__label-wrapper">
          <svelte:component this={Ikonka} kod={vyberVyhladavaniaKod} />
          <!-- <Ikonka kod="{obecConfig.typy_vyhladavani[0].kod_ikonky}" /> -->
        </div>
        <span class="select__text">{obecConfig.typy_vyhladavani[0].nazov}</span>
      </div>
      <Ikonka kod="003" />
    </div>

    <div class="select__body">
      <ul class="select__list">
        {#each obecConfig.typy_vyhladavani as vyhladavanie}
          <li
            vyhladavanie-id={vyhladavanie.id}
            on:click={volbaTypuVyhladavania}
          >
            <Ikonka
              kod={vyhladavanie.kod_ikonky}
              svg-color-class="color__text--{vyhladavanie.kod_farby}"
            />
            <span class="color__text--{vyhladavanie.kod_farby}"
              >{vyhladavanie.nazov}</span
            >
          </li>
        {/each}
      </ul>
    </div>
  </div>
  <!-- Input select end -->
</div>

<style>
  .header__header {
    width: 60%;
  }
  .vyber-vyhladavania {
    width: 36%;
  }

  @media only screen and (max-width: 75em) {
    .header__header,
    .vyber-vyhladavania {
      width: 100%;
    }
  }
</style>
