import Api from "../helpers/api";

export const getCsrf = async () => {
  try {
    const response = await Api.get(`/sanctum/csrf-cookie`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const editaciaPoznamky = async (data) => {
  let response = { success: false, errors: [], successes: [] };
  if (data === undefined) {
    response.errors.push("Chybné dáta.");

    return response;
  }

  if (data.id) {
    await Api.put(`/api/v1/smartmap/draw/${data.id}`, {
      name: data.name,
      public: data.public,
      layer: JSON.parse(data.layer),
      color: data.color,
      description: data.description,
    })
      .then((res) => {
        response.success = true;
        response.successes.push("Poznámka bola úspešne zmenená.");
      })
      .catch((error) => {
        response.success = false;
        if (error.response.data) {
          for (const key in error.response.data.errors) {
            error.response.data.errors[key].forEach((err) =>
              response.errors.push(err)
            );
          }
        }
      });
  } else {
    await Api.post(`/api/v1/smartmap/draw`, {
      name: data.name,
      public: data.public,
      layer: JSON.parse(data.layer),
      color: data.color,
      description: data.description,
    })
      .then((res) => {
        response.success = true;
        response.successes.push("Poznámka bola úspešne pridaná.");
      })
      .catch((error) => {
        response.success = false;
        if (error.response.data) {
          for (const key in error.response.data.errors) {
            error.response.data.errors[key].forEach((err) =>
              response.errors.push(err)
            );
          }
        }
      });
  }

  return response;
};

export const ziskajPoznamky = async (filter) => {
  let response = { success: false, errors: [], successes: [] };

  await Api.get(`/api/v1/smartmap/draw?filter[public]=${filter}`)
    .then((res) => {
      response.success = true;
      response.data = res.data;
    })
    .catch((error) => {
      if (error.response.data) {
        for (const key in error.response.data.errors) {
          error.response.data.errors[key].forEach((err) =>
            response.errors.push(err)
          );
        }
      }
    });

  return response;
};

export const zmazPoznamku = async (id) => {
  let response = { success: false, errors: [], successes: [] };

  await Api.delete(`/api/v1/smartmap/draw/${id}`)
    .then((res) => {
      response.success = true;
    })
    .catch((error) => {
      if (error.response.data) {
        for (const key in error.response.data.errors) {
          error.response.data.errors[key].forEach((err) =>
            response.errors.push(err)
          );
        }
      }
    });

  return response;
};
