// moznost pridat dalsiu ikonku do draw
import ExtendDrawBar from "./extendDrawBar/extendDrawBar";

// nacitam si custom styly pre draw
import drawStyles from "./drawStyles";

import DirectSelectMode from "./extendDrawEvents/direct_select";
import DrawLineStringMode from "./extendDrawEvents/draw_line_string";
import DrawPolygonMode from "./extendDrawEvents/draw_polygon";
import DrawRectangleMode from "./extendDrawEvents/draw_rectangle";

// vytvaram custom mod pre draw - nakreslenie obdlznika
const modes = MapboxDraw.modes;
modes.draw_rectangle = DrawRectangleMode;
modes.direct_select = DirectSelectMode;
modes.draw_line_string = DrawLineStringMode;
modes.draw_polygon = DrawPolygonMode;

// nastavenia pre draw
const Draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    line_string: true,
    polygon: true,
    trash: true
  },
  modes: modes,
  styles: drawStyles
});

// custom pridanie dalsieho buttonu do draw
const mapboxDraw = new ExtendDrawBar({
  draw: Draw,
  buttons: [
    {
      on: "click",
      action: changeModeToDrawRetangle,
      classes: ["mapbox-gl-draw_polygon2"]
    }
  ]
});

// ked kliknem na custom kreslenie obdlznika, musim zmenit funkcionalitu draw
function changeModeToDrawRetangle() {
  Draw.changeMode("draw_rectangle");
}

export default mapboxDraw;
