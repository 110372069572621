import { readable } from "svelte/store";

// potrebujem overit online status
// kazdu minutu zavolam externu URL a podla toho zistim ci som online
// defaultne mam nastavene, ze online nie som
export const onlineStatus = readable(false, function start(set) {
  // hned si zistim stav aby som nemusel cakat minutu
  ping(set);
  // kontrola kazdu minutu
  const interval = setInterval(() => {
    ping(set);
  }, 60000);

  return function stop() {
    clearInterval(interval);
  };
});

function ping(set) {
  fetch("https://ipv4.icanhazip.com")
    .then(result => {
      set(result.status === 200);
    })
    .catch(err => {
      set(false);
    });
}
