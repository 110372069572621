import obecConfig from "../configs/obec.config";
import get from "get-value";

/**
 * Vratim jednu celu vetvu z konfigu
 * @param {String} nazov Nazvov vetvy v konfigu
 */
function vratObjekt(nazov) {
  return get(obecConfig[nazov], { default: false });
}

/**
 * Vratim hladany objekt podla konkretneho kodu
 * @param {Array} poleObjektov Jedna cela vetva (pole objektov) z konfigu
 * @param {String} kod Kod objektu ktory hladam
 */
function vratObjektPodlaKodu(poleObjektov, kod) {
  if (Array.isArray(poleObjektov) === false || !kod) {
    return false;
  }

  const najdenyObjekt = poleObjektov.find((v) => {
    if (typeof v.kod === "string") {
      return v.kod === kod;
    } else if (typeof v.kod === "object") {
      return v.kod.includes(kod);
    }
  });
  if (undefined === typeof najdenyObjekt) {
    return "";
  }

  return najdenyObjekt;
}

/**
 * Vratim HTML kod konkretnej farby, ktoru hladam podla kodu
 * @param {String} kod
 */
function vratHtmlKodFarby(kod) {
  const objektFarieb = vratObjekt("farby");

  if (typeof objektFarieb !== "object" || objektFarieb === null) {
    return false;
  }

  const farba = vratObjektPodlaKodu(objektFarieb, kod);

  if (typeof farba !== "object" || farba === null) {
    return false;
  }

  return get(farba.html_kod, { default: false });
}

/**
 * Vratim konkretny typ z konfigu podla jeho kodu
 * @param {String} kod
 */
function vratTypPodlaKodu(kod) {
  const objektTypov = vratObjekt("typy");

  if (typeof objektTypov !== "object" || objektTypov === null) {
    return false;
  }
  const typ = vratObjektPodlaKodu(objektTypov, kod);

  if (typeof typ !== "object" || typ === null) {
    return false;
  }

  return typ;
}

/**
 * Vratim HTML kod konkretnej farby, ktoru hladam podla kodu konkretneho typu
 * @param {String} kod
 */
export function vratHtmlKodFarbyPodlaTypu(kod) {
  const typ = vratTypPodlaKodu(kod);

  return vratHtmlKodFarby(typ.kod_farby);
}

/**
 * Vratim kod konkretnej farby, ktoru hladam podla kodu konkretneho typu
 * @param {String} kod
 */
export function vratKodFarbyPodlaTypu(kod) {
  const typ = vratTypPodlaKodu(kod);

  return get(typ.kod_farby, { default: null });
}

/**
 * Vratim hrubku ciary, ktoru hladam podla kodu konkretneho typu
 * @param {String} kod
 */
export function vratHrubkuCiaryPodlaTypu(kod) {
  const typ = vratTypPodlaKodu(kod);

  return get(typ.hrubka_ciary, { default: null });
}

/**
 * Vratim pole vrstiev (layer) daneho typu
 * @param {String} kod
 */
export function vratVrstvyMapyPodlaTypu(kod) {
  const typ = vratTypPodlaKodu(kod);

  return get(typ.nastavenie_mapy, { default: null });
}

/**
 * Vratim kod konkretnej ikonky, ktoru hladam podla kodu konkretneho typu
 * @param {String} kod
 */
export function vratKodIkonkyPodlaTypu(kod) {
  const typ = vratTypPodlaKodu(kod);

  return get(typ.kod_ikonky, { default: null });
}
