import { writable } from "svelte/store";

function createMap() {
  const { subscribe, update, set } = writable();

  return {
    subscribe,
    update,
    set,
    initMap: map => set({ mapbox: map })
  };
}

export const mapStore = createMap();
