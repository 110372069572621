import { number_format } from "../../../helpers/functions";

import distance from "@turf/distance";
import area from "@turf/area";
import length from "@turf/length";

/**
 * Funkcia naplna tooltip pocas kreslenia
 * @param {object} feature objekt dat z mapy
 * @param {string} type typ objektu
 */
export function tooltipData(feature, type, nezobrazujAktualnuDlzku) {
  let tooltip = document.getElementById("tooltip");
  tooltip.innerHTML = "";

  if (feature && tooltip) {
    if (type === "polygon") {
      if (feature[0].geometry.coordinates.length === 0) return;
      // if (feature[0].geometry.coordinates[0].length < 4) return;
    } else if (type === "rectangle") {
      if (feature[0].geometry.coordinates.length === 0) return;
      if (feature[0].geometry.coordinates[0].length < 4) return;
    } else if (type === "line_string") {
      if (feature[0].geometry.coordinates.length < 2) return;
    }

    if (
      ["polygon", "rectangle"].includes(type) &&
      type === "polygon" &&
      feature[0].geometry.coordinates[0].length > 3
    ) {
      // vypisuje plochu kresleneho objektu
      const polygonArea = area(feature[0]);
      if (polygonArea > 0) {
        if (polygonArea > 10000) {
          // hektare
          tooltip.innerHTML += renderTooltipText(
            "Plocha",
            `${number_format(polygonArea, 2, ",", " ")} m<sup>2</sup>`,
            `${number_format(polygonArea / 10000, 2, ",", " ")} ha`
          );
        } else if (polygonArea > 100) {
          // are
          tooltip.innerHTML += renderTooltipText(
            "Plocha",
            `${number_format(polygonArea, 2, ",", " ")} m<sup>2</sup>`,
            `${number_format(polygonArea / 100, 2, ",", " ")} a`
          );
        } else {
          // m2
          tooltip.innerHTML += renderTooltipText(
            "Plocha",
            `${number_format(polygonArea, 2, ",", " ")} m<sup>2</sup>`
          );
        }
      }
    }

    // celkova dlzka nakresleneho objektu
    if (
      ["rectangle", "line_string"].includes(type) ||
      (type === "polygon" && feature[0].geometry.coordinates[0].length > 3)
    ) {
      const lineLength = length(feature[0]);
      tooltip.innerHTML += renderTooltipText(
        type === "line_string" ? "Celková dĺžka" : "Obvod",
        `${number_format(lineLength * 1000, 2, ",", " ")} m`,
        `${number_format(lineLength, 2, ",", " ")} km`
      );
    }

    if (nezobrazujAktualnuDlzku !== true) {
      if (["line_string"].includes(type)) {
        // dlzka aktualne kreslenej ciary
        const aktualna = distance(
          feature[0].geometry.coordinates[
            feature[0].geometry.coordinates.length - 2
          ],
          feature[0].geometry.coordinates[
            feature[0].geometry.coordinates.length - 1
          ]
        );
        tooltip.innerHTML += renderTooltipText(
          "Aktuálna dĺžka",
          `${number_format(aktualna * 1000, 2, ",", " ")} m`,
          `${number_format(aktualna, 2, ",", " ")} km`
        );
      }

      // dlzka aktualne kreslenej casti polygonu
      if (
        ["polygon"].includes(type) &&
        feature[0].geometry.coordinates[0].length > 2
      ) {
        const aktualna = distance(
          feature[0].geometry.coordinates[0][
            feature[0].geometry.coordinates[0].length - 3
          ],
          feature[0].geometry.coordinates[0][
            feature[0].geometry.coordinates[0].length - 2
          ]
        );
        tooltip.innerHTML += renderTooltipText(
          "Aktuálna dĺžka",
          `${number_format(aktualna * 1000, 2, ",", " ")} m`,
          `${number_format(aktualna, 2, ",", " ")} km`
        );
      }

      // ked kreslim obdlznik, zobrazuje sirku a vysku
      if (["rectangle"].includes(type)) {
        const sirka = distance(
          feature[0].geometry.coordinates[0][0],
          feature[0].geometry.coordinates[0][1]
        );
        const vyska = distance(
          feature[0].geometry.coordinates[0][1],
          feature[0].geometry.coordinates[0][2]
        );
        tooltip.innerHTML += renderTooltipText(
          "Šírka",
          `${number_format(sirka * 1000, 2, ",", " ")} m`,
          `${number_format(sirka, 2, ",", " ")} km`
        );
        tooltip.innerHTML += renderTooltipText(
          "Výška",
          `${number_format(vyska * 1000, 2, ",", " ")} m`,
          `${number_format(vyska, 2, ",", " ")} km`
        );
      }
    }
  } else {
    tooltip.innerHTML = "";
  }
}

export function tooltipHelp(feature, type) {
  let tooltip = document.getElementById("tooltip");
  let text = "";
  let init = false;
  // console.log(feature[0].geometry.coordinates);
  if (type === "polygon") {
    if (feature[0].geometry.coordinates.length === 0) init = true;
    if (feature[0].geometry.coordinates[0].length <= 2) init = true;
  } else if (type === "rectangle") {
    if (feature[0].geometry.coordinates.length === 0) init = true;
    if (feature[0].geometry.coordinates[0].length <= 1) init = true;
  } else if (type === "line_string") {
    if (feature[0].geometry.coordinates.length < 2) init = true;
  }
  if (init) {
    text += renderTooltipHelper("Kliknite na počiatočný bod.");
  } else {
    if (["line_string"].includes(type)) {
      if (feature[0].geometry.coordinates.length < 3) {
        text += renderTooltipHelper("Kliknite na nasledujúci bod");
      } else {
        text += renderTooltipHelper(
          "Kliknite na nasledujúci bod, alebo kliknite na posledný bod a ukončíte kreslenie."
        );
      }
    }
    if (["rectangle"].includes(type)) {
      text += renderTooltipHelper(
        "Kliknite na koncový bod a ukončíte kreslenie."
      );
    }
    if (["polygon"].includes(type)) {
      if (feature[0].geometry.coordinates[0].length <= 4) {
        text += renderTooltipHelper("Kliknite na nasledujúci bod");
      } else {
        text += renderTooltipHelper(
          "Kliknite na nasledujúci bod, alebo kliknite na posledný bod a ukončíte kreslenie."
        );
      }
    }
  }

  const { tooltipTop, tooltipLeft } = zistiPoziciuTooltipu();
  tooltip.style.top = `${tooltipTop}px`;
  tooltip.style.left = `${tooltipLeft}px`;
  tooltip.innerHTML += text;
}

function ziskajSirkuSidebaruVyskuHlavicky() {
  const headerHeight = document.querySelectorAll("header")[0].offsetHeight;
  const sidebarWidth =
    document.querySelectorAll(".sidebar__nav")[0].offsetWidth;

  return { headerHeight, sidebarWidth };
}

function renderTooltipText(text1, text2, text3) {
  const showText3 = text3 ? ` (${text3})` : "";
  return `<div>${text1}: <strong>${text2}</strong>${showText3}</div>`;
}

function renderTooltipHelper(text) {
  return `<div>${text}</div>`;
}

function zistiPoziciuTooltipu() {
  let tooltip = document.getElementById("tooltip");
  const tooltipHeight = tooltip.offsetHeight;
  const tooltipWidth = tooltip.offsetWidth;

  // zistim si vysku headeru a sirku sidebaru aby som tooltip zobrazil priamo pri myske
  const { headerHeight, sidebarWidth } = ziskajSirkuSidebaruVyskuHlavicky();
  let tooltipTop = window.event.clientY - headerHeight;
  let tooltipLeft = window.event.clientX - sidebarWidth;

  if (tooltipTop + tooltipHeight + 100 > window.innerHeight) {
    tooltipTop -= tooltipHeight;
  }

  if (tooltipLeft + tooltipWidth + 100 > window.innerWidth) {
    tooltipLeft -= tooltipWidth;
  }
  return { tooltipTop, tooltipLeft };
}
