import Api from "../helpers/api";

export const vratGeojsonData = async (typ, id) => {
  if (!typ || !id) {
    return false;
  }
  try {
    const response = await Api.get(`/api/v1/smartmap/geojson/${typ}/${id}`);
    return response.data || false;
  } catch (error) {
    console.error(error);
  }
};
