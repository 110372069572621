<script>
  import { userStore } from "../../stores/user.store.js";
  import { mapStore } from "../../stores/map.store";
  import { object_filter } from "../../helpers/functions";
  import get from "get-value";
  import {
    vratKodFarbyPodlaTypu,
    vratVrstvyMapyPodlaTypu,
  } from "../../helpers/config";
  import { pointTo } from "../../components/map/mapbox";
  import { nastavPremenneZDatZdroja } from "../../helpers/map";

  const map = $mapStore.mapbox.getMap();
  let data = [];

  map.on("load", () => {
    // potrebujem sledovat zmenu ulozenych dat uzivatela
    // ked vlozim vrstvu, ulozi sa to do pamate a to zachytim
    // a potom viem pracovat s navigacnym okienkom
    let unsubscribeUserData = userStore.subscribe((currentValue) => {
      //currentValue == $comparedProducts
      run(currentValue);
    });
  });

  function run(e) {
    // ak vobec mam v pamati vetvu dat aktualneho stavu
    if (e.mapa.stav !== undefined) {
      // nechcem tu zobrazit merania ani auta, ktore su ako samostatne vrstvy
      data = object_filter(e.mapa.stav, (vrstva) =>
        ["meranie", "auta"].includes(vrstva.typ_vrstvy)
      );
    }
  }

  /**
   * Odstranim vrstvu z mapy aj z pamate a tym padom aj z navigacneho okienka
   * @param source_id
   */
  function odstranVrstvuZMapy(source_id) {
    // prechadzam vsetky layery, nie sources, lebo najprv treba vymazat layer
    map.getStyle().layers.forEach(function (layer) {
      // ak ma layer spravny source, tak ho vymazem
      if (source_id === layer.source) {
        map.removeLayer(layer.id);
      }
    });

    // ak existuje source ktory mam vymazat
    if (map.getSource(source_id)) {
      map.removeSource(source_id);

      // zmazem informaciu aj z pamate stavu uzivatela
      const u = userStore.getUser();
      let stav = get(u.mapa.stav);

      if (get(stav[source_id]) !== undefined) {
        if (get(stav[source_id].animacia) !== undefined) {
          window.clearInterval(stav[source_id].animacia.timer);
        }
        delete stav[source_id];
      }

      userStore.updateUser(u);
    }
  }

  function spustiAnimaciu(source_id, layerData) {
    const u = userStore.getUser();
    let stav = get(u.mapa.stav);

    if (get(stav[source_id]) !== undefined) {
      stav[source_id].animacia.isPlaying = true;
    }

    userStore.updateUser(u);
    const coordinates =
      stav[source_id].animacia.tempSourceData.features[0].geometry.coordinates;
    let i;
    if (stav[source_id].animacia.timer === null) {
      const { typ, id, hover } = nastavPremenneZDatZdroja(layerData.data);
      const vrstvy = vratVrstvyMapyPodlaTypu(typ);
      pointTo(map, layerData.sourceData, vrstvy[0].typ_vrstvy);

      layerData.sourceData.features[0].geometry.coordinates = [coordinates[0]];
      i = 0;
    } else {
      i = stav[source_id].animacia.progress;
      layerData.sourceData.features[0].geometry.coordinates.splice(
        i,
        coordinates.length
      );
    }

    stav[source_id].animacia.timer = setInterval(() => {
      if (i < coordinates.length) {
        layerData.sourceData.features[0].geometry.coordinates.push(
          coordinates[i]
        );
        if (map.getSource(source_id)) {
          map.getSource(source_id).setData(layerData.sourceData);
        }
        i++;
        stav[source_id].animacia.progress = i;
        userStore.updateUser(u);
      } else {
        window.clearInterval(stav[source_id].animacia.timer);
        stav[source_id].animacia.progress = 0;
        userStore.updateUser(u);
      }
    }, 20);
  }
  function pozastavAnimaciu(source_id, layerData) {
    const u = userStore.getUser();
    let stav = get(u.mapa.stav);

    if (get(stav[source_id]) !== undefined) {
      stav[source_id].animacia.isPlaying = false;
      window.clearInterval(stav[source_id].animacia.timer);
    }

    userStore.updateUser(u);
  }
  function vypniAnimaciu(source_id, layerData) {
    const u = userStore.getUser();
    let stav = get(u.mapa.stav);

    if (get(stav[source_id]) !== undefined) {
      stav[source_id].animacia.isPlaying = false;
      stav[source_id].animacia.progress = 0;
      layerData.sourceData.features[0].geometry.coordinates =
        stav[
          source_id
        ].animacia.tempSourceData.features[0].geometry.coordinates;
      map.getSource(source_id).setData(layerData.sourceData);
      window.clearInterval(stav[source_id].animacia.timer);
      stav[source_id].animacia.timer = null;
    }

    userStore.updateUser(u);
  }
</script>

{#if data && Object.keys(data).length}
  <!-- Side manager start  -->
  <div class="side-manager">
    <ul>
      {#each Object.entries(data) as [source_id, layer]}
        <li>
          <span on:click={pointTo(map, layer.sourceData, layer.typ_vrstvy)}>
            <!-- JSON icon location start -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              viewBox="0 0 370.854 511.999"
              fill="currentColor"
            >
              <g transform="translate(-70.573)">
                <g>
                  <path
                    d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173,321.035a16.7,16.7,0,0,0,24.846,0c7.065-7.868,173-194.147,173-321.035C441.425,83.182,358.244,0,256,0Zm0,278.719a93.292,93.292,0,1,1,93.291-93.292A93.4,93.4,0,0,1,256,278.719Z"
                  />
                </g>
              </g>
            </svg>
            <!-- JSON icon location end -->
          </span>

          <span
            on:click={pointTo(map, layer.sourceData, layer.typ_vrstvy)}
            class="color__text--{vratKodFarbyPodlaTypu(
              layer.data.gistyp || layer.data.typ
            )}">{layer.data.nazov || layer.data.value}</span
          >

          {#if layer.data.gistyp === "okruhy" || layer.data.typ === "okruhy"}
            {#if layer.animacia.isPlaying === true}
              <span on:click={pozastavAnimaciu(source_id, layer)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="icon"
                  style="enable-background:new 0 0 512 512;"
                >
                  <path
                    d="M256,0C114.617,0,0,114.615,0,256s114.617,256,256,256s256-114.615,256-256S397.383,0,256,0z M224,320
	c0,8.836-7.164,16-16,16h-32c-8.836,0-16-7.164-16-16V192c0-8.836,7.164-16,16-16h32c8.836,0,16,7.164,16,16V320z M352,320
	c0,8.836-7.164,16-16,16h-32c-8.836,0-16-7.164-16-16V192c0-8.836,7.164-16,16-16h32c8.836,0,16,7.164,16,16V320z"
                  />
                </svg>
              </span>
            {:else}
              <span on:click={spustiAnimaciu(source_id, layer)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="icon"
                  style="enable-background:new 0 0 512 512;"
                >
                  <path
                    d="M256,0C114.617,0,0,114.615,0,256s114.617,256,256,256s256-114.615,256-256S397.383,0,256,0z M344.48,269.57l-128,80
	c-2.59,1.617-5.535,2.43-8.48,2.43c-2.668,0-5.34-0.664-7.758-2.008C195.156,347.172,192,341.82,192,336V176
	c0-5.82,3.156-11.172,8.242-13.992c5.086-2.836,11.305-2.664,16.238,0.422l128,80c4.676,2.93,7.52,8.055,7.52,13.57
	S349.156,266.641,344.48,269.57z"
                  />
                </svg>
              </span>
            {/if}

            <span on:click={vypniAnimaciu(source_id, layer)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="icon"
                style="enable-background:new 0 0 512 512;"
              >
                <path
                  d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M336,320
	c0,8.837-7.163,16-16,16H192c-8.837,0-16-7.163-16-16V192c0-8.837,7.163-16,16-16h128c8.837,0,16,7.163,16,16V320z"
                />
              </svg>
            </span>
          {/if}

          <span on:click={odstranVrstvuZMapy(source_id)}>
            <!-- JSON icon Close start -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              viewBox="0 0 329.27 329.263"
            >
              <g transform="translate(0 -0.135)">
                <path
                  d="M21.34,329.4A21.332,21.332,0,0,1,6.258,292.984L292.848,6.39a21.329,21.329,0,0,1,30.164,30.164L36.422,323.148A21.231,21.231,0,0,1,21.34,329.4Zm0,0"
                  fill="#ff8d8d"
                />
                <path
                  d="M307.93,329.4a21.273,21.273,0,0,1-15.082-6.25L6.258,36.554A21.329,21.329,0,0,1,36.422,6.39l286.59,286.594A21.332,21.332,0,0,1,307.93,329.4Zm0,0"
                  fill="#ff8d8d"
                />
              </g>
            </svg>
            <!-- JSON icon Close end -->
          </span>
        </li>
      {/each}
    </ul>
  </div>
  <!-- Side manager end  -->
{/if}
