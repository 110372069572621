// ako prve si nacitam data z local storage pre uzivatela
import { getLocalStorageData } from "./stores/user.store";
import { getLoggedUser } from "./stores/currentLoggedUser.store";
import App from "./App.svelte";

const app = new App({
  target: document.body,
  props: {
    storedUser: getLocalStorageData(), // data poslem do hlavneho komponentu
    storedLastLoggedUser: getLoggedUser(),
  },
});

export default app;
