<script>
  import Ikonka from "../UI/Icon.svelte";
  import Search from "./Header/Search.svelte";
  import Selects from "./Header/Selects.svelte";

  let isHeaderOpen = false;

  function showHeaderMenu(event) {
    isHeaderOpen = !isHeaderOpen;

    // zavriem vsetky selecty
    document.querySelectorAll("header.header .select").forEach((element) => {
      element.classList.remove("js-select--open");
    });
  }
</script>

<!-- ///////////////////////////-->
<!-- header start -->
<!-- //////////////////////////-->
<header class="header" class:js-header--open={isHeaderOpen}>
  <div class="header__wrapper">
    <Search />
    <Selects />
  </div>

  <!-- Hamburger icon start -->
  <div class="header__menu" on:click={showHeaderMenu}>
    <Ikonka kod="011" />
  </div>
  <!-- Hamburger icon end -->
</header>
<!-- ///////////////////////////-->
<!-- header end -->
<!-- //////////////////////////-->
