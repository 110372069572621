<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();
  import { marked } from "marked";
  import Ikonka from "../../UI/Icon.svelte";
  export let open;
  let text;
  let xhr = new XMLHttpRequest();
  xhr.onload = function () {
    text = marked(this.responseText);
  };
  xhr.open("GET", "CHANGELOG.md");
  xhr.send();
</script>

<!-- Tab description start -->
<div
  class="sidebar__tab sidebar__tab--description"
  data-tab="1"
  class:js-tab--open={open}
>
  <div
    class="sidebar__close"
    on:click|preventDefault={() => dispatch("toggleSidebarTabChangelog")}
  >
    <Ikonka kod="012" />
  </div>
  <div class="sidebar__wrapper">
    <h6 class="sidebar__heading">Changelog</h6>
    <p>{@html text}</p>
  </div>
</div>
<!-- Tab description end -->
