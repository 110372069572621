<script>
  import obecConfig from "../../../configs/obec.config";
  import Ikonka from "../../UI/Icon.svelte";
  import { showHideSelect, onDocumentClick } from "../../../helpers/header";
  import SearchSelect from "../../UI/SearchSelect.svelte";
  import { vratZoznamSelectu } from "../../../api/select";
  import Diacritics from "diacritic";
  import { zobrazDataNaMape } from "../../map/mapbox";
  import { mapStore } from "../../../stores/map.store";

  const map = $mapStore.mapbox.getMap();
</script>

<div class="header__body">
  {#each obecConfig.selecty as select, i}
    <!-- Input select start -->
    <div
      class="select input__group"
      style="z-index: {15 - i};"
      on:click={showHideSelect}
    >
      <div class="select__header color__bg--{select.kod_farby}">
        <div class="select__label">
          <div class="select__label-wrapper">
            <Ikonka kod={select.kod_ikonky} />
          </div>
          <span class="select__text">{select.nazov}</span>
        </div>

        <Ikonka kod="003" />
      </div>
      <div class="select__body">
        <SearchSelect
          searchFunction={vratZoznamSelectu}
          searchFunctionOnce="true"
          labelFieldName="nazov"
          inputClassName="select__search"
          dropdownClassName="select__list"
          placeholder="Hľadať"
          {select}
          keywordsCleanFunction={(keyword) => Diacritics.clean(keyword)}
          textCleanFunction={(keyword) => Diacritics.clean(keyword)}
          beforeChange={(o, n) => zobrazDataNaMape(map, n)}
          runOnInit="true"
        />
      </div>
    </div>
    <!-- Input select end -->
  {/each}
</div>

<svelte:window on:click={onDocumentClick} />

<style>
  .header__body {
    width: 40%;
  }
  @media only screen and (max-width: 75em) {
    .header__body {
      width: 100%;
    }
  }
</style>
