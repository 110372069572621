<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();
  import Ikonka from "../../UI/Icon.svelte";
  export let open;
</script>

<!-- Tab description start -->
<div
  class="sidebar__tab sidebar__tab--description"
  data-tab="1"
  class:js-tab--open={open}
>
  <div
    class="sidebar__close"
    on:click|preventDefault={() =>
      dispatch("toggleSidebarTabSystemovdInformacie")}
  >
    <Ikonka kod="012" />
  </div>
  <div class="sidebar__wrapper">
    <h6 class="sidebar__heading">Informácie o systéme</h6>
    <p>Smartmap - systém pre komplexnú integráciu údajov</p>
    <p>
      Neváhajte sa na nás obrátiť s akýmikoľvek otázkami, komentármi alebo
      postrehmi...
    </p>
    <p>
      Email:<br /><strong
        ><a href="mailto:info@smartmap.sk">info@smartmap.sk</a></strong
      >
    </p>
    <p>Obchodné oddelenie:<br /><strong>+421 2 5930 7013</strong></p>
    <p>Zákaznícka linka:<br /><strong>+421 2 5930 7011</strong></p>
    <p class="text-small">
      Smartmap v 4.0<br />
      Adresné body LoRAB®, Cestno-uličný systém, DEM ©2022 ErasData-Pro s.r.o.<br
      />
      Ortofotomozaika, ZBGIS ©2022 GKÚ Bratislava
    </p>
  </div>
</div>

<!-- Tab description end -->
<style>
  .text-small {
    font-size: 80%;
  }
</style>
