<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { editaciaUzivatela, getCsrf } from "../../../api/user";
  import { userStore } from "../../../stores/user.store.js";
  const dispatch = createEventDispatcher();
  import Ikonka from "../../UI/Icon.svelte";
  export let open;
  const u = userStore.getUser();
  let prihlasovacie_meno = u.login;
  let id = u.id;
  let nove_heslo, nove_heslo_kontrola, stare_heslo;
  let tlacitkoOdoslat = "Uložiť";
  let messages = { errors: [], successes: [] };
  let form;

  async function upravUdaje(e) {
    tlacitkoOdoslat = "Ukladanie .";
    messages = { errors: [], successes: [] };
    let count = 0;
    let interval = setInterval(() => {
      if (count % 3 === 0) {
        count = 1;
      } else {
        count++;
      }
      tlacitkoOdoslat = "Ukladanie " + ".".repeat(count);
    }, 300);
    const csrfResponse = await getCsrf();

    const formData = new FormData(form);
    const data = {};
    for (let field of formData) {
      const [key, value] = field;
      data[key] = value;
    }
    data.id = id;

    const loginResponse = await editaciaUzivatela(data);
    messages.errors = loginResponse.errors;
    messages.successes = loginResponse.successes;
    clearInterval(interval);
    tlacitkoOdoslat = "Uložiť";
  }
</script>

<!-- Tab map notes start -->
<div
  class="sidebar__tab sidebar__tab--map"
  data-tab="4"
  class:js-tab--open={open}
>
  <div
    class="sidebar__close"
    on:click|preventDefault={() => dispatch("toggleSidebarTabNastavenieUctu")}
  >
    <Ikonka kod="012" />
  </div>
  <div class="sidebar__wrapper">
    <h6 class="sidebar__heading">Nastavenia účtu</h6>
    <form on:submit|preventDefault={upravUdaje} bind:this={form}>
      <!-- Input start -->
      <div>
        <label class="input__label" for="login">
          Prihlasovacie meno
          <span class="input__label--note"> *Nie je možné meniť </span>
        </label>
        <input
          type="text"
          id="login"
          class="input input-margin"
          readonly
          disabled
          name="login"
          bind:value={prihlasovacie_meno}
        />
      </div>
      <!-- Input end -->

      <!-- Input start -->
      <div>
        <label class="input__label" for="pass-1">
          Nové heslo
          <span class="input__label--note">
            *Vyplniť iba ak chcete zmeniť heslo
          </span>
        </label>
        <input
          type="password"
          id="pass-1"
          class="input input-margin"
          placeholder="Min. 8 znakov"
          name="nove_heslo"
          bind:value={nove_heslo}
        />
      </div>
      <!-- Input end -->

      <!-- Input start -->
      <div>
        <label class="input__label" for="pass-2">
          Zopakovať nové heslo
          <span class="input__label--note">
            *Vyplniť iba ak chcete zmeniť heslo
          </span>
        </label>
        <input
          type="password"
          id="pass-2"
          class="input input-margin"
          placeholder="Min. 8 znakov"
          name="nove_heslo_kontrola"
          bind:value={nove_heslo_kontrola}
        />
      </div>
      <!-- Input end -->

      <!-- Input start -->
      <div>
        <label class="input__label" for="pass-0">
          Aktuálne heslo
          <span class="input__label--note">
            *Vyplniť iba ak chcete zmeniť heslo
          </span>
        </label>
        <input
          type="password"
          id="pass-0"
          class="input input-margin"
          placeholder="Zadajte aktuálne heslo"
          name="stare_heslo"
          bind:value={stare_heslo}
        />
      </div>
      <!-- Input end -->

      <div id="response">
        {#if messages.errors || messages.successes}
          {#each messages.errors as error}
            <div class="error">{error}</div>
          {/each}
          {#each messages.successes as success}
            <div class="success">{success}</div>
          {/each}
        {/if}
      </div>
      <input type="submit" class="btn btn--block" value={tlacitkoOdoslat} />
    </form>
  </div>
</div>

<!-- Tab map notes end -->
<style lang="scss">
  #response {
    margin-bottom: 20px;
    text-align: center;
    font-size: 17px;
    .error {
      color: #c24141;
    }
    .success {
      color: #008000;
    }
  }
</style>
