<script>
  import { createEventDispatcher, onMount } from "svelte";
  import obecConfig from "../../../configs/obec.config";
  import {
    vratHtmlKodFarbyPodlaTypu,
    vratVrstvyMapyPodlaTypu,
  } from "../../../helpers/config";
  const dispatch = createEventDispatcher();
  import Ikonka from "../../UI/Icon.svelte";
  export let open;
  import { mapStore } from "../../../stores/map.store";
  const map = $mapStore.mapbox.getMap();
  import { vratZoznamStanic } from "../../../api/pocasie";
  import { userStore } from "../../../stores/user.store";

  let zoznamStanic = [];
  let interval = null;
  const source_id = "meteo";

  map.on("load", async () => {
    // nacitam si z api zoznam aut aj s polohami
    const stanice = await vratZoznamStanic();
    zoznamStanic = stanice;
    // kazde auto prejdem a nastavim mu defaultne hodnoty
    // ktroe sa pouzivaju pre zobrazenie tlacitok v lavom menu
    zoznamStanic.forEach((stanica) => {
      stanica.checked = false;
      stanica.icons = false;
    });

    pridajVrstvuStanic();
    nastavViditelnostStanic();
  });

  /**
   * Vrati objekt pre zdroj na mape
   * @return {object} Data pre zdroj
   */
  function vytvorDataPreZdroj() {
    let features = [];
    zoznamStanic.forEach((stanica) => {
      features.push({
        type: "Feature",
        properties: {
          id: stanica.station_id,
          gistyp: "meteo",
          identifikator: stanica.station_id,
          teplotaVzduchu: `${stanica.air_t} °C`,
          teplotaVozovky: `${stanica.road_t} °C`,
          icon: "wind-road-icon",
          popup: `<strong>${stanica.station_name}</strong><br/>
                  Teplota vzduchu: ${stanica.air_t} °C<br/>
                  Teplota vozovky: ${stanica.road_t} °C<br/>
                  Vlhkosť: ${stanica.air_rh}%`,
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(stanica.gps_lon),
            parseFloat(stanica.gps_lat),
          ],
        },
      });
    });

    return {
      type: "FeatureCollection",
      features: features,
    };
  }

  /**
   * Zobrazenie auta na mape
   * @param {object}  e       event
   * @param {number } autoId  ID auta
   */
  function nastavViditelnostStanice(e, stanicaId) {
    const stanica = zoznamStanic.find((a) => a.station_id === stanicaId);
    const checked = e.target.checked ? true : false;

    stanica.icons = checked;
    stanica.checked = checked;

    ulozZapnuteStanice();
    pridajVrstvuStanic();
    nastavViditelnostStanic();
  }

  function nastavViditelnostStanic() {
    const u = userStore.getUser();
    let hiddenFilters = ["any"];
    if (!u.mapa.stanice) {
      u.mapa.stanice = [];
    }

    u.mapa.stanice.forEach((stanicaId) => {
      hiddenFilters.push(["==", ["get", "id"], stanicaId]);
      zoznamStanic.find((a) => a.station_id === stanicaId).checked = true;
      zoznamStanic.find((a) => a.station_id === stanicaId).icons = true;
    });

    map.setFilter(`${source_id}_0`, hiddenFilters);

    // vypnem aktualizaciu aut, pretoze interval si pamata udaje
    // cize musim aktualizaciu vypbut a nanovo zapnut
    window.clearInterval(interval);
    interval = null;

    // ak aktualizacia nie je zapnuta, tak ju zapnem
    if (interval === null) {
      zapniAktualizaciu();
    }
  }

  function ulozZapnuteStanice() {
    const u = userStore.getUser();
    u.mapa.stanice = zoznamStanic
      .filter((stanica) => stanica.checked === true)
      .map((stanica) => stanica.station_id);

    userStore.updateUser(u);
  }

  function pridajVrstvuStanic() {
    const sourceData = vytvorDataPreZdroj();
    const typ = "meteo";

    if (map.getSource(source_id)) {
      map.getSource(source_id).setData(sourceData);
    } else {
      map.addSource(source_id, {
        type: "geojson",
        data: sourceData,
        generateId: true,
        maxzoom: obecConfig.layers.maxzoom,
      });

      // z konfigu si vytiahnem data o vrstve daneho typu
      // tych vrstiev moze byt viac. Napr lokalita ma oramovanie a vyfarbenie
      const vrstvy = vratVrstvyMapyPodlaTypu("meteo");
      // idem po jednej vrstve a samostatne jej nastavim layer
      vrstvy.forEach((vrstva, i) => {
        // vrstva moze mat nastavenia pre paint aj pre layout. Je to podla rozneho typu vrstvy
        for (const v in vrstva.paint) {
          if (typeof vrstva.paint[v] === "string") {
            vrstvy[i].paint[v] = vrstvy[i].paint[v].replace(
              /\%kod_farby\%/,
              `#${vratHtmlKodFarbyPodlaTypu(typ)}`
            );
          }
        }

        for (const v in vrstva.layout) {
          if (typeof vrstva.layout[v] === "string") {
            vrstvy[i].layout[v] = vrstvy[i].layout[v].replace(
              /\%kod_farby\%/,
              `#${vratHtmlKodFarbyPodlaTypu(typ)}`
            );
          }
        }

        // ak nemam v konfigu vetvu pre layout, tak ju vytvorim
        if (vrstva.layout === undefined) {
          vrstva.layout = {};
        }

        // defualtne nastavim visibility pre vsetky vrstvy
        // toto sa pouziva pri hoveri na dvojkliku
        vrstva.layout.visibility = "visible";

        const typ_vrstvy = vrstva.typ_vrstvy;
        // vytvorim layer pre danu vrstvu
        const l = map.addLayer({
          id: `${source_id}_${i}`,
          type: typ_vrstvy,
          source: source_id,
          paint: vrstva.paint || {},
          layout: vrstva.layout || {},
        });
      });
    }

    // Create a popup, but don't add it to the map yet.
    const popup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: true,
      offset: 0,
    });

    map.on("mouseenter", `${source_id}_0`, (e) => {
      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = "pointer";

      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.popup;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup.setLngLat(coordinates).setHTML(description).addTo(map);
    });

    map.on("mouseleave", `${source_id}_0`, () => {
      map.getCanvas().style.cursor = "";
      popup.remove();
    });
  }

  /**
   * Aktualizacia dat auta
   */
  async function aktualizacia() {
    // nacitam nove udaje z api
    const stanice = await vratZoznamStanic();

    // prechadzam kazde auto
    stanice.forEach((stanica, index) => {
      zoznamStanic[index].air_t = stanica.air_t;
      zoznamStanic[index].road_t = stanica.road_t;
      zoznamStanic[index].air_rh = stanica.air_rh;

      pridajVrstvuStanic();
      nastavViditelnostStanic();
    });
  }

  /**
   * Zapnutie aktualizacie aut
   */
  function zapniAktualizaciu() {
    const u = userStore.getUser();
    // ak mam zapnute nejake auto, tak zapinam aj aktualizaciu
    if (u.mapa.stanice && u.mapa.stanice.length) {
      interval = setInterval(aktualizacia, 60000);
    }
  }

  /**
   * Nazoomovanie na konkretne auto
   * @param {number}  autoId  ID auta
   */
  function doPointTo(stanicaId) {
    // const source_id = `auto_${autoId}`;
    // pointTo(map, map.getSource(source_id)._data);
    const stanica = zoznamStanic.find((a) => a.station_id === stanicaId);
    map.flyTo({
      center: [stanica.gps_lon, stanica.gps_lat],
      zoom: obecConfig.map.maxZoom,
    });
  }

  /**
   * Nacentrovanie na konkretne auto na aktualnom zoome
   * @param {number}  autoId  ID auta
   */
  function doCenter(stanicaId) {
    const stanica = zoznamStanic.find((a) => a.station_id === stanicaId);
    map.easeTo({
      center: [stanica.gps_lon, stanica.gps_lat],
    });
  }
</script>

<!-- Tab map notes start -->
<div
  class="sidebar__tab sidebar__tab--map"
  data-tab="3"
  class:js-tab--open={open}
>
  <div
    class="sidebar__close"
    on:click|preventDefault={() => dispatch("toggleSidebarTabPocasie")}
  >
    <Ikonka kod="012" />
  </div>
  <div class="sidebar__wrapper">
    <h6 class="sidebar__heading">Počasie</h6>
    <div class="stanice">
      {#each zoznamStanic as stanica}
        <div class="input-group">
          <!-- Toggle start -->
          <div class="toggle">
            <input
              type="checkbox"
              id="stanica{stanica.station_id}"
              on:change={(e) => nastavViditelnostStanice(e, stanica.station_id)}
              value={stanica.station_id}
              bind:checked={stanica.checked}
            />
            <label class="toggle__icon" for="stanica{stanica.station_id}" />
            <label class="toggle__text" for="stanica{stanica.station_id}"
              >{stanica.station_name}</label
            >
            <span class="tlacitka" class:show={stanica.icons}>
              <span on:click={() => doPointTo(stanica.station_id)}>
                <!-- JSON icon location start -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon"
                  viewBox="0 0 370.854 511.999"
                  fill="currentColor"
                >
                  <g transform="translate(-70.573)">
                    <g>
                      <path
                        d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173,321.035a16.7,16.7,0,0,0,24.846,0c7.065-7.868,173-194.147,173-321.035C441.425,83.182,358.244,0,256,0Zm0,278.719a93.292,93.292,0,1,1,93.291-93.292A93.4,93.4,0,0,1,256,278.719Z"
                      />
                    </g>
                  </g>
                </svg>
                <!-- JSON icon location end -->
              </span>
              <span on:click={() => doCenter(stanica.station_id)}>
                <!-- JSON icon location start -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 17.999 18.005"
                  class="icon"
                >
                  <path
                    d="M1,18.554,5.976,13.68A7.045,7.045,0,0,1,4.343,9.172,7.26,7.26,0,0,1,11.672,2,7.259,7.259,0,0,1,19,9.172a7.259,7.259,0,0,1-7.328,7.172,7.4,7.4,0,0,1-4.158-1.269l-5.032,4.93ZM6.438,9.187a5.252,5.252,0,0,0,10.5,0,5.252,5.252,0,0,0-10.5,0Z"
                    transform="translate(-1.001 -2)"
                    fill="currentColor"
                  />
                </svg>
                <!-- JSON icon location end -->
              </span>
            </span>
          </div>
          <!-- Toggle end -->
        </div>
      {/each}
    </div>
  </div>
</div>

<!-- Tab map notes end -->
<style lang="scss">
  .tlacitka {
    margin-left: auto;
    display: none;
    line-height: 1;

    & > span {
      cursor: pointer;
    }

    &.show {
      display: block;
    }
  }
</style>
