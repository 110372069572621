<script>
  // vkladam ikonku a funkcionalitu pre tlac
  import PrintControl from "./ControlsPrint/controlsPrint";
  // vkladam draw plugin a jeho upravy
  import mapboxDraw from "./mapboxDraw";
  // tooltip component - zobrazenie aktualne kreslenej dlzky, ...
  import Tooltip from "./mapboxDraw/tooltip.svelte";
  // logika vypoctu zobrazovanych informacii
  import { zobrazPopUpOkno } from "../../helpers/map";

  import center from "@turf/center";

  import { mapStore } from "../../stores/map.store";

  const map = $mapStore.mapbox.getMap();

  // navigacne ikonky vlavo hore
  map.addControl(
    new mapboxgl.NavigationControl({
      showCompass: false,
      visualizePitch: true,
    }),
    "top-left"
  );

  // mierka vlavo dole
  map.addControl(
    new mapboxgl.ScaleControl({
      maxWidth: 120,
      unit: "metric",
    }),
    "bottom-left"
  );

  // pridavam cely draw do mapy
  // map.addControl(mapboxDraw, "bottom-right");

  // custom event pri kresleni a zobrazovanie info
  // map.on("draw.customOnMouseMove", function(feature, asd) {
  //   console.log(asd);
  //   customOnMouseMove(feature, type);
  // });

  map.on("draw.create", function (feature) {
    let tooltip = document.getElementById("tooltip");
    tooltip.innerHTML = "x";
    var coordinates = feature.features[0].geometry.coordinates.slice();
    const centerPoint = center(feature.features[0]);
    zobrazPopUpOkno(
      map,
      centerPoint.geometry.coordinates,
      `dadadsdad<div class="footer-buttons"><span class="pridat-poznamku" data-source-id="a">Pridat poznámku</span><span data-source-id="a" class="zavriet-okno">Zavrieť</span></div>`
    );
  });

  map.on("draw.update", function (e) {
    let tooltip = document.getElementById("tooltip");
    tooltip.innerHTML = "x";
  });

  const print = new PrintControl();
  map.addControl(print, "bottom-left");
</script>

<Tooltip />
