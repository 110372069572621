import ikonky from "../configs/ikonky.config";
import get from "get-value";
import { errorLog } from "./error";

export function ziskajDataIkonky(kod, $$props) {
  let error = false;
  if (undefined === kod) {
    errorLog("Kod ikonky nie je definovany.");
    return;
  }

  if (undefined === ikonky[kod]) {
    errorLog(`Ikonka s danym kodom '${kod}' neexistuje.`);
    return;
  }

  let ikonka = ikonky[kod];
  let attrs = {
    svg: get(ikonka.svg),
  };

  /**
   * Taky maly fix.
   * V konfigu mozem mat ulozenu zakladnu classu.
   * Ale tu mozem bud prepisat pri vkladani componentu, alebo ju doplnit o classu farby.
   * Zakladnu classu dam do tempu a z konfigu ju docastne vymazem. Potom pridam pridavnu farbu, pripadne
   * nastavim novu classu. Ak som nastavil novu classu, tak zakladnu vymazem z tempu.
   * Nakoniec pridam zakladne classy, ak som ich mal a neprepisoval.
   */
  // ulozim si zakladne classy do tempu.
  let classAttrTemp = {
    svg: attrs.svg ? get(attrs.svg.class) : undefined,
  };

  // vymazem zakladne classy
  if (attrs.svg) {
    attrs.svg.class = "";
  }

  // Ak do komponentu pridam nejake dalsie atributy, tak ich chcem doplnit. Lenze ich moze byt -n.
  if ($$props) {
    // idem po jednotlivom atribute
    for (const key in $$props) {
      // rozdelim podla pomlcky
      const keyParse = key.split(/-(.+)/, 2);
      // ak tam mam atribut s pomlckou
      if (keyParse.length === 2) {
        // ak sa jedna o atribut pridavnej farby alebo zmenu classy
        if (keyParse[1] === "color-class" || keyParse[1] === "class") {
          // pridam hodnotu do classy
          attrs.svg.class += ` ${$$props[key]}`;
          // ak sa jednalo o prepis zakladnej classy, tak tu vyhodim z tempu aby som ju neskor nepridal
          if (keyParse[1] === "class") {
            classAttrTemp.svg = false;
          }
        } else {
          attrs.svg[keyParse[1]] = $$props[key];
        }
      }
    }
  }

  // tu idem pridat zakladne classy, ktore neprepisujem a hore som ich docastne vymazal
  if (undefined !== attrs.svg && classAttrTemp.svg) {
    if (undefined !== attrs.svg.class) {
      attrs.svg.class += ` ${classAttrTemp.svg}`;
    } else {
      attrs.svg.class = classAttrTemp.svg;
    }
  }

  return { attrs: attrs, ikonka: ikonka };
}
