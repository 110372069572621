<style>
  #tooltip:empty {
    display: none;
  }
  #tooltip {
    font-family: Helvetica, Arial, sans-serif;
    position: absolute;
    padding: 4px;
    margin: 8px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    max-width: 300px;
    font-size: 10px;
    z-index: 9;
    pointer-events: none;
  }
</style>

<div id="tooltip" />
