import { writable } from "svelte/store";

export const getLoggedUser = () => {
  const lastLoggedUser = localStorage.getItem("sm-lastLoggedUser");
  if (lastLoggedUser) {
    return lastLoggedUser;
  }

  return false;
};

export const updateLoggedUser = (data) => {
  try {
    localStorage.setItem("sm-lastLoggedUser", data);
    return true;
  } catch (error) {
    errorLog(error);
  }
};

function createLastLoggedUser() {
  const { subscribe, set, update } = writable(0);

  return {
    subscribe,
    initStoredLastLoggedUser: (storedLastLoggedUser) => {
      set(storedLastLoggedUser ? storedLastLoggedUser : "");
    },
    updateLastLoggedUser: (data) => {
      if (updateLoggedUser(data)) {
        update((u) => (u = data));
      }
    },
    getLastLoggedUser: () => getLoggedUser(),
    clearLastLoggedUser: () => {
      if (updateLoggedUser("")) {
        set("");
      }
    },
  };
}

export const lastLoggedUser = createLastLoggedUser();
