import { tooltipData, tooltipHelp } from "../createTooltip.js";

const DrawPolygonMode = MapboxDraw.modes.draw_polygon;

const _onMouseMove = DrawPolygonMode.onMouseMove;

DrawPolygonMode.onMouseMove = function(state, e) {
  const result = _onMouseMove.apply(this, [state, e]);

  const feature = state.polygon.toGeoJSON();

  tooltipData([feature], "polygon");
  tooltipHelp([feature], "polygon");
  // this.map.fire("draw.customOnMouseMove", {
  //   features: [feature],
  //   asd: "polygon"
  // });

  return result;
};

export default DrawPolygonMode;
