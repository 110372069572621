import { tooltipData, tooltipHelp } from "../createTooltip.js";

const DrawLineStringMode = MapboxDraw.modes.draw_line_string;

const _onMouseMove = DrawLineStringMode.onMouseMove;

DrawLineStringMode.onMouseMove = function(state, e) {
  const result = _onMouseMove.apply(this, [state, e]);

  const feature = state.line.toGeoJSON();

  tooltipData([feature], "line_string");
  tooltipHelp([feature], "line_string");

  return result;
};

export default DrawLineStringMode;
