<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import Ikonka from "../../UI/Icon.svelte";
  import { mapStore } from "../../../stores/map.store";
  const map = $mapStore.mapbox.getMap();
  import { userStore } from "../../../stores/user.store.js";
  import { lastLoggedUser } from "../../../stores/currentLoggedUser.store";
  import { logout } from "../../../api/login";

  import obecConfig from "../../../configs/obec.config";

  const u = userStore.getUser();

  function mapReset() {
    map.flyTo({
      center: [
        obecConfig.pociatocne_pozicie.lon,
        obecConfig.pociatocne_pozicie.lat,
      ],
      zoom: obecConfig.pociatocne_pozicie.zoom,
    });
  }

  function mapClear() {
    // prechadzam vsetky layery, nie sources, lebo najprv treba vymazat layer
    map.getStyle().layers.forEach(function (layer) {
      if (layer.source.startsWith("sm_")) {
        map.removeLayer(layer.id);
      }
    });

    // ak existuje source ktory mam vymazat
    for (const source_id in map.getStyle().sources) {
      if (source_id.startsWith("sm_")) {
        map.removeSource(source_id);
      }
    }

    // zmazem informaciu aj z pamate stavu uzivatela
    u.mapa.stav = {};
    userStore.updateUser(u);
  }

  async function logoutFunction() {
    const logoutResponse = await logout();
    if (logoutResponse.success) {
      lastLoggedUser.clearLastLoggedUser();
      map.remove();
      history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }
  }
</script>

<nav class="sidebar__nav js-tab-nav">
  <!-- row 1 start -->
  <ul>
    <li>
      <span class="sidebar__nav-link" on:click|preventDefault={mapReset}>
        <Ikonka kod="015" />
        <p>Zobraziť celú mapu</p>
      </span>
    </li>

    <li>
      <span class="sidebar__nav-link" on:click|preventDefault={mapClear}>
        <Ikonka kod="016" />
        <p>Vyčistiť celú mapu</p>
      </span>
    </li>

    <li>
      <span
        class="sidebar__nav-link"
        data-tab="2"
        on:click|preventDefault={() => dispatch("toggleSidebarTabVrstvy")}
      >
        <Ikonka kod="017" />
        <p>Zmeniť mapu</p>
      </span>
    </li>

    <!-- <li>
      <span
        class="sidebar__nav-link"
        data-tab="1"
        on:click|preventDefault={() => dispatch("toggleSidebarTabPopisZnaciek")}
      >
        <Ikonka kod="018" />
        <p>Popis značiek</p>
      </span>
    </li>-->

    <li>
      <span
        class="sidebar__nav-link"
        on:click|preventDefault={() => dispatch("toggleSidebarTabMeranie")}
      >
        <Ikonka kod="019" />
        <p>Meranie vzdialenosti</p>
      </span>
    </li>
    <li>
      <span
        class="sidebar__nav-link"
        on:click|preventDefault={() => dispatch("toggleSidebarTabAuta")}
      >
        <Ikonka kod="023" />
        <p>Autá</p>
      </span>
    </li>
    <li>
      <span
        class="sidebar__nav-link"
        on:click|preventDefault={() => dispatch("toggleSidebarTabPocasie")}
      >
        <Ikonka kod="024" />
        <p>Počasie</p>
      </span>
    </li>

    <li>
      <span
        class="sidebar__nav-link"
        data-tab="3"
        on:click|preventDefault={() => dispatch("toggleSidebarTabPoznamky")}
      >
        <Ikonka kod="020" />
        <p>Poznámky na mape</p>
      </span>
    </li>
  </ul>
  <!-- row 1 end -->

  <!-- row 2 start -->
  <ul>
    <li>
      <span
        class="sidebar__nav-link"
        data-tab="4"
        on:click|preventDefault={() =>
          dispatch("toggleSidebarTabNastavenieUctu")}
      >
        <Ikonka kod="021" />
        <p>Nastavenie účtu</p>
      </span>
    </li>

    {#if [1, 9].includes(u.id)}
      <li>
        <span
          class="sidebar__nav-link"
          on:click|preventDefault={() => dispatch("toggleSidebarTabChangelog")}
        >
          <Ikonka kod="018" />
          <p>Changelog</p>
        </span>
      </li>
    {/if}

    <li>
      <span
        class="sidebar__nav-link"
        on:click|preventDefault={() =>
          dispatch("toggleSidebarTabSystemovdInformacie")}
      >
        <Ikonka kod="018" />
        <p>Systémové informácie</p>
      </span>
    </li>

    <li>
      <span
        class="sidebar__nav-link"
        on:click|preventDefault={() => logoutFunction()}
      >
        <Ikonka kod="022" />
        <p>Odhlásiť sa</p>
      </span>
    </li>
  </ul>
  <!-- row 2 end -->
</nav>

<style lang="scss">
  .sidebar__nav > ul > li {
    cursor: pointer;
  }
</style>
