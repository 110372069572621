import obecConfig from "../../configs/obec.config";
import bbox from "@turf/bbox";
import center from "@turf/center";
import { userStore } from "../../stores/user.store.js";
import get from "get-value";
import { isEmpty, printf } from "../../helpers/functions";
import systemConfig from "../../configs/system.config";
import {
  sourceExists,
  nastavPremenneZDatZdroja,
  vygenerujIdZdroja,
  pridajVrstvuNaMapu,
  zobrazPopUpOkno,
} from "../../helpers/map";
import { vratGeojsonData } from "../../api/geojson";
import { vratVrstvyMapyPodlaTypu } from "../../helpers/config";

/**
 * Prvotne nacitanie mapboxu
 * @param {element} container
 */
export function mapboxInit(container) {
  // ziskam si zemenpisnu dlzku, sirku a zoom
  const { lat, lng, zoom } = ziskajUlozenuPoziciuMapy();

  // ziskam vrstvy ktore chcem zobrazit v mape
  /* const { interne, externe } = ziskajVrstvy();
  const interneURL = vytvorUrlPreInterneVrstvy(interne); */

  return new mapboxgl.Map({
    container,
    style: "mapbox://styles/mapbox/streets-v11",
    center: [lng, lat],
    zoom: zoom,
    minZoom: obecConfig.map.minZoom,
    maxZoom: obecConfig.map.maxZoom,
    // maxBounds: obecConfig.map.maxBounds,
    style: {
      version: 8,
      glyphs: "assets/fonts/{fontstack}/{range}.pbf",
      sources: {
        /* interne_vrstvy: {
          type: "raster",
          tiles: [interneURL],
          tileSize: 256,
        }, */
      },
      layers: [
        /* {
          id: "interne_vrstvy",
          type: "raster",
          source: "interne_vrstvy",
          minzoom: 10,
          maxzoom: 22,
        }, */
      ],
    },
    preserveDrawingBuffer: true,
    hash: true,
    doubleClickZoom: false,
    transformRequest: (url, resourceType) => {
      if (
        url.includes("zbgisws.skgeodesy.sk") === false &&
        url.includes("fonts.openmaptiles.org") === false
      ) {
        return {
          url: url,
          credentials: "include", // Include cookies for cross-origin requests
        };
      }
    },
  });
}

/**
 * Vytvorim URL z ktorej sa nacitavaju interne vrstvy
 * @param {array} vrstvy
 */
function vytvorUrlPreInterneVrstvy(vrstvy) {
  const podkladove = vrstvy.podkladove.map((vrstva) => vrstva.kod);
  const priehladne = vrstvy.priehladne.map((vrstva) => vrstva.kod);

  let urlVrstvy = "";
  if (!isEmpty(podkladove)) {
    urlVrstvy = `${podkladove}`;
  }
  if (!isEmpty(priehladne)) {
    urlVrstvy += "|";
    urlVrstvy += priehladne.join(",");
  }

  return printf(obecConfig.vrstvy.url, { vrstvy: urlVrstvy });
}

/**
 * potrebujem ziskat aktivne vrstvy
 * bud z ulozeneho local storage
 * alebo z configu
 */
export function ziskajVrstvy(ibaKod = false) {
  // nacitam si uzivatela
  const u = userStore.getUser();
  let interne = get(u.mapa.vrstvy.interne);
  let externe = get(u.mapa.vrstvy.externe);

  /**
   * ak uzivatel nema v local storage ulozenu
   * ani externu ani internu podkladovu vrstvu
   * tak podkladovu aj priehladnu vrstvu nacitam z konfigu
   * ale iba z internych vrstiev
   */
  if (isEmpty(interne.podkladove) && isEmpty(externe.podkladove)) {
    interne = ziskajZakladneVrstvy();
  }

  // chcem vratit iba kody, nie cele objekty vrstiev
  if (ibaKod) {
    if (!isEmpty(interne.podkladove)) {
      interne.podkladove = interne.podkladove.map((vrstva) => vrstva.kod);
    }
    if (!isEmpty(interne.priehladne)) {
      interne.priehladne = interne.priehladne.map((vrstva) => vrstva.kod);
    }

    if (!isEmpty(externe.podkladove)) {
      externe.podkladove = externe.podkladove.map((vrstva) => vrstva.kod);
    }
    if (!isEmpty(externe.priehladne)) {
      externe.priehladne = externe.priehladne.map((vrstva) => vrstva.kod);
    }
  }

  return { interne, externe };
}

/**
 * Nacitam si zakladne interne vrstvy z konfigu
 */
function ziskajZakladneVrstvy() {
  const podkladove = get(obecConfig.vrstvy.interne.podkladove.vrstvy);
  const priehladne = get(obecConfig.vrstvy.interne.priehladne.vrstvy);

  if (!podkladove) {
    return {
      podkladove: [],
      priehladne: [],
    };
  }

  const zakladnePodkladove = podkladove.filter(
    (vrstva) => vrstva.default === true
  );
  const zakladnePriehladne = priehladne.filter(
    (vrstva) => vrstva.default === true
  );

  return {
    podkladove: zakladnePodkladove,
    priehladne: zakladnePriehladne,
  };
}

/**
 * Potrebujem ziskat ulozenu poziciu mapy.
 * Bud ju mam ulozenu v storage (ak uz som bol prihlasny)
 * alebo natiahnem udaje z configu
 */
export function ziskajUlozenuPoziciuMapy() {
  const u = userStore.getUser();
  let lat = get(u.mapa.lat);
  let lng = get(u.mapa.lng);
  let zoom = get(u.mapa.zoom);

  if (
    lat === undefined ||
    lng === undefined ||
    zoom === undefined ||
    lat === "" ||
    lng === "" ||
    zoom === "" ||
    lat === 0 ||
    lng === 0 ||
    zoom === 0
  ) {
    lat = obecConfig.pociatocne_pozicie.lat;
    lng = obecConfig.pociatocne_pozicie.lon;
    zoom = obecConfig.pociatocne_pozicie.zoom;
  }

  return { lat, lng, zoom };
}

/**
 * Ziskam aktualnu poziciu mapy. Cize vzdy ked sa na mape posuniem alebo
 * upravim zoom, tak sa mi zisti aktualna pozicia a zoom
 * @param {object} map
 */
function ziskajAktualnuPoziciuMapy(map) {
  const center = map.getCenter();
  const zoom = Math.round(map.getZoom() * 100) / 100;
  // derived from equation: 512px * 2^z / 360 / 10^d < 0.5px
  const precision = Math.ceil(
    (zoom * Math.LN2 + Math.log(512 / 360 / 0.5)) / Math.LN10
  );
  const m = Math.pow(10, precision);
  const lng = Math.round(center.lng * m) / m;
  const lat = Math.round(center.lat * m) / m;

  return { lat, lng, zoom };
}

/**
 * Z mapboxu ziskam a spracujem aktualne vrstvy
 * @param {object} map
 */
function ziskajAktualneVrstvy(map) {
  const sources = map.getStyle().sources;
  let interne = { podkladove: [], priehladne: [] };
  let externe = { podkladove: [], priehladne: [] };

  // overim si, ci mam interne vrstvy
  const map_interne = get(sources.interne_vrstvy);
  if (map_interne) {
    let ziskaneVrstvy = {};
    // kedze vrstiev moze byt viac (aj ked pouzivame vzdy iba jednu)
    map_interne.tiles.forEach((url) => {
      // z URL ziskam vrstvy podla konfigu
      ziskaneVrstvy = Object.assign(ziskaneVrstvy, ziskajVrstvyZUrl(url));
      if (ziskaneVrstvy) {
        interne = ziskaneVrstvy;
      }
    });
  }

  // overim si, ci mam externe vrstvy podkladove
  const map_externe_podkladove = get(sources.externe_vrstvy_podkladove);
  if (map_externe_podkladove) {
    map_externe_podkladove.tiles.forEach((url) => {
      let najdena = najdiVrstvuPodlaURL(url);
      externe.podkladove.push(najdena.vrstva);
    });
  }

  // overim si, ci mam externe vrstvy priehladne
  const map_externe_priehladne = get(sources.externe_vrstvy_priehladne);
  if (map_externe_priehladne) {
    map_externe_priehladne.tiles.forEach((url) => {
      let najdena = najdiVrstvuPodlaURL(url);
      externe.priehladne.push(najdena.vrstva);
    });
  }

  return { interne: interne, externe: externe };
}

/**
 * Z URL musim ziskat kody vrstiev a nasledne ich vyhladat v kofigu
 * a to potom vratim ako pole pouzitych vrstiev
 * @param {string} url
 */
function ziskajVrstvyZUrl(url) {
  let najdeneVrstvy = { podkladove: [], priehladne: [] };
  // pattern pre URL je {x}.{y}.{z}.[nejake,vrstvy].jpg
  const found = url.match(/\{x\}\.\{y\}\.\{z\}\.(.*)\.jpg/);
  // ak mam vysledky 2, tak som nasiel nieco
  if (found.length === 2) {
    /* let priehladne = found[1];
    let podkladove = '';
    // najprv zistim, ci tam mam podkladovu vrstvu
    // ta je ako prva a oddelene od priehladnych |
    if ( vrstvy.indexOf('|') ) {
      podk_prieh = found[1].split("|");
      podkladove = podk_prieh[0];
      priehladne = podk_prieh[1];
    }
    const vrstvy = priehladne.split(","); */
    const vrstvy = found[1].replace("|", ",").split(",");
    // prechadzam kazdu jednu vrstvu aby som ju nasiel v konfigu podla kodu
    vrstvy.forEach((kod) => {
      if (kod) {
        const { typ, vrstva } = najdiVrstvuPodlaKodu(kod);
        if (
          najdeneVrstvy[typ].findIndex(
            (e) => e.kod === kod || e.vrstvy.includes(kod)
          ) === -1
        ) {
          // najdenu vrstvu vlozim do pola vrstiev podla typu
          najdeneVrstvy[typ].push(vrstva);
        }
      }
    });
  }
  return najdeneVrstvy;
}

/**
 * Podla kodu vrstvy hladam danu vrstvu v konfigu
 * @param {string} kod
 * @param {boolean} interne - hladam v internych ale ak zadam false, tak hladam v externych
 */
export function najdiVrstvuPodlaKodu(kod, interne = true) {
  let najdenaVrstva;
  // do premennej si ulozim bud interne alebo externe
  const objektVrstiev = obecConfig.vrstvy[interne ? "interne" : "externe"];
  // prechadzam jednotlive typy - podkladove alebo priehladne
  Object.entries(objektVrstiev).some(([typ, vrstvy]) => {
    const vrstva = vrstvy.vrstvy.find(
      (vrstva) => vrstva.kod === kod || vrstva.vrstvy.includes(kod)
    );
    if (vrstva) {
      // musim vratit okrem vrstvy aj typ, ci je podkladova alebo priehladna
      najdenaVrstva = { typ, vrstva };
      return true;
    }
  });

  return najdenaVrstva;
}

/**
 * Podla URL vrstvy hladam danu vrstvu v konfigu - pre externe
 * @param {string} kod
 * @param {boolean} interne - hladam v internych ale ak zadam false, tak hladam v externych
 */
function najdiVrstvuPodlaURL(url) {
  let najdenaVrstva;
  // do premennej si ulozim bud interne alebo externe
  const objektVrstiev = obecConfig.vrstvy["externe"];
  // prechadzam jednotlive typy - podkladove alebo priehladne
  Object.entries(objektVrstiev).some(([typ, vrstvy]) => {
    const vrstva = vrstvy.vrstvy.find((vrstva) => vrstva.url === url);
    if (vrstva) {
      // musim vratit okrem vrstvy aj typ, ci je podkladova alebo priehladna
      najdenaVrstva = { typ, vrstva };
      return true;
    }
  });

  return najdenaVrstva;
}

/**
 * z mapy si zistim zemepisnu dlzku, sirku a zoom
 * a tieto informacie nasledne ulozim do storage
 * @param {object} map
 */
export function ulozAktualneZobrazenieMapy(map) {
  const { lat, lng, zoom } = ziskajAktualnuPoziciuMapy(map);
  const { interne, externe } = ziskajAktualneVrstvy(map);

  const u = userStore.getUser();
  u.mapa.lat = lat;
  u.mapa.lng = lng;
  u.mapa.zoom = zoom;
  u.mapa.vrstvy.interne = interne;
  u.mapa.vrstvy.externe = externe;

  userStore.updateUser(u);
}

export function upravZoznamVrstiev(ziskaneVrstvy) {
  if (!ziskaneVrstvy) {
    return false;
  }

  const types = [
    "podkladove_interne",
    "priehladne_interne",
    "podkladove_externe",
    "priehladne_externe",
  ];

  types.map((type) => {
    const [typ, zdroj] = type.split("_");
    const objektVrstiev = obecConfig.vrstvy[zdroj];
    if (ziskaneVrstvy[zdroj][typ].length) {
      ziskaneVrstvy[zdroj][typ].forEach((vrstva, index) => {
        const v = najdiVrstvuPodlaKodu(
          vrstva,
          zdroj === "interne" ? true : false
        );
        if (v) {
          ziskaneVrstvy[zdroj][typ][index] = v.vrstva.vrstvy
            ? v.vrstva.vrstvy.join(",")
            : v.vrstva.kod;
        }
      });
    }
  });

  return ziskaneVrstvy;
}

/**
 * funkcia na zmenu (prepinanie) vrstiev
 * inspiracia: https://github.com/mapbox/mapbox-gl-js/issues/2941#issuecomment-518631078
 * @param {object} map
 * @param {array} vrstvy
 */
export function changeMapTilesSource(map, vrstvy) {
  const sourceInterneExists = map.getSource("interne_vrstvy") ? true : false;

  if (sourceExists) {
    if (map.getLayer("interne_vrstvy")) {
      map.removeLayer("interne_vrstvy");
    }

    if (map.getSource("interne_vrstvy")) {
      map.removeSource("interne_vrstvy");
    }
  }

  if (vrstvy.interne.priehladne.length || vrstvy.interne.podkladove.length) {
    let url = "";

    if (vrstvy.interne.podkladove.length) {
      url = vrstvy.interne.podkladove;
    }
    if (vrstvy.interne.priehladne.length) {
      url += "|" + vrstvy.interne.priehladne.join(",");
    }

    // Ak uz vrstva Interne_vrstvy existuje, tak robim update vrstvy
    /* if (sourceInterneExists) {
      // Set the tile url to a cache-busting url (to circumvent browser caching behaviour):
      map.getSource("interne_vrstvy").tiles = [
        `${systemConfig.apiUrl}/api/v1/smartmap/tilemap/{x}.{y}.{z}.${url}.jpg`,
      ];

      // Remove the tiles for a particular source
      map.style.sourceCaches["interne_vrstvy"].clearTiles();

      // Load the new tiles for the current viewport (map.transform -> viewport)
      map.style.sourceCaches["interne_vrstvy"].update(map.transform);

      // Force a repaint, so that the map will be repainted without you having to touch the map
      map.triggerRepaint();
    } else { */
    map.addSource("interne_vrstvy", {
      type: "raster",
      tiles: [
        `${systemConfig.apiUrl}/api/v1/smartmap/tilemap/{x}.{y}.{z}.${url}.jpg`,
      ],
      tileSize: 256,
      maxzoom: obecConfig.layers.maxzoom,
    });
    const layers = map.getStyle().layers;
    const firstLayerId = layers.length ? layers[0].id : null;
    map.addLayer(
      {
        id: "interne_vrstvy",
        type: "raster",
        source: "interne_vrstvy",
        maxzoom: obecConfig.layers.maxzoom,
        minzoom: obecConfig.layers.minzoom,
      },
      firstLayerId
    );
    // }
  }

  /**
   * Externe vrstvy
   */
  const externe = get(vrstvy.externe);

  // najprv si vsetky existujuce externe vrstvy vymazem
  if (map.getLayer("externe_vrstvy_podkladove")) {
    map.removeLayer("externe_vrstvy_podkladove");
  }

  if (map.getSource("externe_vrstvy_podkladove")) {
    map.removeSource("externe_vrstvy_podkladove");
  }

  if (map.getLayer("externe_vrstvy_priehladne")) {
    map.removeLayer("externe_vrstvy_priehladne");
  }

  if (map.getSource("externe_vrstvy_priehladne")) {
    map.removeSource("externe_vrstvy_priehladne");
  }

  // ak mam zvolenu podkladovu vrstvu, tak jej vytvorim layer a donho dam
  // vrstvu ako source
  if (externe && vrstvy.externe.podkladove.length) {
    const { typ, vrstva } = najdiVrstvuPodlaKodu(
      vrstvy.externe.podkladove[0],
      false
    );

    map.addSource("externe_vrstvy_podkladove", {
      type: "raster",
      tiles: [vrstva.url],
      tileSize: 256,
    });

    // pridam vrstvu pred (pod) interne vrstvy, pretoze
    // ak mam zvolenu externu podkladovu, tak internu podkladovu nemozem mat,
    // cize musi ist uplne naspodok
    map.addLayer(
      {
        id: "externe_vrstvy_podkladove",
        type: "raster",
        source: "externe_vrstvy_podkladove",
        paint: {},
      },
      map.getLayer("interne_vrstvy") ? "interne_vrstvy" : null // pod ktoru vrstvu ma ist
    );
  }

  // ak mam zvolene priehladne vrstvy, tak im vytvorim layer a donho dam
  // vrstvy ako sources
  if (externe && vrstvy.externe.priehladne.length) {
    let tiles = [];

    vrstvy.externe.priehladne.forEach((kod) => {
      const { typ, vrstva } = najdiVrstvuPodlaKodu(kod, false);
      tiles.push(vrstva.url);
    });

    if (tiles.length) {
      map.addSource("externe_vrstvy_priehladne", {
        type: "raster",
        tiles: tiles,
        tileSize: 256,
        maxzoom: obecConfig.layers.maxzoom,
        minzoom: obecConfig.layers.minzoom,
      });
      map.addLayer({
        id: "externe_vrstvy_priehladne",
        type: "raster",
        source: "externe_vrstvy_priehladne",
      });
    }
  }
  // Force a repaint, so that the map will be repainted without you having to touch the map
  map.triggerRepaint();
  ulozAktualneZobrazenieMapy(map);
}

/**
 * Funkcia zobrazi zvolene data na mape
 * @param {object} map
 * @param {object} data Data, ktore chcem zobrazit na mape
 * @returns
 */
export async function zobrazDataNaMape(map, data, doPointTo = true) {
  const { typ, id, hover } = nastavPremenneZDatZdroja(data);

  // vytvorim si ID pre zdroj mapboxu. Musi to byt jedinecne
  const source_id = vygenerujIdZdroja(typ, id);
  if (!source_id) {
    return false;
  }

  // z konfigu si vytiahnem data o vrstve daneho typu
  // tych vrstiev moze byt viac. Napr lokalita ma oramovanie a vyfarbenie
  const vrstvy = vratVrstvyMapyPodlaTypu(typ);

  if (vrstvy) {
    // ak uz taky zdroj mam, tak ho nemozem znova pridava
    // ale mozem ho znova nacentrovat
    if (sourceExists(map, source_id) !== false) {
      // ziskam si data daneho zdroja priamo z mapy
      // a nemusim to ziskavat cez api
      const source = map.getSource(source_id);
      if (doPointTo) {
        pointTo(map, source._data, vrstvy[0].typ_vrstvy);
      }
      return false;
    }

    let sourceData;

    // z api vratim geojson data
    sourceData = await vratGeojsonData(typ, id);
    if (!sourceData) {
      return false;
    }

    // vytvorim si zdroj dat
    map.addSource(source_id, {
      type: "geojson",
      data: sourceData,
      generateId: true,
      maxzoom: obecConfig.layers.maxzoom,
    });

    // ak som nerobil dvojklik
    if (hover === false) {
      ulozPridanuVrstvu(data, source_id, sourceData, vrstvy[0].typ_vrstvy);
      /* const point = center(sourceData);
      zobrazPopUpOkno(
        map,
        point.geometry.coordinates,
        pripravNazov({
          typ: typ,
          nazov: sourceData.features[0].properties.nazov,
        })
      ); */
    }

    // pridam vrstvy do zdroja
    pridajVrstvuNaMapu(map, typ, id);

    // nacentrujem mapu na dane data na mape
    if (doPointTo) {
      pointTo(map, sourceData, vrstvy[0].typ_vrstvy);
    }
  }

  return true;
}

function pripravNazov(item) {
  let nazov = item.nazov;
  switch (item.typ) {
    case "obec":
      return `Obec ${nazov}`;
    case "okres":
      return `Okres ${nazov}`;
    case "ku":
      return `K. ú. ${nazov}`;
    case "cesty":
      return `Cesta č. ${nazov}`;
    case "okruhy":
      return `${nazov}`;
  }

  return nazov;
}

export function pointTo(map, sourceData, typ_vrstvy) {
  // ak je typ vrstvy sumbol, cize nejaky point, tak musim inak nacentrovat objekt
  // lebo funkcia fitBounds by bod nazoomoval az moc
  if (typ_vrstvy === "symbol") {
    const point = center(sourceData);
    map.flyTo({
      center: point.geometry.coordinates,
      zoom: obecConfig.map.maxZoom,
    });
  } else {
    const bounds = bbox(sourceData);
    map.fitBounds(bounds, { padding: 100 });
  }
}

/**
 * Funkcia nastavi viditelnost pre konkretny layer a vsetky vrstvy v nom
 * @param {object} map Objekt mapboxu
 * @param {string} typ
 * @param {string} source_id
 * @param {string} visibility "visible" alebo "none"
 */
export function nastavViditelnostVrstvy(map, typ, source_id, visibility) {
  // z konfigu si vytiahnem data o vrstve daneho typu
  // tych vrstiev moze byt viac. Napr lokalita ma oramovanie a vyfarbenie
  const vrstvy = vratVrstvyMapyPodlaTypu(typ);

  if (vrstvy) {
    // idem po jednej vrstve a samostatne jej nastavim layer
    vrstvy.forEach((vrstva, i) => {
      map.setLayoutProperty(`${source_id}_${i}`, "visibility", visibility);
    });
  }
}

/**
 * ulozim aktualne pridanu vrstvu do storage
 * @param {object} map
 */
export function ulozPridanuVrstvu(data, source_id, sourceData, typ_vrstvy) {
  const u = userStore.getUser();
  const { typ, id, hover } = nastavPremenneZDatZdroja(data);

  let stav = get(u.mapa.stav);

  if (stav === undefined) {
    u.mapa.stav = {};
  }

  //if (get(stav[source_id]) === undefined) {
  stav[source_id] = { data, pointTo, sourceData, typ_vrstvy };
  //}

  if (typ === "okruhy") {
    stav[source_id].animacia = {
      isPlaying: false,
      timer: null,
      tempSourceData: sourceData,
      progress: 0,
    };
  }

  userStore.updateUser(u);
}

export function nacitajUlozenyStav(map) {
  const u = userStore.getUser();

  let stav = get(u.mapa.stav);

  if (stav === undefined) {
    u.mapa.stav = {};
  }

  Object.entries(stav).forEach(([source_id, layer]) => {
    if (source_id.startsWith("auto_")) {
      return;
    }
    zobrazDataNaMape(map, layer.data, false);
  });
}
