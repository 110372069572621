import Api from "../helpers/api";
import { userStore } from "../stores/user.store";

export const getCsrf = async () => {
  try {
    const response = await Api.get(`/sanctum/csrf-cookie`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const editaciaUzivatela = async (data) => {
  const u = userStore.getUser();
  const user_id = data.id;
  let response = { success: false, errors: [], successes: [] };
  if (data === undefined) {
    response.errors.push("Chybné dáta.");

    return response;
  }

  await Api.put(`/api/v1/auth/user/password`, {
    password: data.nove_heslo,
    password_confirmation: data.nove_heslo_kontrola,
    current_password: data.stare_heslo,
  })
    .then((res) => {
      response.successes.push("Heslo bolo úspešne zmenené.");
    })
    .catch((error) => {
      if (error.response.data) {
        for (const key in error.response.data.errors) {
          error.response.data.errors[key].forEach((err) =>
            response.errors.push(err)
          );
        }
      }
    });

  return response;
};
