<script>
  import { createEventDispatcher, onMount } from "svelte";
  import obecConfig from "../../../configs/obec.config";
  import {
    vratHtmlKodFarbyPodlaTypu,
    vratVrstvyMapyPodlaTypu,
  } from "../../../helpers/config";
  const dispatch = createEventDispatcher();
  import Ikonka from "../../UI/Icon.svelte";
  export let open;
  import { mapStore } from "../../../stores/map.store";
  const map = $mapStore.mapbox.getMap();
  import { userStore } from "../../../stores/user.store";
  import { vratZoznamAut } from "../../../api/auta";
  import tempRoute from "../../../stores/tempRoute";

  let zoznamAut = [];
  let interval = null;
  const source_id = "poziciaAut";

  map.on("load", async () => {
    // nacitam si z api zoznam aut aj s polohami
    const auta = await vratZoznamAut();
    zoznamAut = auta;
    zoznamAut.push(pridaDemoAuto());
    // kazde auto prejdem a nastavim mu defaultne hodnoty
    // ktroe sa pouzivaju pre zobrazenie tlacitok v lavom menu
    zoznamAut.forEach((auto) => {
      auto.checked = false;
      auto.icons = false;
      auto.interval = null;
    });

    const filtrovaneAuta = vratFiltrovanyZoznamAut();
    pridajVrstvuAut(filtrovaneAuta);
    pridajVrstvuAutHistoria(filtrovaneAuta);
    nastavViditelnostTlacitok(filtrovaneAuta);
  });

  function pridaDemoAuto() {
    const demoSuradnice = closestFromNow(tempRoute);
    demoSuradnice.history.map((x, i) => {
      demoSuradnice.history[i] = {
        id: 999,
        cargroupid: 0,
        carid: 99999,
        identifikator: "DEMO",
        driver: "",
        mobil: "",
        userrights: 1,
        type: 4,
        online: 1,
        disabled: 0,
        alarmsms: "",
        iddriver: 0,
        webdispatch_cars_position: {
          id: 7,
          carid: 99999,
          zs: x.lat,
          zd: x.lng,
          latitude: x.lat,
          longitude: x.lng,
          positiontime: `2021-12-23 ${x.time}`,
          localpostime: `2021-12-23 ${x.time}`,
          speed: parseInt(x.speed),
          location: "SK Senec 1062",
          location_state: "SK",
          location_city: x.obec,
          location_street: "1062",
          input1: x.pluh === "dole" ? 200 : 32,
          input2: x.sypac === "sype" ? 200 : 32,
          input3: "32",
          input4: "32",
          ac_dallas: "",
          km: "59847.19",
          usedfuel: "0",
          fueltank: "0",
        },
      };
    });

    return {
      id: 999,
      cargroupid: 0,
      carid: 99999,
      identifikator: "DEMO",
      driver: "",
      mobil: "",
      userrights: 1,
      type: 4,
      online: 1,
      disabled: 0,
      alarmsms: "",
      iddriver: 0,
      webdispatch_cars_position: {
        id: 7,
        carid: 99999,
        zs: demoSuradnice.actual.lat,
        zd: demoSuradnice.actual.lng,
        latitude: demoSuradnice.actual.lat,
        longitude: demoSuradnice.actual.lng,
        positiontime: `2021-12-23 ${demoSuradnice.actual.time}`,
        localpostime: `2021-12-23 ${demoSuradnice.actual.time}`,
        speed: parseInt(demoSuradnice.actual.speed),
        location: "SK Senec 1062",
        location_state: "SK",
        location_city: demoSuradnice.actual.obec,
        location_street: "1062",
        input1: demoSuradnice.actual.pluh === "dole" ? 200 : 32,
        input2: demoSuradnice.actual.sypac === "sype" ? 200 : 32,
        input3: "32",
        input4: "32",
        ac_dallas: "",
        km: "59847.19",
        usedfuel: "0",
        fueltank: "0",
      },
      history: demoSuradnice.history,
    };
  }

  function vratFiltrovanyZoznamAut() {
    const u = userStore.getUser();

    if (!u.mapa.auta) {
      u.mapa.auta = [];
    }

    return zoznamAut.filter((auto) => u.mapa.auta.includes(auto.id));
  }

  /**
   * Vrati objekt pre zdroj na mape
   * @return {object} Data pre zdroj
   */
  function vytvorDataPreZdroj(filtrovaneAuta) {
    let features = [];
    filtrovaneAuta.forEach((auto) => {
      features.push({
        type: "Feature",
        properties: {
          id: auto.id,
          gistyp: "auta",
          identifikator: auto.identifikator,
          radlicaText:
            auto.webdispatch_cars_position.input1 < 50 ? false : true,
          posypText: auto.webdispatch_cars_position.input2 < 50 ? false : true,
          rychlost: auto.webdispatch_cars_position.speed,
          popup: `<strong>${auto.identifikator}</strong><br/>
                  Pluh: ${
                    auto.webdispatch_cars_position.input1 < 50 ? "hore" : "dole"
                  }<br/>
                  Posyp: ${
                    auto.webdispatch_cars_position.input2 < 50 ? "nie" : "áno"
                  }<br/>
                  Rýchlosť: ${auto.webdispatch_cars_position.speed} km/h`,
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(auto.webdispatch_cars_position.longitude),
            parseFloat(auto.webdispatch_cars_position.latitude),
          ],
        },
      });
    });

    return {
      type: "FeatureCollection",
      features: features,
    };
  }

  function vytvorDataPreZdrojHistoria(filtrovaneAuta) {
    let features = [];

    filtrovaneAuta.forEach((auto) => {
      if (auto.history) {
        let radlica = "x";
        let posyp = "x";
        auto.history.forEach((his, i) => {
          const r = his.webdispatch_cars_position.input1 < 50 ? false : true;
          const p = his.webdispatch_cars_position.input2 < 50 ? false : true;
          if (radlica !== r || posyp !== p) {
            radlica = r;
            posyp = p;
            features.push({
              type: "Feature",
              properties: {
                id: auto.id,
                gistyp: "auta",
                identifikator: auto.identifikator,
                radlicaText: r,
                posypText: p,
                rychlost: his.webdispatch_cars_position.speed,
                history: true,
              },
              geometry: {
                type: "LineString",
                coordinates: [
                  [
                    parseFloat(his.webdispatch_cars_position.longitude),
                    parseFloat(his.webdispatch_cars_position.latitude),
                  ],
                ],
              },
            });
          } else {
            features[features.length - 1].geometry.coordinates.push([
              parseFloat(his.webdispatch_cars_position.longitude),
              parseFloat(his.webdispatch_cars_position.latitude),
            ]);
          }
        });
      }
    });

    return {
      type: "FeatureCollection",
      features: features,
    };
  }

  /**
   * Zobrazenie auta na mape
   * @param {object}  e       event
   * @param {number } autoId  ID auta
   */
  function nastavViditelnostAuta(e, autoId) {
    const auto = zoznamAut.find((a) => a.id === autoId);
    const checked = e.target.checked ? true : false;

    auto.icons = checked;
    auto.checked = checked;

    ulozZapnuteAuta();
    const filtrovaneAuta = vratFiltrovanyZoznamAut();
    pridajVrstvuAut(filtrovaneAuta);
    pridajVrstvuAutHistoria(filtrovaneAuta);
    nastavViditelnostTlacitok(filtrovaneAuta);
  }

  function nastavViditelnostTlacitok(filtrovaneAuta) {
    const filtrovaneTemp = filtrovaneAuta.map((auto) => auto.id);
    zoznamAut.forEach((auto) => {
      const t = filtrovaneTemp.includes(auto.id);
      auto.checked = t;
      auto.icons = t;
    });

    window.clearInterval(interval);
    interval = null;

    // ak aktualizacia nie je zapnuta, tak ju zapnem
    if (interval === null) {
      zapniAktualizaciu();
    }
  }

  function ulozZapnuteAuta() {
    const u = userStore.getUser();
    u.mapa.auta = zoznamAut
      .filter((auto) => auto.checked === true)
      .map((auto) => auto.id);

    userStore.updateUser(u);
  }

  function pridajVrstvuAut(filtrovaneAuta) {
    const sourceData = vytvorDataPreZdroj(filtrovaneAuta);
    const typ = "auta";

    if (map.getSource(source_id)) {
      map.getSource(source_id).setData(sourceData);
    } else {
      map.addSource(source_id, {
        type: "geojson",
        data: sourceData,
        generateId: true,
        maxzoom: obecConfig.layers.maxzoom,
        cluster: true,
        // clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: {
          popis: ["concat", ["concat", ["get", "identifikator"], "\n"]],
          popup: ["concat", ["concat", ["get", "popup"], "<br/><br/>"]],
        },
      });

      // z konfigu si vytiahnem data o vrstve daneho typu
      // tych vrstiev moze byt viac. Napr lokalita ma oramovanie a vyfarbenie
      const vrstvy = vratVrstvyMapyPodlaTypu("auta");
      // idem po jednej vrstve a samostatne jej nastavim layer
      vrstvy.forEach((vrstva, i) => {
        // vrstva moze mat nastavenia pre paint aj pre layout. Je to podla rozneho typu vrstvy
        for (const v in vrstva.paint) {
          if (typeof vrstva.paint[v] === "string") {
            vrstvy[i].paint[v] = vrstvy[i].paint[v].replace(
              /\%kod_farby\%/,
              `#${vratHtmlKodFarbyPodlaTypu(typ)}`
            );
          }
        }

        for (const v in vrstva.layout) {
          if (typeof vrstva.layout[v] === "string") {
            vrstvy[i].layout[v] = vrstvy[i].layout[v].replace(
              /\%kod_farby\%/,
              `#${vratHtmlKodFarbyPodlaTypu(typ)}`
            );
          }
        }

        // ak nemam v konfigu vetvu pre layout, tak ju vytvorim
        if (vrstva.layout === undefined) {
          vrstva.layout = {};
        }

        // defualtne nastavim visibility pre vsetky vrstvy
        // toto sa pouziva pri hoveri na dvojkliku
        vrstva.layout.visibility = "visible";

        const typ_vrstvy = vrstva.typ_vrstvy;
        // vytvorim layer pre danu vrstvu
        const l = map.addLayer({
          id: `${source_id}_${i}`,
          type: typ_vrstvy,
          source: source_id,
          filter: vrstva.filter || {},
          paint: vrstva.paint || {},
          layout: vrstva.layout || {},
        });
      });
    }

    // Create a popup, but don't add it to the map yet.
    const popup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: true,
      offset: 45,
    });

    map.on("mouseenter", `${source_id}_0`, (e) => {
      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = "pointer";

      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.popup;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup.setLngLat(coordinates).setHTML(description).addTo(map);
    });

    map.on("mouseleave", `${source_id}_0`, () => {
      map.getCanvas().style.cursor = "";
      popup.remove();
    });

    // Create a popup, but don't add it to the map yet.
    const popup2 = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: true,
      offset: 45,
    });

    map.on("mouseenter", `${source_id}_1`, (e) => {
      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = "pointer";

      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.popup;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup2.setLngLat(coordinates).setHTML(description).addTo(map);
    });

    map.on("mouseleave", `${source_id}_1`, () => {
      map.getCanvas().style.cursor = "";
      popup2.remove();
    });
  }

  function pridajVrstvuAutHistoria(filtrovaneAuta) {
    const sourceData = vytvorDataPreZdrojHistoria(filtrovaneAuta);
    const typ = "autaHistoria";
    const source_id_historia = "auta_historia";

    if (map.getSource(source_id_historia)) {
      map.getSource(source_id_historia).setData(sourceData);
    } else {
      map.addSource(source_id_historia, {
        type: "geojson",
        data: sourceData,
        generateId: true,
        maxzoom: obecConfig.layers.maxzoom,
      });

      // z konfigu si vytiahnem data o vrstve daneho typu
      // tych vrstiev moze byt viac. Napr lokalita ma oramovanie a vyfarbenie
      const vrstvy = vratVrstvyMapyPodlaTypu("autaHistoria");
      // idem po jednej vrstve a samostatne jej nastavim layer
      vrstvy.forEach((vrstva, i) => {
        // vrstva moze mat nastavenia pre paint aj pre layout. Je to podla rozneho typu vrstvy
        for (const v in vrstva.paint) {
          if (typeof vrstva.paint[v] === "string") {
            vrstvy[i].paint[v] = vrstvy[i].paint[v].replace(
              /\%kod_farby\%/,
              `#${vratHtmlKodFarbyPodlaTypu(typ)}`
            );
          }
        }

        for (const v in vrstva.layout) {
          if (typeof vrstva.layout[v] === "string") {
            vrstvy[i].layout[v] = vrstvy[i].layout[v].replace(
              /\%kod_farby\%/,
              `#${vratHtmlKodFarbyPodlaTypu(typ)}`
            );
          }
        }

        // ak nemam v konfigu vetvu pre layout, tak ju vytvorim
        if (vrstva.layout === undefined) {
          vrstva.layout = {};
        }

        // defualtne nastavim visibility pre vsetky vrstvy
        // toto sa pouziva pri hoveri na dvojkliku
        vrstva.layout.visibility = "visible";

        const typ_vrstvy = vrstva.typ_vrstvy;
        // vytvorim layer pre danu vrstvu
        const l = map.addLayer(
          {
            id: `${source_id_historia}_${i}`,
            type: typ_vrstvy,
            source: source_id_historia,
            paint: vrstva.paint || {},
            layout: vrstva.layout || {},
          },
          `${source_id}_0`
        );
      });
    }
  }

  /**
   * Aktualizacia dat auta
   */
  async function aktualizacia() {
    // nacitam nove udaje z api
    const auta = await vratZoznamAut();
    auta.push(pridaDemoAuto());
    // prechadzam kazde auto
    auta.forEach((auto, index) => {
      // aktualizaujem udaje o aute
      zoznamAut[index].webdispatch_cars_position =
        auto.webdispatch_cars_position;
      zoznamAut[index].history = auto.history;
    });

    const filtrovaneAuta = vratFiltrovanyZoznamAut();
    pridajVrstvuAut(filtrovaneAuta);
    pridajVrstvuAutHistoria(filtrovaneAuta);
    nastavViditelnostTlacitok(filtrovaneAuta);
  }

  /**
   * Zapnutie aktualizacie aut
   */
  function zapniAktualizaciu() {
    const u = userStore.getUser();
    // ak mam zapnute nejake auto, tak zapinam aj aktualizaciu
    if (u.mapa.auta && u.mapa.auta.length) {
      interval = setInterval(aktualizacia, 5000);
    }
  }

  /**
   * Nazoomovanie na konkretne auto
   * @param {number}  autoId  ID auta
   */
  function doPointTo(autoId) {
    // const source_id = `auto_${autoId}`;
    // pointTo(map, map.getSource(source_id)._data);
    const auto = zoznamAut.find((a) => a.id === autoId);
    map.flyTo({
      center: [
        auto.webdispatch_cars_position.longitude,
        auto.webdispatch_cars_position.latitude,
      ],
      zoom: obecConfig.map.maxZoom,
    });
  }

  /**
   * Nacentrovanie na konkretne auto na aktualnom zoome
   * @param {number}  autoId  ID auta
   */
  function doCenter(autoId) {
    const auto = zoznamAut.find((a) => a.id === autoId);
    map.easeTo({
      center: [
        auto.webdispatch_cars_position.longitude,
        auto.webdispatch_cars_position.latitude,
      ],
    });
  }

  // Is Before Now
  const isBeforeNow = (x) => Date.now() > x.getTime();

  // Generate Key.
  const generateKey = (x) => Math.abs(Date.now() * 1 - x.getTime() * 1);

  // Closest From Now.
  const closestFromNow = (times) => {
    const newArray = times.map((a) => ({ ...a }));
    const dnes = new Date();
    const dnesHodina = dnes.getHours();
    let history = [];
    let actual = null;

    newArray.forEach((x) => {
      const parseTime = x.time.split(":");
      const curr = new Date();
      curr.setHours(parseTime[0], parseTime[1], parseTime[2]);
      if (dnesHodina < 5) {
        curr.setHours(curr.getHours() - 15);
      } else if (dnesHodina < 10) {
        curr.setHours(curr.getHours() - 10);
      } else if (dnesHodina < 15 || dnesHodina > 20) {
        curr.setHours(curr.getHours() - 5);
      }

      let date = curr;
      x.time = `${date.getHours()}:${addZero(date.getMinutes())}:${addZero(
        date.getSeconds()
      )}`;
      if (!isBeforeNow(date)) {
        if (actual === null) {
          actual = x;
        }
      } else {
        history.push(x);
        // return false;
      }
    });

    return { actual, history };
  };

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
</script>

<!-- Tab map notes start -->
<div
  class="sidebar__tab sidebar__tab--map"
  data-tab="3"
  class:js-tab--open={open}
>
  <div
    class="sidebar__close"
    on:click|preventDefault={() => dispatch("toggleSidebarTabAuta")}
  >
    <Ikonka kod="012" />
  </div>
  <div class="sidebar__wrapper">
    <h6 class="sidebar__heading">Autá</h6>
    <div class="auta">
      {#each zoznamAut as auto}
        <div class="input-group">
          <!-- Toggle start -->
          <div class="toggle">
            <input
              type="checkbox"
              id="auto{auto.id}"
              on:change={(e) => nastavViditelnostAuta(e, auto.id)}
              value={auto.id}
              bind:checked={auto.checked}
            />
            <label class="toggle__icon" for="auto{auto.id}" />
            <label class="toggle__text" for="auto{auto.id}"
              >{auto.identifikator} ({auto.webdispatch_cars_position
                .location_city})</label
            >
            <span class="tlacitka" class:show={auto.icons}>
              <span on:click={() => doPointTo(auto.id)}>
                <!-- JSON icon location start -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon"
                  viewBox="0 0 370.854 511.999"
                  fill="currentColor"
                >
                  <g transform="translate(-70.573)">
                    <g>
                      <path
                        d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173,321.035a16.7,16.7,0,0,0,24.846,0c7.065-7.868,173-194.147,173-321.035C441.425,83.182,358.244,0,256,0Zm0,278.719a93.292,93.292,0,1,1,93.291-93.292A93.4,93.4,0,0,1,256,278.719Z"
                      />
                    </g>
                  </g>
                </svg>
                <!-- JSON icon location end -->
              </span>
              <span on:click={() => doCenter(auto.id)}>
                <!-- JSON icon location start -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 17.999 18.005"
                  class="icon"
                >
                  <path
                    d="M1,18.554,5.976,13.68A7.045,7.045,0,0,1,4.343,9.172,7.26,7.26,0,0,1,11.672,2,7.259,7.259,0,0,1,19,9.172a7.259,7.259,0,0,1-7.328,7.172,7.4,7.4,0,0,1-4.158-1.269l-5.032,4.93ZM6.438,9.187a5.252,5.252,0,0,0,10.5,0,5.252,5.252,0,0,0-10.5,0Z"
                    transform="translate(-1.001 -2)"
                    fill="currentColor"
                  />
                </svg>
                <!-- JSON icon location end -->
              </span>
            </span>
          </div>
          <!-- Toggle end -->
        </div>
      {/each}
    </div>
  </div>
</div>

<!-- Tab map notes end -->
<style lang="scss">
  .tlacitka {
    margin-left: auto;
    display: none;
    line-height: 1;

    & > span {
      cursor: pointer;
    }

    &.show {
      display: flex;
    }
  }
</style>
