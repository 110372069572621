<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();
  import Ikonka from "../../UI/Icon.svelte";
  export let open;
</script>

<!-- Tab description start -->
<div
  class="sidebar__tab sidebar__tab--description"
  data-tab="1"
  class:js-tab--open={open}>
  <div
    class="sidebar__close"
    on:click|preventDefault={() => dispatch('toggleSidebarTabPopisZnaciek')}>
    <Ikonka kod="012" />
  </div>
  <div class="sidebar__wrapper">
    <h6 class="sidebar__heading">Popis</h6>
    <ul>
      <li>
        <!-- JSON icon Dog start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 512 512">
          <g>
            <g>
              <path
                d="M256,252C165.761,252,81,373.649,81,447c0,36.449,41.729,65,95,65,20.036,0,33.468-4.429,45.319-8.336C232.165,500.088,241.532,497,256,497s23.835,3.088,34.681,6.664C302.532,507.571,315.964,512,336,512c53.271,0,95-28.551,95-65C431,373.649,346.238,252,256,252Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M166,0C123.944,0,91,50.514,91,115s32.944,115,75,115,75-50.514,75-115S208.056,0,166,0Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M346,0c-42.056,0-75,50.514-75,115s32.944,115,75,115,75-50.514,75-115S388.056,0,346,0Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M108.343,233.193C85.963,194.758,48.885,181,23.212,195.741-2.711,210.624-8.115,250,13.08,288.193,34.4,326.6,73.552,340,98.212,325.645,123.418,310.971,130.552,271.334,108.343,233.193Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M488.788,195.74c-25.673-14.741-62.751-.983-85.131,37.452-22.209,38.141-15.075,77.778,10.131,92.452,24.66,14.356,63.816.958,85.131-37.452C520.115,250,514.71,210.624,488.788,195.74Z" />
            </g>
          </g>
        </svg>
        <!-- JSON icon Dog end -->
        Psy - nezaplatené
      </li>

      <li>
        <!-- JSON icon Dog start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 512 512">
          <g>
            <g>
              <path
                d="M256,252C165.761,252,81,373.649,81,447c0,36.449,41.729,65,95,65,20.036,0,33.468-4.429,45.319-8.336C232.165,500.088,241.532,497,256,497s23.835,3.088,34.681,6.664C302.532,507.571,315.964,512,336,512c53.271,0,95-28.551,95-65C431,373.649,346.238,252,256,252Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M166,0C123.944,0,91,50.514,91,115s32.944,115,75,115,75-50.514,75-115S208.056,0,166,0Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M346,0c-42.056,0-75,50.514-75,115s32.944,115,75,115,75-50.514,75-115S388.056,0,346,0Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M108.343,233.193C85.963,194.758,48.885,181,23.212,195.741-2.711,210.624-8.115,250,13.08,288.193,34.4,326.6,73.552,340,98.212,325.645,123.418,310.971,130.552,271.334,108.343,233.193Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M488.788,195.74c-25.673-14.741-62.751-.983-85.131,37.452-22.209,38.141-15.075,77.778,10.131,92.452,24.66,14.356,63.816.958,85.131-37.452C520.115,250,514.71,210.624,488.788,195.74Z" />
            </g>
          </g>
        </svg>
        <!-- JSON icon Dog end -->
        Psy - zaplatené
      </li>

      <li>
        <!-- JSON icon Dog start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 512 512">
          <g>
            <g>
              <path
                d="M256,252C165.761,252,81,373.649,81,447c0,36.449,41.729,65,95,65,20.036,0,33.468-4.429,45.319-8.336C232.165,500.088,241.532,497,256,497s23.835,3.088,34.681,6.664C302.532,507.571,315.964,512,336,512c53.271,0,95-28.551,95-65C431,373.649,346.238,252,256,252Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M166,0C123.944,0,91,50.514,91,115s32.944,115,75,115,75-50.514,75-115S208.056,0,166,0Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M346,0c-42.056,0-75,50.514-75,115s32.944,115,75,115,75-50.514,75-115S388.056,0,346,0Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M108.343,233.193C85.963,194.758,48.885,181,23.212,195.741-2.711,210.624-8.115,250,13.08,288.193,34.4,326.6,73.552,340,98.212,325.645,123.418,310.971,130.552,271.334,108.343,233.193Z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M488.788,195.74c-25.673-14.741-62.751-.983-85.131,37.452-22.209,38.141-15.075,77.778,10.131,92.452,24.66,14.356,63.816.958,85.131-37.452C520.115,250,514.71,210.624,488.788,195.74Z" />
            </g>
          </g>
        </svg>
        <!-- JSON icon Dog end -->
        Psy - čiastočné zaplatené
      </li>

      <li>
        <!-- JSON icon Waste start -->
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 370 562"
          style="enable-background:new 0 0 370 562;"
          xml:space="preserve">
          <g transform="translate(5409 -1691)">
            <g transform="translate(-5509 1687)">
              <path
                d="M468,42h-56.8l-13.6-27.2C394.3,8.2,387.5,4,380.2,4h-190c-7.4,0-14.1,4.2-17.4,10.8L159.1,42H102c-1.1,0-2,0.9-2,2v35
                c0,1.1,0.9,2,2,2h27.5v272.3l18.4,147.1c0.6,4.9,4.8,8.5,9.6,8.5H169c0,0,0,0,0,0.1v56c0,0.5,0.5,1,1,1h37c0.6,0,1-0.5,1-1v-23.3
                c1.4,3.6,4.9,6.2,9,6.2h136.2c3.8,0,7.2-2.2,8.7-5.4V565c0,0.5,0.5,1,1,1h37c0.6,0,1-0.5,1-1v-56c0,0,0,0,0-0.1h11.8
                c4.9,0,9-3.7,9.7-8.5l18.4-147.1V81H468c1.1,0,2-0.9,2-2V44C470,42.9,469.1,42,468,42z
                M226.8,285.2v29.2h-19.5v-29.2
                c0-37.6,30.5-68.1,68.1-68.1h25.2l-22.3-22.3L292,181l45.8,45.8L292,272.6l-13.8-13.8l22.3-22.3h-25.2
                C248.6,236.5,226.8,258.3,226.8,285.2z
                M372.7,285.2c0,37.6-30.5,68.1-68.1,68.1h-25.2l22.3,22.3L288,389.3l-45.8-45.8l45.8-45.8
                l13.8,13.8l-22.3,22.3h25.2c26.9,0,48.6-21.8,48.7-48.6V256h19.5V285.2z" />
              <path
                d="M337.8,226.8L292,272.6l-13.8-13.8l22.3-22.3h-25.2c-26.9,0-48.6,21.8-48.7,48.6v29.2h-19.5v-29.2
                c0-37.6,30.5-68.1,68.1-68.1h25.2l-22.3-22.3L292,181L337.8,226.8z" />
              <path
                d="M372.7,256v29.2c0,37.6-30.5,68.1-68.1,68.1h-25.2l22.3,22.3L288,389.3l-45.8-45.8l45.8-45.8l13.8,13.8l-22.3,22.3h25.2
                c26.9,0,48.6-21.8,48.7-48.6V256H372.7z" />
              <g transform="translate(207.317 180.98)">
                <path
                  fill="#FFFFFF"
                  d="M130.5,45.8L84.7,91.6L71,77.8l22.3-22.3H68.1c-26.9,0-48.6,21.8-48.7,48.6v29.2H0v-29.2
                  c0-37.6,30.5-68.1,68.1-68.1h25.2L71,13.8L84.7,0L130.5,45.8z" />
                <path
                  fill="#FFFFFF"
                  d="M165.4,75v29.2c0,37.6-30.5,68.1-68.1,68.1H72.1l22.3,22.3l-13.8,13.8l-45.8-45.8l45.8-45.8l13.8,13.8
                  l-22.3,22.3h25.2c26.9,0,48.6-21.8,48.7-48.6V75H165.4z" />
              </g>
            </g>
          </g>
        </svg>
        <!-- JSON icon Waste end -->
        Komunálny odpad - zaplatené
      </li>

      <li>
        <!-- JSON icon Waste start -->
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 370 562"
          style="enable-background:new 0 0 370 562;"
          xml:space="preserve">
          <g transform="translate(5409 -1691)">
            <g transform="translate(-5509 1687)">
              <path
                d="M468,42h-56.8l-13.6-27.2C394.3,8.2,387.5,4,380.2,4h-190c-7.4,0-14.1,4.2-17.4,10.8L159.1,42H102c-1.1,0-2,0.9-2,2v35
                c0,1.1,0.9,2,2,2h27.5v272.3l18.4,147.1c0.6,4.9,4.8,8.5,9.6,8.5H169c0,0,0,0,0,0.1v56c0,0.5,0.5,1,1,1h37c0.6,0,1-0.5,1-1v-23.3
                c1.4,3.6,4.9,6.2,9,6.2h136.2c3.8,0,7.2-2.2,8.7-5.4V565c0,0.5,0.5,1,1,1h37c0.6,0,1-0.5,1-1v-56c0,0,0,0,0-0.1h11.8
                c4.9,0,9-3.7,9.7-8.5l18.4-147.1V81H468c1.1,0,2-0.9,2-2V44C470,42.9,469.1,42,468,42z
                M226.8,285.2v29.2h-19.5v-29.2
                c0-37.6,30.5-68.1,68.1-68.1h25.2l-22.3-22.3L292,181l45.8,45.8L292,272.6l-13.8-13.8l22.3-22.3h-25.2
                C248.6,236.5,226.8,258.3,226.8,285.2z
                M372.7,285.2c0,37.6-30.5,68.1-68.1,68.1h-25.2l22.3,22.3L288,389.3l-45.8-45.8l45.8-45.8
                l13.8,13.8l-22.3,22.3h25.2c26.9,0,48.6-21.8,48.7-48.6V256h19.5V285.2z" />
              <path
                d="M337.8,226.8L292,272.6l-13.8-13.8l22.3-22.3h-25.2c-26.9,0-48.6,21.8-48.7,48.6v29.2h-19.5v-29.2
                c0-37.6,30.5-68.1,68.1-68.1h25.2l-22.3-22.3L292,181L337.8,226.8z" />
              <path
                d="M372.7,256v29.2c0,37.6-30.5,68.1-68.1,68.1h-25.2l22.3,22.3L288,389.3l-45.8-45.8l45.8-45.8l13.8,13.8l-22.3,22.3h25.2
                c26.9,0,48.6-21.8,48.7-48.6V256H372.7z" />
              <g transform="translate(207.317 180.98)">
                <path
                  fill="#FFFFFF"
                  d="M130.5,45.8L84.7,91.6L71,77.8l22.3-22.3H68.1c-26.9,0-48.6,21.8-48.7,48.6v29.2H0v-29.2
                  c0-37.6,30.5-68.1,68.1-68.1h25.2L71,13.8L84.7,0L130.5,45.8z" />
                <path
                  fill="#FFFFFF"
                  d="M165.4,75v29.2c0,37.6-30.5,68.1-68.1,68.1H72.1l22.3,22.3l-13.8,13.8l-45.8-45.8l45.8-45.8l13.8,13.8
                  l-22.3,22.3h25.2c26.9,0,48.6-21.8,48.7-48.6V75H165.4z" />
              </g>
            </g>
          </g>
        </svg>
        <!-- JSON icon Waste end -->
        Komunálny odpad - nezaplatené
      </li>

      <li>
        <!-- JSON icon Waste start -->
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 370 562"
          style="enable-background:new 0 0 370 562;"
          xml:space="preserve">
          <g transform="translate(5409 -1691)">
            <g transform="translate(-5509 1687)">
              <path
                d="M468,42h-56.8l-13.6-27.2C394.3,8.2,387.5,4,380.2,4h-190c-7.4,0-14.1,4.2-17.4,10.8L159.1,42H102c-1.1,0-2,0.9-2,2v35
                c0,1.1,0.9,2,2,2h27.5v272.3l18.4,147.1c0.6,4.9,4.8,8.5,9.6,8.5H169c0,0,0,0,0,0.1v56c0,0.5,0.5,1,1,1h37c0.6,0,1-0.5,1-1v-23.3
                c1.4,3.6,4.9,6.2,9,6.2h136.2c3.8,0,7.2-2.2,8.7-5.4V565c0,0.5,0.5,1,1,1h37c0.6,0,1-0.5,1-1v-56c0,0,0,0,0-0.1h11.8
                c4.9,0,9-3.7,9.7-8.5l18.4-147.1V81H468c1.1,0,2-0.9,2-2V44C470,42.9,469.1,42,468,42z
                M226.8,285.2v29.2h-19.5v-29.2
                c0-37.6,30.5-68.1,68.1-68.1h25.2l-22.3-22.3L292,181l45.8,45.8L292,272.6l-13.8-13.8l22.3-22.3h-25.2
                C248.6,236.5,226.8,258.3,226.8,285.2z
                M372.7,285.2c0,37.6-30.5,68.1-68.1,68.1h-25.2l22.3,22.3L288,389.3l-45.8-45.8l45.8-45.8
                l13.8,13.8l-22.3,22.3h25.2c26.9,0,48.6-21.8,48.7-48.6V256h19.5V285.2z" />
              <path
                d="M337.8,226.8L292,272.6l-13.8-13.8l22.3-22.3h-25.2c-26.9,0-48.6,21.8-48.7,48.6v29.2h-19.5v-29.2
                c0-37.6,30.5-68.1,68.1-68.1h25.2l-22.3-22.3L292,181L337.8,226.8z" />
              <path
                d="M372.7,256v29.2c0,37.6-30.5,68.1-68.1,68.1h-25.2l22.3,22.3L288,389.3l-45.8-45.8l45.8-45.8l13.8,13.8l-22.3,22.3h25.2
                c26.9,0,48.6-21.8,48.7-48.6V256H372.7z" />
              <g transform="translate(207.317 180.98)">
                <path
                  fill="#FFFFFF"
                  d="M130.5,45.8L84.7,91.6L71,77.8l22.3-22.3H68.1c-26.9,0-48.6,21.8-48.7,48.6v29.2H0v-29.2
                  c0-37.6,30.5-68.1,68.1-68.1h25.2L71,13.8L84.7,0L130.5,45.8z" />
                <path
                  fill="#FFFFFF"
                  d="M165.4,75v29.2c0,37.6-30.5,68.1-68.1,68.1H72.1l22.3,22.3l-13.8,13.8l-45.8-45.8l45.8-45.8l13.8,13.8
                  l-22.3,22.3h25.2c26.9,0,48.6-21.8,48.7-48.6V75H165.4z" />
              </g>
            </g>
          </g>
        </svg>
        <!-- JSON icon Waste end -->
        Komunálny odpad - čiastočné zaplatené
      </li>

      <li>
        <!-- JSON icon square start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 403 403">
          <rect width="100%" height="100%" />
        </svg>
        <!-- JSON icon square end -->
        Obecný majetok UO - Vo vlastníctve obce UO
      </li>

      <li>
        <!-- JSON icon square start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 403 403">
          <rect width="100%" height="100%" />
        </svg>
        <!-- JSON icon square end -->
        SPF - UO - Vlastník
      </li>

      <li>
        <!-- JSON icon square start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 403 403">
          <rect width="100%" height="100%" />
        </svg>
        <!-- JSON icon square end -->
        SPF - UO - Správca
      </li>

      <li>
        <!-- JSON icon square start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 403 403">
          <rect width="100%" height="100%" />
        </svg>
        <!-- JSON icon square end -->
        SPF - VKM - Vlastník
      </li>

      <li>
        <!-- JSON icon square start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 403 403">
          <rect width="100%" height="100%" />
        </svg>
        <!-- JSON icon square end -->
        SPF - VKM - Správca
      </li>

      <li>
        <!-- JSON icon square start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 403 403">
          <rect width="100%" height="100%" />
        </svg>
        <!-- JSON icon square end -->
        Dane VKM - zaplatené
      </li>

      <li>
        <!-- JSON icon square start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 403 403">
          <rect width="100%" height="100%" />
        </svg>
        <!-- JSON icon square end -->
        Dane VKM - čiastočne zaplatené
      </li>

      <li>
        <!-- JSON icon square start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 403 403">
          <rect width="100%" height="100%" />
        </svg>
        <!-- JSON icon square end -->
        Dane VKM - nezaplatené
      </li>

      <li>
        <!-- JSON icon square start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 403 403">
          <rect width="100%" height="100%" />
        </svg>
        <!-- JSON icon square end -->
        Dane VKM - nevyrubené
      </li>

      <li>
        <!-- JSON icon square start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 403 403">
          <rect width="100%" height="100%" />
        </svg>
        <!-- JSON icon square end -->
        Dane VKM - bez dane
      </li>

      <li>
        <!-- JSON icon square dashed start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 114.423 114.42">
          <path
            d="M114.42,0H0V114.42H114.423L114.42,0ZM3.572,31.284H27.708V55.42H3.572Zm27.712,0H55.42V55.42H31.284Zm27.712,0h24.14V55.42H59Zm0-3.575V3.575h24.14V27.708Zm-3.575,0H31.284V3.575H55.42V27.708Zm-27.712,0H3.572V3.575H27.708ZM3.572,59H27.708v24.14H3.572Zm27.712,0H55.42v24.14H31.284ZM59,59h24.14v24.14H59Zm27.716,0h24.137v24.14H86.711Zm0-3.572V31.284h24.137V55.42H86.711Zm0-27.716V3.575h24.137V27.708ZM3.572,110.844V86.711H27.708v24.133Zm27.712,0V86.711H55.42v24.133Zm27.712,0V86.711h24.14v24.133Zm27.716,0V86.711h24.137v24.133Z" />
        </svg>

        <!-- JSON icon square dashed end -->
        Dane UO - zaplatené
      </li>

      <li>
        <!-- JSON icon square dashed start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 114.423 114.42">
          <path
            d="M114.42,0H0V114.42H114.423L114.42,0ZM3.572,31.284H27.708V55.42H3.572Zm27.712,0H55.42V55.42H31.284Zm27.712,0h24.14V55.42H59Zm0-3.575V3.575h24.14V27.708Zm-3.575,0H31.284V3.575H55.42V27.708Zm-27.712,0H3.572V3.575H27.708ZM3.572,59H27.708v24.14H3.572Zm27.712,0H55.42v24.14H31.284ZM59,59h24.14v24.14H59Zm27.716,0h24.137v24.14H86.711Zm0-3.572V31.284h24.137V55.42H86.711Zm0-27.716V3.575h24.137V27.708ZM3.572,110.844V86.711H27.708v24.133Zm27.712,0V86.711H55.42v24.133Zm27.712,0V86.711h24.14v24.133Zm27.716,0V86.711h24.137v24.133Z" />
        </svg>

        <!-- JSON icon square dashed end -->
        Dane UO - čiastočne zaplatené
      </li>

      <li>
        <!-- JSON icon square dashed start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 114.423 114.42">
          <path
            d="M114.42,0H0V114.42H114.423L114.42,0ZM3.572,31.284H27.708V55.42H3.572Zm27.712,0H55.42V55.42H31.284Zm27.712,0h24.14V55.42H59Zm0-3.575V3.575h24.14V27.708Zm-3.575,0H31.284V3.575H55.42V27.708Zm-27.712,0H3.572V3.575H27.708ZM3.572,59H27.708v24.14H3.572Zm27.712,0H55.42v24.14H31.284ZM59,59h24.14v24.14H59Zm27.716,0h24.137v24.14H86.711Zm0-3.572V31.284h24.137V55.42H86.711Zm0-27.716V3.575h24.137V27.708ZM3.572,110.844V86.711H27.708v24.133Zm27.712,0V86.711H55.42v24.133Zm27.712,0V86.711h24.14v24.133Zm27.716,0V86.711h24.137v24.133Z" />
        </svg>

        <!-- JSON icon square dashed end -->
        Dane UO - nezaplatené
      </li>

      <li>
        <!-- JSON icon square dashed start -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          fill="currentColor"
          viewBox="0 0 114.423 114.42">
          <path
            d="M114.42,0H0V114.42H114.423L114.42,0ZM3.572,31.284H27.708V55.42H3.572Zm27.712,0H55.42V55.42H31.284Zm27.712,0h24.14V55.42H59Zm0-3.575V3.575h24.14V27.708Zm-3.575,0H31.284V3.575H55.42V27.708Zm-27.712,0H3.572V3.575H27.708ZM3.572,59H27.708v24.14H3.572Zm27.712,0H55.42v24.14H31.284ZM59,59h24.14v24.14H59Zm27.716,0h24.137v24.14H86.711Zm0-3.572V31.284h24.137V55.42H86.711Zm0-27.716V3.575h24.137V27.708ZM3.572,110.844V86.711H27.708v24.133Zm27.712,0V86.711H55.42v24.133Zm27.712,0V86.711h24.14v24.133Zm27.716,0V86.711h24.137v24.133Z" />
        </svg>

        <!-- JSON icon square dashed end -->
        Dane UO - bez dane
      </li>
    </ul>
  </div>
</div>
<!-- Tab description end -->
