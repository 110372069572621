<script>
  import { afterUpdate } from "svelte";
  import { ziskajDataIkonky } from "../../helpers/ikonka";

  export let kod;
  let error = false;
  let attrs, ikonka;

  // nacitam si prvotne data ikonky
  let ikonkaData = ziskajDataIkonky(kod, $$props);

  if ( ikonkaData === undefined ) {
    error = true;
  } else {
    attrs = ikonkaData.attrs;
    ikonka = ikonkaData.ikonka;
  }

  // pripravene pre zmenu ikonky dynamicky
  afterUpdate(() => {
    ikonkaData = ziskajDataIkonky(kod, $$props);
    if ( ikonkaData === undefined ) {
      error = true;
    } else {
      attrs = ikonkaData.attrs;
      ikonka = ikonkaData.ikonka;
    }
  });
</script>

{#if !error}
  <svg xmlns="http://www.w3.org/2000/svg" {...attrs.svg}>
    {@html ikonka.body}
  </svg>
{/if}
