<script>
  import { createEventDispatcher, onDestroy } from "svelte";
  import obecConfig from "../../../configs/obec.config";
  import { zobrazDataNaMape } from "../../map/mapbox";
  import { mapStore } from "../../../stores/map.store";
  import { userStore } from "../../../stores/user.store";
  import {
    vratHtmlKodFarbyPodlaTypu,
    vratVrstvyMapyPodlaTypu,
  } from "../../../helpers/config";
  import { popupEvents } from "../../../helpers/map";
  import { editaciaPoznamky, ziskajPoznamky } from "../../../api/poznamky";
  const dispatch = createEventDispatcher();
  import Ikonka from "../../UI/Icon.svelte";
  import { showHideSelect, onDocumentClick } from "../../../helpers/poznamky";
  import { tempStore } from "../../../stores/temp.store.js";
  export let open;
  let color, name, description, pub;
  let formularIsOpen = false;
  let layer;
  let messages = { errors: [], successes: [] };
  let form;
  let tlacitkoOdoslat = "Uložiť";
  let poznamkaId;
  const map = $mapStore.mapbox.getMap();
  let poznamkaFormular;
  let aditionalData;
  let myPrivate;
  let myPublic;
  let source_id_parent;
  let popup;

  const unsubscribe = tempStore.subscribe((value) => {
    poznamkaFormular = value.poznamkaFormular || false;
    if (poznamkaFormular) {
      layer = poznamkaFormular.geojson;
      poznamkaId = poznamkaFormular.poznamka_id || null;
      source_id_parent = poznamkaFormular.source_id_parent || null;
      popup = poznamkaFormular.popup || null;

      color = poznamkaFormular.color;
      name = poznamkaFormular.title;
      pub = poznamkaFormular.public;
      description = poznamkaFormular.note_desc;

      formularIsOpen = true;
    } else {
      formularIsOpen = false;
    }
  });

  onDestroy(unsubscribe);

  async function upravPoznamku(e) {
    tlacitkoOdoslat = "Ukladanie .";
    messages = { errors: [], successes: [] };
    let count = 0;
    let interval = setInterval(() => {
      if (count % 3 === 0) {
        count = 1;
      } else {
        count++;
      }
      tlacitkoOdoslat = "Ukladanie " + ".".repeat(count);
    }, 300);
    // const csrfResponse = await getCsrf();

    const formData = new FormData(form);
    const data = {};
    for (let field of formData) {
      const [key, value] = field;
      data[key] = value;
    }
    data.id = poznamkaId;

    const layerData = JSON.parse(data.layer);
    const popupHTML = layerData.features[0].properties.description;
    const parser = new DOMParser();
    const doc = parser.parseFromString(popupHTML, "text/html");
    const footerButtons = doc.body.querySelector(".footer-buttons");
    footerButtons.querySelector(".pridat-poznamku").classList.remove("active");
    footerButtons.querySelector(".zavriet-okno").classList.remove("active");
    footerButtons.querySelector(".zobrazit-poznamku").classList.add("active");
    doc.body.querySelector(".tab-poznamka").innerHTML = `<strong>${
      data.name
    }</strong><p>${data.description.replaceAll("\n", "<br />")}</p>`;

    layerData.features[0].properties.description = doc.body.innerHTML;

    layerData.features[0].properties.title = data.name;
    layerData.features[0].properties.public = data.public;
    layerData.features[0].properties.color = data.color;
    layerData.features[0].properties.note_desc = data.description;

    data.layer = JSON.stringify(layerData);

    const loginResponse = await editaciaPoznamky(data);
    if (loginResponse.success) {
      // form.reset();
      color = "";
      name = "";
      pub = "";
      description = "";

      setTimeout(() => {
        messages = { errors: [], successes: [] };
        tempStore.update((t) => {
          delete t.poznamkaFormular;
          return t;
        });
      }, 5000);

      odstranVrstvuZMapy(source_id_parent);

      if (data.public === "1") {
        if (myPublic.checked) {
          myPublic.click();
        }
        setTimeout(() => {
          myPublic.click();
        }, 500);
      } else {
        if (myPrivate.checked) {
          myPrivate.click();
        }
        setTimeout(() => {
          myPrivate.click();
        }, 500);
      }
      if (popup) {
        popup.remove();
      }
    }
    messages.errors = loginResponse.errors;
    messages.successes = loginResponse.successes;
    clearInterval(interval);
    tlacitkoOdoslat = "Uložiť";
  }

  async function zobrazPoznamky(e) {
    const filter = e.target.value;
    const show = e.target.checked;
    const u = userStore.getUser();

    if (show) {
      const data = await ziskajPoznamky(filter);
      if (data.success) {
        data.data.forEach((poznamka) => {
          let source_id;
          if (poznamka.public && poznamka.user_id === u.id) {
            source_id = `poznamka_my-public_${poznamka.id}`;
          } else if (poznamka.public) {
            source_id = `poznamka_other-public_${poznamka.id}`;
          } else if (poznamka.public === false) {
            source_id = `poznamka_my-private_${poznamka.id}`;
          }
          const typ = "point";

          const popupHTML = poznamka.layer.features[0].properties.description;
          const parser = new DOMParser();
          const doc = parser.parseFromString(popupHTML, "text/html");
          const footerButtons = doc.body.querySelector(".footer-buttons");
          footerButtons
            .querySelector(".zmazat-poznamku")
            .setAttribute("data-poznamka-id", poznamka.id);
          footerButtons
            .querySelector(".zmazat-poznamku")
            .setAttribute("data-source-id", source_id);
          footerButtons
            .querySelector(".upravit-poznamku")
            .setAttribute("data-poznamka-id", poznamka.id);
          poznamka.layer.features[0].properties.description =
            doc.body.innerHTML;

          map.addSource(source_id, {
            type: "geojson",
            data: poznamka.layer,
            generateId: true,
            maxzoom: obecConfig.layers.maxzoom,
          });

          // vytvorim layer pre danu vrstvu
          const l = map.addLayer({
            id: `${source_id}_0`,
            type: "symbol",
            source: source_id,
            layout: {
              "icon-image": "marker",
              "icon-anchor": "bottom",
              "icon-offset": [0, 5],
              "icon-allow-overlap": true,
              "icon-size": 0.4,
              "text-field": ["get", "title"],
              "text-variable-anchor": ["top", "bottom", "left", "right"],
              "text-radial-offset": 0.5,
              "text-justify": "auto",
              "text-font": ["Open Sans Bold"],
            },
            paint: {
              "text-color": ["get", "color"],
              "text-halo-color": "#ffffff",
              "text-halo-width": 3,
              "icon-color": ["get", "color"],
              "icon-halo-color": ["get", "color"],
              "icon-halo-width": 1,
            },
          });

          popupEvents(map, source_id, 0);
        });
      }
    } else {
      const zdroje = map.getStyle().sources;
      for (const [key, value] of Object.entries(zdroje)) {
        if (key.startsWith(`poznamka_${filter}_`)) {
          odstranVrstvuZMapy(key);
        }
      }
    }
  }

  /**
   * Odstranim vrstvu z mapy aj z pamate a tym padom aj z navigacneho okienka
   * @param source_id
   */
  function odstranVrstvuZMapy(source_id) {
    // prechadzam vsetky layery, nie sources, lebo najprv treba vymazat layer
    map.getStyle().layers.forEach(function (layer) {
      // ak ma layer spravny source, tak ho vymazem
      if (source_id === layer.source) {
        map.removeLayer(layer.id);
      }
    });

    // ak existuje source ktory mam vymazat
    if (map.getSource(source_id)) {
      map.removeSource(source_id);

      /* // zmazem informaciu aj z pamate stavu uzivatela
      const u = userStore.getUser();
      let stav = get(u.mapa.stav);

      if (get(stav[source_id]) !== undefined) {
        delete stav[source_id];
      }

      userStore.updateUser(u); */
    }
  }

  function zrusit(e) {
    e.preventDefault();
    form.reset();
    messages = { errors: [], successes: [] };
    tempStore.update((t) => {
      delete t.poznamkaFormular;
      return t;
    });
  }
</script>

<!-- Tab map notes start -->
<div
  class="sidebar__tab sidebar__tab--map"
  data-tab="3"
  class:js-tab--open={open}
>
  <div
    class="sidebar__close"
    on:click|preventDefault={() => dispatch("toggleSidebarTabPoznamky")}
  >
    <Ikonka kod="012" />
  </div>
  <div class="sidebar__wrapper">
    <div class="poznamky-vyber">
      <h6 class="sidebar__heading">Zobraziť poznámky v mape</h6>
      <div class="input-group">
        <!-- Toggle start -->
        <div class="toggle">
          <input
            type="checkbox"
            id="toggle1"
            value="my-private"
            on:change={(e) => zobrazPoznamky(e)}
            bind:this={myPrivate}
          />
          <label class="toggle__icon" for="toggle1" />
          <label class="toggle__text" for="toggle1">Moje súkromné</label>
        </div>
        <!-- Toggle end -->
      </div>

      <div class="input-group">
        <!-- Toggle start -->
        <div class="toggle">
          <input
            type="checkbox"
            id="toggle2"
            value="my-public"
            on:change={(e) => zobrazPoznamky(e)}
            bind:this={myPublic}
          />
          <label class="toggle__icon" for="toggle2" />
          <label class="toggle__text" for="toggle2">Moje verené</label>
        </div>
        <!-- Toggle end -->
      </div>

      <div class="input-group">
        <!-- Toggle start -->
        <div class="toggle">
          <input
            type="checkbox"
            id="toggle3"
            value="other-public"
            on:change={(e) => zobrazPoznamky(e)}
          />
          <label class="toggle__icon" for="toggle3" />
          <label class="toggle__text" for="toggle3">Verejné poznámky</label>
        </div>
        <!-- Toggle end -->
      </div>
    </div>

    <div class="poznamky-formular" class:formularIsOpen>
      <form on:submit|preventDefault={upravPoznamku} bind:this={form}>
        <h6 class="sidebar__heading">Upraviť vybranú poznámku</h6>
        <div class="input-group">
          <label class="input__label" for="note-name">Názov</label>
          <input
            type="text"
            id="note-name"
            class="input input-margin"
            name="name"
            bind:value={name}
          />
        </div>

        <div class="input-group">
          <label class="input__label" for="note">Poznámka</label>
          <textarea
            name="description"
            id="note"
            class="input input-margin"
            cols="30"
            rows="10"
            bind:value={description}
          />
        </div>

        <div class="input-group">
          <label class="input__label" for="note-color">Farba</label>
          <input
            type="color"
            id="note-color"
            class="input input-margin"
            bind:value={color}
            name="color"
          />
        </div>

        <!-- <div class="input-group">
          <label class="input__label">Typ poznámky</label>
          <div class="select input__group" on:click={showHideSelect}>
            <div class="select__header">
              <div class="select__label">
                <span class="select__text">Vybrať typ</span>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="select__icon"
                fill="currentColor"
                width="15px"
                height="10px"
                viewBox="0 0 256 158.186"
              >
                <g transform="translate(0 -48.907)">
                  <g>
                    <path
                      d="M225.813,48.907,128,146.72,30.187,48.907,0,79.093l128,128,128-128Z"
                    />
                  </g>
                </g>
              </svg>
            </div>

            <div class="select__body">
              <ul class="select__list">
                <li>Stavebné</li>

                <li>Územné</li>

                <li>Adresy</li>

                <li>Iné</li>
              </ul>
            </div>
          </div>
        </div> -->

        <div class="input-group">
          <label class="checkbox">
            <input
              name="public"
              bind:group={pub}
              type="radio"
              id="public"
              value="1"
            />
            <label for="public">Verejné</label>
          </label>
        </div>

        <div class="input-group">
          <label class="checkbox">
            <input
              name="public"
              bind:group={pub}
              type="radio"
              id="private"
              value="0"
            />
            <label for="private">Súkromné</label>
          </label>
        </div>

        <div class="input-group input-group--cta">
          <input type="hidden" name="layer" bind:value={layer} />
          <input type="hidden" name="poznamka_id" bind:value={poznamkaId} />
          <div id="response">
            {#if messages.errors || messages.successes}
              {#each messages.errors as error}
                <div class="error">{error}</div>
              {/each}
              {#each messages.successes as success}
                <div class="success">{success}</div>
              {/each}
            {/if}
          </div>
          <input
            type="submit"
            class="btn btn--block btn--mb"
            value={tlacitkoOdoslat}
          />
          <button
            type="submit"
            class="btn btn--secondary btn--block"
            on:click={zrusit}>Zrušiť</button
          >
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Tab map notes end -->

<svelte:window on:click={onDocumentClick} />

<style lang="scss">
  .poznamky-vyber {
    margin-bottom: 50px;
  }
  .poznamky-formular {
    display: none;

    &.formularIsOpen {
      display: block;
    }
  }

  #response {
    margin-bottom: 20px;
    text-align: center;
    font-size: 17px;
    .error {
      color: #c24141;
    }
    .success {
      color: #008000;
    }
  }
</style>
