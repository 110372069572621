import Api from "../helpers/api";

export const vratZoznamAut = async () => {
  try {
    const response = await Api.get(`/api/v1/webdispatch/webdispatch`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
