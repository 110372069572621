import Api from "../helpers/api";

export const vratHladanyVyraz = async (typ, hladanyVyraz) => {
  try {
    const response = await Api.get(
      `/api/v1/smartmap/search/${typ}?term=${hladanyVyraz}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
