import { zmazPoznamku } from "../api/poznamky";

export function showHideSelect(event) {
  let open = false;
  // ak klikam na input v otvorenom selecte, tak ho samozrejme nechcem zavriet
  if (
    event.target.closest(".select").classList.contains("js-select--open") &&
    event.target.classList.contains("input")
  ) {
    return;
  }
  // ak kliknuty select nebol otvoreny, tak ho potrebujem otvorit ale az po tom,
  // co zavriem vsetky selecty
  if (!event.target.closest(".select").classList.contains("js-select--open")) {
    open = true;
  }

  // zavriem vsetky selecty
  document.querySelectorAll(".select").forEach((element) => {
    element.classList.remove("js-select--open");
  });

  // otvorim kliknuty select, ak predtym nebol otvoreny a klikol som nanho aby som ho zavrel
  // nastavim focus na vyhladavacie policko
  if (open) {
    event.target.closest(".select").classList.add("js-select--open");
    let select__search = event.target
      .closest(".select")
      .querySelector(".select__search");
    let search_input = event.target
      .closest(".select")
      .querySelector("#vyberVyhladavania");
    if (select__search !== null) {
      select__search.focus();
    } else if (search_input !== null) {
      document.querySelector("#search").focus();
    }
  }
}

export function onDocumentClick(e) {
  if (e.target.closest(".select") === null) {
    // zavriem vsetky selecty
    document.querySelectorAll(".select").forEach((element) => {
      element.classList.remove("js-select--open");
    });
  }
}

export async function zmazatPoznamku(map, source_id, poznamka_id) {
  const response = await zmazPoznamku(poznamka_id);
  if (response.success) {
    map.getStyle().layers.forEach(function (layer) {
      // ak ma layer spravny source, tak ho vymazem
      if (source_id === layer.source) {
        map.removeLayer(layer.id);
      }
    });

    // ak existuje source ktory mam vymazat
    if (map.getSource(source_id)) {
      map.removeSource(source_id);
    }
    return true;
  } else {
    return false;
  }
}
